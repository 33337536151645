import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function ClientesHistoricoQtd() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    const [grafico2, setGrafico2]=useState(false)
    const [grafico3, setGrafico3]=useState(false)
    const [paislist, setPaisList]=useState(false)

    const [tipo, setTipo]=useState('')

    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')
  

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);

 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))

    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });

    setClientes(clientesemDuplicados.sort())
    setLoading(false)
  },[projetos])

  const lista = ()=>{
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
      let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
        return s.indexOf(v) === i;
      });
  
      setClientes(clientesemDuplicados.sort())
      setPaisList(true)
      
  }


    const handleChart = ()=>{

    setGrafico(false)
    setGrafico2(false)
    setGrafico3(false)
        
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];
   
   const Num2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente1)){return val}})
    const L2002 = Num2002.length
    const Num2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente1)){return val}})
    const L2003 = Num2003.length
    const Num2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente1)){return val}})
    const L2004 = Num2004.length
    const Num2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente1)){return val}})
    const L2005 = Num2005.length
    const Num2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente1)){return val}})
    const L2006 = Num2006.length
    const Num2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente1)){return val}})
    const L2007 = Num2007.length
    const Num2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente1)){return val}})
    const L2008 = Num2008.length
    const Num2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente1)){return val}})
    const L2009 = Num2009.length
    const Num2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente1)){return val}})
    const L2010 = Num2010.length
    const Num2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente1)){return val}})
    const L2011 = Num2011.length
    const Num2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente1)){return val}})
    const L2012 = Num2012.length
    const Num2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente1)){return val}})
    const L2013 = Num2013.length
    const Num2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente1)){return val}})
    const L2014 = Num2014.length
    const Num2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente1)){return val}})
    const L2015 = Num2015.length
    const Num2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente1)){return val}})
    const L2016 = Num2016.length
    const Num2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente1)){return val}})
    const L2017 = Num2017.length
    const Num2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente1)){return val}})
    const L2018 = Num2018.length
    const Num2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente1)){return val}})
    const L2019 = Num2019.length
    const Num2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente1)){return val}})
    const L2020 = Num2020.length
    const Num2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente1)){return val}})
    const L2021 = Num2021.length
    const Num2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente1)){return val}})
    const L2022 = Num2022.length
    const Num2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente1)){return val}})
    const L2023 = Num2023.length
    const Num2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const L2024 = Num2024.length
    const Num2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const L2025 = Num2025.length

   
       setProjetData({
           labels: anos,
           datasets:[
            {
             label:`${cliente1} Projetos`,
             data: [L2002, L2003, L2004, L2005, L2006, L2007, L2008, L2009, L2010, L2011,L2012, L2013,L2014, L2015, L2016, L2017, L2018, L2019, L2020, L2021, L2022, L2023, L2024, L2025],
             fill: true,
             borderColor: '#FF5400',
             backgroundColor: 'rgba(255,84,0,.5)',
           }
    
          ]
         })
         setGrafico(true);
     }
    const handleChart2 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
            
        const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];
       
       const Num2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente1)){return val}})
        const L2002 = Num2002.length
        
        const Num20021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente2)){return val}})
        const L20021 = Num20021.length

        const Num2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente1)){return val}})
        const L2003 = Num2003.length

        const Num20031 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente2)){return val}})
        const L20031 = Num20031.length

        const Num2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente1)){return val}})
        const L2004 = Num2004.length

        const Num20041 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente2)){return val}})
        const L20041 = Num20041.length

        const Num2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente1)){return val}})
        const L2005 = Num2005.length

        const Num20051 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente2)){return val}})
        const L20051 = Num20051.length

        const Num2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente1)){return val}})
        const L2006 = Num2006.length

        const Num20061 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente2)){return val}})
        const L20061 = Num20061.length

        const Num2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente1)){return val}})
        const L2007 = Num2007.length

        const Num20071 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente2)){return val}})
        const L20071 = Num20071.length

        const Num2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente1)){return val}})
        const L2008 = Num2008.length

        const Num20081 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente2)){return val}})
        const L20081 = Num20081.length

        const Num2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente1)){return val}})
        const L2009 = Num2009.length

        const Num20091 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente2)){return val}})
        const L20091 = Num20091.length

        const Num2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente1)){return val}})
        const L2010 = Num2010.length

        const Num20101 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente2)){return val}})
        const L20101 = Num20101.length

        const Num2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente1)){return val}})
        const L2011 = Num2011.length

        const Num20111 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente2)){return val}})
        const L20111 = Num20111.length


        const Num2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente1)){return val}})
        const L2012 = Num2012.length

        const Num20121 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente2)){return val}})
        const L20121 = Num20121.length

        const Num2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente1)){return val}})
        const L2013 = Num2013.length

        const Num20131 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente2)){return val}})
        const L20131 = Num20131.length

        const Num2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente1)){return val}})
        const L2014 = Num2014.length

        const Num20141 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente2)){return val}})
        const L20141 = Num20141.length

        const Num2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente1)){return val}})
        const L2015 = Num2015.length

        const Num20151 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente2)){return val}})
        const L20151 = Num20151.length

        const Num2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente1)){return val}})
        const L2016 = Num2016.length

        const Num20161 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente2)){return val}})
        const L20161 = Num20161.length

        const Num2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente1)){return val}})
        const L2017 = Num2017.length

        const Num20171 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente2)){return val}})
        const L20171 = Num20171.length

        const Num2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente1)){return val}})
        const L2018 = Num2018.length

        const Num20181 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente2)){return val}})
        const L20181 = Num20181.length

        const Num2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente1)){return val}})
        const L2019 = Num2019.length

        const Num20191 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente2)){return val}})
        const L20191 = Num20191.length

        const Num2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente1)){return val}})
        const L2020 = Num2020.length

        const Num20201 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente2)){return val}})
        const L20201 = Num20201.length

        const Num2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente1)){return val}})
        const L2021 = Num2021.length

        const Num20211 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente2)){return val}})
        const L20211 = Num20211.length

        const Num2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente1)){return val}})
        const L2022 = Num2022.length

        const Num20221 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente2)){return val}})
        const L20221 = Num20221.length

        const Num2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente1)){return val}})
        const L2023 = Num2023.length

        const Num20231 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente2)){return val}})
        const L20231 = Num20231.length

        const Num2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
        const L2024 = Num2024.length

        const Num20241 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const L20241 = Num20241.length


        const Num2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const L2025 = Num2025.length
     const Num20251 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const L20251 = Num20251.length
    
    
       
           setProjetData({
               labels: anos,
               datasets:[
                {
                 label:`${cliente1} Projetos`,
                 data: [L2002, L2003, L2004, L2005, L2006, L2007, L2008, L2009, L2010, L2011,L2012, L2013,L2014, L2015, L2016, L2017, L2018, L2019, L2020, L2021, L2022, L2023, L2024,L2025],
                 fill: false,
                 borderColor: '#FF9900',
                backgroundColor: 'rgba(255,153,0,.5)',
               },
               {
                label:`${cliente2} Projetos`,
                data: [L20021, L20031, L20041, L20051, L20061, L20071, L20081, L20091, L20101, L20111,L20121, L20131,L20141, L20151, L20161, L20171, L20181, L20191, L20201, L20211, L20221, L20231, L20241,L20251],
                fill: false,
                borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
              }
       
        
              ]
             })
             setGrafico2(true);
         }

    const handleChart3 = ()=>{

            setGrafico(false)
            setGrafico2(false)
            setGrafico3(false)
                
            const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];
           
           const Num2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente1)){return val}})
            const L2002 = Num2002.length
            
            const Num20021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente2)){return val}})
            const L20021 = Num20021.length

            const Num20022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002') && val.data.projClient.includes(cliente3)){return val}})
            const L20022 = Num20022.length
    
            const Num2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente1)){return val}})
            const L2003 = Num2003.length
    
            const Num20031 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente2)){return val}})
            const L20031 = Num20031.length

            const Num20032 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projClient.includes(cliente3)){return val}})
            const L20032 = Num20032.length
    
            const Num2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente1)){return val}})
            const L2004 = Num2004.length
    
            const Num20041 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente2)){return val}})
            const L20041 = Num20041.length

            const Num20042 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004') && val.data.projClient.includes(cliente3)){return val}})
            const L20042 = Num20042.length
    
            const Num2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente1)){return val}})
            const L2005 = Num2005.length
    
            const Num20051 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente2)){return val}})
            const L20051 = Num20051.length

            const Num20052 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005') && val.data.projClient.includes(cliente3)){return val}})
            const L20052 = Num20052.length
    
            const Num2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente1)){return val}})
            const L2006 = Num2006.length
    
            const Num20061 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente2)){return val}})
            const L20061 = Num20061.length

            const Num20062 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006') && val.data.projClient.includes(cliente3)){return val}})
            const L20062 = Num20062.length
    
            const Num2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente1)){return val}})
            const L2007 = Num2007.length
    
            const Num20071 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente2)){return val}})
            const L20071 = Num20071.length

            const Num20072 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007') && val.data.projClient.includes(cliente3)){return val}})
            const L20072 = Num20072.length
    
            const Num2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente1)){return val}})
            const L2008 = Num2008.length
    
            const Num20081 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente2)){return val}})
            const L20081 = Num20081.length

            const Num20082 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008') && val.data.projClient.includes(cliente3)){return val}})
            const L20082 = Num20082.length
    
            const Num2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente1)){return val}})
            const L2009 = Num2009.length
    
            const Num20091 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente2)){return val}})
            const L20091 = Num20091.length

            const Num20092 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009') && val.data.projClient.includes(cliente3)){return val}})
            const L20092 = Num20092.length
    
            const Num2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente1)){return val}})
            const L2010 = Num2010.length
    
            const Num20101 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente2)){return val}})
            const L20101 = Num20101.length

            const Num20102 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010') && val.data.projClient.includes(cliente3)){return val}})
            const L20102 = Num20102.length
    
            const Num2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente1)){return val}})
            const L2011 = Num2011.length
    
            const Num20111 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente2)){return val}})
            const L20111 = Num20111.length

            const Num20112 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011') && val.data.projClient.includes(cliente3)){return val}})
            const L20112 = Num20112.length
    
    
            const Num2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente1)){return val}})
            const L2012 = Num2012.length
    
            const Num20121 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente2)){return val}})
            const L20121 = Num20121.length

            const Num20122 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012') && val.data.projClient.includes(cliente3)){return val}})
            const L20122 = Num20122.length
    
            const Num2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente1)){return val}})
            const L2013 = Num2013.length
    
            const Num20131 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente2)){return val}})
            const L20131 = Num20131.length

            const Num20132 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013') && val.data.projClient.includes(cliente3)){return val}})
            const L20132 = Num20132.length
    
            const Num2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente1)){return val}})
            const L2014 = Num2014.length
    
            const Num20141 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente2)){return val}})
            const L20141 = Num20141.length

            const Num20142 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014') && val.data.projClient.includes(cliente3)){return val}})
            const L20142 = Num20142.length
    
            const Num2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente1)){return val}})
            const L2015 = Num2015.length
    
            const Num20151 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente2)){return val}})
            const L20151 = Num20151.length

            const Num20152 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015') && val.data.projClient.includes(cliente3)){return val}})
            const L20152 = Num20152.length
    
            const Num2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente1)){return val}})
            const L2016 = Num2016.length
    
            const Num20161 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente2)){return val}})
            const L20161 = Num20161.length

            const Num20162 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016') && val.data.projClient.includes(cliente3)){return val}})
            const L20162 = Num20162.length
    
            const Num2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente1)){return val}})
            const L2017 = Num2017.length
    
            const Num20171 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente2)){return val}})
            const L20171 = Num20171.length

            const Num20172 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017') && val.data.projClient.includes(cliente3)){return val}})
            const L20172 = Num20172.length
    
            const Num2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente1)){return val}})
            const L2018 = Num2018.length
    
            const Num20181 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente2)){return val}})
            const L20181 = Num20181.length

            const Num20182 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018') && val.data.projClient.includes(cliente3)){return val}})
            const L20182 = Num20182.length
    
            const Num2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente1)){return val}})
            const L2019 = Num2019.length
    
            const Num20191 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente2)){return val}})
            const L20191 = Num20191.length

            const Num20192 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019') && val.data.projClient.includes(cliente3)){return val}})
            const L20192 = Num20192.length
    
            const Num2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente1)){return val}})
            const L2020 = Num2020.length
    
            const Num20201 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente2)){return val}})
            const L20201 = Num20201.length

            const Num20202 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020') && val.data.projClient.includes(cliente3)){return val}})
            const L20202 = Num20202.length
    
            const Num2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente1)){return val}})
            const L2021 = Num2021.length
    
            const Num20211 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente2)){return val}})
            const L20211 = Num20211.length

            const Num20212 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021') && val.data.projClient.includes(cliente3)){return val}})
            const L20212 = Num20212.length
    
            const Num2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente1)){return val}})
            const L2022 = Num2022.length
    
            const Num20221 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente2)){return val}})
            const L20221 = Num20221.length

            const Num20222 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022') && val.data.projClient.includes(cliente3)){return val}})
            const L20222 = Num20222.length
    
            const Num2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente1)){return val}})
            const L2023 = Num2023.length
    
            const Num20231 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente2)){return val}})
            const L20231 = Num20231.length

            const Num20232 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023') && val.data.projClient.includes(cliente3)){return val}})
            const L20232 = Num20232.length
    
            const Num2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
            const L2024 = Num2024.length
    
            const Num20241 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
            const L20241 = Num20241.length

            const Num20242 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
            const L20242 = Num20242.length


        const Num2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
        const L2025 = Num2025.length
        const Num20251 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const L20251 = Num20251.length
        const Num20252 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
            const L20252 = Num20252.length
        
        
           
               setProjetData({
                   labels: anos,
                   datasets:[
                    {
                     label:`${cliente1} Projetos`,
                     data: [L2002, L2003, L2004, L2005, L2006, L2007, L2008, L2009, L2010, L2011,L2012, L2013,L2014, L2015, L2016, L2017, L2018, L2019, L2020, L2021, L2022, L2023, L2024,L2025],
                     fill: false,
                     borderColor: '#FF9900',
                    backgroundColor: 'rgba(255,153,0,.5)',
                   },
                   {
                    label:`${cliente2} Projetos`,
                    data: [L20021, L20031, L20041, L20051, L20061, L20071, L20081, L20091, L20101, L20111,L20121, L20131,L20141, L20151, L20161, L20171, L20181, L20191, L20201, L20211, L20221, L20231, L20241,L20251],
                    fill: false,
                    borderColor: '#315F30',
                    backgroundColor: 'rgba(49,95,48,.5)',
                  },
                  {
                    label:`${cliente3} Projetos`,
                    data: [L20022, L20032, L20042, L20052, L20062, L20072, L20082, L20092, L20102, L20112,L20122, L20132,L20142, L20152, L20162, L20172, L20182, L20192, L20202, L20212, L20222, L20232, L20242, L20252],
                    fill: false,
                    borderColor: '#11D5EB',
               backgroundColor: 'rgba(17,213,235,.5)',
                  }
           
           
            
                  ]
                 })
                 setGrafico3(true);
             }


  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Clientes - Quantitativo de Projetos Histórico</div>
        {
          !paislist &&
          <div><button onClick={lista}>Gerar Lista</button></div>
          }
          {paislist &&
            <>
        <div>
            <p>
                Tipo:
                <select onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setGrafico2(false); setGrafico3(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                <option value="">Selecione</option>
                <option value="um">Um Cliente</option>
                <option value="dois">Dois Clientes</option>
                <option value="tres">Três Clientes</option>
            </select>
            </p>
        </div>
        {tipo === 'um' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'dois' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico2(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico2(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'tres' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico3(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico3(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico3(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>

        }
        </>
         }
       
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

    {grafico2 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico3 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
     
      
      </>     
    }
</div>
  )
}

export default ClientesHistoricoQtd