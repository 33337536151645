import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function ClientesFaturamentoHistorico() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('um')
    const [paislist, setPaisList]=useState(false)
  
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')
  
  
    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    setClientes(clientesemDuplicados.sort())
    setLoading(false)
  },[projetos])
  
  const lista = ()=>{
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    setClientes(clientesemDuplicados.sort())
    setPaisList(true)
  }
  const handleChart =()=>{
    setGrafico(false)
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024', '2025'  ];
  
    
    //2002 reais
    const projs2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20021R = projs2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projs2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //2002 dolar
    const projs20021D = projs2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projs2002.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

     //2003 reais
    const projs2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20031R = projs2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projs2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //2003 dolar
    const projs20031D = projs2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projs2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //2004 reais
    const projs2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20041R = projs2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projs2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //2004 dolar
    const projs20041D = projs2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projs2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //2005 reais
    const projs2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20051R = projs2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projs2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //2005 dolar
    const projs20051D = projs2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projs2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //2006 reais
    const projs2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20061R = projs2006.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061RLenght = projs20061R.length
    var projs20061RNumeros = [];
    for (var r1 = 0; r1 < projs20061RLenght; r1++)
    projs20061RNumeros.push(Number(projs20061R[r1]))
    const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062R = projs2006.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062RLenght = projs20062R.length
    var projs20062RNumeros = [];
    for (var r2 = 0; r2 < projs20062RLenght; r2++)
    projs20062RNumeros.push(Number(projs20062R[r2]))
    const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2006 = (projs2006Reais1+projs2006Reais2)
    
    //2006 dolar
    const projs20061D = projs2006.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061DLenght = projs20061D.length
    var projs20061DNumeros = [];
    for (var d1 = 0; d1 < projs20061DLenght; d1++)
    projs20061DNumeros.push(Number(projs20061D[d1]))
    const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062D = projs2006.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062DLenght = projs20062D.length
    var projs20062DNumeros = [];
    for (var d2 = 0; d2 < projs20062DLenght; d2++)
    projs20062DNumeros.push(Number(projs20062D[d2]))
    const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)

    //2007 reais
    const projs2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20071R = projs2007.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071RLenght = projs20071R.length
    var projs20071RNumeros = [];
    for (var r1 = 0; r1 < projs20071RLenght; r1++)
    projs20071RNumeros.push(Number(projs20071R[r1]))
    const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072R = projs2007.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072RLenght = projs20072R.length
    var projs20072RNumeros = [];
    for (var r2 = 0; r2 < projs20072RLenght; r2++)
    projs20072RNumeros.push(Number(projs20072R[r2]))
    const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2007 = (projs2007Reais1+projs2007Reais2)
    
    //2007 dolar
    const projs20071D = projs2007.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071DLenght = projs20071D.length
    var projs20071DNumeros = [];
    for (var d1 = 0; d1 < projs20071DLenght; d1++)
    projs20071DNumeros.push(Number(projs20071D[d1]))
    const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072D = projs2007.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072DLenght = projs20072D.length
    var projs20072DNumeros = [];
    for (var d2 = 0; d2 < projs20072DLenght; d2++)
    projs20072DNumeros.push(Number(projs20072D[d2]))
    const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

    //2008 reais
    const projs2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20081R = projs2008.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081RLenght = projs20081R.length
    var projs20081RNumeros = [];
    for (var r1 = 0; r1 < projs20081RLenght; r1++)
    projs20081RNumeros.push(Number(projs20081R[r1]))
    const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082R = projs2008.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082RLenght = projs20082R.length
    var projs20082RNumeros = [];
    for (var r2 = 0; r2 < projs20082RLenght; r2++)
    projs20082RNumeros.push(Number(projs20082R[r2]))
    const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2008 = (projs2008Reais1+projs2008Reais2)
    
    //2008 dolar
    const projs20081D = projs2008.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081DLenght = projs20081D.length
    var projs20081DNumeros = [];
    for (var d1 = 0; d1 < projs20081DLenght; d1++)
    projs20081DNumeros.push(Number(projs20081D[d1]))
    const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082D = projs2008.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082DLenght = projs20082D.length
    var projs20082DNumeros = [];
    for (var d2 = 0; d2 < projs20082DLenght; d2++)
    projs20082DNumeros.push(Number(projs20082D[d2]))
    const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)

    //2009 reais
    const projs2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20091R = projs2009.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091RLenght = projs20091R.length
    var projs20091RNumeros = [];
    for (var r1 = 0; r1 < projs20091RLenght; r1++)
    projs20091RNumeros.push(Number(projs20091R[r1]))
    const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092R = projs2009.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092RLenght = projs20092R.length
    var projs20092RNumeros = [];
    for (var r2 = 0; r2 < projs20092RLenght; r2++)
    projs20092RNumeros.push(Number(projs20092R[r2]))
    const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2009 = (projs2009Reais1+projs2009Reais2)
    
    //2009 dolar
    const projs20091D = projs2009.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091DLenght = projs20091D.length
    var projs20091DNumeros = [];
    for (var d1 = 0; d1 < projs20091DLenght; d1++)
    projs20091DNumeros.push(Number(projs20091D[d1]))
    const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092D = projs2009.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092DLenght = projs20092D.length
    var projs20092DNumeros = [];
    for (var d2 = 0; d2 < projs20092DLenght; d2++)
    projs20092DNumeros.push(Number(projs20092D[d2]))
    const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)

    //2010 reais
    const projs2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20101R = projs2010.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101RLenght = projs20101R.length
    var projs20101RNumeros = [];
    for (var r1 = 0; r1 < projs20101RLenght; r1++)
    projs20101RNumeros.push(Number(projs20101R[r1]))
    const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102R = projs2010.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102RLenght = projs20102R.length
    var projs20102RNumeros = [];
    for (var r2 = 0; r2 < projs20102RLenght; r2++)
    projs20102RNumeros.push(Number(projs20102R[r2]))
    const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2010 = (projs2010Reais1+projs2010Reais2)
    
    //2010 dolar
    const projs20101D = projs2010.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101DLenght = projs20101D.length
    var projs20101DNumeros = [];
    for (var d1 = 0; d1 < projs20101DLenght; d1++)
    projs20101DNumeros.push(Number(projs20101D[d1]))
    const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102D = projs2010.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102DLenght = projs20102D.length
    var projs20102DNumeros = [];
    for (var d2 = 0; d2 < projs20102DLenght; d2++)
    projs20102DNumeros.push(Number(projs20102D[d2]))
    const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

    //2011 reais
    const projs2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20111R = projs2011.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111RLenght = projs20111R.length
    var projs20111RNumeros = [];
    for (var r1 = 0; r1 < projs20111RLenght; r1++)
    projs20111RNumeros.push(Number(projs20111R[r1]))
    const projs2011Reais1 = projs20111RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112R = projs2011.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112RLenght = projs20112R.length
    var projs20112RNumeros = [];
    for (var r2 = 0; r2 < projs20112RLenght; r2++)
    projs20112RNumeros.push(Number(projs20112R[r2]))
    const projs2011Reais2 = projs20112RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2011 = (projs2011Reais1+projs2011Reais2)
    
    //2011 dolar
    const projs20111D = projs2011.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111DLenght = projs20111D.length
    var projs20111DNumeros = [];
    for (var d1 = 0; d1 < projs20111DLenght; d1++)
    projs20111DNumeros.push(Number(projs20111D[d1]))
    const projs2011Dolar1 = projs20111DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112D = projs2011.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112DLenght = projs20112D.length
    var projs20112DNumeros = [];
    for (var d2 = 0; d2 < projs20112DLenght; d2++)
    projs20112DNumeros.push(Number(projs20112D[d2]))
    const projs2011Dolar2 = projs20112DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2011 = (projs2011Dolar1+projs2011Dolar2)

    //2012 reais
    const projs2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20121R = projs2012.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121RLenght = projs20121R.length
    var projs20121RNumeros = [];
    for (var r1 = 0; r1 < projs20121RLenght; r1++)
    projs20121RNumeros.push(Number(projs20121R[r1]))
    const projs2012Reais1 = projs20121RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122R = projs2012.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122RLenght = projs20122R.length
    var projs20122RNumeros = [];
    for (var r2 = 0; r2 < projs20122RLenght; r2++)
    projs20122RNumeros.push(Number(projs20122R[r2]))
    const projs2012Reais2 = projs20122RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2012 = (projs2012Reais1+projs2012Reais2)
    
    //2012 dolar
    const projs20121D = projs2012.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121DLenght = projs20121D.length
    var projs20121DNumeros = [];
    for (var d1 = 0; d1 < projs20121DLenght; d1++)
    projs20121DNumeros.push(Number(projs20121D[d1]))
    const projs2012Dolar1 = projs20121DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122D = projs2012.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122DLenght = projs20122D.length
    var projs20122DNumeros = [];
    for (var d2 = 0; d2 < projs20122DLenght; d2++)
    projs20122DNumeros.push(Number(projs20122D[d2]))
    const projs2012Dolar2 = projs20122DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2012 = (projs2012Dolar1+projs2012Dolar2)
    
    //2013 reais
    const projs2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20131R = projs2013.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131RLenght = projs20131R.length
    var projs20131RNumeros = [];
    for (var r1 = 0; r1 < projs20131RLenght; r1++)
    projs20131RNumeros.push(Number(projs20131R[r1]))
    const projs2013Reais1 = projs20131RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132R = projs2013.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132RLenght = projs20132R.length
    var projs20132RNumeros = [];
    for (var r2 = 0; r2 < projs20132RLenght; r2++)
    projs20132RNumeros.push(Number(projs20132R[r2]))
    const projs2013Reais2 = projs20132RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2013 = (projs2013Reais1+projs2013Reais2)
    
    //2013 dolar
    const projs20131D = projs2013.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131DLenght = projs20131D.length
    var projs20131DNumeros = [];
    for (var d1 = 0; d1 < projs20131DLenght; d1++)
    projs20131DNumeros.push(Number(projs20131D[d1]))
    const projs2013Dolar1 = projs20131DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132D = projs2013.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132DLenght = projs20132D.length
    var projs20132DNumeros = [];
    for (var d2 = 0; d2 < projs20132DLenght; d2++)
    projs20132DNumeros.push(Number(projs20132D[d2]))
    const projs2013Dolar2 = projs20132DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2013 = (projs2013Dolar1+projs2013Dolar2)
    
    //2014 reais
    const projs2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20141R = projs2014.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141RLenght = projs20141R.length
    var projs20141RNumeros = [];
    for (var r1 = 0; r1 < projs20141RLenght; r1++)
    projs20141RNumeros.push(Number(projs20141R[r1]))
    const projs2014Reais1 = projs20141RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142R = projs2014.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142RLenght = projs20142R.length
    var projs20142RNumeros = [];
    for (var r2 = 0; r2 < projs20142RLenght; r2++)
    projs20142RNumeros.push(Number(projs20142R[r2]))
    const projs2014Reais2 = projs20142RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2014 = (projs2014Reais1+projs2014Reais2)
    
    //2014 dolar
    const projs20141D = projs2014.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141DLenght = projs20141D.length
    var projs20141DNumeros = [];
    for (var d1 = 0; d1 < projs20141DLenght; d1++)
    projs20141DNumeros.push(Number(projs20141D[d1]))
    const projs2014Dolar1 = projs20141DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142D = projs2014.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142DLenght = projs20142D.length
    var projs20142DNumeros = [];
    for (var d2 = 0; d2 < projs20142DLenght; d2++)
    projs20142DNumeros.push(Number(projs20142D[d2]))
    const projs2014Dolar2 = projs20142DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2014 = (projs2014Dolar1+projs2014Dolar2)
    
    
    //2015 reais
    const projs2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20151R = projs2015.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151RLenght = projs20151R.length
    var projs20151RNumeros = [];
    for (var r1 = 0; r1 < projs20151RLenght; r1++)
    projs20151RNumeros.push(Number(projs20151R[r1]))
    const projs2015Reais1 = projs20151RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152R = projs2015.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152RLenght = projs20152R.length
    var projs20152RNumeros = [];
    for (var r2 = 0; r2 < projs20152RLenght; r2++)
    projs20152RNumeros.push(Number(projs20152R[r2]))
    const projs2015Reais2 = projs20152RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2015 = (projs2015Reais1+projs2015Reais2)
    
    //2015 dolar
    const projs20151D = projs2015.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151DLenght = projs20151D.length
    var projs20151DNumeros = [];
    for (var d1 = 0; d1 < projs20151DLenght; d1++)
    projs20151DNumeros.push(Number(projs20151D[d1]))
    const projs2015Dolar1 = projs20151DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152D = projs2015.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152DLenght = projs20152D.length
    var projs20152DNumeros = [];
    for (var d2 = 0; d2 < projs20152DLenght; d2++)
    projs20152DNumeros.push(Number(projs20152D[d2]))
    const projs2015Dolar2 = projs20152DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2015 = (projs2015Dolar1+projs2015Dolar2)
    
    //2016 reais
    const projs2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20161R = projs2016.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161RLenght = projs20161R.length
    var projs20161RNumeros = [];
    for (var r1 = 0; r1 < projs20161RLenght; r1++)
    projs20161RNumeros.push(Number(projs20161R[r1]))
    const projs2016Reais1 = projs20161RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162R = projs2016.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162RLenght = projs20162R.length
    var projs20162RNumeros = [];
    for (var r2 = 0; r2 < projs20162RLenght; r2++)
    projs20162RNumeros.push(Number(projs20162R[r2]))
    const projs2016Reais2 = projs20162RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2016 = (projs2016Reais1+projs2016Reais2)
    
    //2016 dolar
    const projs20161D = projs2016.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161DLenght = projs20161D.length
    var projs20161DNumeros = [];
    for (var d1 = 0; d1 < projs20161DLenght; d1++)
    projs20161DNumeros.push(Number(projs20161D[d1]))
    const projs2016Dolar1 = projs20161DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162D = projs2016.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162DLenght = projs20162D.length
    var projs20162DNumeros = [];
    for (var d2 = 0; d2 < projs20162DLenght; d2++)
    projs20162DNumeros.push(Number(projs20162D[d2]))
    const projs2016Dolar2 = projs20162DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2016 = (projs2016Dolar1+projs2016Dolar2)
    
    //2017 reais
    const projs2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20171R = projs2017.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171RLenght = projs20171R.length
    var projs20171RNumeros = [];
    for (var r1 = 0; r1 < projs20171RLenght; r1++)
    projs20171RNumeros.push(Number(projs20171R[r1]))
    const projs2017Reais1 = projs20171RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172R = projs2017.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172RLenght = projs20172R.length
    var projs20172RNumeros = [];
    for (var r2 = 0; r2 < projs20172RLenght; r2++)
    projs20172RNumeros.push(Number(projs20172R[r2]))
    const projs2017Reais2 = projs20172RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2017 = (projs2017Reais1+projs2017Reais2)
    
    //2017 dolar
    const projs20171D = projs2017.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171DLenght = projs20171D.length
    var projs20171DNumeros = [];
    for (var d1 = 0; d1 < projs20171DLenght; d1++)
    projs20171DNumeros.push(Number(projs20171D[d1]))
    const projs2017Dolar1 = projs20171DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172D = projs2017.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172DLenght = projs20172D.length
    var projs20172DNumeros = [];
    for (var d2 = 0; d2 < projs20172DLenght; d2++)
    projs20172DNumeros.push(Number(projs20172D[d2]))
    const projs2017Dolar2 = projs20172DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2017 = (projs2017Dolar1+projs2017Dolar2)
    
    //2018 reais
    const projs2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20181R = projs2018.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181RLenght = projs20181R.length
    var projs20181RNumeros = [];
    for (var r1 = 0; r1 < projs20181RLenght; r1++)
    projs20181RNumeros.push(Number(projs20181R[r1]))
    const projs2018Reais1 = projs20181RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182R = projs2018.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182RLenght = projs20182R.length
    var projs20182RNumeros = [];
    for (var r2 = 0; r2 < projs20182RLenght; r2++)
    projs20182RNumeros.push(Number(projs20182R[r2]))
    const projs2018Reais2 = projs20182RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2018 = (projs2018Reais1+projs2018Reais2)
    
    //2018 dolar
    const projs20181D = projs2018.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181DLenght = projs20181D.length
    var projs20181DNumeros = [];
    for (var d1 = 0; d1 < projs20181DLenght; d1++)
    projs20181DNumeros.push(Number(projs20181D[d1]))
    const projs2018Dolar1 = projs20181DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182D = projs2018.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182DLenght = projs20182D.length
    var projs20182DNumeros = [];
    for (var d2 = 0; d2 < projs20182DLenght; d2++)
    projs20182DNumeros.push(Number(projs20182D[d2]))
    const projs2018Dolar2 = projs20182DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2018 = (projs2018Dolar1+projs2018Dolar2)
    
    //2019 reais
    const projs2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20191R = projs2019.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191RLenght = projs20191R.length
    var projs20191RNumeros = [];
    for (var r1 = 0; r1 < projs20191RLenght; r1++)
    projs20191RNumeros.push(Number(projs20191R[r1]))
    const projs2019Reais1 = projs20191RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192R = projs2019.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192RLenght = projs20192R.length
    var projs20192RNumeros = [];
    for (var r2 = 0; r2 < projs20192RLenght; r2++)
    projs20192RNumeros.push(Number(projs20192R[r2]))
    const projs2019Reais2 = projs20192RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2019 = (projs2019Reais1+projs2019Reais2)
    
    //2019 dolar
    const projs20191D = projs2019.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191DLenght = projs20191D.length
    var projs20191DNumeros = [];
    for (var d1 = 0; d1 < projs20191DLenght; d1++)
    projs20191DNumeros.push(Number(projs20191D[d1]))
    const projs2019Dolar1 = projs20191DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192D = projs2019.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192DLenght = projs20192D.length
    var projs20192DNumeros = [];
    for (var d2 = 0; d2 < projs20192DLenght; d2++)
    projs20192DNumeros.push(Number(projs20192D[d2]))
    const projs2019Dolar2 = projs20192DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2019 = (projs2019Dolar1+projs2019Dolar2)
    
    //2020 reais
    const projs2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20201R = projs2020.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201RLenght = projs20201R.length
    var projs20201RNumeros = [];
    for (var r1 = 0; r1 < projs20201RLenght; r1++)
    projs20201RNumeros.push(Number(projs20201R[r1]))
    const projs2020Reais1 = projs20201RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202R = projs2020.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202RLenght = projs20202R.length
    var projs20202RNumeros = [];
    for (var r2 = 0; r2 < projs20202RLenght; r2++)
    projs20202RNumeros.push(Number(projs20202R[r2]))
    const projs2020Reais2 = projs20202RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2020 = (projs2020Reais1+projs2020Reais2)
    
    //2020 dolar
    const projs20201D = projs2020.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201DLenght = projs20201D.length
    var projs20201DNumeros = [];
    for (var d1 = 0; d1 < projs20201DLenght; d1++)
    projs20201DNumeros.push(Number(projs20201D[d1]))
    const projs2020Dolar1 = projs20201DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202D = projs2020.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202DLenght = projs20202D.length
    var projs20202DNumeros = [];
    for (var d2 = 0; d2 < projs20202DLenght; d2++)
    projs20202DNumeros.push(Number(projs20202D[d2]))
    const projs2020Dolar2 = projs20202DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2020 = (projs2020Dolar1+projs2020Dolar2)
    
    //2021 reais
    const projs2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20211R = projs2021.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211RLenght = projs20211R.length
    var projs20211RNumeros = [];
    for (var r1 = 0; r1 < projs20211RLenght; r1++)
    projs20211RNumeros.push(Number(projs20211R[r1]))
    const projs2021Reais1 = projs20211RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212R = projs2021.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212RLenght = projs20212R.length
    var projs20212RNumeros = [];
    for (var r2 = 0; r2 < projs20212RLenght; r2++)
    projs20212RNumeros.push(Number(projs20212R[r2]))
    const projs2021Reais2 = projs20212RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2021 = (projs2021Reais1+projs2021Reais2)
    
    //2021 dolar
    const projs20211D = projs2021.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211DLenght = projs20211D.length
    var projs20211DNumeros = [];
    for (var d1 = 0; d1 < projs20211DLenght; d1++)
    projs20211DNumeros.push(Number(projs20211D[d1]))
    const projs2021Dolar1 = projs20211DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212D = projs2021.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212DLenght = projs20212D.length
    var projs20212DNumeros = [];
    for (var d2 = 0; d2 < projs20212DLenght; d2++)
    projs20212DNumeros.push(Number(projs20212D[d2]))
    const projs2021Dolar2 = projs20212DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2021 = (projs2021Dolar1+projs2021Dolar2)
    
    //2022 reais
    const projs2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20221R = projs2022.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221RLenght = projs20221R.length
    var projs20221RNumeros = [];
    for (var r1 = 0; r1 < projs20221RLenght; r1++)
    projs20221RNumeros.push(Number(projs20221R[r1]))
    const projs2022Reais1 = projs20221RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222R = projs2022.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222RLenght = projs20222R.length
    var projs20222RNumeros = [];
    for (var r2 = 0; r2 < projs20222RLenght; r2++)
    projs20222RNumeros.push(Number(projs20222R[r2]))
    const projs2022Reais2 = projs20222RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2022 = projs2022Reais1+projs2022Reais2
    
    //2022 dolar
    const projs20221D = projs2022.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221DLenght = projs20221D.length
    var projs20221DNumeros = [];
    for (var d1 = 0; d1 < projs20221DLenght; d1++)
    projs20221DNumeros.push(Number(projs20221D[d1]))
    const projs2022Dolar1 = projs20221DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222D = projs2022.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222DLenght = projs20222D.length
    var projs20222DNumeros = [];
    for (var d2 = 0; d2 < projs20222DLenght; d2++)
    projs20222DNumeros.push(Number(projs20222D[d2]))
    const projs2022Dolar2 = projs20222DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2022 = projs2022Dolar1+projs2022Dolar2
    
    //2023 reais
    const projs2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20231R = projs2023.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231RLenght = projs20231R.length
    var projs20231RNumeros = [];
    for (var r1 = 0; r1 < projs20231RLenght; r1++)
    projs20231RNumeros.push(Number(projs20231R[r1]))
    const projs2023Reais1 = projs20231RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232R = projs2023.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232RLenght = projs20232R.length
    var projs20232RNumeros = [];
    for (var r2 = 0; r2 < projs20232RLenght; r2++)
    projs20232RNumeros.push(Number(projs20232R[r2]))
    const projs2023Reais2 = projs20232RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2023 = projs2023Reais1+projs2023Reais2
    
    //2023 dolar
    const projs20231D = projs2023.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231DLenght = projs20231D.length
    var projs20231DNumeros = [];
    for (var d1 = 0; d1 < projs20231DLenght; d1++)
    projs20231DNumeros.push(Number(projs20231D[d1]))
    const projs2023Dolar1 = projs20231DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232D = projs2023.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232DLenght = projs20232D.length
    var projs20232DNumeros = [];
    for (var d2 = 0; d2 < projs20232DLenght; d2++)
    projs20232DNumeros.push(Number(projs20232D[d2]))
    const projs2023Dolar2 = projs20232DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2023 = projs2023Dolar1+projs2023Dolar2
    
    
    //2024 reais
    const projs2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20241R = projs2024.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241RLenght = projs20241R.length
    var projs20241RNumeros = [];
    for (var r1 = 0; r1 < projs20241RLenght; r1++)
    projs20241RNumeros.push(Number(projs20241R[r1]))
    const projs2024Reais1 = projs20241RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242R = projs2024.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242RLenght = projs20242R.length
    var projs20242RNumeros = [];
    for (var r2 = 0; r2 < projs20242RLenght; r2++)
    projs20242RNumeros.push(Number(projs20242R[r2]))
    const projs2024Reais2 = projs20242RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2024 = projs2024Reais1+projs2024Reais2
    
    //2024 dolar
    const projs20241D = projs2024.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241DLenght = projs20241D.length
    var projs20241DNumeros = [];
    for (var d1 = 0; d1 < projs20241DLenght; d1++)
    projs20241DNumeros.push(Number(projs20241D[d1]))
    const projs2024Dolar1 = projs20241DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242D = projs2024.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242DLenght = projs20242D.length
    var projs20242DNumeros = [];
    for (var d2 = 0; d2 < projs20242DLenght; d2++)
    projs20242DNumeros.push(Number(projs20242D[d2]))
    const projs2024Dolar2 = projs20242DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2024 = projs2024Dolar1+projs2024Dolar2

    //2025 reais
const projs2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
const projs20251R = projs2025.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251RLenght = projs20251R.length
var projs20251RNumeros = [];
for (var r1 = 0; r1 < projs20251RLenght; r1++)
projs20251RNumeros.push(Number(projs20251R[r1]))
const projs2025Reais1 = projs20251RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252R = projs2025.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252RLenght = projs20252R.length
var projs20252RNumeros = [];
for (var r2 = 0; r2 < projs20252RLenght; r2++)
projs20252RNumeros.push(Number(projs20252R[r2]))
const projs2025Reais2 = projs20252RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025 = projs2025Reais1+projs2025Reais2

//2025 dolar
const projs20251D = projs2025.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251DLenght = projs20251D.length
var projs20251DNumeros = [];
for (var d1 = 0; d1 < projs20251DLenght; d1++)
projs20251DNumeros.push(Number(projs20251D[d1]))
const projs2025Dolar1 = projs20251DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252D = projs2025.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252DLenght = projs20252D.length
var projs20252DNumeros = [];
for (var d2 = 0; d2 < projs20252DLenght; d2++)
projs20252DNumeros.push(Number(projs20252D[d2]))
const projs2025Dolar2 = projs20252DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025 = projs2025Dolar1+projs2025Dolar2
    
    
  
    setProjetData({
      labels: anos,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [Reais2002,Reais2003,Reais2004,Reais2005,Reais2006,Reais2007,Reais2008,Reais2009,Reais2010,Reais2011,Reais2012,Reais2013,Reais2014,Reais2015,Reais2016,Reais2017,Reais2018,Reais2019,Reais2020,Reais2021,Reais2022,Reais2023,Reais2024, Reais2025],
        fill: true,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [Dolar2002,Dolar2003,Dolar2004,Dolar2005,Dolar2006,Dolar2007,Dolar2008,Dolar2009,Dolar2010,Dolar2011,Dolar2012,Dolar2013,Dolar2014,Dolar2015,Dolar2016,Dolar2017,Dolar2018,Dolar2019,Dolar2020,Dolar2021,Dolar2022,Dolar2023,Dolar2024, Dolar2025],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2024' ];
  
    
    //2002 reais
    const projs2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20021R = projs2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projs2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //2002 dolar
    const projs20021D = projs2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projs2002.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

     //2003 reais
    const projs2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20031R = projs2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projs2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //2003 dolar
    const projs20031D = projs2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projs2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //2004 reais
    const projs2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20041R = projs2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projs2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //2004 dolar
    const projs20041D = projs2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projs2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //2005 reais
    const projs2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20051R = projs2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projs2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //2005 dolar
    const projs20051D = projs2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projs2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //2006 reais
    const projs2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20061R = projs2006.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061RLenght = projs20061R.length
    var projs20061RNumeros = [];
    for (var r1 = 0; r1 < projs20061RLenght; r1++)
    projs20061RNumeros.push(Number(projs20061R[r1]))
    const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062R = projs2006.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062RLenght = projs20062R.length
    var projs20062RNumeros = [];
    for (var r2 = 0; r2 < projs20062RLenght; r2++)
    projs20062RNumeros.push(Number(projs20062R[r2]))
    const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2006 = (projs2006Reais1+projs2006Reais2)
    
    //2006 dolar
    const projs20061D = projs2006.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061DLenght = projs20061D.length
    var projs20061DNumeros = [];
    for (var d1 = 0; d1 < projs20061DLenght; d1++)
    projs20061DNumeros.push(Number(projs20061D[d1]))
    const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062D = projs2006.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062DLenght = projs20062D.length
    var projs20062DNumeros = [];
    for (var d2 = 0; d2 < projs20062DLenght; d2++)
    projs20062DNumeros.push(Number(projs20062D[d2]))
    const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)

    //2007 reais
    const projs2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20071R = projs2007.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071RLenght = projs20071R.length
    var projs20071RNumeros = [];
    for (var r1 = 0; r1 < projs20071RLenght; r1++)
    projs20071RNumeros.push(Number(projs20071R[r1]))
    const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072R = projs2007.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072RLenght = projs20072R.length
    var projs20072RNumeros = [];
    for (var r2 = 0; r2 < projs20072RLenght; r2++)
    projs20072RNumeros.push(Number(projs20072R[r2]))
    const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2007 = (projs2007Reais1+projs2007Reais2)
    
    //2007 dolar
    const projs20071D = projs2007.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071DLenght = projs20071D.length
    var projs20071DNumeros = [];
    for (var d1 = 0; d1 < projs20071DLenght; d1++)
    projs20071DNumeros.push(Number(projs20071D[d1]))
    const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072D = projs2007.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072DLenght = projs20072D.length
    var projs20072DNumeros = [];
    for (var d2 = 0; d2 < projs20072DLenght; d2++)
    projs20072DNumeros.push(Number(projs20072D[d2]))
    const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

    //2008 reais
    const projs2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20081R = projs2008.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081RLenght = projs20081R.length
    var projs20081RNumeros = [];
    for (var r1 = 0; r1 < projs20081RLenght; r1++)
    projs20081RNumeros.push(Number(projs20081R[r1]))
    const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082R = projs2008.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082RLenght = projs20082R.length
    var projs20082RNumeros = [];
    for (var r2 = 0; r2 < projs20082RLenght; r2++)
    projs20082RNumeros.push(Number(projs20082R[r2]))
    const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2008 = (projs2008Reais1+projs2008Reais2)
    
    //2008 dolar
    const projs20081D = projs2008.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081DLenght = projs20081D.length
    var projs20081DNumeros = [];
    for (var d1 = 0; d1 < projs20081DLenght; d1++)
    projs20081DNumeros.push(Number(projs20081D[d1]))
    const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082D = projs2008.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082DLenght = projs20082D.length
    var projs20082DNumeros = [];
    for (var d2 = 0; d2 < projs20082DLenght; d2++)
    projs20082DNumeros.push(Number(projs20082D[d2]))
    const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)

    //2009 reais
    const projs2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20091R = projs2009.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091RLenght = projs20091R.length
    var projs20091RNumeros = [];
    for (var r1 = 0; r1 < projs20091RLenght; r1++)
    projs20091RNumeros.push(Number(projs20091R[r1]))
    const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092R = projs2009.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092RLenght = projs20092R.length
    var projs20092RNumeros = [];
    for (var r2 = 0; r2 < projs20092RLenght; r2++)
    projs20092RNumeros.push(Number(projs20092R[r2]))
    const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2009 = (projs2009Reais1+projs2009Reais2)
    
    //2009 dolar
    const projs20091D = projs2009.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091DLenght = projs20091D.length
    var projs20091DNumeros = [];
    for (var d1 = 0; d1 < projs20091DLenght; d1++)
    projs20091DNumeros.push(Number(projs20091D[d1]))
    const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092D = projs2009.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092DLenght = projs20092D.length
    var projs20092DNumeros = [];
    for (var d2 = 0; d2 < projs20092DLenght; d2++)
    projs20092DNumeros.push(Number(projs20092D[d2]))
    const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)

    //2010 reais
    const projs2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20101R = projs2010.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101RLenght = projs20101R.length
    var projs20101RNumeros = [];
    for (var r1 = 0; r1 < projs20101RLenght; r1++)
    projs20101RNumeros.push(Number(projs20101R[r1]))
    const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102R = projs2010.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102RLenght = projs20102R.length
    var projs20102RNumeros = [];
    for (var r2 = 0; r2 < projs20102RLenght; r2++)
    projs20102RNumeros.push(Number(projs20102R[r2]))
    const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2010 = (projs2010Reais1+projs2010Reais2)
    
    //2010 dolar
    const projs20101D = projs2010.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101DLenght = projs20101D.length
    var projs20101DNumeros = [];
    for (var d1 = 0; d1 < projs20101DLenght; d1++)
    projs20101DNumeros.push(Number(projs20101D[d1]))
    const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102D = projs2010.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102DLenght = projs20102D.length
    var projs20102DNumeros = [];
    for (var d2 = 0; d2 < projs20102DLenght; d2++)
    projs20102DNumeros.push(Number(projs20102D[d2]))
    const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

    //2011 reais
    const projs2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20111R = projs2011.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111RLenght = projs20111R.length
    var projs20111RNumeros = [];
    for (var r1 = 0; r1 < projs20111RLenght; r1++)
    projs20111RNumeros.push(Number(projs20111R[r1]))
    const projs2011Reais1 = projs20111RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112R = projs2011.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112RLenght = projs20112R.length
    var projs20112RNumeros = [];
    for (var r2 = 0; r2 < projs20112RLenght; r2++)
    projs20112RNumeros.push(Number(projs20112R[r2]))
    const projs2011Reais2 = projs20112RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2011 = (projs2011Reais1+projs2011Reais2)
    
    //2011 dolar
    const projs20111D = projs2011.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111DLenght = projs20111D.length
    var projs20111DNumeros = [];
    for (var d1 = 0; d1 < projs20111DLenght; d1++)
    projs20111DNumeros.push(Number(projs20111D[d1]))
    const projs2011Dolar1 = projs20111DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112D = projs2011.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112DLenght = projs20112D.length
    var projs20112DNumeros = [];
    for (var d2 = 0; d2 < projs20112DLenght; d2++)
    projs20112DNumeros.push(Number(projs20112D[d2]))
    const projs2011Dolar2 = projs20112DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2011 = (projs2011Dolar1+projs2011Dolar2)

    //2012 reais
    const projs2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20121R = projs2012.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121RLenght = projs20121R.length
    var projs20121RNumeros = [];
    for (var r1 = 0; r1 < projs20121RLenght; r1++)
    projs20121RNumeros.push(Number(projs20121R[r1]))
    const projs2012Reais1 = projs20121RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122R = projs2012.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122RLenght = projs20122R.length
    var projs20122RNumeros = [];
    for (var r2 = 0; r2 < projs20122RLenght; r2++)
    projs20122RNumeros.push(Number(projs20122R[r2]))
    const projs2012Reais2 = projs20122RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2012 = (projs2012Reais1+projs2012Reais2)
    
    //2012 dolar
    const projs20121D = projs2012.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121DLenght = projs20121D.length
    var projs20121DNumeros = [];
    for (var d1 = 0; d1 < projs20121DLenght; d1++)
    projs20121DNumeros.push(Number(projs20121D[d1]))
    const projs2012Dolar1 = projs20121DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122D = projs2012.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122DLenght = projs20122D.length
    var projs20122DNumeros = [];
    for (var d2 = 0; d2 < projs20122DLenght; d2++)
    projs20122DNumeros.push(Number(projs20122D[d2]))
    const projs2012Dolar2 = projs20122DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2012 = (projs2012Dolar1+projs2012Dolar2)
    
    //2013 reais
    const projs2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20131R = projs2013.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131RLenght = projs20131R.length
    var projs20131RNumeros = [];
    for (var r1 = 0; r1 < projs20131RLenght; r1++)
    projs20131RNumeros.push(Number(projs20131R[r1]))
    const projs2013Reais1 = projs20131RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132R = projs2013.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132RLenght = projs20132R.length
    var projs20132RNumeros = [];
    for (var r2 = 0; r2 < projs20132RLenght; r2++)
    projs20132RNumeros.push(Number(projs20132R[r2]))
    const projs2013Reais2 = projs20132RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2013 = (projs2013Reais1+projs2013Reais2)
    
    //2013 dolar
    const projs20131D = projs2013.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131DLenght = projs20131D.length
    var projs20131DNumeros = [];
    for (var d1 = 0; d1 < projs20131DLenght; d1++)
    projs20131DNumeros.push(Number(projs20131D[d1]))
    const projs2013Dolar1 = projs20131DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132D = projs2013.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132DLenght = projs20132D.length
    var projs20132DNumeros = [];
    for (var d2 = 0; d2 < projs20132DLenght; d2++)
    projs20132DNumeros.push(Number(projs20132D[d2]))
    const projs2013Dolar2 = projs20132DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2013 = (projs2013Dolar1+projs2013Dolar2)
    
    //2014 reais
    const projs2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20141R = projs2014.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141RLenght = projs20141R.length
    var projs20141RNumeros = [];
    for (var r1 = 0; r1 < projs20141RLenght; r1++)
    projs20141RNumeros.push(Number(projs20141R[r1]))
    const projs2014Reais1 = projs20141RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142R = projs2014.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142RLenght = projs20142R.length
    var projs20142RNumeros = [];
    for (var r2 = 0; r2 < projs20142RLenght; r2++)
    projs20142RNumeros.push(Number(projs20142R[r2]))
    const projs2014Reais2 = projs20142RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2014 = (projs2014Reais1+projs2014Reais2)
    
    //2014 dolar
    const projs20141D = projs2014.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141DLenght = projs20141D.length
    var projs20141DNumeros = [];
    for (var d1 = 0; d1 < projs20141DLenght; d1++)
    projs20141DNumeros.push(Number(projs20141D[d1]))
    const projs2014Dolar1 = projs20141DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142D = projs2014.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142DLenght = projs20142D.length
    var projs20142DNumeros = [];
    for (var d2 = 0; d2 < projs20142DLenght; d2++)
    projs20142DNumeros.push(Number(projs20142D[d2]))
    const projs2014Dolar2 = projs20142DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2014 = (projs2014Dolar1+projs2014Dolar2)
    
    
    //2015 reais
    const projs2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20151R = projs2015.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151RLenght = projs20151R.length
    var projs20151RNumeros = [];
    for (var r1 = 0; r1 < projs20151RLenght; r1++)
    projs20151RNumeros.push(Number(projs20151R[r1]))
    const projs2015Reais1 = projs20151RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152R = projs2015.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152RLenght = projs20152R.length
    var projs20152RNumeros = [];
    for (var r2 = 0; r2 < projs20152RLenght; r2++)
    projs20152RNumeros.push(Number(projs20152R[r2]))
    const projs2015Reais2 = projs20152RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2015 = (projs2015Reais1+projs2015Reais2)
    
    //2015 dolar
    const projs20151D = projs2015.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151DLenght = projs20151D.length
    var projs20151DNumeros = [];
    for (var d1 = 0; d1 < projs20151DLenght; d1++)
    projs20151DNumeros.push(Number(projs20151D[d1]))
    const projs2015Dolar1 = projs20151DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152D = projs2015.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152DLenght = projs20152D.length
    var projs20152DNumeros = [];
    for (var d2 = 0; d2 < projs20152DLenght; d2++)
    projs20152DNumeros.push(Number(projs20152D[d2]))
    const projs2015Dolar2 = projs20152DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2015 = (projs2015Dolar1+projs2015Dolar2)
    
    //2016 reais
    const projs2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20161R = projs2016.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161RLenght = projs20161R.length
    var projs20161RNumeros = [];
    for (var r1 = 0; r1 < projs20161RLenght; r1++)
    projs20161RNumeros.push(Number(projs20161R[r1]))
    const projs2016Reais1 = projs20161RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162R = projs2016.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162RLenght = projs20162R.length
    var projs20162RNumeros = [];
    for (var r2 = 0; r2 < projs20162RLenght; r2++)
    projs20162RNumeros.push(Number(projs20162R[r2]))
    const projs2016Reais2 = projs20162RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2016 = (projs2016Reais1+projs2016Reais2)
    
    //2016 dolar
    const projs20161D = projs2016.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161DLenght = projs20161D.length
    var projs20161DNumeros = [];
    for (var d1 = 0; d1 < projs20161DLenght; d1++)
    projs20161DNumeros.push(Number(projs20161D[d1]))
    const projs2016Dolar1 = projs20161DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162D = projs2016.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162DLenght = projs20162D.length
    var projs20162DNumeros = [];
    for (var d2 = 0; d2 < projs20162DLenght; d2++)
    projs20162DNumeros.push(Number(projs20162D[d2]))
    const projs2016Dolar2 = projs20162DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2016 = (projs2016Dolar1+projs2016Dolar2)
    
    //2017 reais
    const projs2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20171R = projs2017.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171RLenght = projs20171R.length
    var projs20171RNumeros = [];
    for (var r1 = 0; r1 < projs20171RLenght; r1++)
    projs20171RNumeros.push(Number(projs20171R[r1]))
    const projs2017Reais1 = projs20171RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172R = projs2017.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172RLenght = projs20172R.length
    var projs20172RNumeros = [];
    for (var r2 = 0; r2 < projs20172RLenght; r2++)
    projs20172RNumeros.push(Number(projs20172R[r2]))
    const projs2017Reais2 = projs20172RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2017 = (projs2017Reais1+projs2017Reais2)
    
    //2017 dolar
    const projs20171D = projs2017.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171DLenght = projs20171D.length
    var projs20171DNumeros = [];
    for (var d1 = 0; d1 < projs20171DLenght; d1++)
    projs20171DNumeros.push(Number(projs20171D[d1]))
    const projs2017Dolar1 = projs20171DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172D = projs2017.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172DLenght = projs20172D.length
    var projs20172DNumeros = [];
    for (var d2 = 0; d2 < projs20172DLenght; d2++)
    projs20172DNumeros.push(Number(projs20172D[d2]))
    const projs2017Dolar2 = projs20172DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2017 = (projs2017Dolar1+projs2017Dolar2)
    
    //2018 reais
    const projs2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20181R = projs2018.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181RLenght = projs20181R.length
    var projs20181RNumeros = [];
    for (var r1 = 0; r1 < projs20181RLenght; r1++)
    projs20181RNumeros.push(Number(projs20181R[r1]))
    const projs2018Reais1 = projs20181RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182R = projs2018.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182RLenght = projs20182R.length
    var projs20182RNumeros = [];
    for (var r2 = 0; r2 < projs20182RLenght; r2++)
    projs20182RNumeros.push(Number(projs20182R[r2]))
    const projs2018Reais2 = projs20182RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2018 = (projs2018Reais1+projs2018Reais2)
    
    //2018 dolar
    const projs20181D = projs2018.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181DLenght = projs20181D.length
    var projs20181DNumeros = [];
    for (var d1 = 0; d1 < projs20181DLenght; d1++)
    projs20181DNumeros.push(Number(projs20181D[d1]))
    const projs2018Dolar1 = projs20181DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182D = projs2018.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182DLenght = projs20182D.length
    var projs20182DNumeros = [];
    for (var d2 = 0; d2 < projs20182DLenght; d2++)
    projs20182DNumeros.push(Number(projs20182D[d2]))
    const projs2018Dolar2 = projs20182DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2018 = (projs2018Dolar1+projs2018Dolar2)
    
    //2019 reais
    const projs2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20191R = projs2019.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191RLenght = projs20191R.length
    var projs20191RNumeros = [];
    for (var r1 = 0; r1 < projs20191RLenght; r1++)
    projs20191RNumeros.push(Number(projs20191R[r1]))
    const projs2019Reais1 = projs20191RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192R = projs2019.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192RLenght = projs20192R.length
    var projs20192RNumeros = [];
    for (var r2 = 0; r2 < projs20192RLenght; r2++)
    projs20192RNumeros.push(Number(projs20192R[r2]))
    const projs2019Reais2 = projs20192RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2019 = (projs2019Reais1+projs2019Reais2)
    
    //2019 dolar
    const projs20191D = projs2019.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191DLenght = projs20191D.length
    var projs20191DNumeros = [];
    for (var d1 = 0; d1 < projs20191DLenght; d1++)
    projs20191DNumeros.push(Number(projs20191D[d1]))
    const projs2019Dolar1 = projs20191DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192D = projs2019.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192DLenght = projs20192D.length
    var projs20192DNumeros = [];
    for (var d2 = 0; d2 < projs20192DLenght; d2++)
    projs20192DNumeros.push(Number(projs20192D[d2]))
    const projs2019Dolar2 = projs20192DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2019 = (projs2019Dolar1+projs2019Dolar2)
    
    //2020 reais
    const projs2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20201R = projs2020.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201RLenght = projs20201R.length
    var projs20201RNumeros = [];
    for (var r1 = 0; r1 < projs20201RLenght; r1++)
    projs20201RNumeros.push(Number(projs20201R[r1]))
    const projs2020Reais1 = projs20201RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202R = projs2020.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202RLenght = projs20202R.length
    var projs20202RNumeros = [];
    for (var r2 = 0; r2 < projs20202RLenght; r2++)
    projs20202RNumeros.push(Number(projs20202R[r2]))
    const projs2020Reais2 = projs20202RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2020 = (projs2020Reais1+projs2020Reais2)
    
    //2020 dolar
    const projs20201D = projs2020.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201DLenght = projs20201D.length
    var projs20201DNumeros = [];
    for (var d1 = 0; d1 < projs20201DLenght; d1++)
    projs20201DNumeros.push(Number(projs20201D[d1]))
    const projs2020Dolar1 = projs20201DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202D = projs2020.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202DLenght = projs20202D.length
    var projs20202DNumeros = [];
    for (var d2 = 0; d2 < projs20202DLenght; d2++)
    projs20202DNumeros.push(Number(projs20202D[d2]))
    const projs2020Dolar2 = projs20202DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2020 = (projs2020Dolar1+projs2020Dolar2)
    
    //2021 reais
    const projs2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20211R = projs2021.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211RLenght = projs20211R.length
    var projs20211RNumeros = [];
    for (var r1 = 0; r1 < projs20211RLenght; r1++)
    projs20211RNumeros.push(Number(projs20211R[r1]))
    const projs2021Reais1 = projs20211RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212R = projs2021.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212RLenght = projs20212R.length
    var projs20212RNumeros = [];
    for (var r2 = 0; r2 < projs20212RLenght; r2++)
    projs20212RNumeros.push(Number(projs20212R[r2]))
    const projs2021Reais2 = projs20212RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2021 = (projs2021Reais1+projs2021Reais2)
    
    //2021 dolar
    const projs20211D = projs2021.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211DLenght = projs20211D.length
    var projs20211DNumeros = [];
    for (var d1 = 0; d1 < projs20211DLenght; d1++)
    projs20211DNumeros.push(Number(projs20211D[d1]))
    const projs2021Dolar1 = projs20211DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212D = projs2021.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212DLenght = projs20212D.length
    var projs20212DNumeros = [];
    for (var d2 = 0; d2 < projs20212DLenght; d2++)
    projs20212DNumeros.push(Number(projs20212D[d2]))
    const projs2021Dolar2 = projs20212DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2021 = (projs2021Dolar1+projs2021Dolar2)
    
    //2022 reais
    const projs2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20221R = projs2022.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221RLenght = projs20221R.length
    var projs20221RNumeros = [];
    for (var r1 = 0; r1 < projs20221RLenght; r1++)
    projs20221RNumeros.push(Number(projs20221R[r1]))
    const projs2022Reais1 = projs20221RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222R = projs2022.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222RLenght = projs20222R.length
    var projs20222RNumeros = [];
    for (var r2 = 0; r2 < projs20222RLenght; r2++)
    projs20222RNumeros.push(Number(projs20222R[r2]))
    const projs2022Reais2 = projs20222RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2022 = projs2022Reais1+projs2022Reais2
    
    //2022 dolar
    const projs20221D = projs2022.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221DLenght = projs20221D.length
    var projs20221DNumeros = [];
    for (var d1 = 0; d1 < projs20221DLenght; d1++)
    projs20221DNumeros.push(Number(projs20221D[d1]))
    const projs2022Dolar1 = projs20221DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222D = projs2022.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222DLenght = projs20222D.length
    var projs20222DNumeros = [];
    for (var d2 = 0; d2 < projs20222DLenght; d2++)
    projs20222DNumeros.push(Number(projs20222D[d2]))
    const projs2022Dolar2 = projs20222DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2022 = projs2022Dolar1+projs2022Dolar2
    
    //2023 reais
    const projs2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20231R = projs2023.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231RLenght = projs20231R.length
    var projs20231RNumeros = [];
    for (var r1 = 0; r1 < projs20231RLenght; r1++)
    projs20231RNumeros.push(Number(projs20231R[r1]))
    const projs2023Reais1 = projs20231RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232R = projs2023.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232RLenght = projs20232R.length
    var projs20232RNumeros = [];
    for (var r2 = 0; r2 < projs20232RLenght; r2++)
    projs20232RNumeros.push(Number(projs20232R[r2]))
    const projs2023Reais2 = projs20232RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2023 = projs2023Reais1+projs2023Reais2
    
    //2023 dolar
    const projs20231D = projs2023.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231DLenght = projs20231D.length
    var projs20231DNumeros = [];
    for (var d1 = 0; d1 < projs20231DLenght; d1++)
    projs20231DNumeros.push(Number(projs20231D[d1]))
    const projs2023Dolar1 = projs20231DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232D = projs2023.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232DLenght = projs20232D.length
    var projs20232DNumeros = [];
    for (var d2 = 0; d2 < projs20232DLenght; d2++)
    projs20232DNumeros.push(Number(projs20232D[d2]))
    const projs2023Dolar2 = projs20232DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2023 = projs2023Dolar1+projs2023Dolar2
    
    
    //2024 reais
    const projs2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20241R = projs2024.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241RLenght = projs20241R.length
    var projs20241RNumeros = [];
    for (var r1 = 0; r1 < projs20241RLenght; r1++)
    projs20241RNumeros.push(Number(projs20241R[r1]))
    const projs2024Reais1 = projs20241RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242R = projs2024.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242RLenght = projs20242R.length
    var projs20242RNumeros = [];
    for (var r2 = 0; r2 < projs20242RLenght; r2++)
    projs20242RNumeros.push(Number(projs20242R[r2]))
    const projs2024Reais2 = projs20242RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2024 = projs2024Reais1+projs2024Reais2
    
    //2024 dolar
    const projs20241D = projs2024.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241DLenght = projs20241D.length
    var projs20241DNumeros = [];
    for (var d1 = 0; d1 < projs20241DLenght; d1++)
    projs20241DNumeros.push(Number(projs20241D[d1]))
    const projs2024Dolar1 = projs20241DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242D = projs2024.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242DLenght = projs20242D.length
    var projs20242DNumeros = [];
    for (var d2 = 0; d2 < projs20242DLenght; d2++)
    projs20242DNumeros.push(Number(projs20242D[d2]))
    const projs2024Dolar2 = projs20242DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2024 = projs2024Dolar1+projs2024Dolar2

    //2025 reais
const projs2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
const projs20251R = projs2025.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251RLenght = projs20251R.length
var projs20251RNumeros = [];
for (var r1 = 0; r1 < projs20251RLenght; r1++)
projs20251RNumeros.push(Number(projs20251R[r1]))
const projs2025Reais1 = projs20251RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252R = projs2025.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252RLenght = projs20252R.length
var projs20252RNumeros = [];
for (var r2 = 0; r2 < projs20252RLenght; r2++)
projs20252RNumeros.push(Number(projs20252R[r2]))
const projs2025Reais2 = projs20252RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025 = projs2025Reais1+projs2025Reais2

//2025 dolar
const projs20251D = projs2025.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251DLenght = projs20251D.length
var projs20251DNumeros = [];
for (var d1 = 0; d1 < projs20251DLenght; d1++)
projs20251DNumeros.push(Number(projs20251D[d1]))
const projs2025Dolar1 = projs20251DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252D = projs2025.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252DLenght = projs20252D.length
var projs20252DNumeros = [];
for (var d2 = 0; d2 < projs20252DLenght; d2++)
projs20252DNumeros.push(Number(projs20252D[d2]))
const projs2025Dolar2 = projs20252DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025 = projs2025Dolar1+projs2025Dolar2

     //2002Cl2 reais
     const projs2002Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2002Cl21R = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21RLenght = projs2002Cl21R.length
        var projs2002Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2002Cl21RLenght; r1++)
        projs2002Cl21RNumeros.push(Number(projs2002Cl21R[r1]))
        const projs2002Cl2Reais1 = projs2002Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22R = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22RLenght = projs2002Cl22R.length
        var projs2002Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2002Cl22RLenght; r2++)
        projs2002Cl22RNumeros.push(Number(projs2002Cl22R[r2]))
        const projs2002Cl2Reais2 = projs2002Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002Cl2 = (projs2002Cl2Reais1+projs2002Cl2Reais2)
    
        //2002Cl2 dolar
        const projs2002Cl21D = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21DLenght = projs2002Cl21D.length
        var projs2002Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2002Cl21DLenght; d1++)
        projs2002Cl21DNumeros.push(Number(projs2002Cl21D[d1]))
        const projs2002Cl2Dolar1 = projs2002Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22D = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22DLenght = projs2002Cl22D.length
        var projs2002Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2002Cl22DLenght; d2++)
        projs2002Cl22DNumeros.push(Number(projs2002Cl22D[d2]))
        const projs2002Cl2Dolar2 = projs2002Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002Cl2 = (projs2002Cl2Dolar1+projs2002Cl2Dolar2)
    
         //2003Cl2 reais
        const projs2003Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2003Cl21R = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21RLenght = projs2003Cl21R.length
        var projs2003Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2003Cl21RLenght; r1++)
        projs2003Cl21RNumeros.push(Number(projs2003Cl21R[r1]))
        const projs2003Cl2Reais1 = projs2003Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22R = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22RLenght = projs2003Cl22R.length
        var projs2003Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2003Cl22RLenght; r2++)
        projs2003Cl22RNumeros.push(Number(projs2003Cl22R[r2]))
        const projs2003Cl2Reais2 = projs2003Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003Cl2 = (projs2003Cl2Reais1+projs2003Cl2Reais2)
        
        //2003Cl2 dolar
        const projs2003Cl21D = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21DLenght = projs2003Cl21D.length
        var projs2003Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2003Cl21DLenght; d1++)
        projs2003Cl21DNumeros.push(Number(projs2003Cl21D[d1]))
        const projs2003Cl2Dolar1 = projs2003Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22D = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22DLenght = projs2003Cl22D.length
        var projs2003Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2003Cl22DLenght; d2++)
        projs2003Cl22DNumeros.push(Number(projs2003Cl22D[d2]))
        const projs2003Cl2Dolar2 = projs2003Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003Cl2 = (projs2003Cl2Dolar1+projs2003Cl2Dolar2)
    
        //2004Cl2 reais
        const projs2004Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2004Cl21R = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21RLenght = projs2004Cl21R.length
        var projs2004Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2004Cl21RLenght; r1++)
        projs2004Cl21RNumeros.push(Number(projs2004Cl21R[r1]))
        const projs2004Cl2Reais1 = projs2004Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22R = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22RLenght = projs2004Cl22R.length
        var projs2004Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2004Cl22RLenght; r2++)
        projs2004Cl22RNumeros.push(Number(projs2004Cl22R[r2]))
        const projs2004Cl2Reais2 = projs2004Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004Cl2 = (projs2004Cl2Reais1+projs2004Cl2Reais2)
        
        //2004Cl2 dolar
        const projs2004Cl21D = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21DLenght = projs2004Cl21D.length
        var projs2004Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2004Cl21DLenght; d1++)
        projs2004Cl21DNumeros.push(Number(projs2004Cl21D[d1]))
        const projs2004Cl2Dolar1 = projs2004Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22D = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22DLenght = projs2004Cl22D.length
        var projs2004Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2004Cl22DLenght; d2++)
        projs2004Cl22DNumeros.push(Number(projs2004Cl22D[d2]))
        const projs2004Cl2Dolar2 = projs2004Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004Cl2 = (projs2004Cl2Dolar1+projs2004Cl2Dolar2)
    
        //2005Cl2 reais
        const projs2005Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2005Cl21R = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21RLenght = projs2005Cl21R.length
        var projs2005Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2005Cl21RLenght; r1++)
        projs2005Cl21RNumeros.push(Number(projs2005Cl21R[r1]))
        const projs2005Cl2Reais1 = projs2005Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22R = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22RLenght = projs2005Cl22R.length
        var projs2005Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2005Cl22RLenght; r2++)
        projs2005Cl22RNumeros.push(Number(projs2005Cl22R[r2]))
        const projs2005Cl2Reais2 = projs2005Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005Cl2 = (projs2005Cl2Reais1+projs2005Cl2Reais2)
        
        //2005Cl2 dolar
        const projs2005Cl21D = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21DLenght = projs2005Cl21D.length
        var projs2005Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2005Cl21DLenght; d1++)
        projs2005Cl21DNumeros.push(Number(projs2005Cl21D[d1]))
        const projs2005Cl2Dolar1 = projs2005Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22D = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22DLenght = projs2005Cl22D.length
        var projs2005Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2005Cl22DLenght; d2++)
        projs2005Cl22DNumeros.push(Number(projs2005Cl22D[d2]))
        const projs2005Cl2Dolar2 = projs2005Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005Cl2 = (projs2005Cl2Dolar1+projs2005Cl2Dolar2)
    
        //2006Cl2 reais
        const projs2006Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2006Cl21R = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21RLenght = projs2006Cl21R.length
        var projs2006Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2006Cl21RLenght; r1++)
        projs2006Cl21RNumeros.push(Number(projs2006Cl21R[r1]))
        const projs2006Cl2Reais1 = projs2006Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22R = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22RLenght = projs2006Cl22R.length
        var projs2006Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2006Cl22RLenght; r2++)
        projs2006Cl22RNumeros.push(Number(projs2006Cl22R[r2]))
        const projs2006Cl2Reais2 = projs2006Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006Cl2 = (projs2006Cl2Reais1+projs2006Cl2Reais2)
        
        //2006Cl2 dolar
        const projs2006Cl21D = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21DLenght = projs2006Cl21D.length
        var projs2006Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2006Cl21DLenght; d1++)
        projs2006Cl21DNumeros.push(Number(projs2006Cl21D[d1]))
        const projs2006Cl2Dolar1 = projs2006Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22D = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22DLenght = projs2006Cl22D.length
        var projs2006Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2006Cl22DLenght; d2++)
        projs2006Cl22DNumeros.push(Number(projs2006Cl22D[d2]))
        const projs2006Cl2Dolar2 = projs2006Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006Cl2 = (projs2006Cl2Dolar1+projs2006Cl2Dolar2)
    
        //2007Cl2 reais
        const projs2007Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2007Cl21R = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21RLenght = projs2007Cl21R.length
        var projs2007Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2007Cl21RLenght; r1++)
        projs2007Cl21RNumeros.push(Number(projs2007Cl21R[r1]))
        const projs2007Cl2Reais1 = projs2007Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22R = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22RLenght = projs2007Cl22R.length
        var projs2007Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2007Cl22RLenght; r2++)
        projs2007Cl22RNumeros.push(Number(projs2007Cl22R[r2]))
        const projs2007Cl2Reais2 = projs2007Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007Cl2 = (projs2007Cl2Reais1+projs2007Cl2Reais2)
        
        //2007Cl2 dolar
        const projs2007Cl21D = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21DLenght = projs2007Cl21D.length
        var projs2007Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2007Cl21DLenght; d1++)
        projs2007Cl21DNumeros.push(Number(projs2007Cl21D[d1]))
        const projs2007Cl2Dolar1 = projs2007Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22D = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22DLenght = projs2007Cl22D.length
        var projs2007Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2007Cl22DLenght; d2++)
        projs2007Cl22DNumeros.push(Number(projs2007Cl22D[d2]))
        const projs2007Cl2Dolar2 = projs2007Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007Cl2 = (projs2007Cl2Dolar1+projs2007Cl2Dolar2)
    
        //2008Cl2 reais
        const projs2008Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2008Cl21R = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21RLenght = projs2008Cl21R.length
        var projs2008Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2008Cl21RLenght; r1++)
        projs2008Cl21RNumeros.push(Number(projs2008Cl21R[r1]))
        const projs2008Cl2Reais1 = projs2008Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22R = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22RLenght = projs2008Cl22R.length
        var projs2008Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2008Cl22RLenght; r2++)
        projs2008Cl22RNumeros.push(Number(projs2008Cl22R[r2]))
        const projs2008Cl2Reais2 = projs2008Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008Cl2 = (projs2008Cl2Reais1+projs2008Cl2Reais2)
        
        //2008Cl2 dolar
        const projs2008Cl21D = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21DLenght = projs2008Cl21D.length
        var projs2008Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2008Cl21DLenght; d1++)
        projs2008Cl21DNumeros.push(Number(projs2008Cl21D[d1]))
        const projs2008Cl2Dolar1 = projs2008Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22D = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22DLenght = projs2008Cl22D.length
        var projs2008Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2008Cl22DLenght; d2++)
        projs2008Cl22DNumeros.push(Number(projs2008Cl22D[d2]))
        const projs2008Cl2Dolar2 = projs2008Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008Cl2 = (projs2008Cl2Dolar1+projs2008Cl2Dolar2)
    
        //2009Cl2 reais
        const projs2009Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2009Cl21R = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21RLenght = projs2009Cl21R.length
        var projs2009Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2009Cl21RLenght; r1++)
        projs2009Cl21RNumeros.push(Number(projs2009Cl21R[r1]))
        const projs2009Cl2Reais1 = projs2009Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22R = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22RLenght = projs2009Cl22R.length
        var projs2009Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2009Cl22RLenght; r2++)
        projs2009Cl22RNumeros.push(Number(projs2009Cl22R[r2]))
        const projs2009Cl2Reais2 = projs2009Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009Cl2 = (projs2009Cl2Reais1+projs2009Cl2Reais2)
        
        //2009Cl2 dolar
        const projs2009Cl21D = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21DLenght = projs2009Cl21D.length
        var projs2009Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2009Cl21DLenght; d1++)
        projs2009Cl21DNumeros.push(Number(projs2009Cl21D[d1]))
        const projs2009Cl2Dolar1 = projs2009Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22D = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22DLenght = projs2009Cl22D.length
        var projs2009Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2009Cl22DLenght; d2++)
        projs2009Cl22DNumeros.push(Number(projs2009Cl22D[d2]))
        const projs2009Cl2Dolar2 = projs2009Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009Cl2 = (projs2009Cl2Dolar1+projs2009Cl2Dolar2)
    
        //2010Cl2 reais
        const projs2010Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2010Cl21R = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21RLenght = projs2010Cl21R.length
        var projs2010Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2010Cl21RLenght; r1++)
        projs2010Cl21RNumeros.push(Number(projs2010Cl21R[r1]))
        const projs2010Cl2Reais1 = projs2010Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22R = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22RLenght = projs2010Cl22R.length
        var projs2010Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2010Cl22RLenght; r2++)
        projs2010Cl22RNumeros.push(Number(projs2010Cl22R[r2]))
        const projs2010Cl2Reais2 = projs2010Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010Cl2 = (projs2010Cl2Reais1+projs2010Cl2Reais2)
        
        //2010Cl2 dolar
        const projs2010Cl21D = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21DLenght = projs2010Cl21D.length
        var projs2010Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2010Cl21DLenght; d1++)
        projs2010Cl21DNumeros.push(Number(projs2010Cl21D[d1]))
        const projs2010Cl2Dolar1 = projs2010Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22D = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22DLenght = projs2010Cl22D.length
        var projs2010Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2010Cl22DLenght; d2++)
        projs2010Cl22DNumeros.push(Number(projs2010Cl22D[d2]))
        const projs2010Cl2Dolar2 = projs2010Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010Cl2 = (projs2010Cl2Dolar1+projs2010Cl2Dolar2)
    
        //2011Cl2 reais
        const projs2011Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2011Cl21R = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21RLenght = projs2011Cl21R.length
        var projs2011Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2011Cl21RLenght; r1++)
        projs2011Cl21RNumeros.push(Number(projs2011Cl21R[r1]))
        const projs2011Cl2Reais1 = projs2011Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22R = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22RLenght = projs2011Cl22R.length
        var projs2011Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2011Cl22RLenght; r2++)
        projs2011Cl22RNumeros.push(Number(projs2011Cl22R[r2]))
        const projs2011Cl2Reais2 = projs2011Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2011Cl2 = (projs2011Cl2Reais1+projs2011Cl2Reais2)
        
        //2011Cl2 dolar
        const projs2011Cl21D = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21DLenght = projs2011Cl21D.length
        var projs2011Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2011Cl21DLenght; d1++)
        projs2011Cl21DNumeros.push(Number(projs2011Cl21D[d1]))
        const projs2011Cl2Dolar1 = projs2011Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22D = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22DLenght = projs2011Cl22D.length
        var projs2011Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2011Cl22DLenght; d2++)
        projs2011Cl22DNumeros.push(Number(projs2011Cl22D[d2]))
        const projs2011Cl2Dolar2 = projs2011Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2011Cl2 = (projs2011Cl2Dolar1+projs2011Cl2Dolar2)
    
        //2012Cl2 reais
        const projs2012Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2012Cl21R = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21RLenght = projs2012Cl21R.length
        var projs2012Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2012Cl21RLenght; r1++)
        projs2012Cl21RNumeros.push(Number(projs2012Cl21R[r1]))
        const projs2012Cl2Reais1 = projs2012Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22R = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22RLenght = projs2012Cl22R.length
        var projs2012Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2012Cl22RLenght; r2++)
        projs2012Cl22RNumeros.push(Number(projs2012Cl22R[r2]))
        const projs2012Cl2Reais2 = projs2012Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2012Cl2 = (projs2012Cl2Reais1+projs2012Cl2Reais2)
        
        //2012Cl2 dolar
        const projs2012Cl21D = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21DLenght = projs2012Cl21D.length
        var projs2012Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2012Cl21DLenght; d1++)
        projs2012Cl21DNumeros.push(Number(projs2012Cl21D[d1]))
        const projs2012Cl2Dolar1 = projs2012Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22D = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22DLenght = projs2012Cl22D.length
        var projs2012Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2012Cl22DLenght; d2++)
        projs2012Cl22DNumeros.push(Number(projs2012Cl22D[d2]))
        const projs2012Cl2Dolar2 = projs2012Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2012Cl2 = (projs2012Cl2Dolar1+projs2012Cl2Dolar2)
        
        //2013Cl2 reais
        const projs2013Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2013Cl21R = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21RLenght = projs2013Cl21R.length
        var projs2013Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2013Cl21RLenght; r1++)
        projs2013Cl21RNumeros.push(Number(projs2013Cl21R[r1]))
        const projs2013Cl2Reais1 = projs2013Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22R = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22RLenght = projs2013Cl22R.length
        var projs2013Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2013Cl22RLenght; r2++)
        projs2013Cl22RNumeros.push(Number(projs2013Cl22R[r2]))
        const projs2013Cl2Reais2 = projs2013Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2013Cl2 = (projs2013Cl2Reais1+projs2013Cl2Reais2)
        
        //2013Cl2 dolar
        const projs2013Cl21D = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21DLenght = projs2013Cl21D.length
        var projs2013Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2013Cl21DLenght; d1++)
        projs2013Cl21DNumeros.push(Number(projs2013Cl21D[d1]))
        const projs2013Cl2Dolar1 = projs2013Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22D = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22DLenght = projs2013Cl22D.length
        var projs2013Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2013Cl22DLenght; d2++)
        projs2013Cl22DNumeros.push(Number(projs2013Cl22D[d2]))
        const projs2013Cl2Dolar2 = projs2013Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2013Cl2 = (projs2013Cl2Dolar1+projs2013Cl2Dolar2)
        
        //2014Cl2 reais
        const projs2014Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2014Cl21R = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21RLenght = projs2014Cl21R.length
        var projs2014Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2014Cl21RLenght; r1++)
        projs2014Cl21RNumeros.push(Number(projs2014Cl21R[r1]))
        const projs2014Cl2Reais1 = projs2014Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22R = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22RLenght = projs2014Cl22R.length
        var projs2014Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2014Cl22RLenght; r2++)
        projs2014Cl22RNumeros.push(Number(projs2014Cl22R[r2]))
        const projs2014Cl2Reais2 = projs2014Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2014Cl2 = (projs2014Cl2Reais1+projs2014Cl2Reais2)
        
        //2014Cl2 dolar
        const projs2014Cl21D = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21DLenght = projs2014Cl21D.length
        var projs2014Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2014Cl21DLenght; d1++)
        projs2014Cl21DNumeros.push(Number(projs2014Cl21D[d1]))
        const projs2014Cl2Dolar1 = projs2014Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22D = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22DLenght = projs2014Cl22D.length
        var projs2014Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2014Cl22DLenght; d2++)
        projs2014Cl22DNumeros.push(Number(projs2014Cl22D[d2]))
        const projs2014Cl2Dolar2 = projs2014Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2014Cl2 = (projs2014Cl2Dolar1+projs2014Cl2Dolar2)
        
        
        //2015Cl2 reais
        const projs2015Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2015Cl21R = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21RLenght = projs2015Cl21R.length
        var projs2015Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2015Cl21RLenght; r1++)
        projs2015Cl21RNumeros.push(Number(projs2015Cl21R[r1]))
        const projs2015Cl2Reais1 = projs2015Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22R = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22RLenght = projs2015Cl22R.length
        var projs2015Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2015Cl22RLenght; r2++)
        projs2015Cl22RNumeros.push(Number(projs2015Cl22R[r2]))
        const projs2015Cl2Reais2 = projs2015Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2015Cl2 = (projs2015Cl2Reais1+projs2015Cl2Reais2)
        
        //2015Cl2 dolar
        const projs2015Cl21D = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21DLenght = projs2015Cl21D.length
        var projs2015Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2015Cl21DLenght; d1++)
        projs2015Cl21DNumeros.push(Number(projs2015Cl21D[d1]))
        const projs2015Cl2Dolar1 = projs2015Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22D = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22DLenght = projs2015Cl22D.length
        var projs2015Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2015Cl22DLenght; d2++)
        projs2015Cl22DNumeros.push(Number(projs2015Cl22D[d2]))
        const projs2015Cl2Dolar2 = projs2015Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2015Cl2 = (projs2015Cl2Dolar1+projs2015Cl2Dolar2)
        
        //2016Cl2 reais
        const projs2016Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2016Cl21R = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21RLenght = projs2016Cl21R.length
        var projs2016Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2016Cl21RLenght; r1++)
        projs2016Cl21RNumeros.push(Number(projs2016Cl21R[r1]))
        const projs2016Cl2Reais1 = projs2016Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22R = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22RLenght = projs2016Cl22R.length
        var projs2016Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2016Cl22RLenght; r2++)
        projs2016Cl22RNumeros.push(Number(projs2016Cl22R[r2]))
        const projs2016Cl2Reais2 = projs2016Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2016Cl2 = (projs2016Cl2Reais1+projs2016Cl2Reais2)
        
        //2016Cl2 dolar
        const projs2016Cl21D = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21DLenght = projs2016Cl21D.length
        var projs2016Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2016Cl21DLenght; d1++)
        projs2016Cl21DNumeros.push(Number(projs2016Cl21D[d1]))
        const projs2016Cl2Dolar1 = projs2016Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22D = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22DLenght = projs2016Cl22D.length
        var projs2016Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2016Cl22DLenght; d2++)
        projs2016Cl22DNumeros.push(Number(projs2016Cl22D[d2]))
        const projs2016Cl2Dolar2 = projs2016Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2016Cl2 = (projs2016Cl2Dolar1+projs2016Cl2Dolar2)
        
        //2017Cl2 reais
        const projs2017Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2017Cl21R = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21RLenght = projs2017Cl21R.length
        var projs2017Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2017Cl21RLenght; r1++)
        projs2017Cl21RNumeros.push(Number(projs2017Cl21R[r1]))
        const projs2017Cl2Reais1 = projs2017Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22R = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22RLenght = projs2017Cl22R.length
        var projs2017Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2017Cl22RLenght; r2++)
        projs2017Cl22RNumeros.push(Number(projs2017Cl22R[r2]))
        const projs2017Cl2Reais2 = projs2017Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2017Cl2 = (projs2017Cl2Reais1+projs2017Cl2Reais2)
        
        //2017Cl2 dolar
        const projs2017Cl21D = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21DLenght = projs2017Cl21D.length
        var projs2017Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2017Cl21DLenght; d1++)
        projs2017Cl21DNumeros.push(Number(projs2017Cl21D[d1]))
        const projs2017Cl2Dolar1 = projs2017Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22D = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22DLenght = projs2017Cl22D.length
        var projs2017Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2017Cl22DLenght; d2++)
        projs2017Cl22DNumeros.push(Number(projs2017Cl22D[d2]))
        const projs2017Cl2Dolar2 = projs2017Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2017Cl2 = (projs2017Cl2Dolar1+projs2017Cl2Dolar2)
        
        //2018Cl2 reais
        const projs2018Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2018Cl21R = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21RLenght = projs2018Cl21R.length
        var projs2018Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2018Cl21RLenght; r1++)
        projs2018Cl21RNumeros.push(Number(projs2018Cl21R[r1]))
        const projs2018Cl2Reais1 = projs2018Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22R = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22RLenght = projs2018Cl22R.length
        var projs2018Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2018Cl22RLenght; r2++)
        projs2018Cl22RNumeros.push(Number(projs2018Cl22R[r2]))
        const projs2018Cl2Reais2 = projs2018Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2018Cl2 = (projs2018Cl2Reais1+projs2018Cl2Reais2)
        
        //2018Cl2 dolar
        const projs2018Cl21D = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21DLenght = projs2018Cl21D.length
        var projs2018Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2018Cl21DLenght; d1++)
        projs2018Cl21DNumeros.push(Number(projs2018Cl21D[d1]))
        const projs2018Cl2Dolar1 = projs2018Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22D = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22DLenght = projs2018Cl22D.length
        var projs2018Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2018Cl22DLenght; d2++)
        projs2018Cl22DNumeros.push(Number(projs2018Cl22D[d2]))
        const projs2018Cl2Dolar2 = projs2018Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2018Cl2 = (projs2018Cl2Dolar1+projs2018Cl2Dolar2)
        
        //2019Cl2 reais
        const projs2019Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2019Cl21R = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21RLenght = projs2019Cl21R.length
        var projs2019Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2019Cl21RLenght; r1++)
        projs2019Cl21RNumeros.push(Number(projs2019Cl21R[r1]))
        const projs2019Cl2Reais1 = projs2019Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22R = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22RLenght = projs2019Cl22R.length
        var projs2019Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2019Cl22RLenght; r2++)
        projs2019Cl22RNumeros.push(Number(projs2019Cl22R[r2]))
        const projs2019Cl2Reais2 = projs2019Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2019Cl2 = (projs2019Cl2Reais1+projs2019Cl2Reais2)
        
        //2019Cl2 dolar
        const projs2019Cl21D = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21DLenght = projs2019Cl21D.length
        var projs2019Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2019Cl21DLenght; d1++)
        projs2019Cl21DNumeros.push(Number(projs2019Cl21D[d1]))
        const projs2019Cl2Dolar1 = projs2019Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22D = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22DLenght = projs2019Cl22D.length
        var projs2019Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2019Cl22DLenght; d2++)
        projs2019Cl22DNumeros.push(Number(projs2019Cl22D[d2]))
        const projs2019Cl2Dolar2 = projs2019Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2019Cl2 = (projs2019Cl2Dolar1+projs2019Cl2Dolar2)
        
        //2020Cl2 reais
        const projs2020Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2020Cl21R = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21RLenght = projs2020Cl21R.length
        var projs2020Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2020Cl21RLenght; r1++)
        projs2020Cl21RNumeros.push(Number(projs2020Cl21R[r1]))
        const projs2020Cl2Reais1 = projs2020Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22R = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22RLenght = projs2020Cl22R.length
        var projs2020Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2020Cl22RLenght; r2++)
        projs2020Cl22RNumeros.push(Number(projs2020Cl22R[r2]))
        const projs2020Cl2Reais2 = projs2020Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2020Cl2 = (projs2020Cl2Reais1+projs2020Cl2Reais2)
        
        //2020Cl2 dolar
        const projs2020Cl21D = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21DLenght = projs2020Cl21D.length
        var projs2020Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2020Cl21DLenght; d1++)
        projs2020Cl21DNumeros.push(Number(projs2020Cl21D[d1]))
        const projs2020Cl2Dolar1 = projs2020Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22D = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22DLenght = projs2020Cl22D.length
        var projs2020Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2020Cl22DLenght; d2++)
        projs2020Cl22DNumeros.push(Number(projs2020Cl22D[d2]))
        const projs2020Cl2Dolar2 = projs2020Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2020Cl2 = (projs2020Cl2Dolar1+projs2020Cl2Dolar2)
        
        //2021Cl2 reais
        const projs2021Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2021Cl21R = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21RLenght = projs2021Cl21R.length
        var projs2021Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2021Cl21RLenght; r1++)
        projs2021Cl21RNumeros.push(Number(projs2021Cl21R[r1]))
        const projs2021Cl2Reais1 = projs2021Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22R = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22RLenght = projs2021Cl22R.length
        var projs2021Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2021Cl22RLenght; r2++)
        projs2021Cl22RNumeros.push(Number(projs2021Cl22R[r2]))
        const projs2021Cl2Reais2 = projs2021Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2021Cl2 = (projs2021Cl2Reais1+projs2021Cl2Reais2)
        
        //2021Cl2 dolar
        const projs2021Cl21D = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21DLenght = projs2021Cl21D.length
        var projs2021Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2021Cl21DLenght; d1++)
        projs2021Cl21DNumeros.push(Number(projs2021Cl21D[d1]))
        const projs2021Cl2Dolar1 = projs2021Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22D = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22DLenght = projs2021Cl22D.length
        var projs2021Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2021Cl22DLenght; d2++)
        projs2021Cl22DNumeros.push(Number(projs2021Cl22D[d2]))
        const projs2021Cl2Dolar2 = projs2021Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2021Cl2 = (projs2021Cl2Dolar1+projs2021Cl2Dolar2)
        
        //2022Cl2 reais
        const projs2022Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2022Cl21R = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21RLenght = projs2022Cl21R.length
        var projs2022Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2022Cl21RLenght; r1++)
        projs2022Cl21RNumeros.push(Number(projs2022Cl21R[r1]))
        const projs2022Cl2Reais1 = projs2022Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22R = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22RLenght = projs2022Cl22R.length
        var projs2022Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2022Cl22RLenght; r2++)
        projs2022Cl22RNumeros.push(Number(projs2022Cl22R[r2]))
        const projs2022Cl2Reais2 = projs2022Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2022Cl2 = projs2022Cl2Reais1+projs2022Cl2Reais2
        
        //2022Cl2 dolar
        const projs2022Cl21D = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21DLenght = projs2022Cl21D.length
        var projs2022Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2022Cl21DLenght; d1++)
        projs2022Cl21DNumeros.push(Number(projs2022Cl21D[d1]))
        const projs2022Cl2Dolar1 = projs2022Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22D = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22DLenght = projs2022Cl22D.length
        var projs2022Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2022Cl22DLenght; d2++)
        projs2022Cl22DNumeros.push(Number(projs2022Cl22D[d2]))
        const projs2022Cl2Dolar2 = projs2022Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2022Cl2 = projs2022Cl2Dolar1+projs2022Cl2Dolar2
        
        //2023Cl2 reais
        const projs2023Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2023Cl21R = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21RLenght = projs2023Cl21R.length
        var projs2023Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2023Cl21RLenght; r1++)
        projs2023Cl21RNumeros.push(Number(projs2023Cl21R[r1]))
        const projs2023Cl2Reais1 = projs2023Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22R = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22RLenght = projs2023Cl22R.length
        var projs2023Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2023Cl22RLenght; r2++)
        projs2023Cl22RNumeros.push(Number(projs2023Cl22R[r2]))
        const projs2023Cl2Reais2 = projs2023Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2023Cl2 = projs2023Cl2Reais1+projs2023Cl2Reais2
        
        //2023Cl2 dolar
        const projs2023Cl21D = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21DLenght = projs2023Cl21D.length
        var projs2023Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2023Cl21DLenght; d1++)
        projs2023Cl21DNumeros.push(Number(projs2023Cl21D[d1]))
        const projs2023Cl2Dolar1 = projs2023Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22D = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22DLenght = projs2023Cl22D.length
        var projs2023Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2023Cl22DLenght; d2++)
        projs2023Cl22DNumeros.push(Number(projs2023Cl22D[d2]))
        const projs2023Cl2Dolar2 = projs2023Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2023Cl2 = projs2023Cl2Dolar1+projs2023Cl2Dolar2
        
        
        //2024Cl2 reais
        const projs2024Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2024Cl21R = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21RLenght = projs2024Cl21R.length
        var projs2024Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2024Cl21RLenght; r1++)
        projs2024Cl21RNumeros.push(Number(projs2024Cl21R[r1]))
        const projs2024Cl2Reais1 = projs2024Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22R = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22RLenght = projs2024Cl22R.length
        var projs2024Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2024Cl22RLenght; r2++)
        projs2024Cl22RNumeros.push(Number(projs2024Cl22R[r2]))
        const projs2024Cl2Reais2 = projs2024Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2024Cl2 = projs2024Cl2Reais1+projs2024Cl2Reais2
        
        //2024Cl2 dolar
        const projs2024Cl21D = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21DLenght = projs2024Cl21D.length
        var projs2024Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2024Cl21DLenght; d1++)
        projs2024Cl21DNumeros.push(Number(projs2024Cl21D[d1]))
        const projs2024Cl2Dolar1 = projs2024Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22D = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22DLenght = projs2024Cl22D.length
        var projs2024Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2024Cl22DLenght; d2++)
        projs2024Cl22DNumeros.push(Number(projs2024Cl22D[d2]))
        const projs2024Cl2Dolar2 = projs2024Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2024Cl2 = projs2024Cl2Dolar1+projs2024Cl2Dolar2

        //2025Cl2 reais
const projs2025Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
const projs2025Cl21R = projs2025Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl21RLenght = projs2025Cl21R.length
var projs2025Cl21RNumeros = [];
for (var r1 = 0; r1 < projs2025Cl21RLenght; r1++)
projs2025Cl21RNumeros.push(Number(projs2025Cl21R[r1]))
const projs2025Cl2Reais1 = projs2025Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl22R = projs2025Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl22RLenght = projs2025Cl22R.length
var projs2025Cl22RNumeros = [];
for (var r2 = 0; r2 < projs2025Cl22RLenght; r2++)
projs2025Cl22RNumeros.push(Number(projs2025Cl22R[r2]))
const projs2025Cl2Reais2 = projs2025Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025Cl2 = projs2025Cl2Reais1+projs2025Cl2Reais2

//2025Cl2 dolar
const projs2025Cl21D = projs2025Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl21DLenght = projs2025Cl21D.length
var projs2025Cl21DNumeros = [];
for (var d1 = 0; d1 < projs2025Cl21DLenght; d1++)
projs2025Cl21DNumeros.push(Number(projs2025Cl21D[d1]))
const projs2025Cl2Dolar1 = projs2025Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl22D = projs2025Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl22DLenght = projs2025Cl22D.length
var projs2025Cl22DNumeros = [];
for (var d2 = 0; d2 < projs2025Cl22DLenght; d2++)
projs2025Cl22DNumeros.push(Number(projs2025Cl22D[d2]))
const projs2025Cl2Dolar2 = projs2025Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025Cl2 = projs2025Cl2Dolar1+projs2025Cl2Dolar2
    
    
  
    setProjetData({
      labels: anos,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [Reais2002,Reais2003,Reais2004,Reais2005,Reais2006,Reais2007,Reais2008,Reais2009,Reais2010,Reais2011,Reais2012,Reais2013,Reais2014,Reais2015,Reais2016,Reais2017,Reais2018,Reais2019,Reais2020,Reais2021,Reais2022,Reais2023,Reais2024, Reais2025],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [Dolar2002,Dolar2003,Dolar2004,Dolar2005,Dolar2006,Dolar2007,Dolar2008,Dolar2009,Dolar2010,Dolar2011,Dolar2012,Dolar2013,Dolar2014,Dolar2015,Dolar2016,Dolar2017,Dolar2018,Dolar2019,Dolar2020,Dolar2021,Dolar2022,Dolar2023,Dolar2024, Dolar2025],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },{
        label:`Faturamento Reais - ${cliente2}`,
        data: [Reais2002Cl2,Reais2003Cl2,Reais2004Cl2,Reais2005Cl2,Reais2006Cl2,Reais2007Cl2,Reais2008Cl2,Reais2009Cl2,Reais2010Cl2,Reais2011Cl2,Reais2012Cl2,Reais2013Cl2,Reais2014Cl2,Reais2015Cl2,Reais2016Cl2,Reais2017Cl2,Reais2018Cl2,Reais2019Cl2,Reais2020Cl2,Reais2021Cl2,Reais2022Cl2,Reais2023Cl2,Reais2024Cl2, Reais2025Cl2],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data: [Dolar2002Cl2,Dolar2003Cl2,Dolar2004Cl2,Dolar2005Cl2,Dolar2006Cl2,Dolar2007Cl2,Dolar2008Cl2,Dolar2009Cl2,Dolar2010Cl2,Dolar2011Cl2,Dolar2012Cl2,Dolar2013Cl2,Dolar2014Cl2,Dolar2015Cl2,Dolar2016Cl2,Dolar2017Cl2,Dolar2018Cl2,Dolar2019Cl2,Dolar2020Cl2,Dolar2021Cl2,Dolar2022Cl2,Dolar2023Cl2,Dolar2024Cl2, Dolar2025Cl2],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
   
  }
  
  const handleChart3 =()=>{
    setGrafico(false)
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];
  
    
    //2002 reais
    const projs2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20021R = projs2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projs2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //2002 dolar
    const projs20021D = projs2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projs2002.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

     //2003 reais
    const projs2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20031R = projs2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projs2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //2003 dolar
    const projs20031D = projs2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projs2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //2004 reais
    const projs2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20041R = projs2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projs2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //2004 dolar
    const projs20041D = projs2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projs2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //2005 reais
    const projs2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20051R = projs2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projs2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //2005 dolar
    const projs20051D = projs2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projs2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //2006 reais
    const projs2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
    const projs20061R = projs2006.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061RLenght = projs20061R.length
    var projs20061RNumeros = [];
    for (var r1 = 0; r1 < projs20061RLenght; r1++)
    projs20061RNumeros.push(Number(projs20061R[r1]))
    const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062R = projs2006.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062RLenght = projs20062R.length
    var projs20062RNumeros = [];
    for (var r2 = 0; r2 < projs20062RLenght; r2++)
    projs20062RNumeros.push(Number(projs20062R[r2]))
    const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2006 = (projs2006Reais1+projs2006Reais2)
    
    //2006 dolar
    const projs20061D = projs2006.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061DLenght = projs20061D.length
    var projs20061DNumeros = [];
    for (var d1 = 0; d1 < projs20061DLenght; d1++)
    projs20061DNumeros.push(Number(projs20061D[d1]))
    const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062D = projs2006.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062DLenght = projs20062D.length
    var projs20062DNumeros = [];
    for (var d2 = 0; d2 < projs20062DLenght; d2++)
    projs20062DNumeros.push(Number(projs20062D[d2]))
    const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)

    //2007 reais
    const projs2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20071R = projs2007.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071RLenght = projs20071R.length
    var projs20071RNumeros = [];
    for (var r1 = 0; r1 < projs20071RLenght; r1++)
    projs20071RNumeros.push(Number(projs20071R[r1]))
    const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072R = projs2007.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072RLenght = projs20072R.length
    var projs20072RNumeros = [];
    for (var r2 = 0; r2 < projs20072RLenght; r2++)
    projs20072RNumeros.push(Number(projs20072R[r2]))
    const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2007 = (projs2007Reais1+projs2007Reais2)
    
    //2007 dolar
    const projs20071D = projs2007.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071DLenght = projs20071D.length
    var projs20071DNumeros = [];
    for (var d1 = 0; d1 < projs20071DLenght; d1++)
    projs20071DNumeros.push(Number(projs20071D[d1]))
    const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072D = projs2007.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072DLenght = projs20072D.length
    var projs20072DNumeros = [];
    for (var d2 = 0; d2 < projs20072DLenght; d2++)
    projs20072DNumeros.push(Number(projs20072D[d2]))
    const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

    //2008 reais
    const projs2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20081R = projs2008.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081RLenght = projs20081R.length
    var projs20081RNumeros = [];
    for (var r1 = 0; r1 < projs20081RLenght; r1++)
    projs20081RNumeros.push(Number(projs20081R[r1]))
    const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082R = projs2008.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082RLenght = projs20082R.length
    var projs20082RNumeros = [];
    for (var r2 = 0; r2 < projs20082RLenght; r2++)
    projs20082RNumeros.push(Number(projs20082R[r2]))
    const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2008 = (projs2008Reais1+projs2008Reais2)
    
    //2008 dolar
    const projs20081D = projs2008.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081DLenght = projs20081D.length
    var projs20081DNumeros = [];
    for (var d1 = 0; d1 < projs20081DLenght; d1++)
    projs20081DNumeros.push(Number(projs20081D[d1]))
    const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082D = projs2008.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082DLenght = projs20082D.length
    var projs20082DNumeros = [];
    for (var d2 = 0; d2 < projs20082DLenght; d2++)
    projs20082DNumeros.push(Number(projs20082D[d2]))
    const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)

    //2009 reais
    const projs2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20091R = projs2009.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091RLenght = projs20091R.length
    var projs20091RNumeros = [];
    for (var r1 = 0; r1 < projs20091RLenght; r1++)
    projs20091RNumeros.push(Number(projs20091R[r1]))
    const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092R = projs2009.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092RLenght = projs20092R.length
    var projs20092RNumeros = [];
    for (var r2 = 0; r2 < projs20092RLenght; r2++)
    projs20092RNumeros.push(Number(projs20092R[r2]))
    const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2009 = (projs2009Reais1+projs2009Reais2)
    
    //2009 dolar
    const projs20091D = projs2009.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091DLenght = projs20091D.length
    var projs20091DNumeros = [];
    for (var d1 = 0; d1 < projs20091DLenght; d1++)
    projs20091DNumeros.push(Number(projs20091D[d1]))
    const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092D = projs2009.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092DLenght = projs20092D.length
    var projs20092DNumeros = [];
    for (var d2 = 0; d2 < projs20092DLenght; d2++)
    projs20092DNumeros.push(Number(projs20092D[d2]))
    const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)

    //2010 reais
    const projs2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20101R = projs2010.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101RLenght = projs20101R.length
    var projs20101RNumeros = [];
    for (var r1 = 0; r1 < projs20101RLenght; r1++)
    projs20101RNumeros.push(Number(projs20101R[r1]))
    const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102R = projs2010.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102RLenght = projs20102R.length
    var projs20102RNumeros = [];
    for (var r2 = 0; r2 < projs20102RLenght; r2++)
    projs20102RNumeros.push(Number(projs20102R[r2]))
    const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2010 = (projs2010Reais1+projs2010Reais2)
    
    //2010 dolar
    const projs20101D = projs2010.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101DLenght = projs20101D.length
    var projs20101DNumeros = [];
    for (var d1 = 0; d1 < projs20101DLenght; d1++)
    projs20101DNumeros.push(Number(projs20101D[d1]))
    const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102D = projs2010.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102DLenght = projs20102D.length
    var projs20102DNumeros = [];
    for (var d2 = 0; d2 < projs20102DLenght; d2++)
    projs20102DNumeros.push(Number(projs20102D[d2]))
    const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

    //2011 reais
    const projs2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20111R = projs2011.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111RLenght = projs20111R.length
    var projs20111RNumeros = [];
    for (var r1 = 0; r1 < projs20111RLenght; r1++)
    projs20111RNumeros.push(Number(projs20111R[r1]))
    const projs2011Reais1 = projs20111RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112R = projs2011.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112RLenght = projs20112R.length
    var projs20112RNumeros = [];
    for (var r2 = 0; r2 < projs20112RLenght; r2++)
    projs20112RNumeros.push(Number(projs20112R[r2]))
    const projs2011Reais2 = projs20112RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2011 = (projs2011Reais1+projs2011Reais2)
    
    //2011 dolar
    const projs20111D = projs2011.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111DLenght = projs20111D.length
    var projs20111DNumeros = [];
    for (var d1 = 0; d1 < projs20111DLenght; d1++)
    projs20111DNumeros.push(Number(projs20111D[d1]))
    const projs2011Dolar1 = projs20111DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112D = projs2011.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112DLenght = projs20112D.length
    var projs20112DNumeros = [];
    for (var d2 = 0; d2 < projs20112DLenght; d2++)
    projs20112DNumeros.push(Number(projs20112D[d2]))
    const projs2011Dolar2 = projs20112DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2011 = (projs2011Dolar1+projs2011Dolar2)

    //2012 reais
    const projs2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20121R = projs2012.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121RLenght = projs20121R.length
    var projs20121RNumeros = [];
    for (var r1 = 0; r1 < projs20121RLenght; r1++)
    projs20121RNumeros.push(Number(projs20121R[r1]))
    const projs2012Reais1 = projs20121RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122R = projs2012.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122RLenght = projs20122R.length
    var projs20122RNumeros = [];
    for (var r2 = 0; r2 < projs20122RLenght; r2++)
    projs20122RNumeros.push(Number(projs20122R[r2]))
    const projs2012Reais2 = projs20122RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2012 = (projs2012Reais1+projs2012Reais2)
    
    //2012 dolar
    const projs20121D = projs2012.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121DLenght = projs20121D.length
    var projs20121DNumeros = [];
    for (var d1 = 0; d1 < projs20121DLenght; d1++)
    projs20121DNumeros.push(Number(projs20121D[d1]))
    const projs2012Dolar1 = projs20121DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122D = projs2012.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122DLenght = projs20122D.length
    var projs20122DNumeros = [];
    for (var d2 = 0; d2 < projs20122DLenght; d2++)
    projs20122DNumeros.push(Number(projs20122D[d2]))
    const projs2012Dolar2 = projs20122DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2012 = (projs2012Dolar1+projs2012Dolar2)
    
    //2013 reais
    const projs2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20131R = projs2013.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131RLenght = projs20131R.length
    var projs20131RNumeros = [];
    for (var r1 = 0; r1 < projs20131RLenght; r1++)
    projs20131RNumeros.push(Number(projs20131R[r1]))
    const projs2013Reais1 = projs20131RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132R = projs2013.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132RLenght = projs20132R.length
    var projs20132RNumeros = [];
    for (var r2 = 0; r2 < projs20132RLenght; r2++)
    projs20132RNumeros.push(Number(projs20132R[r2]))
    const projs2013Reais2 = projs20132RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2013 = (projs2013Reais1+projs2013Reais2)
    
    //2013 dolar
    const projs20131D = projs2013.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131DLenght = projs20131D.length
    var projs20131DNumeros = [];
    for (var d1 = 0; d1 < projs20131DLenght; d1++)
    projs20131DNumeros.push(Number(projs20131D[d1]))
    const projs2013Dolar1 = projs20131DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132D = projs2013.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132DLenght = projs20132D.length
    var projs20132DNumeros = [];
    for (var d2 = 0; d2 < projs20132DLenght; d2++)
    projs20132DNumeros.push(Number(projs20132D[d2]))
    const projs2013Dolar2 = projs20132DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2013 = (projs2013Dolar1+projs2013Dolar2)
    
    //2014 reais
    const projs2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20141R = projs2014.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141RLenght = projs20141R.length
    var projs20141RNumeros = [];
    for (var r1 = 0; r1 < projs20141RLenght; r1++)
    projs20141RNumeros.push(Number(projs20141R[r1]))
    const projs2014Reais1 = projs20141RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142R = projs2014.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142RLenght = projs20142R.length
    var projs20142RNumeros = [];
    for (var r2 = 0; r2 < projs20142RLenght; r2++)
    projs20142RNumeros.push(Number(projs20142R[r2]))
    const projs2014Reais2 = projs20142RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2014 = (projs2014Reais1+projs2014Reais2)
    
    //2014 dolar
    const projs20141D = projs2014.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141DLenght = projs20141D.length
    var projs20141DNumeros = [];
    for (var d1 = 0; d1 < projs20141DLenght; d1++)
    projs20141DNumeros.push(Number(projs20141D[d1]))
    const projs2014Dolar1 = projs20141DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142D = projs2014.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142DLenght = projs20142D.length
    var projs20142DNumeros = [];
    for (var d2 = 0; d2 < projs20142DLenght; d2++)
    projs20142DNumeros.push(Number(projs20142D[d2]))
    const projs2014Dolar2 = projs20142DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2014 = (projs2014Dolar1+projs2014Dolar2)
    
    
    //2015 reais
    const projs2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20151R = projs2015.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151RLenght = projs20151R.length
    var projs20151RNumeros = [];
    for (var r1 = 0; r1 < projs20151RLenght; r1++)
    projs20151RNumeros.push(Number(projs20151R[r1]))
    const projs2015Reais1 = projs20151RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152R = projs2015.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152RLenght = projs20152R.length
    var projs20152RNumeros = [];
    for (var r2 = 0; r2 < projs20152RLenght; r2++)
    projs20152RNumeros.push(Number(projs20152R[r2]))
    const projs2015Reais2 = projs20152RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2015 = (projs2015Reais1+projs2015Reais2)
    
    //2015 dolar
    const projs20151D = projs2015.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151DLenght = projs20151D.length
    var projs20151DNumeros = [];
    for (var d1 = 0; d1 < projs20151DLenght; d1++)
    projs20151DNumeros.push(Number(projs20151D[d1]))
    const projs2015Dolar1 = projs20151DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152D = projs2015.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152DLenght = projs20152D.length
    var projs20152DNumeros = [];
    for (var d2 = 0; d2 < projs20152DLenght; d2++)
    projs20152DNumeros.push(Number(projs20152D[d2]))
    const projs2015Dolar2 = projs20152DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2015 = (projs2015Dolar1+projs2015Dolar2)
    
    //2016 reais
    const projs2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20161R = projs2016.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161RLenght = projs20161R.length
    var projs20161RNumeros = [];
    for (var r1 = 0; r1 < projs20161RLenght; r1++)
    projs20161RNumeros.push(Number(projs20161R[r1]))
    const projs2016Reais1 = projs20161RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162R = projs2016.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162RLenght = projs20162R.length
    var projs20162RNumeros = [];
    for (var r2 = 0; r2 < projs20162RLenght; r2++)
    projs20162RNumeros.push(Number(projs20162R[r2]))
    const projs2016Reais2 = projs20162RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2016 = (projs2016Reais1+projs2016Reais2)
    
    //2016 dolar
    const projs20161D = projs2016.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161DLenght = projs20161D.length
    var projs20161DNumeros = [];
    for (var d1 = 0; d1 < projs20161DLenght; d1++)
    projs20161DNumeros.push(Number(projs20161D[d1]))
    const projs2016Dolar1 = projs20161DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162D = projs2016.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162DLenght = projs20162D.length
    var projs20162DNumeros = [];
    for (var d2 = 0; d2 < projs20162DLenght; d2++)
    projs20162DNumeros.push(Number(projs20162D[d2]))
    const projs2016Dolar2 = projs20162DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2016 = (projs2016Dolar1+projs2016Dolar2)
    
    //2017 reais
    const projs2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20171R = projs2017.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171RLenght = projs20171R.length
    var projs20171RNumeros = [];
    for (var r1 = 0; r1 < projs20171RLenght; r1++)
    projs20171RNumeros.push(Number(projs20171R[r1]))
    const projs2017Reais1 = projs20171RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172R = projs2017.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172RLenght = projs20172R.length
    var projs20172RNumeros = [];
    for (var r2 = 0; r2 < projs20172RLenght; r2++)
    projs20172RNumeros.push(Number(projs20172R[r2]))
    const projs2017Reais2 = projs20172RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2017 = (projs2017Reais1+projs2017Reais2)
    
    //2017 dolar
    const projs20171D = projs2017.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171DLenght = projs20171D.length
    var projs20171DNumeros = [];
    for (var d1 = 0; d1 < projs20171DLenght; d1++)
    projs20171DNumeros.push(Number(projs20171D[d1]))
    const projs2017Dolar1 = projs20171DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172D = projs2017.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172DLenght = projs20172D.length
    var projs20172DNumeros = [];
    for (var d2 = 0; d2 < projs20172DLenght; d2++)
    projs20172DNumeros.push(Number(projs20172D[d2]))
    const projs2017Dolar2 = projs20172DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2017 = (projs2017Dolar1+projs2017Dolar2)
    
    //2018 reais
    const projs2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20181R = projs2018.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181RLenght = projs20181R.length
    var projs20181RNumeros = [];
    for (var r1 = 0; r1 < projs20181RLenght; r1++)
    projs20181RNumeros.push(Number(projs20181R[r1]))
    const projs2018Reais1 = projs20181RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182R = projs2018.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182RLenght = projs20182R.length
    var projs20182RNumeros = [];
    for (var r2 = 0; r2 < projs20182RLenght; r2++)
    projs20182RNumeros.push(Number(projs20182R[r2]))
    const projs2018Reais2 = projs20182RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2018 = (projs2018Reais1+projs2018Reais2)
    
    //2018 dolar
    const projs20181D = projs2018.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181DLenght = projs20181D.length
    var projs20181DNumeros = [];
    for (var d1 = 0; d1 < projs20181DLenght; d1++)
    projs20181DNumeros.push(Number(projs20181D[d1]))
    const projs2018Dolar1 = projs20181DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182D = projs2018.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182DLenght = projs20182D.length
    var projs20182DNumeros = [];
    for (var d2 = 0; d2 < projs20182DLenght; d2++)
    projs20182DNumeros.push(Number(projs20182D[d2]))
    const projs2018Dolar2 = projs20182DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2018 = (projs2018Dolar1+projs2018Dolar2)
    
    //2019 reais
    const projs2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20191R = projs2019.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191RLenght = projs20191R.length
    var projs20191RNumeros = [];
    for (var r1 = 0; r1 < projs20191RLenght; r1++)
    projs20191RNumeros.push(Number(projs20191R[r1]))
    const projs2019Reais1 = projs20191RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192R = projs2019.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192RLenght = projs20192R.length
    var projs20192RNumeros = [];
    for (var r2 = 0; r2 < projs20192RLenght; r2++)
    projs20192RNumeros.push(Number(projs20192R[r2]))
    const projs2019Reais2 = projs20192RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2019 = (projs2019Reais1+projs2019Reais2)
    
    //2019 dolar
    const projs20191D = projs2019.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191DLenght = projs20191D.length
    var projs20191DNumeros = [];
    for (var d1 = 0; d1 < projs20191DLenght; d1++)
    projs20191DNumeros.push(Number(projs20191D[d1]))
    const projs2019Dolar1 = projs20191DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192D = projs2019.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192DLenght = projs20192D.length
    var projs20192DNumeros = [];
    for (var d2 = 0; d2 < projs20192DLenght; d2++)
    projs20192DNumeros.push(Number(projs20192D[d2]))
    const projs2019Dolar2 = projs20192DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2019 = (projs2019Dolar1+projs2019Dolar2)
    
    //2020 reais
    const projs2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20201R = projs2020.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201RLenght = projs20201R.length
    var projs20201RNumeros = [];
    for (var r1 = 0; r1 < projs20201RLenght; r1++)
    projs20201RNumeros.push(Number(projs20201R[r1]))
    const projs2020Reais1 = projs20201RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202R = projs2020.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202RLenght = projs20202R.length
    var projs20202RNumeros = [];
    for (var r2 = 0; r2 < projs20202RLenght; r2++)
    projs20202RNumeros.push(Number(projs20202R[r2]))
    const projs2020Reais2 = projs20202RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2020 = (projs2020Reais1+projs2020Reais2)
    
    //2020 dolar
    const projs20201D = projs2020.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201DLenght = projs20201D.length
    var projs20201DNumeros = [];
    for (var d1 = 0; d1 < projs20201DLenght; d1++)
    projs20201DNumeros.push(Number(projs20201D[d1]))
    const projs2020Dolar1 = projs20201DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202D = projs2020.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202DLenght = projs20202D.length
    var projs20202DNumeros = [];
    for (var d2 = 0; d2 < projs20202DLenght; d2++)
    projs20202DNumeros.push(Number(projs20202D[d2]))
    const projs2020Dolar2 = projs20202DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2020 = (projs2020Dolar1+projs2020Dolar2)
    
    //2021 reais
    const projs2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20211R = projs2021.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211RLenght = projs20211R.length
    var projs20211RNumeros = [];
    for (var r1 = 0; r1 < projs20211RLenght; r1++)
    projs20211RNumeros.push(Number(projs20211R[r1]))
    const projs2021Reais1 = projs20211RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212R = projs2021.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212RLenght = projs20212R.length
    var projs20212RNumeros = [];
    for (var r2 = 0; r2 < projs20212RLenght; r2++)
    projs20212RNumeros.push(Number(projs20212R[r2]))
    const projs2021Reais2 = projs20212RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2021 = (projs2021Reais1+projs2021Reais2)
    
    //2021 dolar
    const projs20211D = projs2021.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211DLenght = projs20211D.length
    var projs20211DNumeros = [];
    for (var d1 = 0; d1 < projs20211DLenght; d1++)
    projs20211DNumeros.push(Number(projs20211D[d1]))
    const projs2021Dolar1 = projs20211DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212D = projs2021.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212DLenght = projs20212D.length
    var projs20212DNumeros = [];
    for (var d2 = 0; d2 < projs20212DLenght; d2++)
    projs20212DNumeros.push(Number(projs20212D[d2]))
    const projs2021Dolar2 = projs20212DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2021 = (projs2021Dolar1+projs2021Dolar2)
    
    //2022 reais
    const projs2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20221R = projs2022.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221RLenght = projs20221R.length
    var projs20221RNumeros = [];
    for (var r1 = 0; r1 < projs20221RLenght; r1++)
    projs20221RNumeros.push(Number(projs20221R[r1]))
    const projs2022Reais1 = projs20221RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222R = projs2022.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222RLenght = projs20222R.length
    var projs20222RNumeros = [];
    for (var r2 = 0; r2 < projs20222RLenght; r2++)
    projs20222RNumeros.push(Number(projs20222R[r2]))
    const projs2022Reais2 = projs20222RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2022 = projs2022Reais1+projs2022Reais2
    
    //2022 dolar
    const projs20221D = projs2022.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221DLenght = projs20221D.length
    var projs20221DNumeros = [];
    for (var d1 = 0; d1 < projs20221DLenght; d1++)
    projs20221DNumeros.push(Number(projs20221D[d1]))
    const projs2022Dolar1 = projs20221DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222D = projs2022.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222DLenght = projs20222D.length
    var projs20222DNumeros = [];
    for (var d2 = 0; d2 < projs20222DLenght; d2++)
    projs20222DNumeros.push(Number(projs20222D[d2]))
    const projs2022Dolar2 = projs20222DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2022 = projs2022Dolar1+projs2022Dolar2
    
    //2023 reais
    const projs2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20231R = projs2023.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231RLenght = projs20231R.length
    var projs20231RNumeros = [];
    for (var r1 = 0; r1 < projs20231RLenght; r1++)
    projs20231RNumeros.push(Number(projs20231R[r1]))
    const projs2023Reais1 = projs20231RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232R = projs2023.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232RLenght = projs20232R.length
    var projs20232RNumeros = [];
    for (var r2 = 0; r2 < projs20232RLenght; r2++)
    projs20232RNumeros.push(Number(projs20232R[r2]))
    const projs2023Reais2 = projs20232RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2023 = projs2023Reais1+projs2023Reais2
    
    //2023 dolar
    const projs20231D = projs2023.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231DLenght = projs20231D.length
    var projs20231DNumeros = [];
    for (var d1 = 0; d1 < projs20231DLenght; d1++)
    projs20231DNumeros.push(Number(projs20231D[d1]))
    const projs2023Dolar1 = projs20231DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232D = projs2023.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232DLenght = projs20232D.length
    var projs20232DNumeros = [];
    for (var d2 = 0; d2 < projs20232DLenght; d2++)
    projs20232DNumeros.push(Number(projs20232D[d2]))
    const projs2023Dolar2 = projs20232DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2023 = projs2023Dolar1+projs2023Dolar2
    
    
    //2024 reais
    const projs2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    const projs20241R = projs2024.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241RLenght = projs20241R.length
    var projs20241RNumeros = [];
    for (var r1 = 0; r1 < projs20241RLenght; r1++)
    projs20241RNumeros.push(Number(projs20241R[r1]))
    const projs2024Reais1 = projs20241RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242R = projs2024.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242RLenght = projs20242R.length
    var projs20242RNumeros = [];
    for (var r2 = 0; r2 < projs20242RLenght; r2++)
    projs20242RNumeros.push(Number(projs20242R[r2]))
    const projs2024Reais2 = projs20242RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2024 = projs2024Reais1+projs2024Reais2
    
    //2024 dolar
    const projs20241D = projs2024.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241DLenght = projs20241D.length
    var projs20241DNumeros = [];
    for (var d1 = 0; d1 < projs20241DLenght; d1++)
    projs20241DNumeros.push(Number(projs20241D[d1]))
    const projs2024Dolar1 = projs20241DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242D = projs2024.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242DLenght = projs20242D.length
    var projs20242DNumeros = [];
    for (var d2 = 0; d2 < projs20242DLenght; d2++)
    projs20242DNumeros.push(Number(projs20242D[d2]))
    const projs2024Dolar2 = projs20242DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2024 = projs2024Dolar1+projs2024Dolar2

     //2002Cl2 reais
     const projs2002Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2002Cl21R = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21RLenght = projs2002Cl21R.length
        var projs2002Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2002Cl21RLenght; r1++)
        projs2002Cl21RNumeros.push(Number(projs2002Cl21R[r1]))
        const projs2002Cl2Reais1 = projs2002Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22R = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22RLenght = projs2002Cl22R.length
        var projs2002Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2002Cl22RLenght; r2++)
        projs2002Cl22RNumeros.push(Number(projs2002Cl22R[r2]))
        const projs2002Cl2Reais2 = projs2002Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002Cl2 = (projs2002Cl2Reais1+projs2002Cl2Reais2)
    
        //2002Cl2 dolar
        const projs2002Cl21D = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21DLenght = projs2002Cl21D.length
        var projs2002Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2002Cl21DLenght; d1++)
        projs2002Cl21DNumeros.push(Number(projs2002Cl21D[d1]))
        const projs2002Cl2Dolar1 = projs2002Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22D = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22DLenght = projs2002Cl22D.length
        var projs2002Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2002Cl22DLenght; d2++)
        projs2002Cl22DNumeros.push(Number(projs2002Cl22D[d2]))
        const projs2002Cl2Dolar2 = projs2002Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002Cl2 = (projs2002Cl2Dolar1+projs2002Cl2Dolar2)
    
         //2003Cl2 reais
        const projs2003Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2003Cl21R = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21RLenght = projs2003Cl21R.length
        var projs2003Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2003Cl21RLenght; r1++)
        projs2003Cl21RNumeros.push(Number(projs2003Cl21R[r1]))
        const projs2003Cl2Reais1 = projs2003Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22R = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22RLenght = projs2003Cl22R.length
        var projs2003Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2003Cl22RLenght; r2++)
        projs2003Cl22RNumeros.push(Number(projs2003Cl22R[r2]))
        const projs2003Cl2Reais2 = projs2003Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003Cl2 = (projs2003Cl2Reais1+projs2003Cl2Reais2)
        
        //2003Cl2 dolar
        const projs2003Cl21D = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21DLenght = projs2003Cl21D.length
        var projs2003Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2003Cl21DLenght; d1++)
        projs2003Cl21DNumeros.push(Number(projs2003Cl21D[d1]))
        const projs2003Cl2Dolar1 = projs2003Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22D = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22DLenght = projs2003Cl22D.length
        var projs2003Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2003Cl22DLenght; d2++)
        projs2003Cl22DNumeros.push(Number(projs2003Cl22D[d2]))
        const projs2003Cl2Dolar2 = projs2003Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003Cl2 = (projs2003Cl2Dolar1+projs2003Cl2Dolar2)
    
        //2004Cl2 reais
        const projs2004Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2004Cl21R = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21RLenght = projs2004Cl21R.length
        var projs2004Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2004Cl21RLenght; r1++)
        projs2004Cl21RNumeros.push(Number(projs2004Cl21R[r1]))
        const projs2004Cl2Reais1 = projs2004Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22R = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22RLenght = projs2004Cl22R.length
        var projs2004Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2004Cl22RLenght; r2++)
        projs2004Cl22RNumeros.push(Number(projs2004Cl22R[r2]))
        const projs2004Cl2Reais2 = projs2004Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004Cl2 = (projs2004Cl2Reais1+projs2004Cl2Reais2)
        
        //2004Cl2 dolar
        const projs2004Cl21D = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21DLenght = projs2004Cl21D.length
        var projs2004Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2004Cl21DLenght; d1++)
        projs2004Cl21DNumeros.push(Number(projs2004Cl21D[d1]))
        const projs2004Cl2Dolar1 = projs2004Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22D = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22DLenght = projs2004Cl22D.length
        var projs2004Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2004Cl22DLenght; d2++)
        projs2004Cl22DNumeros.push(Number(projs2004Cl22D[d2]))
        const projs2004Cl2Dolar2 = projs2004Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004Cl2 = (projs2004Cl2Dolar1+projs2004Cl2Dolar2)
    
        //2005Cl2 reais
        const projs2005Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2005Cl21R = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21RLenght = projs2005Cl21R.length
        var projs2005Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2005Cl21RLenght; r1++)
        projs2005Cl21RNumeros.push(Number(projs2005Cl21R[r1]))
        const projs2005Cl2Reais1 = projs2005Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22R = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22RLenght = projs2005Cl22R.length
        var projs2005Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2005Cl22RLenght; r2++)
        projs2005Cl22RNumeros.push(Number(projs2005Cl22R[r2]))
        const projs2005Cl2Reais2 = projs2005Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005Cl2 = (projs2005Cl2Reais1+projs2005Cl2Reais2)
        
        //2005Cl2 dolar
        const projs2005Cl21D = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21DLenght = projs2005Cl21D.length
        var projs2005Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2005Cl21DLenght; d1++)
        projs2005Cl21DNumeros.push(Number(projs2005Cl21D[d1]))
        const projs2005Cl2Dolar1 = projs2005Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22D = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22DLenght = projs2005Cl22D.length
        var projs2005Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2005Cl22DLenght; d2++)
        projs2005Cl22DNumeros.push(Number(projs2005Cl22D[d2]))
        const projs2005Cl2Dolar2 = projs2005Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005Cl2 = (projs2005Cl2Dolar1+projs2005Cl2Dolar2)
    
        //2006Cl2 reais
        const projs2006Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
        const projs2006Cl21R = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21RLenght = projs2006Cl21R.length
        var projs2006Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2006Cl21RLenght; r1++)
        projs2006Cl21RNumeros.push(Number(projs2006Cl21R[r1]))
        const projs2006Cl2Reais1 = projs2006Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22R = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22RLenght = projs2006Cl22R.length
        var projs2006Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2006Cl22RLenght; r2++)
        projs2006Cl22RNumeros.push(Number(projs2006Cl22R[r2]))
        const projs2006Cl2Reais2 = projs2006Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006Cl2 = (projs2006Cl2Reais1+projs2006Cl2Reais2)
        
        //2006Cl2 dolar
        const projs2006Cl21D = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21DLenght = projs2006Cl21D.length
        var projs2006Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2006Cl21DLenght; d1++)
        projs2006Cl21DNumeros.push(Number(projs2006Cl21D[d1]))
        const projs2006Cl2Dolar1 = projs2006Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22D = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22DLenght = projs2006Cl22D.length
        var projs2006Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2006Cl22DLenght; d2++)
        projs2006Cl22DNumeros.push(Number(projs2006Cl22D[d2]))
        const projs2006Cl2Dolar2 = projs2006Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006Cl2 = (projs2006Cl2Dolar1+projs2006Cl2Dolar2)
    
        //2007Cl2 reais
        const projs2007Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2007Cl21R = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21RLenght = projs2007Cl21R.length
        var projs2007Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2007Cl21RLenght; r1++)
        projs2007Cl21RNumeros.push(Number(projs2007Cl21R[r1]))
        const projs2007Cl2Reais1 = projs2007Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22R = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22RLenght = projs2007Cl22R.length
        var projs2007Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2007Cl22RLenght; r2++)
        projs2007Cl22RNumeros.push(Number(projs2007Cl22R[r2]))
        const projs2007Cl2Reais2 = projs2007Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007Cl2 = (projs2007Cl2Reais1+projs2007Cl2Reais2)
        
        //2007Cl2 dolar
        const projs2007Cl21D = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21DLenght = projs2007Cl21D.length
        var projs2007Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2007Cl21DLenght; d1++)
        projs2007Cl21DNumeros.push(Number(projs2007Cl21D[d1]))
        const projs2007Cl2Dolar1 = projs2007Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22D = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22DLenght = projs2007Cl22D.length
        var projs2007Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2007Cl22DLenght; d2++)
        projs2007Cl22DNumeros.push(Number(projs2007Cl22D[d2]))
        const projs2007Cl2Dolar2 = projs2007Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007Cl2 = (projs2007Cl2Dolar1+projs2007Cl2Dolar2)
    
        //2008Cl2 reais
        const projs2008Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2008Cl21R = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21RLenght = projs2008Cl21R.length
        var projs2008Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2008Cl21RLenght; r1++)
        projs2008Cl21RNumeros.push(Number(projs2008Cl21R[r1]))
        const projs2008Cl2Reais1 = projs2008Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22R = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22RLenght = projs2008Cl22R.length
        var projs2008Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2008Cl22RLenght; r2++)
        projs2008Cl22RNumeros.push(Number(projs2008Cl22R[r2]))
        const projs2008Cl2Reais2 = projs2008Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008Cl2 = (projs2008Cl2Reais1+projs2008Cl2Reais2)
        
        //2008Cl2 dolar
        const projs2008Cl21D = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21DLenght = projs2008Cl21D.length
        var projs2008Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2008Cl21DLenght; d1++)
        projs2008Cl21DNumeros.push(Number(projs2008Cl21D[d1]))
        const projs2008Cl2Dolar1 = projs2008Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22D = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22DLenght = projs2008Cl22D.length
        var projs2008Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2008Cl22DLenght; d2++)
        projs2008Cl22DNumeros.push(Number(projs2008Cl22D[d2]))
        const projs2008Cl2Dolar2 = projs2008Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008Cl2 = (projs2008Cl2Dolar1+projs2008Cl2Dolar2)
    
        //2009Cl2 reais
        const projs2009Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2009Cl21R = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21RLenght = projs2009Cl21R.length
        var projs2009Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2009Cl21RLenght; r1++)
        projs2009Cl21RNumeros.push(Number(projs2009Cl21R[r1]))
        const projs2009Cl2Reais1 = projs2009Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22R = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22RLenght = projs2009Cl22R.length
        var projs2009Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2009Cl22RLenght; r2++)
        projs2009Cl22RNumeros.push(Number(projs2009Cl22R[r2]))
        const projs2009Cl2Reais2 = projs2009Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009Cl2 = (projs2009Cl2Reais1+projs2009Cl2Reais2)
        
        //2009Cl2 dolar
        const projs2009Cl21D = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21DLenght = projs2009Cl21D.length
        var projs2009Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2009Cl21DLenght; d1++)
        projs2009Cl21DNumeros.push(Number(projs2009Cl21D[d1]))
        const projs2009Cl2Dolar1 = projs2009Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22D = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22DLenght = projs2009Cl22D.length
        var projs2009Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2009Cl22DLenght; d2++)
        projs2009Cl22DNumeros.push(Number(projs2009Cl22D[d2]))
        const projs2009Cl2Dolar2 = projs2009Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009Cl2 = (projs2009Cl2Dolar1+projs2009Cl2Dolar2)
    
        //2010Cl2 reais
        const projs2010Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2010Cl21R = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21RLenght = projs2010Cl21R.length
        var projs2010Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2010Cl21RLenght; r1++)
        projs2010Cl21RNumeros.push(Number(projs2010Cl21R[r1]))
        const projs2010Cl2Reais1 = projs2010Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22R = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22RLenght = projs2010Cl22R.length
        var projs2010Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2010Cl22RLenght; r2++)
        projs2010Cl22RNumeros.push(Number(projs2010Cl22R[r2]))
        const projs2010Cl2Reais2 = projs2010Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010Cl2 = (projs2010Cl2Reais1+projs2010Cl2Reais2)
        
        //2010Cl2 dolar
        const projs2010Cl21D = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21DLenght = projs2010Cl21D.length
        var projs2010Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2010Cl21DLenght; d1++)
        projs2010Cl21DNumeros.push(Number(projs2010Cl21D[d1]))
        const projs2010Cl2Dolar1 = projs2010Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22D = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22DLenght = projs2010Cl22D.length
        var projs2010Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2010Cl22DLenght; d2++)
        projs2010Cl22DNumeros.push(Number(projs2010Cl22D[d2]))
        const projs2010Cl2Dolar2 = projs2010Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010Cl2 = (projs2010Cl2Dolar1+projs2010Cl2Dolar2)
    
        //2011Cl2 reais
        const projs2011Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2011Cl21R = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21RLenght = projs2011Cl21R.length
        var projs2011Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2011Cl21RLenght; r1++)
        projs2011Cl21RNumeros.push(Number(projs2011Cl21R[r1]))
        const projs2011Cl2Reais1 = projs2011Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22R = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22RLenght = projs2011Cl22R.length
        var projs2011Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2011Cl22RLenght; r2++)
        projs2011Cl22RNumeros.push(Number(projs2011Cl22R[r2]))
        const projs2011Cl2Reais2 = projs2011Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2011Cl2 = (projs2011Cl2Reais1+projs2011Cl2Reais2)
        
        //2011Cl2 dolar
        const projs2011Cl21D = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21DLenght = projs2011Cl21D.length
        var projs2011Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2011Cl21DLenght; d1++)
        projs2011Cl21DNumeros.push(Number(projs2011Cl21D[d1]))
        const projs2011Cl2Dolar1 = projs2011Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22D = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22DLenght = projs2011Cl22D.length
        var projs2011Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2011Cl22DLenght; d2++)
        projs2011Cl22DNumeros.push(Number(projs2011Cl22D[d2]))
        const projs2011Cl2Dolar2 = projs2011Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2011Cl2 = (projs2011Cl2Dolar1+projs2011Cl2Dolar2)
    
        //2012Cl2 reais
        const projs2012Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2012Cl21R = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21RLenght = projs2012Cl21R.length
        var projs2012Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2012Cl21RLenght; r1++)
        projs2012Cl21RNumeros.push(Number(projs2012Cl21R[r1]))
        const projs2012Cl2Reais1 = projs2012Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22R = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22RLenght = projs2012Cl22R.length
        var projs2012Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2012Cl22RLenght; r2++)
        projs2012Cl22RNumeros.push(Number(projs2012Cl22R[r2]))
        const projs2012Cl2Reais2 = projs2012Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2012Cl2 = (projs2012Cl2Reais1+projs2012Cl2Reais2)
        
        //2012Cl2 dolar
        const projs2012Cl21D = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21DLenght = projs2012Cl21D.length
        var projs2012Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2012Cl21DLenght; d1++)
        projs2012Cl21DNumeros.push(Number(projs2012Cl21D[d1]))
        const projs2012Cl2Dolar1 = projs2012Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22D = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22DLenght = projs2012Cl22D.length
        var projs2012Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2012Cl22DLenght; d2++)
        projs2012Cl22DNumeros.push(Number(projs2012Cl22D[d2]))
        const projs2012Cl2Dolar2 = projs2012Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2012Cl2 = (projs2012Cl2Dolar1+projs2012Cl2Dolar2)
        
        //2013Cl2 reais
        const projs2013Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2013Cl21R = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21RLenght = projs2013Cl21R.length
        var projs2013Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2013Cl21RLenght; r1++)
        projs2013Cl21RNumeros.push(Number(projs2013Cl21R[r1]))
        const projs2013Cl2Reais1 = projs2013Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22R = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22RLenght = projs2013Cl22R.length
        var projs2013Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2013Cl22RLenght; r2++)
        projs2013Cl22RNumeros.push(Number(projs2013Cl22R[r2]))
        const projs2013Cl2Reais2 = projs2013Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2013Cl2 = (projs2013Cl2Reais1+projs2013Cl2Reais2)
        
        //2013Cl2 dolar
        const projs2013Cl21D = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21DLenght = projs2013Cl21D.length
        var projs2013Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2013Cl21DLenght; d1++)
        projs2013Cl21DNumeros.push(Number(projs2013Cl21D[d1]))
        const projs2013Cl2Dolar1 = projs2013Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22D = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22DLenght = projs2013Cl22D.length
        var projs2013Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2013Cl22DLenght; d2++)
        projs2013Cl22DNumeros.push(Number(projs2013Cl22D[d2]))
        const projs2013Cl2Dolar2 = projs2013Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2013Cl2 = (projs2013Cl2Dolar1+projs2013Cl2Dolar2)
        
        //2014Cl2 reais
        const projs2014Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2014Cl21R = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21RLenght = projs2014Cl21R.length
        var projs2014Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2014Cl21RLenght; r1++)
        projs2014Cl21RNumeros.push(Number(projs2014Cl21R[r1]))
        const projs2014Cl2Reais1 = projs2014Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22R = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22RLenght = projs2014Cl22R.length
        var projs2014Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2014Cl22RLenght; r2++)
        projs2014Cl22RNumeros.push(Number(projs2014Cl22R[r2]))
        const projs2014Cl2Reais2 = projs2014Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2014Cl2 = (projs2014Cl2Reais1+projs2014Cl2Reais2)
        
        //2014Cl2 dolar
        const projs2014Cl21D = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21DLenght = projs2014Cl21D.length
        var projs2014Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2014Cl21DLenght; d1++)
        projs2014Cl21DNumeros.push(Number(projs2014Cl21D[d1]))
        const projs2014Cl2Dolar1 = projs2014Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22D = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22DLenght = projs2014Cl22D.length
        var projs2014Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2014Cl22DLenght; d2++)
        projs2014Cl22DNumeros.push(Number(projs2014Cl22D[d2]))
        const projs2014Cl2Dolar2 = projs2014Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2014Cl2 = (projs2014Cl2Dolar1+projs2014Cl2Dolar2)
        
        
        //2015Cl2 reais
        const projs2015Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2015Cl21R = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21RLenght = projs2015Cl21R.length
        var projs2015Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2015Cl21RLenght; r1++)
        projs2015Cl21RNumeros.push(Number(projs2015Cl21R[r1]))
        const projs2015Cl2Reais1 = projs2015Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22R = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22RLenght = projs2015Cl22R.length
        var projs2015Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2015Cl22RLenght; r2++)
        projs2015Cl22RNumeros.push(Number(projs2015Cl22R[r2]))
        const projs2015Cl2Reais2 = projs2015Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2015Cl2 = (projs2015Cl2Reais1+projs2015Cl2Reais2)
        
        //2015Cl2 dolar
        const projs2015Cl21D = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21DLenght = projs2015Cl21D.length
        var projs2015Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2015Cl21DLenght; d1++)
        projs2015Cl21DNumeros.push(Number(projs2015Cl21D[d1]))
        const projs2015Cl2Dolar1 = projs2015Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22D = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22DLenght = projs2015Cl22D.length
        var projs2015Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2015Cl22DLenght; d2++)
        projs2015Cl22DNumeros.push(Number(projs2015Cl22D[d2]))
        const projs2015Cl2Dolar2 = projs2015Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2015Cl2 = (projs2015Cl2Dolar1+projs2015Cl2Dolar2)
        
        //2016Cl2 reais
        const projs2016Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2016Cl21R = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21RLenght = projs2016Cl21R.length
        var projs2016Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2016Cl21RLenght; r1++)
        projs2016Cl21RNumeros.push(Number(projs2016Cl21R[r1]))
        const projs2016Cl2Reais1 = projs2016Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22R = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22RLenght = projs2016Cl22R.length
        var projs2016Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2016Cl22RLenght; r2++)
        projs2016Cl22RNumeros.push(Number(projs2016Cl22R[r2]))
        const projs2016Cl2Reais2 = projs2016Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2016Cl2 = (projs2016Cl2Reais1+projs2016Cl2Reais2)
        
        //2016Cl2 dolar
        const projs2016Cl21D = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21DLenght = projs2016Cl21D.length
        var projs2016Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2016Cl21DLenght; d1++)
        projs2016Cl21DNumeros.push(Number(projs2016Cl21D[d1]))
        const projs2016Cl2Dolar1 = projs2016Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22D = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22DLenght = projs2016Cl22D.length
        var projs2016Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2016Cl22DLenght; d2++)
        projs2016Cl22DNumeros.push(Number(projs2016Cl22D[d2]))
        const projs2016Cl2Dolar2 = projs2016Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2016Cl2 = (projs2016Cl2Dolar1+projs2016Cl2Dolar2)
        
        //2017Cl2 reais
        const projs2017Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017Cl2')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2017Cl21R = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21RLenght = projs2017Cl21R.length
        var projs2017Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2017Cl21RLenght; r1++)
        projs2017Cl21RNumeros.push(Number(projs2017Cl21R[r1]))
        const projs2017Cl2Reais1 = projs2017Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22R = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22RLenght = projs2017Cl22R.length
        var projs2017Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2017Cl22RLenght; r2++)
        projs2017Cl22RNumeros.push(Number(projs2017Cl22R[r2]))
        const projs2017Cl2Reais2 = projs2017Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2017Cl2 = (projs2017Cl2Reais1+projs2017Cl2Reais2)
        
        //2017Cl2 dolar
        const projs2017Cl21D = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21DLenght = projs2017Cl21D.length
        var projs2017Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2017Cl21DLenght; d1++)
        projs2017Cl21DNumeros.push(Number(projs2017Cl21D[d1]))
        const projs2017Cl2Dolar1 = projs2017Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22D = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22DLenght = projs2017Cl22D.length
        var projs2017Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2017Cl22DLenght; d2++)
        projs2017Cl22DNumeros.push(Number(projs2017Cl22D[d2]))
        const projs2017Cl2Dolar2 = projs2017Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2017Cl2 = (projs2017Cl2Dolar1+projs2017Cl2Dolar2)
        
        //2018Cl2 reais
        const projs2018Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2018Cl21R = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21RLenght = projs2018Cl21R.length
        var projs2018Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2018Cl21RLenght; r1++)
        projs2018Cl21RNumeros.push(Number(projs2018Cl21R[r1]))
        const projs2018Cl2Reais1 = projs2018Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22R = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22RLenght = projs2018Cl22R.length
        var projs2018Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2018Cl22RLenght; r2++)
        projs2018Cl22RNumeros.push(Number(projs2018Cl22R[r2]))
        const projs2018Cl2Reais2 = projs2018Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2018Cl2 = (projs2018Cl2Reais1+projs2018Cl2Reais2)
        
        //2018Cl2 dolar
        const projs2018Cl21D = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21DLenght = projs2018Cl21D.length
        var projs2018Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2018Cl21DLenght; d1++)
        projs2018Cl21DNumeros.push(Number(projs2018Cl21D[d1]))
        const projs2018Cl2Dolar1 = projs2018Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22D = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22DLenght = projs2018Cl22D.length
        var projs2018Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2018Cl22DLenght; d2++)
        projs2018Cl22DNumeros.push(Number(projs2018Cl22D[d2]))
        const projs2018Cl2Dolar2 = projs2018Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2018Cl2 = (projs2018Cl2Dolar1+projs2018Cl2Dolar2)
        
        //2019Cl2 reais
        const projs2019Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2019Cl21R = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21RLenght = projs2019Cl21R.length
        var projs2019Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2019Cl21RLenght; r1++)
        projs2019Cl21RNumeros.push(Number(projs2019Cl21R[r1]))
        const projs2019Cl2Reais1 = projs2019Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22R = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22RLenght = projs2019Cl22R.length
        var projs2019Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2019Cl22RLenght; r2++)
        projs2019Cl22RNumeros.push(Number(projs2019Cl22R[r2]))
        const projs2019Cl2Reais2 = projs2019Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2019Cl2 = (projs2019Cl2Reais1+projs2019Cl2Reais2)
        
        //2019Cl2 dolar
        const projs2019Cl21D = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21DLenght = projs2019Cl21D.length
        var projs2019Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2019Cl21DLenght; d1++)
        projs2019Cl21DNumeros.push(Number(projs2019Cl21D[d1]))
        const projs2019Cl2Dolar1 = projs2019Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22D = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22DLenght = projs2019Cl22D.length
        var projs2019Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2019Cl22DLenght; d2++)
        projs2019Cl22DNumeros.push(Number(projs2019Cl22D[d2]))
        const projs2019Cl2Dolar2 = projs2019Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2019Cl2 = (projs2019Cl2Dolar1+projs2019Cl2Dolar2)
        
        //2020Cl2 reais
        const projs2020Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2020Cl21R = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21RLenght = projs2020Cl21R.length
        var projs2020Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2020Cl21RLenght; r1++)
        projs2020Cl21RNumeros.push(Number(projs2020Cl21R[r1]))
        const projs2020Cl2Reais1 = projs2020Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22R = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22RLenght = projs2020Cl22R.length
        var projs2020Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2020Cl22RLenght; r2++)
        projs2020Cl22RNumeros.push(Number(projs2020Cl22R[r2]))
        const projs2020Cl2Reais2 = projs2020Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2020Cl2 = (projs2020Cl2Reais1+projs2020Cl2Reais2)
        
        //2020Cl2 dolar
        const projs2020Cl21D = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21DLenght = projs2020Cl21D.length
        var projs2020Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2020Cl21DLenght; d1++)
        projs2020Cl21DNumeros.push(Number(projs2020Cl21D[d1]))
        const projs2020Cl2Dolar1 = projs2020Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22D = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22DLenght = projs2020Cl22D.length
        var projs2020Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2020Cl22DLenght; d2++)
        projs2020Cl22DNumeros.push(Number(projs2020Cl22D[d2]))
        const projs2020Cl2Dolar2 = projs2020Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2020Cl2 = (projs2020Cl2Dolar1+projs2020Cl2Dolar2)
        
        //2021Cl2 reais
        const projs2021Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2021Cl21R = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21RLenght = projs2021Cl21R.length
        var projs2021Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2021Cl21RLenght; r1++)
        projs2021Cl21RNumeros.push(Number(projs2021Cl21R[r1]))
        const projs2021Cl2Reais1 = projs2021Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22R = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22RLenght = projs2021Cl22R.length
        var projs2021Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2021Cl22RLenght; r2++)
        projs2021Cl22RNumeros.push(Number(projs2021Cl22R[r2]))
        const projs2021Cl2Reais2 = projs2021Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2021Cl2 = (projs2021Cl2Reais1+projs2021Cl2Reais2)
        
        //2021Cl2 dolar
        const projs2021Cl21D = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21DLenght = projs2021Cl21D.length
        var projs2021Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2021Cl21DLenght; d1++)
        projs2021Cl21DNumeros.push(Number(projs2021Cl21D[d1]))
        const projs2021Cl2Dolar1 = projs2021Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22D = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22DLenght = projs2021Cl22D.length
        var projs2021Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2021Cl22DLenght; d2++)
        projs2021Cl22DNumeros.push(Number(projs2021Cl22D[d2]))
        const projs2021Cl2Dolar2 = projs2021Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2021Cl2 = (projs2021Cl2Dolar1+projs2021Cl2Dolar2)
        
        //2022Cl2 reais
        const projs2022Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2022Cl21R = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21RLenght = projs2022Cl21R.length
        var projs2022Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2022Cl21RLenght; r1++)
        projs2022Cl21RNumeros.push(Number(projs2022Cl21R[r1]))
        const projs2022Cl2Reais1 = projs2022Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22R = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22RLenght = projs2022Cl22R.length
        var projs2022Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2022Cl22RLenght; r2++)
        projs2022Cl22RNumeros.push(Number(projs2022Cl22R[r2]))
        const projs2022Cl2Reais2 = projs2022Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2022Cl2 = projs2022Cl2Reais1+projs2022Cl2Reais2
        
        //2022Cl2 dolar
        const projs2022Cl21D = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21DLenght = projs2022Cl21D.length
        var projs2022Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2022Cl21DLenght; d1++)
        projs2022Cl21DNumeros.push(Number(projs2022Cl21D[d1]))
        const projs2022Cl2Dolar1 = projs2022Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22D = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22DLenght = projs2022Cl22D.length
        var projs2022Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2022Cl22DLenght; d2++)
        projs2022Cl22DNumeros.push(Number(projs2022Cl22D[d2]))
        const projs2022Cl2Dolar2 = projs2022Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2022Cl2 = projs2022Cl2Dolar1+projs2022Cl2Dolar2
        
        //2023Cl2 reais
        const projs2023Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2023Cl21R = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21RLenght = projs2023Cl21R.length
        var projs2023Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2023Cl21RLenght; r1++)
        projs2023Cl21RNumeros.push(Number(projs2023Cl21R[r1]))
        const projs2023Cl2Reais1 = projs2023Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22R = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22RLenght = projs2023Cl22R.length
        var projs2023Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2023Cl22RLenght; r2++)
        projs2023Cl22RNumeros.push(Number(projs2023Cl22R[r2]))
        const projs2023Cl2Reais2 = projs2023Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2023Cl2 = projs2023Cl2Reais1+projs2023Cl2Reais2
        
        //2023Cl2 dolar
        const projs2023Cl21D = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21DLenght = projs2023Cl21D.length
        var projs2023Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2023Cl21DLenght; d1++)
        projs2023Cl21DNumeros.push(Number(projs2023Cl21D[d1]))
        const projs2023Cl2Dolar1 = projs2023Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22D = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22DLenght = projs2023Cl22D.length
        var projs2023Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2023Cl22DLenght; d2++)
        projs2023Cl22DNumeros.push(Number(projs2023Cl22D[d2]))
        const projs2023Cl2Dolar2 = projs2023Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2023Cl2 = projs2023Cl2Dolar1+projs2023Cl2Dolar2
        
        
        //2024Cl2 reais
        const projs2024Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        const projs2024Cl21R = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21RLenght = projs2024Cl21R.length
        var projs2024Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2024Cl21RLenght; r1++)
        projs2024Cl21RNumeros.push(Number(projs2024Cl21R[r1]))
        const projs2024Cl2Reais1 = projs2024Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22R = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22RLenght = projs2024Cl22R.length
        var projs2024Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2024Cl22RLenght; r2++)
        projs2024Cl22RNumeros.push(Number(projs2024Cl22R[r2]))
        const projs2024Cl2Reais2 = projs2024Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2024Cl2 = projs2024Cl2Reais1+projs2024Cl2Reais2
        
        //2024Cl2 dolar
        const projs2024Cl21D = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21DLenght = projs2024Cl21D.length
        var projs2024Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2024Cl21DLenght; d1++)
        projs2024Cl21DNumeros.push(Number(projs2024Cl21D[d1]))
        const projs2024Cl2Dolar1 = projs2024Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22D = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22DLenght = projs2024Cl22D.length
        var projs2024Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2024Cl22DLenght; d2++)
        projs2024Cl22DNumeros.push(Number(projs2024Cl22D[d2]))
        const projs2024Cl2Dolar2 = projs2024Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2024Cl2 = projs2024Cl2Dolar1+projs2024Cl2Dolar2


         //2002Cl3 reais
    const projs2002Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
        const projs2002Cl31R = projs2002Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl31RLenght = projs2002Cl31R.length
        var projs2002Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2002Cl31RLenght; r1++)
        projs2002Cl31RNumeros.push(Number(projs2002Cl31R[r1]))
        const projs2002Cl3Reais1 = projs2002Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl32R = projs2002Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl32RLenght = projs2002Cl32R.length
        var projs2002Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2002Cl32RLenght; r2++)
        projs2002Cl32RNumeros.push(Number(projs2002Cl32R[r2]))
        const projs2002Cl3Reais2 = projs2002Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002Cl3 = (projs2002Cl3Reais1+projs2002Cl3Reais2)
    
        //2002Cl3 dolar
        const projs2002Cl31D = projs2002Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl31DLenght = projs2002Cl31D.length
        var projs2002Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2002Cl31DLenght; d1++)
        projs2002Cl31DNumeros.push(Number(projs2002Cl31D[d1]))
        const projs2002Cl3Dolar1 = projs2002Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl32D = projs2002Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl32DLenght = projs2002Cl32D.length
        var projs2002Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2002Cl32DLenght; d2++)
        projs2002Cl32DNumeros.push(Number(projs2002Cl32D[d2]))
        const projs2002Cl3Dolar2 = projs2002Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002Cl3 = (projs2002Cl3Dolar1+projs2002Cl3Dolar2)
    
         //2003Cl3 reais
        const projs2003Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
        const projs2003Cl31R = projs2003Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl31RLenght = projs2003Cl31R.length
        var projs2003Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2003Cl31RLenght; r1++)
        projs2003Cl31RNumeros.push(Number(projs2003Cl31R[r1]))
        const projs2003Cl3Reais1 = projs2003Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl32R = projs2003Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl32RLenght = projs2003Cl32R.length
        var projs2003Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2003Cl32RLenght; r2++)
        projs2003Cl32RNumeros.push(Number(projs2003Cl32R[r2]))
        const projs2003Cl3Reais2 = projs2003Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003Cl3 = (projs2003Cl3Reais1+projs2003Cl3Reais2)
        
        //2003Cl3 dolar
        const projs2003Cl31D = projs2003Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl31DLenght = projs2003Cl31D.length
        var projs2003Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2003Cl31DLenght; d1++)
        projs2003Cl31DNumeros.push(Number(projs2003Cl31D[d1]))
        const projs2003Cl3Dolar1 = projs2003Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl32D = projs2003Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl32DLenght = projs2003Cl32D.length
        var projs2003Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2003Cl32DLenght; d2++)
        projs2003Cl32DNumeros.push(Number(projs2003Cl32D[d2]))
        const projs2003Cl3Dolar2 = projs2003Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003Cl3 = (projs2003Cl3Dolar1+projs2003Cl3Dolar2)
    
        //2004Cl3 reais
        const projs2004Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
        const projs2004Cl31R = projs2004Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl31RLenght = projs2004Cl31R.length
        var projs2004Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2004Cl31RLenght; r1++)
        projs2004Cl31RNumeros.push(Number(projs2004Cl31R[r1]))
        const projs2004Cl3Reais1 = projs2004Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl32R = projs2004Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl32RLenght = projs2004Cl32R.length
        var projs2004Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2004Cl32RLenght; r2++)
        projs2004Cl32RNumeros.push(Number(projs2004Cl32R[r2]))
        const projs2004Cl3Reais2 = projs2004Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004Cl3 = (projs2004Cl3Reais1+projs2004Cl3Reais2)
        
        //2004Cl3 dolar
        const projs2004Cl31D = projs2004Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl31DLenght = projs2004Cl31D.length
        var projs2004Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2004Cl31DLenght; d1++)
        projs2004Cl31DNumeros.push(Number(projs2004Cl31D[d1]))
        const projs2004Cl3Dolar1 = projs2004Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl32D = projs2004Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl32DLenght = projs2004Cl32D.length
        var projs2004Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2004Cl32DLenght; d2++)
        projs2004Cl32DNumeros.push(Number(projs2004Cl32D[d2]))
        const projs2004Cl3Dolar2 = projs2004Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004Cl3 = (projs2004Cl3Dolar1+projs2004Cl3Dolar2)
    
        //2005Cl3 reais
        const projs2005Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
        const projs2005Cl31R = projs2005Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl31RLenght = projs2005Cl31R.length
        var projs2005Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2005Cl31RLenght; r1++)
        projs2005Cl31RNumeros.push(Number(projs2005Cl31R[r1]))
        const projs2005Cl3Reais1 = projs2005Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl32R = projs2005Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl32RLenght = projs2005Cl32R.length
        var projs2005Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2005Cl32RLenght; r2++)
        projs2005Cl32RNumeros.push(Number(projs2005Cl32R[r2]))
        const projs2005Cl3Reais2 = projs2005Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005Cl3 = (projs2005Cl3Reais1+projs2005Cl3Reais2)
        
        //2005Cl3 dolar
        const projs2005Cl31D = projs2005Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl31DLenght = projs2005Cl31D.length
        var projs2005Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2005Cl31DLenght; d1++)
        projs2005Cl31DNumeros.push(Number(projs2005Cl31D[d1]))
        const projs2005Cl3Dolar1 = projs2005Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl32D = projs2005Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl32DLenght = projs2005Cl32D.length
        var projs2005Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2005Cl32DLenght; d2++)
        projs2005Cl32DNumeros.push(Number(projs2005Cl32D[d2]))
        const projs2005Cl3Dolar2 = projs2005Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005Cl3 = (projs2005Cl3Dolar1+projs2005Cl3Dolar2)
    
        //2006Cl3 reais
        const projs2006Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
        const projs2006Cl31R = projs2006Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl31RLenght = projs2006Cl31R.length
        var projs2006Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2006Cl31RLenght; r1++)
        projs2006Cl31RNumeros.push(Number(projs2006Cl31R[r1]))
        const projs2006Cl3Reais1 = projs2006Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl32R = projs2006Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl32RLenght = projs2006Cl32R.length
        var projs2006Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2006Cl32RLenght; r2++)
        projs2006Cl32RNumeros.push(Number(projs2006Cl32R[r2]))
        const projs2006Cl3Reais2 = projs2006Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006Cl3 = (projs2006Cl3Reais1+projs2006Cl3Reais2)
        
        //2006Cl3 dolar
        const projs2006Cl31D = projs2006Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl31DLenght = projs2006Cl31D.length
        var projs2006Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2006Cl31DLenght; d1++)
        projs2006Cl31DNumeros.push(Number(projs2006Cl31D[d1]))
        const projs2006Cl3Dolar1 = projs2006Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl32D = projs2006Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl32DLenght = projs2006Cl32D.length
        var projs2006Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2006Cl32DLenght; d2++)
        projs2006Cl32DNumeros.push(Number(projs2006Cl32D[d2]))
        const projs2006Cl3Dolar2 = projs2006Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006Cl3 = (projs2006Cl3Dolar1+projs2006Cl3Dolar2)
    
        //2007Cl3 reais
        const projs2007Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2007Cl31R = projs2007Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl31RLenght = projs2007Cl31R.length
        var projs2007Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2007Cl31RLenght; r1++)
        projs2007Cl31RNumeros.push(Number(projs2007Cl31R[r1]))
        const projs2007Cl3Reais1 = projs2007Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl32R = projs2007Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl32RLenght = projs2007Cl32R.length
        var projs2007Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2007Cl32RLenght; r2++)
        projs2007Cl32RNumeros.push(Number(projs2007Cl32R[r2]))
        const projs2007Cl3Reais2 = projs2007Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007Cl3 = (projs2007Cl3Reais1+projs2007Cl3Reais2)
        
        //2007Cl3 dolar
        const projs2007Cl31D = projs2007Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl31DLenght = projs2007Cl31D.length
        var projs2007Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2007Cl31DLenght; d1++)
        projs2007Cl31DNumeros.push(Number(projs2007Cl31D[d1]))
        const projs2007Cl3Dolar1 = projs2007Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl32D = projs2007Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl32DLenght = projs2007Cl32D.length
        var projs2007Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2007Cl32DLenght; d2++)
        projs2007Cl32DNumeros.push(Number(projs2007Cl32D[d2]))
        const projs2007Cl3Dolar2 = projs2007Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007Cl3 = (projs2007Cl3Dolar1+projs2007Cl3Dolar2)
    
        //2008Cl3 reais
        const projs2008Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2008Cl31R = projs2008Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl31RLenght = projs2008Cl31R.length
        var projs2008Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2008Cl31RLenght; r1++)
        projs2008Cl31RNumeros.push(Number(projs2008Cl31R[r1]))
        const projs2008Cl3Reais1 = projs2008Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl32R = projs2008Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl32RLenght = projs2008Cl32R.length
        var projs2008Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2008Cl32RLenght; r2++)
        projs2008Cl32RNumeros.push(Number(projs2008Cl32R[r2]))
        const projs2008Cl3Reais2 = projs2008Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008Cl3 = (projs2008Cl3Reais1+projs2008Cl3Reais2)
        
        //2008Cl3 dolar
        const projs2008Cl31D = projs2008Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl31DLenght = projs2008Cl31D.length
        var projs2008Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2008Cl31DLenght; d1++)
        projs2008Cl31DNumeros.push(Number(projs2008Cl31D[d1]))
        const projs2008Cl3Dolar1 = projs2008Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl32D = projs2008Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl32DLenght = projs2008Cl32D.length
        var projs2008Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2008Cl32DLenght; d2++)
        projs2008Cl32DNumeros.push(Number(projs2008Cl32D[d2]))
        const projs2008Cl3Dolar2 = projs2008Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008Cl3 = (projs2008Cl3Dolar1+projs2008Cl3Dolar2)
    
        //2009Cl3 reais
        const projs2009Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2009Cl31R = projs2009Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl31RLenght = projs2009Cl31R.length
        var projs2009Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2009Cl31RLenght; r1++)
        projs2009Cl31RNumeros.push(Number(projs2009Cl31R[r1]))
        const projs2009Cl3Reais1 = projs2009Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl32R = projs2009Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl32RLenght = projs2009Cl32R.length
        var projs2009Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2009Cl32RLenght; r2++)
        projs2009Cl32RNumeros.push(Number(projs2009Cl32R[r2]))
        const projs2009Cl3Reais2 = projs2009Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009Cl3 = (projs2009Cl3Reais1+projs2009Cl3Reais2)
        
        //2009Cl3 dolar
        const projs2009Cl31D = projs2009Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl31DLenght = projs2009Cl31D.length
        var projs2009Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2009Cl31DLenght; d1++)
        projs2009Cl31DNumeros.push(Number(projs2009Cl31D[d1]))
        const projs2009Cl3Dolar1 = projs2009Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl32D = projs2009Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl32DLenght = projs2009Cl32D.length
        var projs2009Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2009Cl32DLenght; d2++)
        projs2009Cl32DNumeros.push(Number(projs2009Cl32D[d2]))
        const projs2009Cl3Dolar2 = projs2009Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009Cl3 = (projs2009Cl3Dolar1+projs2009Cl3Dolar2)
    
        //2010Cl3 reais
        const projs2010Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2010Cl31R = projs2010Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl31RLenght = projs2010Cl31R.length
        var projs2010Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2010Cl31RLenght; r1++)
        projs2010Cl31RNumeros.push(Number(projs2010Cl31R[r1]))
        const projs2010Cl3Reais1 = projs2010Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl32R = projs2010Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl32RLenght = projs2010Cl32R.length
        var projs2010Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2010Cl32RLenght; r2++)
        projs2010Cl32RNumeros.push(Number(projs2010Cl32R[r2]))
        const projs2010Cl3Reais2 = projs2010Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010Cl3 = (projs2010Cl3Reais1+projs2010Cl3Reais2)
        
        //2010Cl3 dolar
        const projs2010Cl31D = projs2010Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl31DLenght = projs2010Cl31D.length
        var projs2010Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2010Cl31DLenght; d1++)
        projs2010Cl31DNumeros.push(Number(projs2010Cl31D[d1]))
        const projs2010Cl3Dolar1 = projs2010Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl32D = projs2010Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl32DLenght = projs2010Cl32D.length
        var projs2010Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2010Cl32DLenght; d2++)
        projs2010Cl32DNumeros.push(Number(projs2010Cl32D[d2]))
        const projs2010Cl3Dolar2 = projs2010Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010Cl3 = (projs2010Cl3Dolar1+projs2010Cl3Dolar2)
    
        //2011Cl3 reais
        const projs2011Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2011Cl31R = projs2011Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl31RLenght = projs2011Cl31R.length
        var projs2011Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2011Cl31RLenght; r1++)
        projs2011Cl31RNumeros.push(Number(projs2011Cl31R[r1]))
        const projs2011Cl3Reais1 = projs2011Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl32R = projs2011Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl32RLenght = projs2011Cl32R.length
        var projs2011Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2011Cl32RLenght; r2++)
        projs2011Cl32RNumeros.push(Number(projs2011Cl32R[r2]))
        const projs2011Cl3Reais2 = projs2011Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2011Cl3 = (projs2011Cl3Reais1+projs2011Cl3Reais2)
        
        //2011Cl3 dolar
        const projs2011Cl31D = projs2011Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl31DLenght = projs2011Cl31D.length
        var projs2011Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2011Cl31DLenght; d1++)
        projs2011Cl31DNumeros.push(Number(projs2011Cl31D[d1]))
        const projs2011Cl3Dolar1 = projs2011Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl32D = projs2011Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl32DLenght = projs2011Cl32D.length
        var projs2011Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2011Cl32DLenght; d2++)
        projs2011Cl32DNumeros.push(Number(projs2011Cl32D[d2]))
        const projs2011Cl3Dolar2 = projs2011Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2011Cl3 = (projs2011Cl3Dolar1+projs2011Cl3Dolar2)
    
        //2012Cl3 reais
        const projs2012Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2012Cl31R = projs2012Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl31RLenght = projs2012Cl31R.length
        var projs2012Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2012Cl31RLenght; r1++)
        projs2012Cl31RNumeros.push(Number(projs2012Cl31R[r1]))
        const projs2012Cl3Reais1 = projs2012Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl32R = projs2012Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl32RLenght = projs2012Cl32R.length
        var projs2012Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2012Cl32RLenght; r2++)
        projs2012Cl32RNumeros.push(Number(projs2012Cl32R[r2]))
        const projs2012Cl3Reais2 = projs2012Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2012Cl3 = (projs2012Cl3Reais1+projs2012Cl3Reais2)
        
        //2012Cl3 dolar
        const projs2012Cl31D = projs2012Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl31DLenght = projs2012Cl31D.length
        var projs2012Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2012Cl31DLenght; d1++)
        projs2012Cl31DNumeros.push(Number(projs2012Cl31D[d1]))
        const projs2012Cl3Dolar1 = projs2012Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl32D = projs2012Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl32DLenght = projs2012Cl32D.length
        var projs2012Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2012Cl32DLenght; d2++)
        projs2012Cl32DNumeros.push(Number(projs2012Cl32D[d2]))
        const projs2012Cl3Dolar2 = projs2012Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2012Cl3 = (projs2012Cl3Dolar1+projs2012Cl3Dolar2)
        
        //2013Cl3 reais
        const projs2013Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2013Cl31R = projs2013Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl31RLenght = projs2013Cl31R.length
        var projs2013Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2013Cl31RLenght; r1++)
        projs2013Cl31RNumeros.push(Number(projs2013Cl31R[r1]))
        const projs2013Cl3Reais1 = projs2013Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl32R = projs2013Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl32RLenght = projs2013Cl32R.length
        var projs2013Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2013Cl32RLenght; r2++)
        projs2013Cl32RNumeros.push(Number(projs2013Cl32R[r2]))
        const projs2013Cl3Reais2 = projs2013Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2013Cl3 = (projs2013Cl3Reais1+projs2013Cl3Reais2)
        
        //2013Cl3 dolar
        const projs2013Cl31D = projs2013Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl31DLenght = projs2013Cl31D.length
        var projs2013Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2013Cl31DLenght; d1++)
        projs2013Cl31DNumeros.push(Number(projs2013Cl31D[d1]))
        const projs2013Cl3Dolar1 = projs2013Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl32D = projs2013Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl32DLenght = projs2013Cl32D.length
        var projs2013Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2013Cl32DLenght; d2++)
        projs2013Cl32DNumeros.push(Number(projs2013Cl32D[d2]))
        const projs2013Cl3Dolar2 = projs2013Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2013Cl3 = (projs2013Cl3Dolar1+projs2013Cl3Dolar2)
        
        //2014Cl3 reais
        const projs2014Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2014Cl31R = projs2014Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl31RLenght = projs2014Cl31R.length
        var projs2014Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2014Cl31RLenght; r1++)
        projs2014Cl31RNumeros.push(Number(projs2014Cl31R[r1]))
        const projs2014Cl3Reais1 = projs2014Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl32R = projs2014Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl32RLenght = projs2014Cl32R.length
        var projs2014Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2014Cl32RLenght; r2++)
        projs2014Cl32RNumeros.push(Number(projs2014Cl32R[r2]))
        const projs2014Cl3Reais2 = projs2014Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2014Cl3 = (projs2014Cl3Reais1+projs2014Cl3Reais2)
        
        //2014Cl3 dolar
        const projs2014Cl31D = projs2014Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl31DLenght = projs2014Cl31D.length
        var projs2014Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2014Cl31DLenght; d1++)
        projs2014Cl31DNumeros.push(Number(projs2014Cl31D[d1]))
        const projs2014Cl3Dolar1 = projs2014Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl32D = projs2014Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl32DLenght = projs2014Cl32D.length
        var projs2014Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2014Cl32DLenght; d2++)
        projs2014Cl32DNumeros.push(Number(projs2014Cl32D[d2]))
        const projs2014Cl3Dolar2 = projs2014Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2014Cl3 = (projs2014Cl3Dolar1+projs2014Cl3Dolar2)
        
        
        //2015Cl3 reais
        const projs2015Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2015Cl31R = projs2015Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl31RLenght = projs2015Cl31R.length
        var projs2015Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2015Cl31RLenght; r1++)
        projs2015Cl31RNumeros.push(Number(projs2015Cl31R[r1]))
        const projs2015Cl3Reais1 = projs2015Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl32R = projs2015Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl32RLenght = projs2015Cl32R.length
        var projs2015Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2015Cl32RLenght; r2++)
        projs2015Cl32RNumeros.push(Number(projs2015Cl32R[r2]))
        const projs2015Cl3Reais2 = projs2015Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2015Cl3 = (projs2015Cl3Reais1+projs2015Cl3Reais2)
        
        //2015Cl3 dolar
        const projs2015Cl31D = projs2015Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl31DLenght = projs2015Cl31D.length
        var projs2015Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2015Cl31DLenght; d1++)
        projs2015Cl31DNumeros.push(Number(projs2015Cl31D[d1]))
        const projs2015Cl3Dolar1 = projs2015Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl32D = projs2015Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl32DLenght = projs2015Cl32D.length
        var projs2015Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2015Cl32DLenght; d2++)
        projs2015Cl32DNumeros.push(Number(projs2015Cl32D[d2]))
        const projs2015Cl3Dolar2 = projs2015Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2015Cl3 = (projs2015Cl3Dolar1+projs2015Cl3Dolar2)
        
        //2016Cl3 reais
        const projs2016Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2016Cl31R = projs2016Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl31RLenght = projs2016Cl31R.length
        var projs2016Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2016Cl31RLenght; r1++)
        projs2016Cl31RNumeros.push(Number(projs2016Cl31R[r1]))
        const projs2016Cl3Reais1 = projs2016Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl32R = projs2016Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl32RLenght = projs2016Cl32R.length
        var projs2016Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2016Cl32RLenght; r2++)
        projs2016Cl32RNumeros.push(Number(projs2016Cl32R[r2]))
        const projs2016Cl3Reais2 = projs2016Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2016Cl3 = (projs2016Cl3Reais1+projs2016Cl3Reais2)
        
        //2016Cl3 dolar
        const projs2016Cl31D = projs2016Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl31DLenght = projs2016Cl31D.length
        var projs2016Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2016Cl31DLenght; d1++)
        projs2016Cl31DNumeros.push(Number(projs2016Cl31D[d1]))
        const projs2016Cl3Dolar1 = projs2016Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl32D = projs2016Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl32DLenght = projs2016Cl32D.length
        var projs2016Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2016Cl32DLenght; d2++)
        projs2016Cl32DNumeros.push(Number(projs2016Cl32D[d2]))
        const projs2016Cl3Dolar2 = projs2016Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2016Cl3 = (projs2016Cl3Dolar1+projs2016Cl3Dolar2)
        
        //2017Cl3 reais
        const projs2017Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2017Cl31R = projs2017Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl31RLenght = projs2017Cl31R.length
        var projs2017Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2017Cl31RLenght; r1++)
        projs2017Cl31RNumeros.push(Number(projs2017Cl31R[r1]))
        const projs2017Cl3Reais1 = projs2017Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl32R = projs2017Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl32RLenght = projs2017Cl32R.length
        var projs2017Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2017Cl32RLenght; r2++)
        projs2017Cl32RNumeros.push(Number(projs2017Cl32R[r2]))
        const projs2017Cl3Reais2 = projs2017Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2017Cl3 = (projs2017Cl3Reais1+projs2017Cl3Reais2)
        
        //2017Cl3 dolar
        const projs2017Cl31D = projs2017Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl31DLenght = projs2017Cl31D.length
        var projs2017Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2017Cl31DLenght; d1++)
        projs2017Cl31DNumeros.push(Number(projs2017Cl31D[d1]))
        const projs2017Cl3Dolar1 = projs2017Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl32D = projs2017Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl32DLenght = projs2017Cl32D.length
        var projs2017Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2017Cl32DLenght; d2++)
        projs2017Cl32DNumeros.push(Number(projs2017Cl32D[d2]))
        const projs2017Cl3Dolar2 = projs2017Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2017Cl3 = (projs2017Cl3Dolar1+projs2017Cl3Dolar2)
        
        //2018Cl3 reais
        const projs2018Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2018Cl31R = projs2018Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl31RLenght = projs2018Cl31R.length
        var projs2018Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2018Cl31RLenght; r1++)
        projs2018Cl31RNumeros.push(Number(projs2018Cl31R[r1]))
        const projs2018Cl3Reais1 = projs2018Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl32R = projs2018Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl32RLenght = projs2018Cl32R.length
        var projs2018Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2018Cl32RLenght; r2++)
        projs2018Cl32RNumeros.push(Number(projs2018Cl32R[r2]))
        const projs2018Cl3Reais2 = projs2018Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2018Cl3 = (projs2018Cl3Reais1+projs2018Cl3Reais2)
        
        //2018Cl3 dolar
        const projs2018Cl31D = projs2018Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl31DLenght = projs2018Cl31D.length
        var projs2018Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2018Cl31DLenght; d1++)
        projs2018Cl31DNumeros.push(Number(projs2018Cl31D[d1]))
        const projs2018Cl3Dolar1 = projs2018Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl32D = projs2018Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl32DLenght = projs2018Cl32D.length
        var projs2018Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2018Cl32DLenght; d2++)
        projs2018Cl32DNumeros.push(Number(projs2018Cl32D[d2]))
        const projs2018Cl3Dolar2 = projs2018Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2018Cl3 = (projs2018Cl3Dolar1+projs2018Cl3Dolar2)
        
        //2019Cl3 reais
        const projs2019Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2019Cl31R = projs2019Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl31RLenght = projs2019Cl31R.length
        var projs2019Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2019Cl31RLenght; r1++)
        projs2019Cl31RNumeros.push(Number(projs2019Cl31R[r1]))
        const projs2019Cl3Reais1 = projs2019Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl32R = projs2019Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl32RLenght = projs2019Cl32R.length
        var projs2019Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2019Cl32RLenght; r2++)
        projs2019Cl32RNumeros.push(Number(projs2019Cl32R[r2]))
        const projs2019Cl3Reais2 = projs2019Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2019Cl3 = (projs2019Cl3Reais1+projs2019Cl3Reais2)
        
        //2019Cl3 dolar
        const projs2019Cl31D = projs2019Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl31DLenght = projs2019Cl31D.length
        var projs2019Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2019Cl31DLenght; d1++)
        projs2019Cl31DNumeros.push(Number(projs2019Cl31D[d1]))
        const projs2019Cl3Dolar1 = projs2019Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl32D = projs2019Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl32DLenght = projs2019Cl32D.length
        var projs2019Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2019Cl32DLenght; d2++)
        projs2019Cl32DNumeros.push(Number(projs2019Cl32D[d2]))
        const projs2019Cl3Dolar2 = projs2019Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2019Cl3 = (projs2019Cl3Dolar1+projs2019Cl3Dolar2)
        
        //2020Cl3 reais
        const projs2020Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2020Cl31R = projs2020Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl31RLenght = projs2020Cl31R.length
        var projs2020Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2020Cl31RLenght; r1++)
        projs2020Cl31RNumeros.push(Number(projs2020Cl31R[r1]))
        const projs2020Cl3Reais1 = projs2020Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl32R = projs2020Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl32RLenght = projs2020Cl32R.length
        var projs2020Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2020Cl32RLenght; r2++)
        projs2020Cl32RNumeros.push(Number(projs2020Cl32R[r2]))
        const projs2020Cl3Reais2 = projs2020Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2020Cl3 = (projs2020Cl3Reais1+projs2020Cl3Reais2)
        
        //2020Cl3 dolar
        const projs2020Cl31D = projs2020Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl31DLenght = projs2020Cl31D.length
        var projs2020Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2020Cl31DLenght; d1++)
        projs2020Cl31DNumeros.push(Number(projs2020Cl31D[d1]))
        const projs2020Cl3Dolar1 = projs2020Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl32D = projs2020Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl32DLenght = projs2020Cl32D.length
        var projs2020Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2020Cl32DLenght; d2++)
        projs2020Cl32DNumeros.push(Number(projs2020Cl32D[d2]))
        const projs2020Cl3Dolar2 = projs2020Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2020Cl3 = (projs2020Cl3Dolar1+projs2020Cl3Dolar2)
        
        //2021Cl3 reais
        const projs2021Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2021Cl31R = projs2021Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl31RLenght = projs2021Cl31R.length
        var projs2021Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2021Cl31RLenght; r1++)
        projs2021Cl31RNumeros.push(Number(projs2021Cl31R[r1]))
        const projs2021Cl3Reais1 = projs2021Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl32R = projs2021Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl32RLenght = projs2021Cl32R.length
        var projs2021Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2021Cl32RLenght; r2++)
        projs2021Cl32RNumeros.push(Number(projs2021Cl32R[r2]))
        const projs2021Cl3Reais2 = projs2021Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2021Cl3 = (projs2021Cl3Reais1+projs2021Cl3Reais2)
        
        //2021Cl3 dolar
        const projs2021Cl31D = projs2021Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl31DLenght = projs2021Cl31D.length
        var projs2021Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2021Cl31DLenght; d1++)
        projs2021Cl31DNumeros.push(Number(projs2021Cl31D[d1]))
        const projs2021Cl3Dolar1 = projs2021Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl32D = projs2021Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl32DLenght = projs2021Cl32D.length
        var projs2021Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2021Cl32DLenght; d2++)
        projs2021Cl32DNumeros.push(Number(projs2021Cl32D[d2]))
        const projs2021Cl3Dolar2 = projs2021Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2021Cl3 = (projs2021Cl3Dolar1+projs2021Cl3Dolar2)
        
        //2022Cl3 reais
        const projs2022Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2022Cl31R = projs2022Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl31RLenght = projs2022Cl31R.length
        var projs2022Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2022Cl31RLenght; r1++)
        projs2022Cl31RNumeros.push(Number(projs2022Cl31R[r1]))
        const projs2022Cl3Reais1 = projs2022Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl32R = projs2022Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl32RLenght = projs2022Cl32R.length
        var projs2022Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2022Cl32RLenght; r2++)
        projs2022Cl32RNumeros.push(Number(projs2022Cl32R[r2]))
        const projs2022Cl3Reais2 = projs2022Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2022Cl3 = projs2022Cl3Reais1+projs2022Cl3Reais2
        
        //2022Cl3 dolar
        const projs2022Cl31D = projs2022Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl31DLenght = projs2022Cl31D.length
        var projs2022Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2022Cl31DLenght; d1++)
        projs2022Cl31DNumeros.push(Number(projs2022Cl31D[d1]))
        const projs2022Cl3Dolar1 = projs2022Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl32D = projs2022Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl32DLenght = projs2022Cl32D.length
        var projs2022Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2022Cl32DLenght; d2++)
        projs2022Cl32DNumeros.push(Number(projs2022Cl32D[d2]))
        const projs2022Cl3Dolar2 = projs2022Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2022Cl3 = projs2022Cl3Dolar1+projs2022Cl3Dolar2
        
        //2023Cl3 reais
        const projs2023Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2023Cl31R = projs2023Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl31RLenght = projs2023Cl31R.length
        var projs2023Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2023Cl31RLenght; r1++)
        projs2023Cl31RNumeros.push(Number(projs2023Cl31R[r1]))
        const projs2023Cl3Reais1 = projs2023Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl32R = projs2023Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl32RLenght = projs2023Cl32R.length
        var projs2023Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2023Cl32RLenght; r2++)
        projs2023Cl32RNumeros.push(Number(projs2023Cl32R[r2]))
        const projs2023Cl3Reais2 = projs2023Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2023Cl3 = projs2023Cl3Reais1+projs2023Cl3Reais2
        
        //2023Cl3 dolar
        const projs2023Cl31D = projs2023Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl31DLenght = projs2023Cl31D.length
        var projs2023Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2023Cl31DLenght; d1++)
        projs2023Cl31DNumeros.push(Number(projs2023Cl31D[d1]))
        const projs2023Cl3Dolar1 = projs2023Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl32D = projs2023Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl32DLenght = projs2023Cl32D.length
        var projs2023Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2023Cl32DLenght; d2++)
        projs2023Cl32DNumeros.push(Number(projs2023Cl32D[d2]))
        const projs2023Cl3Dolar2 = projs2023Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2023Cl3 = projs2023Cl3Dolar1+projs2023Cl3Dolar2
        
        
        //2024Cl3 reais
        const projs2024Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        const projs2024Cl31R = projs2024Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl31RLenght = projs2024Cl31R.length
        var projs2024Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2024Cl31RLenght; r1++)
        projs2024Cl31RNumeros.push(Number(projs2024Cl31R[r1]))
        const projs2024Cl3Reais1 = projs2024Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl32R = projs2024Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl32RLenght = projs2024Cl32R.length
        var projs2024Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2024Cl32RLenght; r2++)
        projs2024Cl32RNumeros.push(Number(projs2024Cl32R[r2]))
        const projs2024Cl3Reais2 = projs2024Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2024Cl3 = projs2024Cl3Reais1+projs2024Cl3Reais2
        
        //2024Cl3 dolar
        const projs2024Cl31D = projs2024Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl31DLenght = projs2024Cl31D.length
        var projs2024Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2024Cl31DLenght; d1++)
        projs2024Cl31DNumeros.push(Number(projs2024Cl31D[d1]))
        const projs2024Cl3Dolar1 = projs2024Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl32D = projs2024Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl32DLenght = projs2024Cl32D.length
        var projs2024Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2024Cl32DLenght; d2++)
        projs2024Cl32DNumeros.push(Number(projs2024Cl32D[d2]))
        const projs2024Cl3Dolar2 = projs2024Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2024Cl3 = projs2024Cl3Dolar1+projs2024Cl3Dolar2


    //2025 reais
const projs2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
const projs20251R = projs2025.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251RLenght = projs20251R.length
var projs20251RNumeros = [];
for (var r1 = 0; r1 < projs20251RLenght; r1++)
projs20251RNumeros.push(Number(projs20251R[r1]))
const projs2025Reais1 = projs20251RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252R = projs2025.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252RLenght = projs20252R.length
var projs20252RNumeros = [];
for (var r2 = 0; r2 < projs20252RLenght; r2++)
projs20252RNumeros.push(Number(projs20252R[r2]))
const projs2025Reais2 = projs20252RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025 = projs2025Reais1+projs2025Reais2

//2025 dolar
const projs20251D = projs2025.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20251DLenght = projs20251D.length
var projs20251DNumeros = [];
for (var d1 = 0; d1 < projs20251DLenght; d1++)
projs20251DNumeros.push(Number(projs20251D[d1]))
const projs2025Dolar1 = projs20251DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20252D = projs2025.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20252DLenght = projs20252D.length
var projs20252DNumeros = [];
for (var d2 = 0; d2 < projs20252DLenght; d2++)
projs20252DNumeros.push(Number(projs20252D[d2]))
const projs2025Dolar2 = projs20252DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025 = projs2025Dolar1+projs2025Dolar2


//2025Cl2 reais
const projs2025Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
const projs2025Cl21R = projs2025Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl21RLenght = projs2025Cl21R.length
var projs2025Cl21RNumeros = [];
for (var r1 = 0; r1 < projs2025Cl21RLenght; r1++)
projs2025Cl21RNumeros.push(Number(projs2025Cl21R[r1]))
const projs2025Cl2Reais1 = projs2025Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl22R = projs2025Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl22RLenght = projs2025Cl22R.length
var projs2025Cl22RNumeros = [];
for (var r2 = 0; r2 < projs2025Cl22RLenght; r2++)
projs2025Cl22RNumeros.push(Number(projs2025Cl22R[r2]))
const projs2025Cl2Reais2 = projs2025Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025Cl2 = projs2025Cl2Reais1+projs2025Cl2Reais2

//2025Cl2 dolar
const projs2025Cl21D = projs2025Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl21DLenght = projs2025Cl21D.length
var projs2025Cl21DNumeros = [];
for (var d1 = 0; d1 < projs2025Cl21DLenght; d1++)
projs2025Cl21DNumeros.push(Number(projs2025Cl21D[d1]))
const projs2025Cl2Dolar1 = projs2025Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl22D = projs2025Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl22DLenght = projs2025Cl22D.length
var projs2025Cl22DNumeros = [];
for (var d2 = 0; d2 < projs2025Cl22DLenght; d2++)
projs2025Cl22DNumeros.push(Number(projs2025Cl22D[d2]))
const projs2025Cl2Dolar2 = projs2025Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025Cl2 = projs2025Cl2Dolar1+projs2025Cl2Dolar2

//2025Cl3 reais
const projs2025Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
const projs2025Cl31R = projs2025Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl31RLenght = projs2025Cl31R.length
var projs2025Cl31RNumeros = [];
for (var r1 = 0; r1 < projs2025Cl31RLenght; r1++)
projs2025Cl31RNumeros.push(Number(projs2025Cl31R[r1]))
const projs2025Cl3Reais1 = projs2025Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl32R = projs2025Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl32RLenght = projs2025Cl32R.length
var projs2025Cl32RNumeros = [];
for (var r2 = 0; r2 < projs2025Cl32RLenght; r2++)
projs2025Cl32RNumeros.push(Number(projs2025Cl32R[r2]))
const projs2025Cl3Reais2 = projs2025Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2025Cl3 = projs2025Cl3Reais1+projs2025Cl3Reais2

//2025Cl3 dolar
const projs2025Cl31D = projs2025Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs2025Cl31DLenght = projs2025Cl31D.length
var projs2025Cl31DNumeros = [];
for (var d1 = 0; d1 < projs2025Cl31DLenght; d1++)
projs2025Cl31DNumeros.push(Number(projs2025Cl31D[d1]))
const projs2025Cl3Dolar1 = projs2025Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs2025Cl32D = projs2025Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs2025Cl32DLenght = projs2025Cl32D.length
var projs2025Cl32DNumeros = [];
for (var d2 = 0; d2 < projs2025Cl32DLenght; d2++)
projs2025Cl32DNumeros.push(Number(projs2025Cl32D[d2]))
const projs2025Cl3Dolar2 = projs2025Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2025Cl3 = projs2025Cl3Dolar1+projs2025Cl3Dolar2
    
    
  
    setProjetData({
      labels: anos,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [Reais2002,Reais2003,Reais2004,Reais2005,Reais2006,Reais2007,Reais2008,Reais2009,Reais2010,Reais2011,Reais2012,Reais2013,Reais2014,Reais2015,Reais2016,Reais2017,Reais2018,Reais2019,Reais2020,Reais2021,Reais2022,Reais2023,Reais2024,Reais2025],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [Dolar2002,Dolar2003,Dolar2004,Dolar2005,Dolar2006,Dolar2007,Dolar2008,Dolar2009,Dolar2010,Dolar2011,Dolar2012,Dolar2013,Dolar2014,Dolar2015,Dolar2016,Dolar2017,Dolar2018,Dolar2019,Dolar2020,Dolar2021,Dolar2022,Dolar2023,Dolar2024,Dolar2025],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },
      {
        label:`Faturamento Reais - ${cliente2}`,
        data: [Reais2002Cl2,Reais2003Cl2,Reais2004Cl2,Reais2005Cl2,Reais2006Cl2,Reais2007Cl2,Reais2008Cl2,Reais2009Cl2,Reais2010Cl2,Reais2011Cl2,Reais2012Cl2,Reais2013Cl2,Reais2014Cl2,Reais2015Cl2,Reais2016Cl2,Reais2017Cl2,Reais2018Cl2,Reais2019Cl2,Reais2020Cl2,Reais2021Cl2,Reais2022Cl2,Reais2023Cl2,Reais2024Cl2,Reais2025Cl2],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data: [Dolar2002Cl2,Dolar2003Cl2,Dolar2004Cl2,Dolar2005Cl2,Dolar2006Cl2,Dolar2007Cl2,Dolar2008Cl2,Dolar2009Cl2,Dolar2010Cl2,Dolar2011Cl2,Dolar2012Cl2,Dolar2013Cl2,Dolar2014Cl2,Dolar2015Cl2,Dolar2016Cl2,Dolar2017Cl2,Dolar2018Cl2,Dolar2019Cl2,Dolar2020Cl2,Dolar2021Cl2,Dolar2022Cl2,Dolar2023Cl2,Dolar2024Cl2,Dolar2025Cl2],
        fill: false,
        borderColor: '#9EAD42',
        backgroundColor: 'rgba(158,173,66,.5)',
      },
      {
        label:`Faturamento Reais - ${cliente3}`,
        data: [Reais2002Cl3,Reais2003Cl3,Reais2004Cl3,Reais2005Cl3,Reais2006Cl3,Reais2007Cl3,Reais2008Cl3,Reais2009Cl3,Reais2010Cl3,Reais2011Cl3,Reais2012Cl3,Reais2013Cl3,Reais2014Cl3,Reais2015Cl3,Reais2016Cl3,Reais2017Cl3,Reais2018Cl3,Reais2019Cl3,Reais2020Cl3,Reais2021Cl3,Reais2022Cl3,Reais2023Cl3,Reais2024Cl3,Reais2025Cl3],
        fill: false,
        borderColor: '#0DA1B1',
        backgroundColor: 'rgba(13,161,177,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente3}`,
        data: [Dolar2002Cl3,Dolar2003Cl3,Dolar2004Cl3,Dolar2005Cl3,Dolar2006Cl3,Dolar2007Cl3,Dolar2008Cl3,Dolar2009Cl3,Dolar2010Cl3,Dolar2011Cl3,Dolar2012Cl3,Dolar2013Cl3,Dolar2014Cl3,Dolar2015Cl3,Dolar2016Cl3,Dolar2017Cl3,Dolar2018Cl3,Dolar2019Cl3,Dolar2020Cl3,Dolar2021Cl3,Dolar2022Cl3,Dolar2023Cl3,Dolar2024Cl3,Dolar2025Cl3],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
    return (
      <div>
      <Header />
      {loading &&
         <div className="pageTitle">
         <div>Carregando...</div>
       </div>
      }
      {!loading &&
        <>
        <div className="pageTitle">
          <div>Clientes - Faturamento Histórico</div>
          {
          !paislist &&
          <div><button onClick={lista}>Gerar Lista</button></div>
          }
          {paislist &&
          <>

          <div>
              <p>
                  Tipo:
                  <select defaultValue={'um'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                  <option value="">Selecione</option>
                  <option value="um">Um Cliente</option>
                  <option value="dois">Dois Clientes</option>
                  <option value="tres">Três Clientes</option>
              </select>
              </p>
          </div>
          {tipo === 'um' &&
              <div className='tipo'>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' &&
                  <div><button onClick={handleChart}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'dois' &&
              <div className='tipo'>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' && cliente2 !== '' &&
                  <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'tres' &&
            
              <div className='tipo'>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um Cliente:</p>
                  <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                  <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
              }
              </div>
          }
         </>
          }
        </div>
        {grafico &&
          <div className="pageContent">
          <div className="pageChart">
            <LineChart chartData={projectData} />
          </div>
        </div>
        }
  
        </>
             
      }
  </div>
    )
}

export default ClientesFaturamentoHistorico