import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import Header from '../../components/Header/Header'
import '../pages.css'
import emailjs from '@emailjs/browser';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'

function Relatorios() {

    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const atual = new Date();
    const hoje = atual.toISOString().slice(0,10)
    const ano = new Date().getFullYear()
    const [trimestre, setTrimestre]=useState('')
    const [quantidade, setQuantidade]=useState(0)
    const [YTDqtd, setYTDQtd]=useState(0)
    const [fatTrimestreDolar, setFatTrimestreDolar]=useState(0)
    const [fatTrimestreReal, setFatTrimestreReal]=useState(0)
    const [fatDolarAte, setFatDolarAte]=useState(0)
    const [fatRealAte, setFatRealAte]=useState(0)
    const [contratantes, setContratantes]=useState('')
    const [naoTrimeste, setNaotrimestre]=useState('')
    const [novosCLientes, setNovosclientes]=useState('')
    const [voltaCliente, setVoltaCliente]=useState([])
    const [enviar, setEnviar]=useState(false)
    const [enviado, setEnviado]=useState(false)
    const [erro, setErro]=useState(false)
    const [todos, setTodos]=useState([])

    useEffect(()=>{

        const handletrimestre=()=>{
        if (hoje>= `${ano}-01-01`&& hoje < `${ano}-04-01`){setTrimestre('4')}
         if (hoje>= `${ano}-04-01`&& hoje < `${ano}-07-01`){setTrimestre('1')}
         if (hoje>= `${ano}-07-01`&& hoje < `${ano}-10-01`){setTrimestre('2')}
         if (hoje>= `${ano}-10-01`&& hoje < `${ano+1}-01-01`){setTrimestre('3')}
    }

          const q = query(projectsCollectionRef);
          setLoading(true)
          const change = onSnapshot(q, snapshot=>{
              setProjetos(snapshot.docs.map(doc=>({
              data:doc.data(),
              id:doc.id
              })))
            setLoading(false)
          })


          return()=>{handletrimestre();change()}
      
       },[]);

   useLayoutEffect(()=>{
    setLoading(true)

    setTodos(projetos.concat(ProjetosHistorico))

    if (hoje>= `${ano}-01-01`&& hoje < `${ano}-04-01`){setTrimestre('4')}
    if (hoje>= `${ano}-04-01`&& hoje < `${ano}-07-01`){setTrimestre('1')}
    if (hoje>= `${ano}-07-01`&& hoje < `${ano}-10-01`){setTrimestre('2')}
    if (hoje>= `${ano}-10-01`&& hoje < `${ano+1}-01-01`){setTrimestre('3')}

    setLoading(false)
  
  
  },[projetos])

const PriTri=()=>{
     // listando projetos por data
                  var projetos1 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate < `${ano}-04-01`){return val}})
                  
                  var projetos1Ate = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate < `${ano}-04-01`){return val}})
                  //quantitativo trimestre
                  setQuantidade(projetos1.length) 
                  setYTDQtd(projetos1Ate.length)
                  

                  //dolar trimestre
                  var dolarTrimestre1= projetos1.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var dolarTrimestre2= projetos1.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  // dolar ate o fim do trimestre
                    var dolarTrimestre1Ate= projetos1Ate.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                    var dolarTrimestre2Ate= projetos1Ate.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

                  //real trimestre
                      var realTrimestre1= projetos1.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                      var realTrimestre2= projetos1.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  // real ate o fim do trimestre
                      var realTrimestre1Ate= projetos1Ate.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                    var realTrimestre2Ate= projetos1Ate.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')


                  //faturamento dolar trimestre
                    var dolarTrimestre = ()=>{
                      const dolar1 = dolarTrimestre1
                      var dolar1L = dolar1.length
                      var dolar1N = [];
                      for (var d1 = 0; d1 < dolar1L; d1++)
                      dolar1N.push(Number(dolar1[d1]))
                      const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                      
                      const dolar2 = dolarTrimestre2
                      var dolar2L = dolar2.length
                      var dolar2N = [];
                      for (var d2 = 0; d2 < dolar2L; d2++)
                      dolar2N.push(Number(dolar2[d2]))
                      const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

                      const dolarTotal = (clienteDolar1+clientedolar2)
                      return(dolarTotal)
                  }
                  setFatTrimestreDolar(dolarTrimestre())

                //faturamento reais trimestre
                  var reaisTrimestre= ()=>{
                      const Reais1 = realTrimestre1
                      var Reais1L = Reais1.length
                      var Reais1N = [];
                      for (var d1 = 0; d1 < Reais1L; d1++)
                      Reais1N.push(Number(Reais1[d1]))
                      const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                      
                      const Reais2 = realTrimestre2
                      var Reais2L = Reais2.length
                      var Reais2N = [];
                      for (var d2 = 0; d2 < Reais2L; d2++)
                      Reais2N.push(Number(Reais2[d2]))
                      const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)

                      const ReaisTotal = (clienteReais1+clienteReais2)
                      return(ReaisTotal)
                  }
                  setFatTrimestreReal(reaisTrimestre())

                  //faturamento dolar ate fim do trimestre
                  var dolarTrimestreTe = ()=>{
                      const dolar3 = dolarTrimestre1Ate
                      var dolar3L = dolar3.length
                      var dolar3N = [];
                      for (var d3 = 0; d3 < dolar3L; d3++)
                      dolar3N.push(Number(dolar3[d3]))
                      const clienteDolar3 = dolar3N.reduce((acc, curr) => acc + curr, 0)
                      
                      const dolar4 = dolarTrimestre2Ate
                      var dolar4L = dolar4.length
                      var dolar4N = [];
                      for (var d4 = 0; d4 < dolar4L; d4++)
                      dolar4N.push(Number(dolar4[d4]))
                      const clientedolar4 = dolar4N.reduce((acc, curr) => acc + curr, 0)

                      const dolarTotalAte = (clienteDolar3+clientedolar4)
                      return(dolarTotalAte)
                  }
                  setFatDolarAte(dolarTrimestreTe())

                    //faturamento reais ate fim do trimestre
                  var reaisTrimestreTe= ()=>{
                      const Reais3 = realTrimestre1Ate
                      var Reais3L = Reais3.length
                      var Reais3N = [];
                      for (var d3 = 0; d3 < Reais3L; d3++)
                      Reais3N.push(Number(Reais3[d3]))
                      const clienteReais3 = Reais3N.reduce((acc, curr) => acc + curr, 0)
                      
                      const Reais4 = realTrimestre2Ate
                      var Reais4L = Reais4.length
                      var Reais4N = [];
                      for (var d4 = 0; d4 < Reais4L; d4++)
                      Reais4N.push(Number(Reais4[d4]))
                      const clienteReais4 = Reais4N.reduce((acc, curr) => acc + curr, 0)

                      const ReaisTotalAte = (clienteReais3+clienteReais4)
                      return(ReaisTotalAte)
                  }
                  setFatRealAte(reaisTrimestreTe())

                  //clientes contratantes timestre
                    let clientesContratantes =  projetos1.map((i)=>i.data.projClient)
                  let clientesemDuplicados = clientesContratantes.filter((v, i, s) => {
                    return s.indexOf(v) === i;
                  });

                          var dolar = (nome)=>{
                      const dolar5 = projetos1.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                      var dolar5L = dolar5.length
                      var dolar5N = [];
                      for (var d5 = 0; d5 < dolar5L; d5++)
                      dolar5N.push(Number(dolar5[d5]))
                      const clienteDolar5 = dolar5N.reduce((acc, curr) => acc + curr, 0)
                      
                      const dolar6 = projetos1.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                      var dolar6L = dolar6.length
                      var dolar6N = [];
                      for (var d6 = 0; d6 < dolar6L; d6++)
                      dolar6N.push(Number(dolar6[d6]))
                      const clientedolar6 = dolar6N.reduce((acc, curr) => acc + curr, 0)

                      const dolarTotal6 = (clienteDolar5+clientedolar6)
                      return(dolarTotal6)
                  }

                  var reais = (nome)=>{
                      const Reais5 = projetos1.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                      var Reais5L = Reais5.length
                      var Reais5N = [];
                      for (var d5 = 0; d5 < Reais5L; d5++)
                      Reais5N.push(Number(Reais5[d5]))
                      const clienteReais5 = Reais5N.reduce((acc, curr) => acc + curr, 0)
                      
                      const Reais6 = projetos1.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                      var Reais6L = Reais6.length
                      var Reais6N = [];
                      for (var d6 = 0; d6 < Reais6L; d6++)
                      Reais6N.push(Number(Reais6[d6]))
                      const clienteReais6 = Reais6N.reduce((acc, curr) => acc + curr, 0)

                      const ReaisTotal6 = (clienteReais5+clienteReais6)
                      return(ReaisTotal6)
                  }
                  
                  var clientes1 = clientesemDuplicados.map((item, index)=>(
                      {
                        "id": index,
                        "name":item,
                        "quantidade":clientesContratantes.filter((val)=>{if(val === item){return val}}).length,
                          "dolares":dolar(item).toLocaleString('pt-br',{style: 'currency', currency: 'USD'}),

                        "reais":reais(item).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    }
                  ))

                  var clientes2 = clientes1.sort((a,b)=>{
                      if (a.quantidade < b.quantidade) {
                        return 1;
                      }
                      if (a.quantidade > b.quantidade) {
                        return -1;
                      }
                      return 0;
                    })

                  var contratantesString = () => {
                      let str = "";
                      clientes2.map((item) => {
                        str += `${item.name} - ${item.quantidade} - ${item.dolares} -  ${item.reais} <br/> <br/>`;
                      });
                      return str;
                    };  
                  setContratantes(contratantesString)
                  //clientes que contrataram ultimo ano mas nao nesse trimestre
                var ClienteAnoprojetos3Ano = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano-1}-01-01` && val.data.projStartDate < `${ano}-01-01`){return val}}).map((i)=>i.data.projClient)

                let ClienteAnoprojetos3SemDuplicados = ClienteAnoprojetos3Ano.filter((v, i, s) => {
                    return s.indexOf(v) === i;
                  });

                var ClentesAnoSemTrimestre =  ClienteAnoprojetos3SemDuplicados.filter(val => !clientesemDuplicados.includes(val))

                  var ClentesAnoSemTrimestre2 = ClentesAnoSemTrimestre.sort((a,b)=>{
                      if (a > b) {
                        return 1;
                      }
                      if (a < b) {
                        return -1;
                      }
                      return 0;
                    })

                var ClentesAnoSemTrimestreString = () => {
                      let str = "";
                      ClentesAnoSemTrimestre2.map((item) => {
                        str += `${item}<br/> <br/>`;
                      });
                      return str;
                    };  
                setNaotrimestre(ClentesAnoSemTrimestreString)

                // novos clientes no trimestre

                  var clientesTotais = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate < `${ano}-01-01`){return val}}).map((i)=>i.data.projClient)
                  
                let clientesTotaisSemDuplicados = clientesTotais.filter((v, i, s) => {
                    return s.indexOf(v) === i;
                  });

                var novosClientes = clientesemDuplicados.filter(val => !clientesTotaisSemDuplicados.includes(val))
                
                var novosClientes2 = novosClientes.sort((a,b)=>{
                      if (a > b) {
                        return 1;
                      }
                      if (a < b) {
                        return -1;
                      }
                      return 0;
                    })
                

                  var novosClientesString = () => {
                      let str = "";
                      novosClientes2.map((item) => {
                        str += `${item}<br/> <br/>`;
                      });
                      return str;
                    };  
                setNovosclientes(novosClientesString)

                //voltaram a contratar depois de 6 mesmes
                var clientes6Meses =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate >= `${ano-1}-07-01` && val.data.projStartDate < `${ano}-01-01`){return val}}).map((i)=>i.data.projClient)
                var clientes6MesesSemDuplicado=  clientes6Meses.filter((v, i, s) => {
                    return s.indexOf(v) === i;
                  });
                var clientesVoltaram = clientesemDuplicados.filter(val => !clientes6MesesSemDuplicado.includes(val)&& !novosClientes.includes(val))

                var clientesVoltaram2 = clientesVoltaram.sort((a,b)=>{
                      if (a > b) {
                        return 1;
                      }
                      if (a < b) {
                        return -1;
                      }
                      return 0;
                    })


                  var clientesVoltaramString = () => {
                      let str = "";
                      clientesVoltaram2.map((item) => {
                        str += `${item}<br/> <br/>`;
                      });
                      return str;
                    };  
                setVoltaCliente(clientesVoltaramString)
                setEnviar(true)
}
const Segtri=()=>{
             
    // listando projetos por data
    var projetos2 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate < `${ano}-07-01`){return val}})
    
    var projetos2Ate = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate < `${ano}-07-01`){return val}})
    //quantitativo trimestre
     setQuantidade(projetos2.length) 
    setYTDQtd(projetos2Ate.length) 

    //dolar trimestre
    var dolarTrimestre1= projetos2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var dolarTrimestre2= projetos2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    // dolar ate o fim do trimestre
      var dolarTrimestre1Ate= projetos2Ate.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var dolarTrimestre2Ate= projetos2Ate.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

    //real trimestre
        var realTrimestre1= projetos2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var realTrimestre2= projetos2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    // real ate o fim do trimestre
        var realTrimestre1Ate= projetos2Ate.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var realTrimestre2Ate= projetos2Ate.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')


    //faturamento dolar trimestre
      var dolarTrimestre = ()=>{
        const dolar1 = dolarTrimestre1
        var dolar1L = dolar1.length
        var dolar1N = [];
        for (var d1 = 0; d1 < dolar1L; d1++)
        dolar1N.push(Number(dolar1[d1]))
        const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
        
        const dolar2 = dolarTrimestre2
        var dolar2L = dolar2.length
        var dolar2N = [];
        for (var d2 = 0; d2 < dolar2L; d2++)
        dolar2N.push(Number(dolar2[d2]))
        const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

        const dolarTotal = (clienteDolar1+clientedolar2)
        return(dolarTotal)
    }
    setFatTrimestreDolar(dolarTrimestre())

  //faturamento reais trimestre
    var reaisTrimestre= ()=>{
        const Reais1 = realTrimestre1
        var Reais1L = Reais1.length
        var Reais1N = [];
        for (var d1 = 0; d1 < Reais1L; d1++)
        Reais1N.push(Number(Reais1[d1]))
        const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2 = realTrimestre2
        var Reais2L = Reais2.length
        var Reais2N = [];
        for (var d2 = 0; d2 < Reais2L; d2++)
        Reais2N.push(Number(Reais2[d2]))
        const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)

        const ReaisTotal = (clienteReais1+clienteReais2)
        return(ReaisTotal)
    }
    setFatTrimestreReal(reaisTrimestre())

    //faturamento dolar ate fim do trimestre
    var dolarTrimestreTe = ()=>{
        const dolar3 = dolarTrimestre1Ate
        var dolar3L = dolar3.length
        var dolar3N = [];
        for (var d3 = 0; d3 < dolar3L; d3++)
        dolar3N.push(Number(dolar3[d3]))
        const clienteDolar3 = dolar3N.reduce((acc, curr) => acc + curr, 0)
        
        const dolar4 = dolarTrimestre2Ate
        var dolar4L = dolar4.length
        var dolar4N = [];
        for (var d4 = 0; d4 < dolar4L; d4++)
        dolar4N.push(Number(dolar4[d4]))
        const clientedolar4 = dolar4N.reduce((acc, curr) => acc + curr, 0)

        const dolarTotalAte = (clienteDolar3+clientedolar4)
        return(dolarTotalAte)
    }
    setFatDolarAte(dolarTrimestreTe())

      //faturamento reais ate fim do trimestre
    var reaisTrimestreTe= ()=>{
        const Reais3 = realTrimestre1Ate
        var Reais3L = Reais3.length
        var Reais3N = [];
        for (var d3 = 0; d3 < Reais3L; d3++)
        Reais3N.push(Number(Reais3[d3]))
        const clienteReais3 = Reais3N.reduce((acc, curr) => acc + curr, 0)
        
        const Reais4 = realTrimestre2Ate
        var Reais4L = Reais4.length
        var Reais4N = [];
        for (var d4 = 0; d4 < Reais4L; d4++)
        Reais4N.push(Number(Reais4[d4]))
        const clienteReais4 = Reais4N.reduce((acc, curr) => acc + curr, 0)

        const ReaisTotalAte = (clienteReais3+clienteReais4)
        return(ReaisTotalAte)
    }
    setFatRealAte(reaisTrimestreTe())

    //clientes contratantes timestre
      let clientesContratantes =  projetos2.map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesContratantes.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });

            var dolar = (nome)=>{
        const dolar5 = projetos2.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var dolar5L = dolar5.length
        var dolar5N = [];
        for (var d5 = 0; d5 < dolar5L; d5++)
        dolar5N.push(Number(dolar5[d5]))
        const clienteDolar5 = dolar5N.reduce((acc, curr) => acc + curr, 0)
        
        const dolar6 = projetos2.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var dolar6L = dolar6.length
        var dolar6N = [];
        for (var d6 = 0; d6 < dolar6L; d6++)
        dolar6N.push(Number(dolar6[d6]))
        const clientedolar6 = dolar6N.reduce((acc, curr) => acc + curr, 0)

        const dolarTotal6 = (clienteDolar5+clientedolar6)
        return(dolarTotal6)
    }

    var reais = (nome)=>{
        const Reais5 = projetos2.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var Reais5L = Reais5.length
        var Reais5N = [];
        for (var d5 = 0; d5 < Reais5L; d5++)
        Reais5N.push(Number(Reais5[d5]))
        const clienteReais5 = Reais5N.reduce((acc, curr) => acc + curr, 0)
        
        const Reais6 = projetos2.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var Reais6L = Reais6.length
        var Reais6N = [];
        for (var d6 = 0; d6 < Reais6L; d6++)
        Reais6N.push(Number(Reais6[d6]))
        const clienteReais6 = Reais6N.reduce((acc, curr) => acc + curr, 0)

        const ReaisTotal6 = (clienteReais5+clienteReais6)
        return(ReaisTotal6)
    }
    
    var clientes1 = clientesemDuplicados.map((item, index)=>(
        {
          "id": index,
          "name":item,
          "quantidade":clientesContratantes.filter((val)=>{if(val === item){return val}}).length,
            "dolares":dolar(item).toLocaleString('pt-br',{style: 'currency', currency: 'USD'}),

          "reais":reais(item).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
      }
    ))

    var clientes2 = clientes1.sort((a,b)=>{
        if (a.quantidade < b.quantidade) {
          return 1;
        }
        if (a.quantidade > b.quantidade) {
          return -1;
        }
        return 0;
      })

    var contratantesString = () => {
        let str = "";
        clientes2.map((item) => {
          str += `${item.name} - ${item.quantidade} - ${item.dolares} -  ${item.reais} <br/> <br/>`;
        });
        return str;
      };  
    setContratantes(contratantesString)
    //clientes que contrataram ultimo ano mas nao nesse trimestre
  var ClienteAnoprojetos3Ano = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano-1}-04-01` && val.data.projStartDate < `${ano}-04-01`){return val}}).map((i)=>i.data.projClient)

  let ClienteAnoprojetos3SemDuplicados = ClienteAnoprojetos3Ano.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });

  var ClentesAnoSemTrimestre =  ClienteAnoprojetos3SemDuplicados.filter(val => !clientesemDuplicados.includes(val))

    var ClentesAnoSemTrimestre2 = ClentesAnoSemTrimestre.sort((a,b)=>{
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })

  var ClentesAnoSemTrimestreString = () => {
        let str = "";
        ClentesAnoSemTrimestre2.map((item) => {
          str += `${item}<br/> <br/>`;
        });
        return str;
      };  
  setNaotrimestre(ClentesAnoSemTrimestreString)

  // novos clientes no trimestre

    var clientesTotais = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate < `${ano}-04-01`){return val}}).map((i)=>i.data.projClient)
    
  let clientesTotaisSemDuplicados = clientesTotais.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });

  var novosClientes = clientesemDuplicados.filter(val => !clientesTotaisSemDuplicados.includes(val))
  
  var novosClientes2 = novosClientes.sort((a,b)=>{
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
  

    var novosClientesString = () => {
        let str = "";
        novosClientes2.map((item) => {
          str += `${item}<br/> <br/>`;
        });
        return str;
      };  
  setNovosclientes(novosClientesString)

  //voltaram a contratar depois de 6 mesmes
  var clientes6Meses =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate >= `${ano-1}-10-01` && val.data.projStartDate < `${ano}-04-01`){return val}}).map((i)=>i.data.projClient)
  var clientes6MesesSemDuplicado=  clientes6Meses.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  var clientesVoltaram = clientesemDuplicados.filter(val => !clientes6MesesSemDuplicado.includes(val)&& !novosClientes.includes(val))

  var clientesVoltaram2 = clientesVoltaram.sort((a,b)=>{
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })


    var clientesVoltaramString = () => {
        let str = "";
        clientesVoltaram2.map((item) => {
          str += `${item}<br/> <br/>`;
        });
        return str;
      };  
  setVoltaCliente(clientesVoltaramString)
  setEnviar(true)
}
const TerTri=()=>{
 // listando projetos por data
            var projetos3 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate < `${ano}-10-01`){return val}})
            var projetos3Ate = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate < `${ano}-10-01`){return val}})
            //quantitativo trimestre
            setQuantidade(projetos3.length)
            setYTDQtd(projetos3Ate.length)
            

            //dolar trimestre
            var dolarTrimestre1= projetos3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
            var dolarTrimestre2= projetos3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
            // dolar ate o fim do trimestre
             var dolarTrimestre1Ate= projetos3Ate.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
            var dolarTrimestre2Ate= projetos3Ate.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

            //real trimestre
               var realTrimestre1= projetos3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
            var realTrimestre2= projetos3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
            // real ate o fim do trimestre
               var realTrimestre1Ate= projetos3Ate.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
            var realTrimestre2Ate= projetos3Ate.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')


            //faturamento dolar trimestre
             var dolarTrimestre = ()=>{
                const dolar1 = dolarTrimestre1
                var dolar1L = dolar1.length
                var dolar1N = [];
                for (var d1 = 0; d1 < dolar1L; d1++)
                dolar1N.push(Number(dolar1[d1]))
                const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar2 = dolarTrimestre2
                var dolar2L = dolar2.length
                var dolar2N = [];
                for (var d2 = 0; d2 < dolar2L; d2++)
                dolar2N.push(Number(dolar2[d2]))
                const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

                const dolarTotal = (clienteDolar1+clientedolar2)
                return(dolarTotal)
            }
            setFatTrimestreDolar(dolarTrimestre())

          //faturamento reais trimestre
           var reaisTrimestre= ()=>{
                const Reais1 = realTrimestre1
                var Reais1L = Reais1.length
                var Reais1N = [];
                for (var d1 = 0; d1 < Reais1L; d1++)
                Reais1N.push(Number(Reais1[d1]))
                const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais2 = realTrimestre2
                var Reais2L = Reais2.length
                var Reais2N = [];
                for (var d2 = 0; d2 < Reais2L; d2++)
                Reais2N.push(Number(Reais2[d2]))
                const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)

                const ReaisTotal = (clienteReais1+clienteReais2)
                return(ReaisTotal)
            }
            setFatTrimestreReal(reaisTrimestre())

            //faturamento dolar ate fim do trimestre
            var dolarTrimestreTe = ()=>{
                const dolar3 = dolarTrimestre1Ate
                var dolar3L = dolar3.length
                var dolar3N = [];
                for (var d3 = 0; d3 < dolar3L; d3++)
                dolar3N.push(Number(dolar3[d3]))
                const clienteDolar3 = dolar3N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar4 = dolarTrimestre2Ate
                var dolar4L = dolar4.length
                var dolar4N = [];
                for (var d4 = 0; d4 < dolar4L; d4++)
                dolar4N.push(Number(dolar4[d4]))
                const clientedolar4 = dolar4N.reduce((acc, curr) => acc + curr, 0)

                const dolarTotalAte = (clienteDolar3+clientedolar4)
                return(dolarTotalAte)
            }
            setFatDolarAte(dolarTrimestreTe())

              //faturamento reais ate fim do trimestre
           var reaisTrimestreTe= ()=>{
                const Reais3 = realTrimestre1Ate
                var Reais3L = Reais3.length
                var Reais3N = [];
                for (var d3 = 0; d3 < Reais3L; d3++)
                Reais3N.push(Number(Reais3[d3]))
                const clienteReais3 = Reais3N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais4 = realTrimestre2Ate
                var Reais4L = Reais4.length
                var Reais4N = [];
                for (var d4 = 0; d4 < Reais4L; d4++)
                Reais4N.push(Number(Reais4[d4]))
                const clienteReais4 = Reais4N.reduce((acc, curr) => acc + curr, 0)

                const ReaisTotalAte = (clienteReais3+clienteReais4)
                return(ReaisTotalAte)
            }
            setFatRealAte(reaisTrimestreTe())

            //clientes contratantes timestre
             let clientesContratantes =  projetos3.map((i)=>i.data.projClient)
            let clientesemDuplicados = clientesContratantes.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });

                   var dolar = (nome)=>{
                const dolar5 = projetos3.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolar5L = dolar5.length
                var dolar5N = [];
                for (var d5 = 0; d5 < dolar5L; d5++)
                dolar5N.push(Number(dolar5[d5]))
                const clienteDolar5 = dolar5N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar6 = projetos3.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var dolar6L = dolar6.length
                var dolar6N = [];
                for (var d6 = 0; d6 < dolar6L; d6++)
                dolar6N.push(Number(dolar6[d6]))
                const clientedolar6 = dolar6N.reduce((acc, curr) => acc + curr, 0)

                const dolarTotal6 = (clienteDolar5+clientedolar6)
                return(dolarTotal6)
            }

            var reais = (nome)=>{
                const Reais5 = projetos3.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var Reais5L = Reais5.length
                var Reais5N = [];
                for (var d5 = 0; d5 < Reais5L; d5++)
                Reais5N.push(Number(Reais5[d5]))
                const clienteReais5 = Reais5N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais6 = projetos3.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var Reais6L = Reais6.length
                var Reais6N = [];
                for (var d6 = 0; d6 < Reais6L; d6++)
                Reais6N.push(Number(Reais6[d6]))
                const clienteReais6 = Reais6N.reduce((acc, curr) => acc + curr, 0)

                const ReaisTotal6 = (clienteReais5+clienteReais6)
                return(ReaisTotal6)
            }
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade":clientesContratantes.filter((val)=>{if(val === item){return val}}).length,
                    "dolares":dolar(item).toLocaleString('pt-br',{style: 'currency', currency: 'USD'}),

                  "reais":reais(item).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.quantidade < b.quantidade) {
                  return 1;
                }
                if (a.quantidade > b.quantidade) {
                  return -1;
                }
                return 0;
              })

            var contratantesString = () => {
                let str = "";
                clientes2.map((item) => {
                  str += `${item.name} - ${item.quantidade} - ${item.dolares} -  ${item.reais} <br/> <br/>`;
                });
                return str;
              };  
           setContratantes(contratantesString)
           //clientes que contrataram ultimo ano mas nao nesse trimestre
          var ClienteAnoprojetos3Ano = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano-1}-07-01` && val.data.projStartDate < `${ano}-07-01`){return val}}).map((i)=>i.data.projClient)

          let ClienteAnoprojetos3SemDuplicados = ClienteAnoprojetos3Ano.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });

          var ClentesAnoSemTrimestre =  ClienteAnoprojetos3SemDuplicados.filter(val => !clientesemDuplicados.includes(val))

           var ClentesAnoSemTrimestre2 = ClentesAnoSemTrimestre.sort((a,b)=>{
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                return 0;
              })

          var ClentesAnoSemTrimestreString = () => {
                let str = "";
                ClentesAnoSemTrimestre2.map((item) => {
                  str += `${item}<br/> <br/>`;
                });
                return str;
              };  
          setNaotrimestre(ClentesAnoSemTrimestreString)

          // novos clientes no trimestre

           var clientesTotais = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate < `${ano}-07-01`){return val}}).map((i)=>i.data.projClient)
           
          let clientesTotaisSemDuplicados = clientesTotais.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });

          var novosClientes = clientesemDuplicados.filter(val => !clientesTotaisSemDuplicados.includes(val))
          
          var novosClientes2 = novosClientes.sort((a,b)=>{
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                return 0;
              })
          

           var novosClientesString = () => {
                let str = "";
                novosClientes2.map((item) => {
                  str += `${item}<br/> <br/>`;
                });
                return str;
              };  
          setNovosclientes(novosClientesString)

          //voltaram a contratar depois de 6 mesmes
          var clientes6Meses =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate < `${ano}-07-01`){return val}}).map((i)=>i.data.projClient)
          var clientes6MesesSemDuplicado=  clientes6Meses.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          var clientesVoltaram = clientesemDuplicados.filter(val => !clientes6MesesSemDuplicado.includes(val) && !novosClientes.includes(val))

          var clientesVoltaram2 = clientesVoltaram.sort((a,b)=>{
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                return 0;
              })


           var clientesVoltaramString = () => {
                let str = "";
                clientesVoltaram2.map((item) => {
                  str += `${item}<br/> <br/>`;
                });
                return str;
              };  
          setVoltaCliente(clientesVoltaramString)
          setEnviar(true)
}
const QuaTri=()=>{
 // listando projetos por data
              var projetos4 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate >= `${ano-1}-10-01` && val.data.projStartDate < `${ano}-01-01`){return val}})
              
              var projetos4Ate = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano-1}-01-01` && val.data.projStartDate < `${ano}-01-01`){return val}})
              //quantitativo trimestre
              setQuantidade(projetos4.length) 
              setYTDQtd(projetos4Ate.length)
              

              //dolar trimestre
              var dolarTrimestre1= projetos4.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
              var dolarTrimestre2= projetos4.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
              // dolar ate o fim do trimestre
                var dolarTrimestre1Ate= projetos4Ate.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolarTrimestre2Ate= projetos4Ate.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

              //real trimestre
                  var realTrimestre1= projetos4.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var realTrimestre2= projetos4.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
              // real ate o fim do trimestre
                  var realTrimestre1Ate= projetos4Ate.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var realTrimestre2Ate= projetos4Ate.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')


              //faturamento dolar trimestre
                var dolarTrimestre = ()=>{
                  const dolar1 = dolarTrimestre1
                  var dolar1L = dolar1.length
                  var dolar1N = [];
                  for (var d1 = 0; d1 < dolar1L; d1++)
                  dolar1N.push(Number(dolar1[d1]))
                  const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar2 = dolarTrimestre2
                  var dolar2L = dolar2.length
                  var dolar2N = [];
                  for (var d2 = 0; d2 < dolar2L; d2++)
                  dolar2N.push(Number(dolar2[d2]))
                  const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

                  const dolarTotal = (clienteDolar1+clientedolar2)
                  return(dolarTotal)
              }
              setFatTrimestreDolar(dolarTrimestre())

            //faturamento reais trimestre
              var reaisTrimestre= ()=>{
                  const Reais1 = realTrimestre1
                  var Reais1L = Reais1.length
                  var Reais1N = [];
                  for (var d1 = 0; d1 < Reais1L; d1++)
                  Reais1N.push(Number(Reais1[d1]))
                  const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais2 = realTrimestre2
                  var Reais2L = Reais2.length
                  var Reais2N = [];
                  for (var d2 = 0; d2 < Reais2L; d2++)
                  Reais2N.push(Number(Reais2[d2]))
                  const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)

                  const ReaisTotal = (clienteReais1+clienteReais2)
                  return(ReaisTotal)
              }
              setFatTrimestreReal(reaisTrimestre())

              //faturamento dolar ate fim do trimestre
              var dolarTrimestreTe = ()=>{
                  const dolar3 = dolarTrimestre1Ate
                  var dolar3L = dolar3.length
                  var dolar3N = [];
                  for (var d3 = 0; d3 < dolar3L; d3++)
                  dolar3N.push(Number(dolar3[d3]))
                  const clienteDolar3 = dolar3N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar4 = dolarTrimestre2Ate
                  var dolar4L = dolar4.length
                  var dolar4N = [];
                  for (var d4 = 0; d4 < dolar4L; d4++)
                  dolar4N.push(Number(dolar4[d4]))
                  const clientedolar4 = dolar4N.reduce((acc, curr) => acc + curr, 0)

                  const dolarTotalAte = (clienteDolar3+clientedolar4)
                  return(dolarTotalAte)
              }
              setFatDolarAte(dolarTrimestreTe())

                //faturamento reais ate fim do trimestre
              var reaisTrimestreTe= ()=>{
                  const Reais3 = realTrimestre1Ate
                  var Reais3L = Reais3.length
                  var Reais3N = [];
                  for (var d3 = 0; d3 < Reais3L; d3++)
                  Reais3N.push(Number(Reais3[d3]))
                  const clienteReais3 = Reais3N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais4 = realTrimestre2Ate
                  var Reais4L = Reais4.length
                  var Reais4N = [];
                  for (var d4 = 0; d4 < Reais4L; d4++)
                  Reais4N.push(Number(Reais4[d4]))
                  const clienteReais4 = Reais4N.reduce((acc, curr) => acc + curr, 0)

                  const ReaisTotalAte = (clienteReais3+clienteReais4)
                  return(ReaisTotalAte)
              }
              setFatRealAte(reaisTrimestreTe())

              //clientes contratantes timestre
                let clientesContratantes =  projetos4.map((i)=>i.data.projClient)
              let clientesemDuplicados = clientesContratantes.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });

                      var dolar = (nome)=>{
                  const dolar5 = projetos4.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var dolar5L = dolar5.length
                  var dolar5N = [];
                  for (var d5 = 0; d5 < dolar5L; d5++)
                  dolar5N.push(Number(dolar5[d5]))
                  const clienteDolar5 = dolar5N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar6 = projetos4.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var dolar6L = dolar6.length
                  var dolar6N = [];
                  for (var d6 = 0; d6 < dolar6L; d6++)
                  dolar6N.push(Number(dolar6[d6]))
                  const clientedolar6 = dolar6N.reduce((acc, curr) => acc + curr, 0)

                  const dolarTotal6 = (clienteDolar5+clientedolar6)
                  return(dolarTotal6)
              }

              var reais = (nome)=>{
                  const Reais5 = projetos4.filter((val)=>{if(val.data.projClient === nome && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var Reais5L = Reais5.length
                  var Reais5N = [];
                  for (var d5 = 0; d5 < Reais5L; d5++)
                  Reais5N.push(Number(Reais5[d5]))
                  const clienteReais5 = Reais5N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais6 = projetos4.filter((val)=>{if(val.data.projClient === nome && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var Reais6L = Reais6.length
                  var Reais6N = [];
                  for (var d6 = 0; d6 < Reais6L; d6++)
                  Reais6N.push(Number(Reais6[d6]))
                  const clienteReais6 = Reais6N.reduce((acc, curr) => acc + curr, 0)

                  const ReaisTotal6 = (clienteReais5+clienteReais6)
                  return(ReaisTotal6)
              }
              
              var clientes1 = clientesemDuplicados.map((item, index)=>(
                  {
                    "id": index,
                    "name":item,
                    "quantidade":clientesContratantes.filter((val)=>{if(val === item){return val}}).length,
                      "dolares":dolar(item).toLocaleString('pt-br',{style: 'currency', currency: 'USD'}),

                    "reais":reais(item).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                }
              ))

              var clientes2 = clientes1.sort((a,b)=>{
                  if (a.quantidade < b.quantidade) {
                    return 1;
                  }
                  if (a.quantidade > b.quantidade) {
                    return -1;
                  }
                  return 0;
                })

              var contratantesString = () => {
                  let str = "";
                  clientes2.map((item) => {
                    str += `${item.name} - ${item.quantidade} - ${item.dolares} -  ${item.reais} <br/> <br/>`;
                  });
                  return str;
                };  
              setContratantes(contratantesString)
              //clientes que contrataram ultimo ano mas nao nesse trimestre
            var ClienteAnoprojetos3Ano = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' &&val.data.projStartDate >= `${ano-2}-10-01` && val.data.projStartDate < `${ano-1}-10-01`){return val}}).map((i)=>i.data.projClient)

            let ClienteAnoprojetos3SemDuplicados = ClienteAnoprojetos3Ano.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });

            var ClentesAnoSemTrimestre =  ClienteAnoprojetos3SemDuplicados.filter(val => !clientesemDuplicados.includes(val))

              var ClentesAnoSemTrimestre2 = ClentesAnoSemTrimestre.sort((a,b)=>{
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                })

            var ClentesAnoSemTrimestreString = () => {
                  let str = "";
                  ClentesAnoSemTrimestre2.map((item) => {
                    str += `${item}<br/> <br/>`;
                  });
                  return str;
                };  
            setNaotrimestre(ClentesAnoSemTrimestreString)

            // novos clientes no trimestre

              var clientesTotais = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'&& val.data.projStartDate < `${ano-1}-10-01`){return val}}).map((i)=>i.data.projClient)
              
            let clientesTotaisSemDuplicados = clientesTotais.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });

            var novosClientes = clientesemDuplicados.filter(val => !clientesTotaisSemDuplicados.includes(val))
            
            var novosClientes2 = novosClientes.sort((a,b)=>{
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                })
            

              var novosClientesString = () => {
                  let str = "";
                  novosClientes2.map((item) => {
                    str += `${item}<br/> <br/>`;
                  });
                  return str;
                };  
            setNovosclientes(novosClientesString)

            //voltaram a contratar depois de 6 mesmes
            var clientes6Meses =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate >= `${ano-1}-04-01`&& val.data.projStartDate < `${ano-1}-10-01` ){return val}}).map((i)=>i.data.projClient)
            var clientes6MesesSemDuplicado=  clientes6Meses.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });
            var clientesVoltaram = clientesemDuplicados.filter(val => !clientes6MesesSemDuplicado.includes(val) && !novosClientes.includes(val))

            var clientesVoltaram2 = clientesVoltaram.sort((a,b)=>{
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                })


              var clientesVoltaramString = () => {
                  let str = "";
                  clientesVoltaram2.map((item) => {
                    str += `${item}<br/> <br/>`;
                  });
                  return str;
                };  
            setVoltaCliente(clientesVoltaramString)
            setEnviar(true)
}


const handleRelatorio=()=>{
        if(trimestre === '1'){
          PriTri()
        }    
         if(trimestre === '2'){
            Segtri() 
        }
        if(trimestre === '3'){
           TerTri()
        }
         if(trimestre === '4'){
          QuaTri()
        }
       }

    const enviarRelatorio=()=>{
         var templateParams = {
        subject: `Relatório ${trimestre}° Trimestre de ${trimestre ==='4'? ano-1: ano}`,
        message:`
                  <h2>Relatório ${trimestre}° Trimestre de ${trimestre ==='4'? ano-1: ano}</h2>
                  <p>Quantidade de projetos no trimestre: ${quantidade}</p>
                  <p>Faturamento em dolares no trimestre: ${fatTrimestreDolar.toLocaleString('pt-br',{style: 'currency', currency: 'USD'})}</p>
                  <p>Faturamento em reais no trimestre: ${fatTrimestreReal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>

                  <h3>YTD</h3>
                  <p>Quantidade de projetos: ${YTDqtd}</p>
                  <p>Faturamento em dolares YTD: ${fatDolarAte.toLocaleString('pt-br',{style: 'currency', currency: 'USD'})}</p>
                  <p>Faturamento em reais YTD: ${fatRealAte.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>

                  <h3>Clientes Contratantes no Trimestre</h3>
                  <p>${contratantes === ''? 'Não há registro.': contratantes}</p>
                  <h3>Clientes Contratantes no Último Ano e NÂO Neste Trimestre</h3>
                  <p>${naoTrimeste  === ''? 'Não há registro.': naoTrimeste}</p>
                  <h3>Novos Clientes no Trimestre</h3>
                  <p>${novosCLientes === ''? 'Não há registro de novos clientes neste trimeste.': novosCLientes}</p>
                  <h3>Clientes que NÂO Contratavam a 6 Meses e Voltaram a Contratar</h3>
                   <p>${voltaCliente === ''? 'Não há registro de clientes que voltaram a contratar neste trimeste.': voltaCliente}</p>
              
        `,
    
        to:['antoniobelias@gmail.com','luisesnal@gmail.com']
      };
      emailjs.send('GmailNexoIntell', 'template_o0l1b3m', templateParams, '4M21HcjlE7N2OeNsv')
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            setEnviado(true)
          }, function(error) {
            console.log('FAILED...', error);
            setErro(true)
          });
}
     
  return (
     <div>
    <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading && projetos.length>0&&

     <div className="pageTitle">
       <div>Envio Relatório Trimestral</div>
       
       <div><button onClick={handleRelatorio}>Gerar Relatório<br/> {trimestre}° Trimestre <br/> {trimestre ==='4'? ano-1: ano}</button></div>
       {enviar &&
       <div><button onClick={enviarRelatorio}>Enviar</button></div>
      }
      {enviado &&
        <p>Relatório enviado com sucesso!</p>
      }
      {erro &&
        <p>Houve um erro no envio do relatório, tente novamente mais tarde!</p>
      }
     </div>
     
   }
   {loading===false && projetos.length===0&&
      <div className="pageTitle">
        <p>Houve um erro de carregamento do Banco de dados do Firebase, recarregue a páguina</p>
      </div>
   
   }
</div>
  )
}

export default Relatorios