import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function Historico() {
  const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      return()=>{change()}
  
   },[]);



 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
    setLoading(false)
  },[projetos])



  const handleChart = ()=>{

    setGrafico(false)
        
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];
   
   const Num2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')){return val}})
    const L2002 = Num2002.length
    const Num2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003')){return val}})
    const L2003 = Num2003.length
    const Num2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')){return val}})
    const L2004 = Num2004.length
    const Num2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')){return val}})
    const L2005 = Num2005.length
    const Num2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')){return val}})
    const L2006 = Num2006.length
    const Num2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')){return val}})
    const L2007 = Num2007.length
    const Num2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')){return val}})
    const L2008 = Num2008.length
    const Num2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')){return val}})
    const L2009 = Num2009.length
    const Num2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')){return val}})
    const L2010 = Num2010.length
    const Num2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')){return val}})
    const L2011 = Num2011.length
    const Num2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')){return val}})
    const L2012 = Num2012.length
    const Num2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')){return val}})
    const L2013 = Num2013.length
    const Num2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')){return val}})
    const L2014 = Num2014.length
    const Num2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')){return val}})
    const L2015 = Num2015.length
    const Num2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')){return val}})
    const L2016 = Num2016.length
    const Num2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')){return val}})
    const L2017 = Num2017.length
    const Num2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')){return val}})
    const L2018 = Num2018.length
    const Num2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')){return val}})
    const L2019 = Num2019.length
    const Num2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')){return val}})
    const L2020 = Num2020.length
    const Num2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')){return val}})
    const L2021 = Num2021.length
    const Num2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')){return val}})
    const L2022 = Num2022.length
    const Num2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')){return val}})
    const L2023 = Num2023.length
    const Num2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado'){return val}})
    const L2024 = Num2024.length
    const Num2025 = todos.filter((val)=>{if(val.data.projStartDate.includes('2025')&& val.data.projStatus1 !== 'deletado'){return val}})
const L2025 = Num2025.length

   
       setProjetData({
           labels: anos,
           datasets:[
            {
             label:'Quantidade de Projetos',
             data: [L2002, L2003, L2004, L2005, L2006, L2007, L2008, L2009, L2010, L2011,L2012, L2013,L2014, L2015, L2016, L2017, L2018, L2019, L2020, L2021, L2022, L2023, L2024, L2025],
             fill: true,
             borderColor: '#FF5400',
             backgroundColor: 'rgba(255,84,0,.5)',
           }
    
          ]
         })
         setGrafico(true);
     }
  return (
    <div>
        <Header />
        {loading &&
           <div className="pageTitle">
           <div>Carregando...</div>
         </div>
        }
        {!loading &&
          <>
          <div className="pageTitle">
            <div>Histórico de Quantitativo de Projetos</div>
            <div><button onClick={handleChart}>Gerar Gráfico</button></div>
          </div>
          {grafico &&
            <div className="pageContent">
            <div className="pageChart">
              <LineChart chartData={projectData} />
            </div>
          </div>
          }
          
          </>     
        }
    </div>
  )
}

export default Historico