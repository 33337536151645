import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function ClientesAnoFaturamento() {
  const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [paislist, setPaisList]=useState(false)
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('um')
  
    const [ano, setAno]=useState('2025')
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')

    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025' ];

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    setClientes(clientesemDuplicados.sort())
    setLoading(false)
  },[projetos])

  const lista =()=>{
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    setClientes(clientesemDuplicados.sort())
    setPaisList(true)
  }
  
  const handleChart =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
    
    
  
    setProjetData({
      labels:meses ,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: true,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)

      const NumJaneiroCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
  
        const NumFevereiroCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
    
        const NumMarcoCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
    
        const NumAbrilCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumMaioCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumJunhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumJulhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumAgostoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumSetembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumOutubroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumNovembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumDezembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
    
        // JaneiroCl2
        const pReaisJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJaneiroCl2L = pReaisJaneiroCl2.length
          var pReaisJaneiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJaneiroCl2L; r1++)
          pReaisJaneiroCl2Numeros.push(Number(pReaisJaneiroCl2[r1]))
          const pReaisJaneiroCl2Total1 = pReaisJaneiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJaneiroCl2L2 = pReaisJaneiroCl22.length
          var pReaisJaneiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJaneiroCl2L2; r2++)
          pReaisJaneiroCl2Numeros2.push(Number(pReaisJaneiroCl22[r2]))
          const pReaisJaneiroCl2Total2 = pReaisJaneiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJaneiroCl2 = (pReaisJaneiroCl2Total1+pReaisJaneiroCl2Total2)
      
          const pDolarJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJaneiroCl2L = pDolarJaneiroCl2.length
          var pDolarJanrioNumeros = [];
          for (var d1 = 0; d1 < pDolarJaneiroCl2L; d1++)
          pDolarJanrioNumeros.push(Number(pDolarJaneiroCl2[d1]))
          const pDolarJaneiroCl2Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJaneiroCl2L2 = pDolarJaneiroCl22.length
          var pDolarJanrioNumeros2 = [];
          for (var d2 = 0; d2 < pDolarJaneiroCl2L2; d2++)
          pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl22[d2]))
          const pDolarJaneiroCl2Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJaneiroCl2 = (pDolarJaneiroCl2Total1+pDolarJaneiroCl2Total2)
      
      
      
          //FevereiroCl2  
          const pReaisFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisFevereiroCl2L = pReaisFevereiroCl2.length
          var pReaisFevereiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisFevereiroCl2L; r1++)
          pReaisFevereiroCl2Numeros.push(Number(pReaisFevereiroCl2[r1]))
          const pReaisFevereiroCl2Total1 = pReaisFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisFevereiroCl2L2 = pReaisFevereiroCl22.length
          var pReaisFevereiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisFevereiroCl2L2; r2++)
          pReaisFevereiroCl2Numeros2.push(Number(pReaisFevereiroCl22[r2]))
          const pReaisFevereiroCl2Total2 = pReaisFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisFevereiroCl2 = (pReaisFevereiroCl2Total1+pReaisFevereiroCl2Total2)
      
          const pDolarFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarFevereiroCl2L = pDolarFevereiroCl2.length
          var pDolarFevereiroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarFevereiroCl2L; d1++)
          pDolarFevereiroCl2Numeros.push(Number(pDolarFevereiroCl2[d1]))
          const pDolarFevereiroCl2Total1 = pDolarFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarFevereiroCl2L2 = pDolarFevereiroCl22.length
          var pDolarFevereiroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarFevereiroCl2L2; d2++)
          pDolarFevereiroCl2Numeros2.push(Number(pDolarFevereiroCl22[d2]))
          const pDolarFevereiroCl2Total2 = pDolarFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarFevereiroCl2 = (pDolarFevereiroCl2Total1+pDolarFevereiroCl2Total2)
          
          //MarcoCl2
          const pReaisMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMarcoCl2L = pReaisMarcoCl2.length
          var pReaisMarcoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMarcoCl2L; r1++)
          pReaisMarcoCl2Numeros.push(Number(pReaisMarcoCl2[r1]))
          const pReaisMarcoCl2Total1 = pReaisMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMarcoCl2L2 = pReaisMarcoCl22.length
          var pReaisMarcoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMarcoCl2L2; r2++)
          pReaisMarcoCl2Numeros2.push(Number(pReaisMarcoCl22[r2]))
          const pReaisMarcoCl2Total2 = pReaisMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMarcoCl2 = (pReaisMarcoCl2Total1+pReaisMarcoCl2Total2)
      
          const pDolarMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMarcoCl2L = pDolarMarcoCl2.length
          var pDolarMarcoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMarcoCl2L; d1++)
          pDolarMarcoCl2Numeros.push(Number(pDolarMarcoCl2[d1]))
          const pDolarMarcoCl2Total1 = pDolarMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMarcoCl2L2 = pDolarMarcoCl22.length
          var pDolarMarcoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMarcoCl2L2; d2++)
          pDolarMarcoCl2Numeros2.push(Number(pDolarMarcoCl22[d2]))
          const pDolarMarcoCl2Total2 = pDolarMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMarcoCl2 = (pDolarMarcoCl2Total1+pDolarMarcoCl2Total2)
      
          ///AbrilCl2
          const pReaisAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAbrilCl2L = pReaisAbrilCl2.length
          var pReaisAbrilCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAbrilCl2L; r1++)
          pReaisAbrilCl2Numeros.push(Number(pReaisAbrilCl2[r1]))
          const pReaisAbrilCl2Total1 = pReaisAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAbrilCl2L2 = pReaisAbrilCl22.length
          var pReaisAbrilCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAbrilCl2L2; r2++)
          pReaisAbrilCl2Numeros2.push(Number(pReaisAbrilCl22[r2]))
          const pReaisAbrilCl2Total2 = pReaisAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAbrilCl2 = (pReaisAbrilCl2Total1+pReaisAbrilCl2Total2)
      
          const pDolarAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAbrilCl2L = pDolarAbrilCl2.length
          var pDolarAbrilCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAbrilCl2L; d1++)
          pDolarAbrilCl2Numeros.push(Number(pDolarAbrilCl2[d1]))
          const pDolarAbrilCl2Total1 = pDolarAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAbrilCl2L2 = pDolarAbrilCl22.length
          var pDolarAbrilCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAbrilCl2L2; d2++)
          pDolarAbrilCl2Numeros2.push(Number(pDolarAbrilCl22[d2]))
          const pDolarAbrilCl2Total2 = pDolarAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAbrilCl2 = (pDolarAbrilCl2Total1+pDolarAbrilCl2Total2)
          //MaioCl2
          const pReaisMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMaioCl2L = pReaisMaioCl2.length
          var pReaisMaioCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMaioCl2L; r1++)
          pReaisMaioCl2Numeros.push(Number(pReaisMaioCl2[r1]))
          const pReaisMaioCl2Total1 = pReaisMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMaioCl2L2 = pReaisMaioCl22.length
          var pReaisMaioCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMaioCl2L2; r2++)
          pReaisMaioCl2Numeros2.push(Number(pReaisMaioCl22[r2]))
          const pReaisMaioCl2Total2 = pReaisMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMaioCl2 = (pReaisMaioCl2Total1+pReaisMaioCl2Total2)
      
          const pDolarMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMaioCl2L = pDolarMaioCl2.length
          var pDolarMaioCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMaioCl2L; d1++)
          pDolarMaioCl2Numeros.push(Number(pDolarMaioCl2[d1]))
          const pDolarMaioCl2Total1 = pDolarMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMaioCl2L2 = pDolarMaioCl22.length
          var pDolarMaioCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMaioCl2L2; d2++)
          pDolarMaioCl2Numeros2.push(Number(pDolarMaioCl22[d2]))
          const pDolarMaioCl2Total2 = pDolarMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMaioCl2 = (pDolarMaioCl2Total1+pDolarMaioCl2Total2)
      
          //JunhoCl2
          const pReaisJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJunhoCl2L = pReaisJunhoCl2.length
          var pReaisJunhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJunhoCl2L; r1++)
          pReaisJunhoCl2Numeros.push(Number(pReaisJunhoCl2[r1]))
          const pReaisJunhoCl2Total1 = pReaisJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJunhoCl2L2 = pReaisJunhoCl22.length
          var pReaisJunhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJunhoCl2L2; r2++)
          pReaisJunhoCl2Numeros2.push(Number(pReaisJunhoCl22[r2]))
          const pReaisJunhoCl2Total2 = pReaisJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJunhoCl2 = (pReaisJunhoCl2Total1+pReaisJunhoCl2Total2)
      
          const pDolarJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJunhoCl2L = pDolarJunhoCl2.length
          var pDolarJunhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJunhoCl2L; d1++)
          pDolarJunhoCl2Numeros.push(Number(pDolarJunhoCl2[d1]))
          const pDolarJunhoCl2Total1 = pDolarJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJunhoCl2L2 = pDolarJunhoCl22.length
          var pDolarJunhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJunhoCl2L2; d2++)
          pDolarJunhoCl2Numeros2.push(Number(pDolarJunhoCl22[d2]))
          const pDolarJunhoCl2Total2 = pDolarJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJunhoCl2 = (pDolarJunhoCl2Total1+pDolarJunhoCl2Total2)
          
          //JulhoCl2
          const pReaisJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJulhoCl2L = pReaisJulhoCl2.length
          var pReaisJulhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJulhoCl2L; r1++)
          pReaisJulhoCl2Numeros.push(Number(pReaisJulhoCl2[r1]))
          const pReaisJulhoCl2Total1 = pReaisJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJulhoCl2L2 = pReaisJulhoCl22.length
          var pReaisJulhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJulhoCl2L2; r2++)
          pReaisJulhoCl2Numeros2.push(Number(pReaisJulhoCl22[r2]))
          const pReaisJulhoCl2Total2 = pReaisJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJulhoCl2 = (pReaisJulhoCl2Total1+pReaisJulhoCl2Total2)
      
          const pDolarJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJulhoCl2L = pDolarJulhoCl2.length
          var pDolaroJulhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJulhoCl2L; d1++)
          pDolaroJulhoCl2Numeros.push(Number(pDolarJulhoCl2[d1]))
          const pDolarJulhoCl2Total1 = pDolaroJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJulhoCl2L2 = pDolarJulhoCl22.length
          var pDolaroJulhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJulhoCl2L2; d2++)
          pDolaroJulhoCl2Numeros2.push(Number(pDolarJulhoCl22[d2]))
          const pDolarJulhoCl2Total2 = pDolaroJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJulhoCl2 = (pDolarJulhoCl2Total1+pDolarJulhoCl2Total2)
      
          //AgostoCl2
          const pReaisAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAgostoCl2L = pReaisAgostoCl2.length
          var pReaisAgostoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAgostoCl2L; r1++)
          pReaisAgostoCl2Numeros.push(Number(pReaisAgostoCl2[r1]))
          const pReaisAgostoCl2Total1 = pReaisAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAgostoCl2L2 = pReaisAgostoCl22.length
          var pReaisAgostoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAgostoCl2L2; r2++)
          pReaisAgostoCl2Numeros2.push(Number(pReaisAgostoCl22[r2]))
          const pReaisAgostoCl2Total2 = pReaisAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAgostoCl2 = (pReaisAgostoCl2Total1+pReaisAgostoCl2Total2)
      
          const pDolarAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAgostoCl2L = pDolarAgostoCl2.length
          var pDolarAgostoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAgostoCl2L; d1++)
          pDolarAgostoCl2Numeros.push(Number(pDolarAgostoCl2[d1]))
          const pDolarAgostoCl2Total1 = pDolarAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAgostoCl2L2 = pDolarAgostoCl22.length
          var pDolarAgostoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAgostoCl2L2; d2++)
          pDolarAgostoCl2Numeros2.push(Number(pDolarAgostoCl22[d2]))
          const pDolarAgostoCl2Total2 = pDolarAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAgostoCl2 = (pDolarAgostoCl2Total1+pDolarAgostoCl2Total2)
          
          //SetembroCl2
          const pReaisSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisSetembroCl2L = pReaisSetembroCl2.length
          var pReaisSetembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisSetembroCl2L; r1++)
          pReaisSetembroCl2Numeros.push(Number(pReaisSetembroCl2[r1]))
          const pReaisSetembroCl2Total1 = pReaisSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisSetembroCl2L2 = pReaisSetembroCl22.length
          var pReaisSetembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisSetembroCl2L2; r2++)
          pReaisSetembroCl2Numeros2.push(Number(pReaisSetembroCl22[r2]))
          const pReaisSetembroCl2Total2 = pReaisSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisSetembroCl2 = (pReaisSetembroCl2Total1+pReaisSetembroCl2Total2)
          
      
          const pDolarSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarSetembroCl2L = pDolarSetembroCl2.length
          var pDolarSetembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarSetembroCl2L; d1++)
          pDolarSetembroCl2Numeros.push(Number(pDolarSetembroCl2[d1]))
          const pDolarSetembroCl2Total1 = pDolarSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarSetembroCl2L2 = pDolarSetembroCl22.length
          var pDolarSetembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarSetembroCl2L2; d2++)
          pDolarSetembroCl2Numeros2.push(Number(pDolarSetembroCl22[d2]))
          const pDolarSetembroCl2Total2 = pDolarSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarSetembroCl2 = (pDolarSetembroCl2Total1+pDolarSetembroCl2Total2)
          
          //OutubroCl2
          const pReaisOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisOutubroCl2L = pReaisOutubroCl2.length
          var pReaisOutubroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisOutubroCl2L; r1++)
          pReaisOutubroCl2Numeros.push(Number(pReaisOutubroCl2[r1]))
          const pReaisOutubroCl2Total1 = pReaisOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisOutubroCl2L2 = pReaisOutubroCl22.length
          var pReaisOutubroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisOutubroCl2L2; r2++)
          pReaisOutubroCl2Numeros2.push(Number(pReaisOutubroCl22[r2]))
          const pReaisOutubroCl2Total2 = pReaisOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisOutubroCl2 = (pReaisOutubroCl2Total1+pReaisOutubroCl2Total2)
      
          const pDolarOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarOutubroCl2L = pDolarOutubroCl2.length
          var pDolarOutubroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarOutubroCl2L; d1++)
          pDolarOutubroCl2Numeros.push(Number(pDolarOutubroCl2[d1]))
          const pDolarOutubroCl2Total1 = pDolarOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarOutubroCl2L2 = pDolarOutubroCl22.length
          var pDolarOutubroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarOutubroCl2L2; d2++)
          pDolarOutubroCl2Numeros2.push(Number(pDolarOutubroCl22[d2]))
          const pDolarOutubroCl2Total2 = pDolarOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarOutubroCl2 = (pDolarOutubroCl2Total1+pDolarOutubroCl2Total2)
      
          //NovembroCl2
          const pReaisNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisNovembroCl2L = pReaisNovembroCl2.length
          var pReaisNovembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisNovembroCl2L; r1++)
          pReaisNovembroCl2Numeros.push(Number(pReaisNovembroCl2[r1]))
          const pReaisNovembroCl2Total1 = pReaisNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisNovembroCl2L2 = pReaisNovembroCl22.length
          var pReaisNovembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisNovembroCl2L2; r2++)
          pReaisNovembroCl2Numeros2.push(Number(pReaisNovembroCl22[r2]))
          const pReaisNovembroCl2Total2 = pReaisNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisNovembroCl2 = (pReaisNovembroCl2Total1+pReaisNovembroCl2Total2)
      
          const pDolarNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarNovembroCl2L = pDolarNovembroCl2.length
          var pDolarNovembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarNovembroCl2L; d1++)
          pDolarNovembroCl2Numeros.push(Number(pDolarNovembroCl2[d1]))
          const pDolarNovembroCl2Total1 = pDolarNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarNovembroCl2L2 = pDolarNovembroCl22.length
          var pDolarNovembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarNovembroCl2L2; d2++)
          pDolarNovembroCl2Numeros2.push(Number(pDolarNovembroCl22[d2]))
          const pDolarNovembroCl2Total2 = pDolarNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarNovembroCl2 = (pDolarNovembroCl2Total1+pDolarNovembroCl2Total2)
        
      
          //DezembroCl2
          const pReaisDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisDezembroCl2L = pReaisDezembroCl2.length
          var pReaisDezembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisDezembroCl2L; r1++)
          pReaisDezembroCl2Numeros.push(Number(pReaisDezembroCl2[r1]))
          const pReaisDezembroCl2Total1 = pReaisDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisDezembroCl2L2 = pReaisDezembroCl22.length
          var pReaisDezembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisDezembroCl2L2; r2++)
          pReaisDezembroCl2Numeros2.push(Number(pReaisDezembroCl22[r2]))
          const pReaisDezembroCl2Total2 = pReaisDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisDezembroCl2 = (pReaisDezembroCl2Total1+pReaisDezembroCl2Total2)
      
          const pDolarDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarDezembroCl2L = pDolarDezembroCl2.length
          var pDolarDezembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarDezembroCl2L; d1++)
          pDolarDezembroCl2Numeros.push(Number(pDolarDezembroCl2[d1]))
          const pDolarDezembroCl2Total1 = pDolarDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarDezembroCl2L2 = pDolarDezembroCl22.length
          var pDolarDezembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarDezembroCl2L2; d2++)
          pDolarDezembroCl2Numeros2.push(Number(pDolarDezembroCl22[d2]))
          const pDolarDezembroCl2Total2 = pDolarDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarDezembroCl2 = (pDolarDezembroCl2Total1+pDolarDezembroCl2Total2)
    
    
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data:[DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },{
        label:`Faturamento Reais - ${cliente2}`,
        data: [ReaisJaneiroCl2, ReaisFevereiroCl2, ReaisMarcoCl2, ReaisAbrilCl2, ReaisMaioCl2, ReaisJunhoCl2, ReaisJulhoCl2, ReaisAgostoCl2, ReaisSetembroCl2, ReaisOutubroCl2, ReaisNovembroCl2, ReaisDezembroCl2 ],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data:  [DolarJaneiroCl2, DolarFevereiroCl2, DolarMarcoCl2, DolarAbrilCl2, DolarMaioCl2, DolarJunhoCl2, DolarJulhoCl2, DolarAgostoCl2, DolarSetembroCl2, DolarOutubroCl2, DolarNovembroCl2, DolarDezembroCl2 ],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
   
  }
  
  const handleChart3 =()=>{
      setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)

      const NumJaneiroCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
  
        const NumFevereiroCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2) ){return val}})
    
        const NumMarcoCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
    
        const NumAbrilCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumMaioCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumJunhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumJulhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumAgostoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumSetembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumOutubroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumNovembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
        
        const NumDezembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente2)){return val}})
    
        // JaneiroCl2
        const pReaisJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJaneiroCl2L = pReaisJaneiroCl2.length
          var pReaisJaneiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJaneiroCl2L; r1++)
          pReaisJaneiroCl2Numeros.push(Number(pReaisJaneiroCl2[r1]))
          const pReaisJaneiroCl2Total1 = pReaisJaneiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJaneiroCl2L2 = pReaisJaneiroCl22.length
          var pReaisJaneiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJaneiroCl2L2; r2++)
          pReaisJaneiroCl2Numeros2.push(Number(pReaisJaneiroCl22[r2]))
          const pReaisJaneiroCl2Total2 = pReaisJaneiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJaneiroCl2 = (pReaisJaneiroCl2Total1+pReaisJaneiroCl2Total2)
      
          const pDolarJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJaneiroCl2L = pDolarJaneiroCl2.length
          var pDolarJanrioNumeros = [];
          for (var d1 = 0; d1 < pDolarJaneiroCl2L; d1++)
          pDolarJanrioNumeros.push(Number(pDolarJaneiroCl2[d1]))
          const pDolarJaneiroCl2Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJaneiroCl2L2 = pDolarJaneiroCl22.length
          var pDolarJanrioNumeros2 = [];
          for (var d2 = 0; d2 < pDolarJaneiroCl2L2; d2++)
          pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl22[d2]))
          const pDolarJaneiroCl2Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJaneiroCl2 = (pDolarJaneiroCl2Total1+pDolarJaneiroCl2Total2)
      
      
      
          //FevereiroCl2  
          const pReaisFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisFevereiroCl2L = pReaisFevereiroCl2.length
          var pReaisFevereiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisFevereiroCl2L; r1++)
          pReaisFevereiroCl2Numeros.push(Number(pReaisFevereiroCl2[r1]))
          const pReaisFevereiroCl2Total1 = pReaisFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisFevereiroCl2L2 = pReaisFevereiroCl22.length
          var pReaisFevereiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisFevereiroCl2L2; r2++)
          pReaisFevereiroCl2Numeros2.push(Number(pReaisFevereiroCl22[r2]))
          const pReaisFevereiroCl2Total2 = pReaisFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisFevereiroCl2 = (pReaisFevereiroCl2Total1+pReaisFevereiroCl2Total2)
      
          const pDolarFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarFevereiroCl2L = pDolarFevereiroCl2.length
          var pDolarFevereiroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarFevereiroCl2L; d1++)
          pDolarFevereiroCl2Numeros.push(Number(pDolarFevereiroCl2[d1]))
          const pDolarFevereiroCl2Total1 = pDolarFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarFevereiroCl2L2 = pDolarFevereiroCl22.length
          var pDolarFevereiroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarFevereiroCl2L2; d2++)
          pDolarFevereiroCl2Numeros2.push(Number(pDolarFevereiroCl22[d2]))
          const pDolarFevereiroCl2Total2 = pDolarFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarFevereiroCl2 = (pDolarFevereiroCl2Total1+pDolarFevereiroCl2Total2)
          
          //MarcoCl2
          const pReaisMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMarcoCl2L = pReaisMarcoCl2.length
          var pReaisMarcoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMarcoCl2L; r1++)
          pReaisMarcoCl2Numeros.push(Number(pReaisMarcoCl2[r1]))
          const pReaisMarcoCl2Total1 = pReaisMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMarcoCl2L2 = pReaisMarcoCl22.length
          var pReaisMarcoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMarcoCl2L2; r2++)
          pReaisMarcoCl2Numeros2.push(Number(pReaisMarcoCl22[r2]))
          const pReaisMarcoCl2Total2 = pReaisMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMarcoCl2 = (pReaisMarcoCl2Total1+pReaisMarcoCl2Total2)
      
          const pDolarMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMarcoCl2L = pDolarMarcoCl2.length
          var pDolarMarcoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMarcoCl2L; d1++)
          pDolarMarcoCl2Numeros.push(Number(pDolarMarcoCl2[d1]))
          const pDolarMarcoCl2Total1 = pDolarMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMarcoCl2L2 = pDolarMarcoCl22.length
          var pDolarMarcoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMarcoCl2L2; d2++)
          pDolarMarcoCl2Numeros2.push(Number(pDolarMarcoCl22[d2]))
          const pDolarMarcoCl2Total2 = pDolarMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMarcoCl2 = (pDolarMarcoCl2Total1+pDolarMarcoCl2Total2)
      
          ///AbrilCl2
          const pReaisAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAbrilCl2L = pReaisAbrilCl2.length
          var pReaisAbrilCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAbrilCl2L; r1++)
          pReaisAbrilCl2Numeros.push(Number(pReaisAbrilCl2[r1]))
          const pReaisAbrilCl2Total1 = pReaisAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAbrilCl2L2 = pReaisAbrilCl22.length
          var pReaisAbrilCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAbrilCl2L2; r2++)
          pReaisAbrilCl2Numeros2.push(Number(pReaisAbrilCl22[r2]))
          const pReaisAbrilCl2Total2 = pReaisAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAbrilCl2 = (pReaisAbrilCl2Total1+pReaisAbrilCl2Total2)
      
          const pDolarAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAbrilCl2L = pDolarAbrilCl2.length
          var pDolarAbrilCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAbrilCl2L; d1++)
          pDolarAbrilCl2Numeros.push(Number(pDolarAbrilCl2[d1]))
          const pDolarAbrilCl2Total1 = pDolarAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAbrilCl2L2 = pDolarAbrilCl22.length
          var pDolarAbrilCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAbrilCl2L2; d2++)
          pDolarAbrilCl2Numeros2.push(Number(pDolarAbrilCl22[d2]))
          const pDolarAbrilCl2Total2 = pDolarAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAbrilCl2 = (pDolarAbrilCl2Total1+pDolarAbrilCl2Total2)
          //MaioCl2
          const pReaisMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMaioCl2L = pReaisMaioCl2.length
          var pReaisMaioCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMaioCl2L; r1++)
          pReaisMaioCl2Numeros.push(Number(pReaisMaioCl2[r1]))
          const pReaisMaioCl2Total1 = pReaisMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMaioCl2L2 = pReaisMaioCl22.length
          var pReaisMaioCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMaioCl2L2; r2++)
          pReaisMaioCl2Numeros2.push(Number(pReaisMaioCl22[r2]))
          const pReaisMaioCl2Total2 = pReaisMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMaioCl2 = (pReaisMaioCl2Total1+pReaisMaioCl2Total2)
      
          const pDolarMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMaioCl2L = pDolarMaioCl2.length
          var pDolarMaioCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMaioCl2L; d1++)
          pDolarMaioCl2Numeros.push(Number(pDolarMaioCl2[d1]))
          const pDolarMaioCl2Total1 = pDolarMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMaioCl2L2 = pDolarMaioCl22.length
          var pDolarMaioCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMaioCl2L2; d2++)
          pDolarMaioCl2Numeros2.push(Number(pDolarMaioCl22[d2]))
          const pDolarMaioCl2Total2 = pDolarMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMaioCl2 = (pDolarMaioCl2Total1+pDolarMaioCl2Total2)
      
          //JunhoCl2
          const pReaisJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJunhoCl2L = pReaisJunhoCl2.length
          var pReaisJunhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJunhoCl2L; r1++)
          pReaisJunhoCl2Numeros.push(Number(pReaisJunhoCl2[r1]))
          const pReaisJunhoCl2Total1 = pReaisJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJunhoCl2L2 = pReaisJunhoCl22.length
          var pReaisJunhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJunhoCl2L2; r2++)
          pReaisJunhoCl2Numeros2.push(Number(pReaisJunhoCl22[r2]))
          const pReaisJunhoCl2Total2 = pReaisJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJunhoCl2 = (pReaisJunhoCl2Total1+pReaisJunhoCl2Total2)
      
          const pDolarJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJunhoCl2L = pDolarJunhoCl2.length
          var pDolarJunhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJunhoCl2L; d1++)
          pDolarJunhoCl2Numeros.push(Number(pDolarJunhoCl2[d1]))
          const pDolarJunhoCl2Total1 = pDolarJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJunhoCl2L2 = pDolarJunhoCl22.length
          var pDolarJunhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJunhoCl2L2; d2++)
          pDolarJunhoCl2Numeros2.push(Number(pDolarJunhoCl22[d2]))
          const pDolarJunhoCl2Total2 = pDolarJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJunhoCl2 = (pDolarJunhoCl2Total1+pDolarJunhoCl2Total2)
          
          //JulhoCl2
          const pReaisJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJulhoCl2L = pReaisJulhoCl2.length
          var pReaisJulhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJulhoCl2L; r1++)
          pReaisJulhoCl2Numeros.push(Number(pReaisJulhoCl2[r1]))
          const pReaisJulhoCl2Total1 = pReaisJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJulhoCl2L2 = pReaisJulhoCl22.length
          var pReaisJulhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJulhoCl2L2; r2++)
          pReaisJulhoCl2Numeros2.push(Number(pReaisJulhoCl22[r2]))
          const pReaisJulhoCl2Total2 = pReaisJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJulhoCl2 = (pReaisJulhoCl2Total1+pReaisJulhoCl2Total2)
      
          const pDolarJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJulhoCl2L = pDolarJulhoCl2.length
          var pDolaroJulhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJulhoCl2L; d1++)
          pDolaroJulhoCl2Numeros.push(Number(pDolarJulhoCl2[d1]))
          const pDolarJulhoCl2Total1 = pDolaroJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJulhoCl2L2 = pDolarJulhoCl22.length
          var pDolaroJulhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJulhoCl2L2; d2++)
          pDolaroJulhoCl2Numeros2.push(Number(pDolarJulhoCl22[d2]))
          const pDolarJulhoCl2Total2 = pDolaroJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJulhoCl2 = (pDolarJulhoCl2Total1+pDolarJulhoCl2Total2)
      
          //AgostoCl2
          const pReaisAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAgostoCl2L = pReaisAgostoCl2.length
          var pReaisAgostoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAgostoCl2L; r1++)
          pReaisAgostoCl2Numeros.push(Number(pReaisAgostoCl2[r1]))
          const pReaisAgostoCl2Total1 = pReaisAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAgostoCl2L2 = pReaisAgostoCl22.length
          var pReaisAgostoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAgostoCl2L2; r2++)
          pReaisAgostoCl2Numeros2.push(Number(pReaisAgostoCl22[r2]))
          const pReaisAgostoCl2Total2 = pReaisAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAgostoCl2 = (pReaisAgostoCl2Total1+pReaisAgostoCl2Total2)
      
          const pDolarAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAgostoCl2L = pDolarAgostoCl2.length
          var pDolarAgostoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAgostoCl2L; d1++)
          pDolarAgostoCl2Numeros.push(Number(pDolarAgostoCl2[d1]))
          const pDolarAgostoCl2Total1 = pDolarAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAgostoCl2L2 = pDolarAgostoCl22.length
          var pDolarAgostoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAgostoCl2L2; d2++)
          pDolarAgostoCl2Numeros2.push(Number(pDolarAgostoCl22[d2]))
          const pDolarAgostoCl2Total2 = pDolarAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAgostoCl2 = (pDolarAgostoCl2Total1+pDolarAgostoCl2Total2)
          
          //SetembroCl2
          const pReaisSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisSetembroCl2L = pReaisSetembroCl2.length
          var pReaisSetembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisSetembroCl2L; r1++)
          pReaisSetembroCl2Numeros.push(Number(pReaisSetembroCl2[r1]))
          const pReaisSetembroCl2Total1 = pReaisSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisSetembroCl2L2 = pReaisSetembroCl22.length
          var pReaisSetembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisSetembroCl2L2; r2++)
          pReaisSetembroCl2Numeros2.push(Number(pReaisSetembroCl22[r2]))
          const pReaisSetembroCl2Total2 = pReaisSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisSetembroCl2 = (pReaisSetembroCl2Total1+pReaisSetembroCl2Total2)
          
      
          const pDolarSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarSetembroCl2L = pDolarSetembroCl2.length
          var pDolarSetembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarSetembroCl2L; d1++)
          pDolarSetembroCl2Numeros.push(Number(pDolarSetembroCl2[d1]))
          const pDolarSetembroCl2Total1 = pDolarSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarSetembroCl2L2 = pDolarSetembroCl22.length
          var pDolarSetembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarSetembroCl2L2; d2++)
          pDolarSetembroCl2Numeros2.push(Number(pDolarSetembroCl22[d2]))
          const pDolarSetembroCl2Total2 = pDolarSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarSetembroCl2 = (pDolarSetembroCl2Total1+pDolarSetembroCl2Total2)
          
          //OutubroCl2
          const pReaisOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisOutubroCl2L = pReaisOutubroCl2.length
          var pReaisOutubroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisOutubroCl2L; r1++)
          pReaisOutubroCl2Numeros.push(Number(pReaisOutubroCl2[r1]))
          const pReaisOutubroCl2Total1 = pReaisOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisOutubroCl2L2 = pReaisOutubroCl22.length
          var pReaisOutubroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisOutubroCl2L2; r2++)
          pReaisOutubroCl2Numeros2.push(Number(pReaisOutubroCl22[r2]))
          const pReaisOutubroCl2Total2 = pReaisOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisOutubroCl2 = (pReaisOutubroCl2Total1+pReaisOutubroCl2Total2)
      
          const pDolarOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarOutubroCl2L = pDolarOutubroCl2.length
          var pDolarOutubroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarOutubroCl2L; d1++)
          pDolarOutubroCl2Numeros.push(Number(pDolarOutubroCl2[d1]))
          const pDolarOutubroCl2Total1 = pDolarOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarOutubroCl2L2 = pDolarOutubroCl22.length
          var pDolarOutubroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarOutubroCl2L2; d2++)
          pDolarOutubroCl2Numeros2.push(Number(pDolarOutubroCl22[d2]))
          const pDolarOutubroCl2Total2 = pDolarOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarOutubroCl2 = (pDolarOutubroCl2Total1+pDolarOutubroCl2Total2)
      
          //NovembroCl2
          const pReaisNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisNovembroCl2L = pReaisNovembroCl2.length
          var pReaisNovembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisNovembroCl2L; r1++)
          pReaisNovembroCl2Numeros.push(Number(pReaisNovembroCl2[r1]))
          const pReaisNovembroCl2Total1 = pReaisNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisNovembroCl2L2 = pReaisNovembroCl22.length
          var pReaisNovembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisNovembroCl2L2; r2++)
          pReaisNovembroCl2Numeros2.push(Number(pReaisNovembroCl22[r2]))
          const pReaisNovembroCl2Total2 = pReaisNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisNovembroCl2 = (pReaisNovembroCl2Total1+pReaisNovembroCl2Total2)
      
          const pDolarNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarNovembroCl2L = pDolarNovembroCl2.length
          var pDolarNovembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarNovembroCl2L; d1++)
          pDolarNovembroCl2Numeros.push(Number(pDolarNovembroCl2[d1]))
          const pDolarNovembroCl2Total1 = pDolarNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarNovembroCl2L2 = pDolarNovembroCl22.length
          var pDolarNovembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarNovembroCl2L2; d2++)
          pDolarNovembroCl2Numeros2.push(Number(pDolarNovembroCl22[d2]))
          const pDolarNovembroCl2Total2 = pDolarNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarNovembroCl2 = (pDolarNovembroCl2Total1+pDolarNovembroCl2Total2)
        
      
          //DezembroCl2
          const pReaisDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisDezembroCl2L = pReaisDezembroCl2.length
          var pReaisDezembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisDezembroCl2L; r1++)
          pReaisDezembroCl2Numeros.push(Number(pReaisDezembroCl2[r1]))
          const pReaisDezembroCl2Total1 = pReaisDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisDezembroCl2L2 = pReaisDezembroCl22.length
          var pReaisDezembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisDezembroCl2L2; r2++)
          pReaisDezembroCl2Numeros2.push(Number(pReaisDezembroCl22[r2]))
          const pReaisDezembroCl2Total2 = pReaisDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisDezembroCl2 = (pReaisDezembroCl2Total1+pReaisDezembroCl2Total2)
      
          const pDolarDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarDezembroCl2L = pDolarDezembroCl2.length
          var pDolarDezembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarDezembroCl2L; d1++)
          pDolarDezembroCl2Numeros.push(Number(pDolarDezembroCl2[d1]))
          const pDolarDezembroCl2Total1 = pDolarDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarDezembroCl2L2 = pDolarDezembroCl22.length
          var pDolarDezembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarDezembroCl2L2; d2++)
          pDolarDezembroCl2Numeros2.push(Number(pDolarDezembroCl22[d2]))
          const pDolarDezembroCl2Total2 = pDolarDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarDezembroCl2 = (pDolarDezembroCl2Total1+pDolarDezembroCl2Total2)
    
    
  const NumJaneiroCL3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
  
        const NumFevereiroCL3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3) ){return val}})
    
        const NumMarcoCL3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
    
        const NumAbrilCL3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumMaioCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumJunhoCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumJulhoCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumAgostoCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumSetembroCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumOutubroCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumNovembroCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
        
        const NumDezembroCL3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projClient.includes(cliente3)){return val}})
    
        // JaneiroCL3
        const pReaisJaneiroCL3 = NumJaneiroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJaneiroCL3L = pReaisJaneiroCL3.length
          var pReaisJaneiroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisJaneiroCL3L; r1++)
          pReaisJaneiroCL3Numeros.push(Number(pReaisJaneiroCL3[r1]))
          const pReaisJaneiroCL3Total1 = pReaisJaneiroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJaneiroCL32 = NumJaneiroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJaneiroCL3L2 = pReaisJaneiroCL32.length
          var pReaisJaneiroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJaneiroCL3L2; r2++)
          pReaisJaneiroCL3Numeros2.push(Number(pReaisJaneiroCL32[r2]))
          const pReaisJaneiroCL3Total2 = pReaisJaneiroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJaneiroCL3 = (pReaisJaneiroCL3Total1+pReaisJaneiroCL3Total2)
      
          const pDolarJaneiroCL3 = NumJaneiroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJaneiroCL3L = pDolarJaneiroCL3.length
          var pDolarJanrioNumeros = [];
          for (var d1 = 0; d1 < pDolarJaneiroCL3L; d1++)
          pDolarJanrioNumeros.push(Number(pDolarJaneiroCL3[d1]))
          const pDolarJaneiroCL3Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJaneiroCL32 = NumJaneiroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJaneiroCL3L2 = pDolarJaneiroCL32.length
          var pDolarJanrioNumeros2 = [];
          for (var d2 = 0; d2 < pDolarJaneiroCL3L2; d2++)
          pDolarJanrioNumeros2.push(Number(pDolarJaneiroCL32[d2]))
          const pDolarJaneiroCL3Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJaneiroCL3 = (pDolarJaneiroCL3Total1+pDolarJaneiroCL3Total2)
      
      
      
          //FevereiroCL3  
          const pReaisFevereiroCL3 = NumFevereiroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisFevereiroCL3L = pReaisFevereiroCL3.length
          var pReaisFevereiroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisFevereiroCL3L; r1++)
          pReaisFevereiroCL3Numeros.push(Number(pReaisFevereiroCL3[r1]))
          const pReaisFevereiroCL3Total1 = pReaisFevereiroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisFevereiroCL32 = NumFevereiroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisFevereiroCL3L2 = pReaisFevereiroCL32.length
          var pReaisFevereiroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisFevereiroCL3L2; r2++)
          pReaisFevereiroCL3Numeros2.push(Number(pReaisFevereiroCL32[r2]))
          const pReaisFevereiroCL3Total2 = pReaisFevereiroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisFevereiroCL3 = (pReaisFevereiroCL3Total1+pReaisFevereiroCL3Total2)
      
          const pDolarFevereiroCL3 = NumFevereiroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarFevereiroCL3L = pDolarFevereiroCL3.length
          var pDolarFevereiroCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarFevereiroCL3L; d1++)
          pDolarFevereiroCL3Numeros.push(Number(pDolarFevereiroCL3[d1]))
          const pDolarFevereiroCL3Total1 = pDolarFevereiroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarFevereiroCL32 = NumFevereiroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarFevereiroCL3L2 = pDolarFevereiroCL32.length
          var pDolarFevereiroCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarFevereiroCL3L2; d2++)
          pDolarFevereiroCL3Numeros2.push(Number(pDolarFevereiroCL32[d2]))
          const pDolarFevereiroCL3Total2 = pDolarFevereiroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarFevereiroCL3 = (pDolarFevereiroCL3Total1+pDolarFevereiroCL3Total2)
          
          //MarcoCL3
          const pReaisMarcoCL3 = NumMarcoCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMarcoCL3L = pReaisMarcoCL3.length
          var pReaisMarcoCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisMarcoCL3L; r1++)
          pReaisMarcoCL3Numeros.push(Number(pReaisMarcoCL3[r1]))
          const pReaisMarcoCL3Total1 = pReaisMarcoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMarcoCL32 = NumMarcoCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMarcoCL3L2 = pReaisMarcoCL32.length
          var pReaisMarcoCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMarcoCL3L2; r2++)
          pReaisMarcoCL3Numeros2.push(Number(pReaisMarcoCL32[r2]))
          const pReaisMarcoCL3Total2 = pReaisMarcoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMarcoCL3 = (pReaisMarcoCL3Total1+pReaisMarcoCL3Total2)
      
          const pDolarMarcoCL3 = NumMarcoCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMarcoCL3L = pDolarMarcoCL3.length
          var pDolarMarcoCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarMarcoCL3L; d1++)
          pDolarMarcoCL3Numeros.push(Number(pDolarMarcoCL3[d1]))
          const pDolarMarcoCL3Total1 = pDolarMarcoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMarcoCL32 = NumMarcoCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMarcoCL3L2 = pDolarMarcoCL32.length
          var pDolarMarcoCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMarcoCL3L2; d2++)
          pDolarMarcoCL3Numeros2.push(Number(pDolarMarcoCL32[d2]))
          const pDolarMarcoCL3Total2 = pDolarMarcoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMarcoCL3 = (pDolarMarcoCL3Total1+pDolarMarcoCL3Total2)
      
          ///AbrilCL3
          const pReaisAbrilCL3 = NumAbrilCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAbrilCL3L = pReaisAbrilCL3.length
          var pReaisAbrilCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisAbrilCL3L; r1++)
          pReaisAbrilCL3Numeros.push(Number(pReaisAbrilCL3[r1]))
          const pReaisAbrilCL3Total1 = pReaisAbrilCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAbrilCL32 = NumAbrilCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAbrilCL3L2 = pReaisAbrilCL32.length
          var pReaisAbrilCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAbrilCL3L2; r2++)
          pReaisAbrilCL3Numeros2.push(Number(pReaisAbrilCL32[r2]))
          const pReaisAbrilCL3Total2 = pReaisAbrilCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAbrilCL3 = (pReaisAbrilCL3Total1+pReaisAbrilCL3Total2)
      
          const pDolarAbrilCL3 = NumAbrilCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAbrilCL3L = pDolarAbrilCL3.length
          var pDolarAbrilCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarAbrilCL3L; d1++)
          pDolarAbrilCL3Numeros.push(Number(pDolarAbrilCL3[d1]))
          const pDolarAbrilCL3Total1 = pDolarAbrilCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAbrilCL32 = NumAbrilCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAbrilCL3L2 = pDolarAbrilCL32.length
          var pDolarAbrilCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAbrilCL3L2; d2++)
          pDolarAbrilCL3Numeros2.push(Number(pDolarAbrilCL32[d2]))
          const pDolarAbrilCL3Total2 = pDolarAbrilCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAbrilCL3 = (pDolarAbrilCL3Total1+pDolarAbrilCL3Total2)
          //MaioCL3
          const pReaisMaioCL3 = NumMaioCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMaioCL3L = pReaisMaioCL3.length
          var pReaisMaioCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisMaioCL3L; r1++)
          pReaisMaioCL3Numeros.push(Number(pReaisMaioCL3[r1]))
          const pReaisMaioCL3Total1 = pReaisMaioCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMaioCL32 = NumMaioCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMaioCL3L2 = pReaisMaioCL32.length
          var pReaisMaioCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMaioCL3L2; r2++)
          pReaisMaioCL3Numeros2.push(Number(pReaisMaioCL32[r2]))
          const pReaisMaioCL3Total2 = pReaisMaioCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMaioCL3 = (pReaisMaioCL3Total1+pReaisMaioCL3Total2)
      
          const pDolarMaioCL3 = NumMaioCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMaioCL3L = pDolarMaioCL3.length
          var pDolarMaioCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarMaioCL3L; d1++)
          pDolarMaioCL3Numeros.push(Number(pDolarMaioCL3[d1]))
          const pDolarMaioCL3Total1 = pDolarMaioCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMaioCL32 = NumMaioCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMaioCL3L2 = pDolarMaioCL32.length
          var pDolarMaioCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMaioCL3L2; d2++)
          pDolarMaioCL3Numeros2.push(Number(pDolarMaioCL32[d2]))
          const pDolarMaioCL3Total2 = pDolarMaioCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMaioCL3 = (pDolarMaioCL3Total1+pDolarMaioCL3Total2)
      
          //JunhoCL3
          const pReaisJunhoCL3 = NumJunhoCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJunhoCL3L = pReaisJunhoCL3.length
          var pReaisJunhoCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisJunhoCL3L; r1++)
          pReaisJunhoCL3Numeros.push(Number(pReaisJunhoCL3[r1]))
          const pReaisJunhoCL3Total1 = pReaisJunhoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJunhoCL32 = NumJunhoCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJunhoCL3L2 = pReaisJunhoCL32.length
          var pReaisJunhoCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJunhoCL3L2; r2++)
          pReaisJunhoCL3Numeros2.push(Number(pReaisJunhoCL32[r2]))
          const pReaisJunhoCL3Total2 = pReaisJunhoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJunhoCL3 = (pReaisJunhoCL3Total1+pReaisJunhoCL3Total2)
      
          const pDolarJunhoCL3 = NumJunhoCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJunhoCL3L = pDolarJunhoCL3.length
          var pDolarJunhoCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarJunhoCL3L; d1++)
          pDolarJunhoCL3Numeros.push(Number(pDolarJunhoCL3[d1]))
          const pDolarJunhoCL3Total1 = pDolarJunhoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJunhoCL32 = NumJunhoCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJunhoCL3L2 = pDolarJunhoCL32.length
          var pDolarJunhoCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJunhoCL3L2; d2++)
          pDolarJunhoCL3Numeros2.push(Number(pDolarJunhoCL32[d2]))
          const pDolarJunhoCL3Total2 = pDolarJunhoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJunhoCL3 = (pDolarJunhoCL3Total1+pDolarJunhoCL3Total2)
          
          //JulhoCL3
          const pReaisJulhoCL3 = NumJulhoCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJulhoCL3L = pReaisJulhoCL3.length
          var pReaisJulhoCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisJulhoCL3L; r1++)
          pReaisJulhoCL3Numeros.push(Number(pReaisJulhoCL3[r1]))
          const pReaisJulhoCL3Total1 = pReaisJulhoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJulhoCL32 = NumJulhoCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJulhoCL3L2 = pReaisJulhoCL32.length
          var pReaisJulhoCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJulhoCL3L2; r2++)
          pReaisJulhoCL3Numeros2.push(Number(pReaisJulhoCL32[r2]))
          const pReaisJulhoCL3Total2 = pReaisJulhoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJulhoCL3 = (pReaisJulhoCL3Total1+pReaisJulhoCL3Total2)
      
          const pDolarJulhoCL3 = NumJulhoCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJulhoCL3L = pDolarJulhoCL3.length
          var pDolaroJulhoCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarJulhoCL3L; d1++)
          pDolaroJulhoCL3Numeros.push(Number(pDolarJulhoCL3[d1]))
          const pDolarJulhoCL3Total1 = pDolaroJulhoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJulhoCL32 = NumJulhoCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJulhoCL3L2 = pDolarJulhoCL32.length
          var pDolaroJulhoCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJulhoCL3L2; d2++)
          pDolaroJulhoCL3Numeros2.push(Number(pDolarJulhoCL32[d2]))
          const pDolarJulhoCL3Total2 = pDolaroJulhoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJulhoCL3 = (pDolarJulhoCL3Total1+pDolarJulhoCL3Total2)
      
          //AgostoCL3
          const pReaisAgostoCL3 = NumAgostoCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAgostoCL3L = pReaisAgostoCL3.length
          var pReaisAgostoCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisAgostoCL3L; r1++)
          pReaisAgostoCL3Numeros.push(Number(pReaisAgostoCL3[r1]))
          const pReaisAgostoCL3Total1 = pReaisAgostoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAgostoCL32 = NumAgostoCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAgostoCL3L2 = pReaisAgostoCL32.length
          var pReaisAgostoCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAgostoCL3L2; r2++)
          pReaisAgostoCL3Numeros2.push(Number(pReaisAgostoCL32[r2]))
          const pReaisAgostoCL3Total2 = pReaisAgostoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAgostoCL3 = (pReaisAgostoCL3Total1+pReaisAgostoCL3Total2)
      
          const pDolarAgostoCL3 = NumAgostoCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAgostoCL3L = pDolarAgostoCL3.length
          var pDolarAgostoCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarAgostoCL3L; d1++)
          pDolarAgostoCL3Numeros.push(Number(pDolarAgostoCL3[d1]))
          const pDolarAgostoCL3Total1 = pDolarAgostoCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAgostoCL32 = NumAgostoCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAgostoCL3L2 = pDolarAgostoCL32.length
          var pDolarAgostoCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAgostoCL3L2; d2++)
          pDolarAgostoCL3Numeros2.push(Number(pDolarAgostoCL32[d2]))
          const pDolarAgostoCL3Total2 = pDolarAgostoCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAgostoCL3 = (pDolarAgostoCL3Total1+pDolarAgostoCL3Total2)
          
          //SetembroCL3
          const pReaisSetembroCL3 = NumSetembroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisSetembroCL3L = pReaisSetembroCL3.length
          var pReaisSetembroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisSetembroCL3L; r1++)
          pReaisSetembroCL3Numeros.push(Number(pReaisSetembroCL3[r1]))
          const pReaisSetembroCL3Total1 = pReaisSetembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisSetembroCL32 = NumSetembroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisSetembroCL3L2 = pReaisSetembroCL32.length
          var pReaisSetembroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisSetembroCL3L2; r2++)
          pReaisSetembroCL3Numeros2.push(Number(pReaisSetembroCL32[r2]))
          const pReaisSetembroCL3Total2 = pReaisSetembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisSetembroCL3 = (pReaisSetembroCL3Total1+pReaisSetembroCL3Total2)
          
      
          const pDolarSetembroCL3 = NumSetembroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarSetembroCL3L = pDolarSetembroCL3.length
          var pDolarSetembroCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarSetembroCL3L; d1++)
          pDolarSetembroCL3Numeros.push(Number(pDolarSetembroCL3[d1]))
          const pDolarSetembroCL3Total1 = pDolarSetembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarSetembroCL32 = NumSetembroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarSetembroCL3L2 = pDolarSetembroCL32.length
          var pDolarSetembroCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarSetembroCL3L2; d2++)
          pDolarSetembroCL3Numeros2.push(Number(pDolarSetembroCL32[d2]))
          const pDolarSetembroCL3Total2 = pDolarSetembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarSetembroCL3 = (pDolarSetembroCL3Total1+pDolarSetembroCL3Total2)
          
          //OutubroCL3
          const pReaisOutubroCL3 = NumOutubroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisOutubroCL3L = pReaisOutubroCL3.length
          var pReaisOutubroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisOutubroCL3L; r1++)
          pReaisOutubroCL3Numeros.push(Number(pReaisOutubroCL3[r1]))
          const pReaisOutubroCL3Total1 = pReaisOutubroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisOutubroCL32 = NumOutubroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisOutubroCL3L2 = pReaisOutubroCL32.length
          var pReaisOutubroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisOutubroCL3L2; r2++)
          pReaisOutubroCL3Numeros2.push(Number(pReaisOutubroCL32[r2]))
          const pReaisOutubroCL3Total2 = pReaisOutubroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisOutubroCL3 = (pReaisOutubroCL3Total1+pReaisOutubroCL3Total2)
      
          const pDolarOutubroCL3 = NumOutubroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarOutubroCL3L = pDolarOutubroCL3.length
          var pDolarOutubroCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarOutubroCL3L; d1++)
          pDolarOutubroCL3Numeros.push(Number(pDolarOutubroCL3[d1]))
          const pDolarOutubroCL3Total1 = pDolarOutubroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarOutubroCL32 = NumOutubroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarOutubroCL3L2 = pDolarOutubroCL32.length
          var pDolarOutubroCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarOutubroCL3L2; d2++)
          pDolarOutubroCL3Numeros2.push(Number(pDolarOutubroCL32[d2]))
          const pDolarOutubroCL3Total2 = pDolarOutubroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarOutubroCL3 = (pDolarOutubroCL3Total1+pDolarOutubroCL3Total2)
      
          //NovembroCL3
          const pReaisNovembroCL3 = NumNovembroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisNovembroCL3L = pReaisNovembroCL3.length
          var pReaisNovembroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisNovembroCL3L; r1++)
          pReaisNovembroCL3Numeros.push(Number(pReaisNovembroCL3[r1]))
          const pReaisNovembroCL3Total1 = pReaisNovembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisNovembroCL32 = NumNovembroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisNovembroCL3L2 = pReaisNovembroCL32.length
          var pReaisNovembroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisNovembroCL3L2; r2++)
          pReaisNovembroCL3Numeros2.push(Number(pReaisNovembroCL32[r2]))
          const pReaisNovembroCL3Total2 = pReaisNovembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisNovembroCL3 = (pReaisNovembroCL3Total1+pReaisNovembroCL3Total2)
      
          const pDolarNovembroCL3 = NumNovembroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarNovembroCL3L = pDolarNovembroCL3.length
          var pDolarNovembroCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarNovembroCL3L; d1++)
          pDolarNovembroCL3Numeros.push(Number(pDolarNovembroCL3[d1]))
          const pDolarNovembroCL3Total1 = pDolarNovembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarNovembroCL32 = NumNovembroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarNovembroCL3L2 = pDolarNovembroCL32.length
          var pDolarNovembroCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarNovembroCL3L2; d2++)
          pDolarNovembroCL3Numeros2.push(Number(pDolarNovembroCL32[d2]))
          const pDolarNovembroCL3Total2 = pDolarNovembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarNovembroCL3 = (pDolarNovembroCL3Total1+pDolarNovembroCL3Total2)
        
      
          //DezembroCL3
          const pReaisDezembroCL3 = NumDezembroCL3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisDezembroCL3L = pReaisDezembroCL3.length
          var pReaisDezembroCL3Numeros = [];
          for (var r1 = 0; r1 < pReaisDezembroCL3L; r1++)
          pReaisDezembroCL3Numeros.push(Number(pReaisDezembroCL3[r1]))
          const pReaisDezembroCL3Total1 = pReaisDezembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisDezembroCL32 = NumDezembroCL3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisDezembroCL3L2 = pReaisDezembroCL32.length
          var pReaisDezembroCL3Numeros2 = [];
          for (var r2 = 0; r2 < pReaisDezembroCL3L2; r2++)
          pReaisDezembroCL3Numeros2.push(Number(pReaisDezembroCL32[r2]))
          const pReaisDezembroCL3Total2 = pReaisDezembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisDezembroCL3 = (pReaisDezembroCL3Total1+pReaisDezembroCL3Total2)
      
          const pDolarDezembroCL3 = NumDezembroCL3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarDezembroCL3L = pDolarDezembroCL3.length
          var pDolarDezembroCL3Numeros = [];
          for (var d1 = 0; d1 < pDolarDezembroCL3L; d1++)
          pDolarDezembroCL3Numeros.push(Number(pDolarDezembroCL3[d1]))
          const pDolarDezembroCL3Total1 = pDolarDezembroCL3Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarDezembroCL32 = NumDezembroCL3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarDezembroCL3L2 = pDolarDezembroCL32.length
          var pDolarDezembroCL3Numeros2 = [];
          for (var d2 = 0; d2 < pDolarDezembroCL3L2; d2++)
          pDolarDezembroCL3Numeros2.push(Number(pDolarDezembroCL32[d2]))
          const pDolarDezembroCL3Total2 = pDolarDezembroCL3Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarDezembroCL3 = (pDolarDezembroCL3Total1+pDolarDezembroCL3Total2)
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data:[DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },{
        label:`Faturamento Reais - ${cliente2}`,
        data: [ReaisJaneiroCl2, ReaisFevereiroCl2, ReaisMarcoCl2, ReaisAbrilCl2, ReaisMaioCl2, ReaisJunhoCl2, ReaisJulhoCl2, ReaisAgostoCl2, ReaisSetembroCl2, ReaisOutubroCl2, ReaisNovembroCl2, ReaisDezembroCl2 ],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data:  [DolarJaneiroCl2, DolarFevereiroCl2, DolarMarcoCl2, DolarAbrilCl2, DolarMaioCl2, DolarJunhoCl2, DolarJulhoCl2, DolarAgostoCl2, DolarSetembroCl2, DolarOutubroCl2, DolarNovembroCl2, DolarDezembroCl2 ],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      },
      {
        label:`Faturamento Reais - ${cliente3}`,
        data: [ReaisJaneiroCL3, ReaisFevereiroCL3, ReaisMarcoCL3, ReaisAbrilCL3, ReaisMaioCL3, ReaisJunho, ReaisJulhoCL3, ReaisAgostoCL3, ReaisSetembroCl2, ReaisOutubroCL3, ReaisNovembroCL3, ReaisDezembroCL3 ],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente3}`,
        data:  [DolarJaneiroCL3, DolarFevereiroCL3, DolarMarcoCL3, DolarAbrilCL3, DolarMaioCL3, DolarJunhoCL3, DolarJulhoCL3, DolarAgostoCL3, DolarSetembroCL3, DolarOutubroCL3, DolarNovembroCL3, DolarDezembroCL3 ],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Clientes - Faturamento em Ano</div>
        {
          !paislist &&
          <div><button onClick={lista}>Gerar Lista</button></div>
          }
        {paislist &&   
        <>
        <div>
            <p>
                Tipo:
                <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                <option value="">Selecione</option>
                <option value="um">Um Cliente</option>
                <option value="dois">Dois Clientes</option>
                <option value="tres">Três Clientes</option>
            </select>
            </p>
        </div>
        {tipo === 'um' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select defaultValue={'2025'} onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'dois' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'tres' &&
          
            <div className='tipo'>
              <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>
        }
        </>
       }
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

      </>     
    }
</div>
  )
}

export default ClientesAnoFaturamento