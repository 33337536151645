import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import Header from '../../components/Header/Header'
import '../pages.css'
import BarChart from '../../components/Charts/BarChart';

function ClientesLucro() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [grafico, setGrafico]=useState(false)
    const [lista, setLista]=useState([])

    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
            setLoading(false)
        })
        
        return()=>{change()}
    
    },[]);

    const handleChart = ()=>{
            setGrafico(false)
            let clientesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
            let clientesemDuplicados = clientesAndamento.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });

            var impostoPNF = (nome)=>{
                const imposto1NF =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projCurrency === 'USD' && val.data.projClientBillInfo === 'NF'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var imposto1PrimarioNFL = imposto1NF.length
                var imposto1PrimarioNFN = [];
                for (var d1 = 0; d1 < imposto1PrimarioNFL; d1++)
                imposto1PrimarioNFN.push(Number(imposto1NF[d1]))
                const impostoPrimarioNFSomado = imposto1PrimarioNFN.reduce((acc, curr) => acc + curr, 0)*0.3

                const imposto2NF =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projCurrency === 'BRL' && val.data.projClientBillInfo === 'NF'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var imposto1SecunoNFL = imposto2NF.length
                var imposto1SecundNFN = [];
                for (var d2 = 0; d2 < imposto1SecunoNFL; d2++)
                imposto1SecundNFN.push(Number(imposto2NF[d2]))
                const impostoSecundarioNFSomado = imposto1SecundNFN.reduce((acc, curr) => acc + curr, 0)*0.3

                const imposto1IN =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projCurrency === 'USD' && val.data.projClientBillInfo === 'Invoice'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var imposto1PrimarioINL = imposto1IN.length
                var imposto1PrimarioINN = [];
                for (var d3 = 0; d3 < imposto1PrimarioINL; d3++)
                imposto1PrimarioINN.push(Number(imposto1IN[d3]))
                const impostoPrimarioINSomado = imposto1PrimarioINN.reduce((acc, curr) => acc + curr, 0)*0.15

                const imposto2IN =  projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projCurrency === 'BRL' && val.data.projClientBillInfo === 'Invoice'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var imposto1SecunoINL = imposto2IN.length
                var imposto1SecundINN = [];
                for (var d4 = 0; d4 < imposto1SecunoINL; d4++)
                imposto1SecundINN.push(Number(imposto2IN[d4]))
                const impostoSecundarioINSomado = imposto1SecundINN.reduce((acc, curr) => acc + curr, 0)*0.15
              
              var imposto = (impostoPrimarioNFSomado+impostoSecundarioNFSomado+impostoPrimarioINSomado+impostoSecundarioINSomado)

              return(imposto)
                
            }

             var dolar = (nome)=>{
                const dolar1 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolar1L = dolar1.length
                var dolar1N = [];
                for (var d1 = 0; d1 < dolar1L; d1++)
                dolar1N.push(Number(dolar1[d1]))
                const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar2 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var dolar2L = dolar2.length
                var dolar2N = [];
                for (var d2 = 0; d2 < dolar2L; d2++)
                dolar2N.push(Number(dolar2[d2]))
                const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

                const dolarTotal = (clienteDolar1+clientedolar2)
                return(dolarTotal)
            }

        var lider = (nome)=>{
              var lider1= projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome ){return val}})
              const dolares=lider1.map((item)=>item.data.dolarDia)
              var lider2=lider1.map((item)=>item.data.projLideres)

             
              var liderValores = lider2.map((item,index)=>({
                  "id":index,
                  "valor":item.map((i)=>i.paymentValue).toString(),
                  "moeda":item.map((i)=>i.paymentCurrency).toString(),
                  "multiplicador":dolares[index]

              }))

              var lideresMulti = liderValores.map((i)=>({
                "valorMulti":i.moeda === "USD"? parseFloat(i.valor) : parseFloat(i.valor)/i.multiplicador
              }))

              var liderJunto = lideresMulti.map((i)=>i.valorMulti).toString().split(",")

              var liderL= liderJunto.length
              var liderN= [];
              for (var d1=0; d1<liderL; d1++)
              liderN.push(Number(liderJunto[d1]))
              const liderSomado= liderN.reduce((acc,curr)=> acc+curr,0)
              return(liderSomado)


        }

          var analista = (nome)=>{
              var lider1= projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome ){return val}})
              const dolares=lider1.map((item)=>item.data.dolarDia)
              var lider2=lider1.map((item)=>item.data.projAnalistas)

             
              var liderValores = lider2.map((item,index)=>({
                  "id":index,
                  "valor":item.map((i)=>i.payment).toString(),
                  "moeda":item.map((i)=>i.paymentCurrency).toString(),
                  "multiplicador":dolares[index]

              }))

              var lideresMulti = liderValores.map((i)=>({
                "valorMulti":i.moeda === "USD"? parseFloat(i.valor) : parseFloat(i.valor)/i.multiplicador
              }))

              var liderJunto = lideresMulti.map((i)=>i.valorMulti).toString().split(",")

              var liderL= liderJunto.length
              var liderN= [];
              for (var d1=0; d1<liderL; d1++)
              liderN.push(Number(liderJunto[d1]))
              const liderSomado= liderN.reduce((acc,curr)=> acc+curr,0)
              return(liderSomado)


        }

        var fornecedor = (nome)=>{
              var lider1= projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome && val.data.projFornecedors ===true ){return val}})
              const dolares=lider1.map((item)=>item.data.dolarDia)
              var lider2=lider1.map((item)=>item.data.projFornecedors)

             
              var liderValores = lider2.map((item,index)=>({
                  "id":index,
                  "valor":item.map((i)=>i.payment).toString(),
                  "moeda":item.map((i)=>i.paymentCurrency).toString(),
                  "multiplicador":dolares[index]

              }))

              var lideresMulti = liderValores.map((i)=>({
                "valorMulti":i.moeda === "USD"? parseFloat(i.valor) : parseFloat(i.valor)/i.multiplicador
              }))

              var liderJunto = lideresMulti.map((i)=>i.valorMulti).toString().split(",")

              var liderL= liderJunto.length
              var liderN= [];
              for (var d1=0; d1<liderL; d1++)
              liderN.push(Number(liderJunto[d1]))
              const liderSomado= liderN.reduce((acc,curr)=> acc+curr,0)
              return(liderSomado)


        }
            

             var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade":clientesAndamento.filter((val)=>{if(val === item){return val}}).length,
                  "faturamento":dolar(item),
                  "imposto":impostoPNF(item),
                  "vLider":lider(item),
                  "vAnalista":analista(item),
                  "vfornecedores":fornecedor(item),

              }
            ))

             var clientes2 = clientes1.map((item, index)=>(
                {
                  "id": index,
                  "name":item.name,
                  "quantidade":item.quantidade,
                  "faturamento":item.faturamento,
                  "imposto":item.imposto,
                  "lider":item.vLider,
                  "lucro":((item.imposto+item.vLider+item.vAnalista+item.vfornecedores) / item.faturamento)*100
              }
            ))


            var clientes3 = clientes2.sort((a,b)=>{
                if (a.lucro < b.lucro) {
                  return 1;
                }
                if (a.lucro > b.lucro) {
                  return -1;
                }
                return 0;
              })
            var clientes4 = clientes3.slice(0,30)
            
          
          

            setProjetData({
                labels: clientes4.map((i)=>i.name),
                datasets:[
                 {
                     label:'Porcentagem de Lucro',
                     data:clientes4.map((item)=>item.lucro),
                     fill: true,
                     borderColor: '#FF9900',
                    backgroundColor:'#FF9900',
                   },

                   {
                    label:'Quantidade',
                    data: clientes4.map((item)=>item.quantidade),
                    borderColor: '#315F30',
                    backgroundColor:'#315F30',
                  },

                
                
         
               ]
              })

            setGrafico(true)

            
    }


  return (
    <div>
    <Header />

   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Maiores Clientes Por Lucratividade</div>
       <div className='subtitle'>Maiores 30</div>
       <div><button onClick={handleChart}>Gerar Gráfico</button></div>
     </div>
    
     {grafico &&
       <div className="pageContent">
       <div className="pageChart">
         <BarChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
</div>
  )
}

export default ClientesLucro