import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function PaisesFaturamentoAno() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('um')
  
    const [ano, setAno]=useState('2025')
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')

    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024',,'2025' ];

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setLoading(false)
  },[projetos])
  
  const handleChart =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
    
    
  
    setProjetData({
      labels:meses ,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: true,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)

      const NumJaneiroCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2) ){return val}})
  
        const NumFevereiroCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2) ){return val}})
    
        const NumMarcoCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
    
        const NumAbrilCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumMaioCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumJunhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumJulhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumAgostoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumSetembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumOutubroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumNovembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
        
        const NumDezembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})
    
        // JaneiroCl2
        const pReaisJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJaneiroCl2L = pReaisJaneiroCl2.length
          var pReaisJaneiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJaneiroCl2L; r1++)
          pReaisJaneiroCl2Numeros.push(Number(pReaisJaneiroCl2[r1]))
          const pReaisJaneiroCl2Total1 = pReaisJaneiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJaneiroCl2L2 = pReaisJaneiroCl22.length
          var pReaisJaneiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJaneiroCl2L2; r2++)
          pReaisJaneiroCl2Numeros2.push(Number(pReaisJaneiroCl22[r2]))
          const pReaisJaneiroCl2Total2 = pReaisJaneiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJaneiroCl2 = (pReaisJaneiroCl2Total1+pReaisJaneiroCl2Total2)
      
          const pDolarJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJaneiroCl2L = pDolarJaneiroCl2.length
          var pDolarJanrioNumeros = [];
          for (var d1 = 0; d1 < pDolarJaneiroCl2L; d1++)
          pDolarJanrioNumeros.push(Number(pDolarJaneiroCl2[d1]))
          const pDolarJaneiroCl2Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJaneiroCl2L2 = pDolarJaneiroCl22.length
          var pDolarJanrioNumeros2 = [];
          for (var d2 = 0; d2 < pDolarJaneiroCl2L2; d2++)
          pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl22[d2]))
          const pDolarJaneiroCl2Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJaneiroCl2 = (pDolarJaneiroCl2Total1+pDolarJaneiroCl2Total2)
      
      
      
          //FevereiroCl2  
          const pReaisFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisFevereiroCl2L = pReaisFevereiroCl2.length
          var pReaisFevereiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisFevereiroCl2L; r1++)
          pReaisFevereiroCl2Numeros.push(Number(pReaisFevereiroCl2[r1]))
          const pReaisFevereiroCl2Total1 = pReaisFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisFevereiroCl2L2 = pReaisFevereiroCl22.length
          var pReaisFevereiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisFevereiroCl2L2; r2++)
          pReaisFevereiroCl2Numeros2.push(Number(pReaisFevereiroCl22[r2]))
          const pReaisFevereiroCl2Total2 = pReaisFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisFevereiroCl2 = (pReaisFevereiroCl2Total1+pReaisFevereiroCl2Total2)
      
          const pDolarFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarFevereiroCl2L = pDolarFevereiroCl2.length
          var pDolarFevereiroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarFevereiroCl2L; d1++)
          pDolarFevereiroCl2Numeros.push(Number(pDolarFevereiroCl2[d1]))
          const pDolarFevereiroCl2Total1 = pDolarFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarFevereiroCl2L2 = pDolarFevereiroCl22.length
          var pDolarFevereiroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarFevereiroCl2L2; d2++)
          pDolarFevereiroCl2Numeros2.push(Number(pDolarFevereiroCl22[d2]))
          const pDolarFevereiroCl2Total2 = pDolarFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarFevereiroCl2 = (pDolarFevereiroCl2Total1+pDolarFevereiroCl2Total2)
          
          //MarcoCl2
          const pReaisMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMarcoCl2L = pReaisMarcoCl2.length
          var pReaisMarcoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMarcoCl2L; r1++)
          pReaisMarcoCl2Numeros.push(Number(pReaisMarcoCl2[r1]))
          const pReaisMarcoCl2Total1 = pReaisMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMarcoCl2L2 = pReaisMarcoCl22.length
          var pReaisMarcoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMarcoCl2L2; r2++)
          pReaisMarcoCl2Numeros2.push(Number(pReaisMarcoCl22[r2]))
          const pReaisMarcoCl2Total2 = pReaisMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMarcoCl2 = (pReaisMarcoCl2Total1+pReaisMarcoCl2Total2)
      
          const pDolarMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMarcoCl2L = pDolarMarcoCl2.length
          var pDolarMarcoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMarcoCl2L; d1++)
          pDolarMarcoCl2Numeros.push(Number(pDolarMarcoCl2[d1]))
          const pDolarMarcoCl2Total1 = pDolarMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMarcoCl2L2 = pDolarMarcoCl22.length
          var pDolarMarcoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMarcoCl2L2; d2++)
          pDolarMarcoCl2Numeros2.push(Number(pDolarMarcoCl22[d2]))
          const pDolarMarcoCl2Total2 = pDolarMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMarcoCl2 = (pDolarMarcoCl2Total1+pDolarMarcoCl2Total2)
      
          ///AbrilCl2
          const pReaisAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAbrilCl2L = pReaisAbrilCl2.length
          var pReaisAbrilCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAbrilCl2L; r1++)
          pReaisAbrilCl2Numeros.push(Number(pReaisAbrilCl2[r1]))
          const pReaisAbrilCl2Total1 = pReaisAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAbrilCl2L2 = pReaisAbrilCl22.length
          var pReaisAbrilCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAbrilCl2L2; r2++)
          pReaisAbrilCl2Numeros2.push(Number(pReaisAbrilCl22[r2]))
          const pReaisAbrilCl2Total2 = pReaisAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAbrilCl2 = (pReaisAbrilCl2Total1+pReaisAbrilCl2Total2)
      
          const pDolarAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAbrilCl2L = pDolarAbrilCl2.length
          var pDolarAbrilCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAbrilCl2L; d1++)
          pDolarAbrilCl2Numeros.push(Number(pDolarAbrilCl2[d1]))
          const pDolarAbrilCl2Total1 = pDolarAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAbrilCl2L2 = pDolarAbrilCl22.length
          var pDolarAbrilCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAbrilCl2L2; d2++)
          pDolarAbrilCl2Numeros2.push(Number(pDolarAbrilCl22[d2]))
          const pDolarAbrilCl2Total2 = pDolarAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAbrilCl2 = (pDolarAbrilCl2Total1+pDolarAbrilCl2Total2)
          //MaioCl2
          const pReaisMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMaioCl2L = pReaisMaioCl2.length
          var pReaisMaioCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMaioCl2L; r1++)
          pReaisMaioCl2Numeros.push(Number(pReaisMaioCl2[r1]))
          const pReaisMaioCl2Total1 = pReaisMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMaioCl2L2 = pReaisMaioCl22.length
          var pReaisMaioCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMaioCl2L2; r2++)
          pReaisMaioCl2Numeros2.push(Number(pReaisMaioCl22[r2]))
          const pReaisMaioCl2Total2 = pReaisMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMaioCl2 = (pReaisMaioCl2Total1+pReaisMaioCl2Total2)
      
          const pDolarMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMaioCl2L = pDolarMaioCl2.length
          var pDolarMaioCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMaioCl2L; d1++)
          pDolarMaioCl2Numeros.push(Number(pDolarMaioCl2[d1]))
          const pDolarMaioCl2Total1 = pDolarMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMaioCl2L2 = pDolarMaioCl22.length
          var pDolarMaioCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMaioCl2L2; d2++)
          pDolarMaioCl2Numeros2.push(Number(pDolarMaioCl22[d2]))
          const pDolarMaioCl2Total2 = pDolarMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMaioCl2 = (pDolarMaioCl2Total1+pDolarMaioCl2Total2)
      
          //JunhoCl2
          const pReaisJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJunhoCl2L = pReaisJunhoCl2.length
          var pReaisJunhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJunhoCl2L; r1++)
          pReaisJunhoCl2Numeros.push(Number(pReaisJunhoCl2[r1]))
          const pReaisJunhoCl2Total1 = pReaisJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJunhoCl2L2 = pReaisJunhoCl22.length
          var pReaisJunhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJunhoCl2L2; r2++)
          pReaisJunhoCl2Numeros2.push(Number(pReaisJunhoCl22[r2]))
          const pReaisJunhoCl2Total2 = pReaisJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJunhoCl2 = (pReaisJunhoCl2Total1+pReaisJunhoCl2Total2)
      
          const pDolarJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJunhoCl2L = pDolarJunhoCl2.length
          var pDolarJunhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJunhoCl2L; d1++)
          pDolarJunhoCl2Numeros.push(Number(pDolarJunhoCl2[d1]))
          const pDolarJunhoCl2Total1 = pDolarJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJunhoCl2L2 = pDolarJunhoCl22.length
          var pDolarJunhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJunhoCl2L2; d2++)
          pDolarJunhoCl2Numeros2.push(Number(pDolarJunhoCl22[d2]))
          const pDolarJunhoCl2Total2 = pDolarJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJunhoCl2 = (pDolarJunhoCl2Total1+pDolarJunhoCl2Total2)
          
          //JulhoCl2
          const pReaisJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJulhoCl2L = pReaisJulhoCl2.length
          var pReaisJulhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJulhoCl2L; r1++)
          pReaisJulhoCl2Numeros.push(Number(pReaisJulhoCl2[r1]))
          const pReaisJulhoCl2Total1 = pReaisJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJulhoCl2L2 = pReaisJulhoCl22.length
          var pReaisJulhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJulhoCl2L2; r2++)
          pReaisJulhoCl2Numeros2.push(Number(pReaisJulhoCl22[r2]))
          const pReaisJulhoCl2Total2 = pReaisJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJulhoCl2 = (pReaisJulhoCl2Total1+pReaisJulhoCl2Total2)
      
          const pDolarJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJulhoCl2L = pDolarJulhoCl2.length
          var pDolaroJulhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJulhoCl2L; d1++)
          pDolaroJulhoCl2Numeros.push(Number(pDolarJulhoCl2[d1]))
          const pDolarJulhoCl2Total1 = pDolaroJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJulhoCl2L2 = pDolarJulhoCl22.length
          var pDolaroJulhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJulhoCl2L2; d2++)
          pDolaroJulhoCl2Numeros2.push(Number(pDolarJulhoCl22[d2]))
          const pDolarJulhoCl2Total2 = pDolaroJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJulhoCl2 = (pDolarJulhoCl2Total1+pDolarJulhoCl2Total2)
      
          //AgostoCl2
          const pReaisAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAgostoCl2L = pReaisAgostoCl2.length
          var pReaisAgostoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAgostoCl2L; r1++)
          pReaisAgostoCl2Numeros.push(Number(pReaisAgostoCl2[r1]))
          const pReaisAgostoCl2Total1 = pReaisAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAgostoCl2L2 = pReaisAgostoCl22.length
          var pReaisAgostoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAgostoCl2L2; r2++)
          pReaisAgostoCl2Numeros2.push(Number(pReaisAgostoCl22[r2]))
          const pReaisAgostoCl2Total2 = pReaisAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAgostoCl2 = (pReaisAgostoCl2Total1+pReaisAgostoCl2Total2)
      
          const pDolarAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAgostoCl2L = pDolarAgostoCl2.length
          var pDolarAgostoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAgostoCl2L; d1++)
          pDolarAgostoCl2Numeros.push(Number(pDolarAgostoCl2[d1]))
          const pDolarAgostoCl2Total1 = pDolarAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAgostoCl2L2 = pDolarAgostoCl22.length
          var pDolarAgostoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAgostoCl2L2; d2++)
          pDolarAgostoCl2Numeros2.push(Number(pDolarAgostoCl22[d2]))
          const pDolarAgostoCl2Total2 = pDolarAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAgostoCl2 = (pDolarAgostoCl2Total1+pDolarAgostoCl2Total2)
          
          //SetembroCl2
          const pReaisSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisSetembroCl2L = pReaisSetembroCl2.length
          var pReaisSetembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisSetembroCl2L; r1++)
          pReaisSetembroCl2Numeros.push(Number(pReaisSetembroCl2[r1]))
          const pReaisSetembroCl2Total1 = pReaisSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisSetembroCl2L2 = pReaisSetembroCl22.length
          var pReaisSetembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisSetembroCl2L2; r2++)
          pReaisSetembroCl2Numeros2.push(Number(pReaisSetembroCl22[r2]))
          const pReaisSetembroCl2Total2 = pReaisSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisSetembroCl2 = (pReaisSetembroCl2Total1+pReaisSetembroCl2Total2)
          
      
          const pDolarSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarSetembroCl2L = pDolarSetembroCl2.length
          var pDolarSetembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarSetembroCl2L; d1++)
          pDolarSetembroCl2Numeros.push(Number(pDolarSetembroCl2[d1]))
          const pDolarSetembroCl2Total1 = pDolarSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarSetembroCl2L2 = pDolarSetembroCl22.length
          var pDolarSetembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarSetembroCl2L2; d2++)
          pDolarSetembroCl2Numeros2.push(Number(pDolarSetembroCl22[d2]))
          const pDolarSetembroCl2Total2 = pDolarSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarSetembroCl2 = (pDolarSetembroCl2Total1+pDolarSetembroCl2Total2)
          
          //OutubroCl2
          const pReaisOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisOutubroCl2L = pReaisOutubroCl2.length
          var pReaisOutubroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisOutubroCl2L; r1++)
          pReaisOutubroCl2Numeros.push(Number(pReaisOutubroCl2[r1]))
          const pReaisOutubroCl2Total1 = pReaisOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisOutubroCl2L2 = pReaisOutubroCl22.length
          var pReaisOutubroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisOutubroCl2L2; r2++)
          pReaisOutubroCl2Numeros2.push(Number(pReaisOutubroCl22[r2]))
          const pReaisOutubroCl2Total2 = pReaisOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisOutubroCl2 = (pReaisOutubroCl2Total1+pReaisOutubroCl2Total2)
      
          const pDolarOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarOutubroCl2L = pDolarOutubroCl2.length
          var pDolarOutubroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarOutubroCl2L; d1++)
          pDolarOutubroCl2Numeros.push(Number(pDolarOutubroCl2[d1]))
          const pDolarOutubroCl2Total1 = pDolarOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarOutubroCl2L2 = pDolarOutubroCl22.length
          var pDolarOutubroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarOutubroCl2L2; d2++)
          pDolarOutubroCl2Numeros2.push(Number(pDolarOutubroCl22[d2]))
          const pDolarOutubroCl2Total2 = pDolarOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarOutubroCl2 = (pDolarOutubroCl2Total1+pDolarOutubroCl2Total2)
      
          //NovembroCl2
          const pReaisNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisNovembroCl2L = pReaisNovembroCl2.length
          var pReaisNovembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisNovembroCl2L; r1++)
          pReaisNovembroCl2Numeros.push(Number(pReaisNovembroCl2[r1]))
          const pReaisNovembroCl2Total1 = pReaisNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisNovembroCl2L2 = pReaisNovembroCl22.length
          var pReaisNovembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisNovembroCl2L2; r2++)
          pReaisNovembroCl2Numeros2.push(Number(pReaisNovembroCl22[r2]))
          const pReaisNovembroCl2Total2 = pReaisNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisNovembroCl2 = (pReaisNovembroCl2Total1+pReaisNovembroCl2Total2)
      
          const pDolarNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarNovembroCl2L = pDolarNovembroCl2.length
          var pDolarNovembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarNovembroCl2L; d1++)
          pDolarNovembroCl2Numeros.push(Number(pDolarNovembroCl2[d1]))
          const pDolarNovembroCl2Total1 = pDolarNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarNovembroCl2L2 = pDolarNovembroCl22.length
          var pDolarNovembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarNovembroCl2L2; d2++)
          pDolarNovembroCl2Numeros2.push(Number(pDolarNovembroCl22[d2]))
          const pDolarNovembroCl2Total2 = pDolarNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarNovembroCl2 = (pDolarNovembroCl2Total1+pDolarNovembroCl2Total2)
        
      
          //DezembroCl2
          const pReaisDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisDezembroCl2L = pReaisDezembroCl2.length
          var pReaisDezembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisDezembroCl2L; r1++)
          pReaisDezembroCl2Numeros.push(Number(pReaisDezembroCl2[r1]))
          const pReaisDezembroCl2Total1 = pReaisDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisDezembroCl2L2 = pReaisDezembroCl22.length
          var pReaisDezembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisDezembroCl2L2; r2++)
          pReaisDezembroCl2Numeros2.push(Number(pReaisDezembroCl22[r2]))
          const pReaisDezembroCl2Total2 = pReaisDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisDezembroCl2 = (pReaisDezembroCl2Total1+pReaisDezembroCl2Total2)
      
          const pDolarDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarDezembroCl2L = pDolarDezembroCl2.length
          var pDolarDezembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarDezembroCl2L; d1++)
          pDolarDezembroCl2Numeros.push(Number(pDolarDezembroCl2[d1]))
          const pDolarDezembroCl2Total1 = pDolarDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarDezembroCl2L2 = pDolarDezembroCl22.length
          var pDolarDezembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarDezembroCl2L2; d2++)
          pDolarDezembroCl2Numeros2.push(Number(pDolarDezembroCl22[d2]))
          const pDolarDezembroCl2Total2 = pDolarDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarDezembroCl2 = (pDolarDezembroCl2Total1+pDolarDezembroCl2Total2)
    
    
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data:[DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },{
        label:`Faturamento Reais - ${cliente2}`,
        data: [ReaisJaneiroCl2, ReaisFevereiroCl2, ReaisMarcoCl2, ReaisAbrilCl2, ReaisMaioCl2, ReaisJunhoCl2, ReaisJulhoCl2, ReaisAgostoCl2, ReaisSetembroCl2, ReaisOutubroCl2, ReaisNovembroCl2, ReaisDezembroCl2 ],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data:  [DolarJaneiroCl2, DolarFevereiroCl2, DolarMarcoCl2, DolarAbrilCl2, DolarMaioCl2, DolarJunhoCl2, DolarJulhoCl2, DolarAgostoCl2, DolarSetembroCl2, DolarOutubroCl2, DolarNovembroCl2, DolarDezembroCl2 ],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
   
  }
  
  const handleChart3 =()=>{
    setGrafico(false)
const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})

const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1) ){return val}})

const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente1)){return val}})

// janeiro
const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJaneiroL = pReaisJaneiro.length
var pReaisJaneiroNumeros = [];
for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJaneiroL2 = pReaisJaneiro2.length
var pReaisJaneiroNumeros2 = [];
for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)

const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJaneiroL = pDolarJaneiro.length
var pDolarJanrioNumeros = [];
for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJaneiroL2 = pDolarJaneiro2.length
var pDolarJanrioNumeros2 = [];
for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)



//fevereiro  
const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisFevereiroL = pReaisFevereiro.length
var pReaisFevereiroNumeros = [];
for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisFevereiroL2 = pReaisFevereiro2.length
var pReaisFevereiroNumeros2 = [];
for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)

const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarFevereiroL = pDolarFevereiro.length
var pDolarFevereiroNumeros = [];
for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarFevereiroL2 = pDolarFevereiro2.length
var pDolarFevereiroNumeros2 = [];
for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)

//Marco
const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMarcoL = pReaisMarco.length
var pReaisMarcoNumeros = [];
for (var r1 = 0; r1 < pReaisMarcoL; r1++)
pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMarcoL2 = pReaisMarco2.length
var pReaisMarcoNumeros2 = [];
for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)

const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMarcoL = pDolarMarco.length
var pDolarMarcoNumeros = [];
for (var d1 = 0; d1 < pDolarMarcoL; d1++)
pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMarcoL2 = pDolarMarco2.length
var pDolarMarcoNumeros2 = [];
for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)

///Abril
const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAbrilL = pReaisAbril.length
var pReaisAbrilNumeros = [];
for (var r1 = 0; r1 < pReaisAbrilL; r1++)
pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAbrilL2 = pReaisAbril2.length
var pReaisAbrilNumeros2 = [];
for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)

const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAbrilL = pDolarAbril.length
var pDolarAbrilNumeros = [];
for (var d1 = 0; d1 < pDolarAbrilL; d1++)
pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAbrilL2 = pDolarAbril2.length
var pDolarAbrilNumeros2 = [];
for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
//Maio
const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMaioL = pReaisMaio.length
var pReaisMaioNumeros = [];
for (var r1 = 0; r1 < pReaisMaioL; r1++)
pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMaioL2 = pReaisMaio2.length
var pReaisMaioNumeros2 = [];
for (var r2 = 0; r2 < pReaisMaioL2; r2++)
pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)

const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMaioL = pDolarMaio.length
var pDolarMaioNumeros = [];
for (var d1 = 0; d1 < pDolarMaioL; d1++)
pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMaioL2 = pDolarMaio2.length
var pDolarMaioNumeros2 = [];
for (var d2 = 0; d2 < pDolarMaioL2; d2++)
pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)

//Junho
const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJunhoL = pReaisJunho.length
var pReaisJunhoNumeros = [];
for (var r1 = 0; r1 < pReaisJunhoL; r1++)
pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJunhoL2 = pReaisJunho2.length
var pReaisJunhoNumeros2 = [];
for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)

const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJunhoL = pDolarJunho.length
var pDolarJunhoNumeros = [];
for (var d1 = 0; d1 < pDolarJunhoL; d1++)
pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJunhoL2 = pDolarJunho2.length
var pDolarJunhoNumeros2 = [];
for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)

//Julho
const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJulhoL = pReaisJulho.length
var pReaisJulhoNumeros = [];
for (var r1 = 0; r1 < pReaisJulhoL; r1++)
pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJulhoL2 = pReaisJulho2.length
var pReaisJulhoNumeros2 = [];
for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)

const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJulhoL = pDolarJulho.length
var pDolaroJulhoNumeros = [];
for (var d1 = 0; d1 < pDolarJulhoL; d1++)
pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJulhoL2 = pDolarJulho2.length
var pDolaroJulhoNumeros2 = [];
for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)

//Agosto
const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAgostoL = pReaisAgosto.length
var pReaisAgostoNumeros = [];
for (var r1 = 0; r1 < pReaisAgostoL; r1++)
pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAgostoL2 = pReaisAgosto2.length
var pReaisAgostoNumeros2 = [];
for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)

const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAgostoL = pDolarAgosto.length
var pDolarAgostoNumeros = [];
for (var d1 = 0; d1 < pDolarAgostoL; d1++)
pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAgostoL2 = pDolarAgosto2.length
var pDolarAgostoNumeros2 = [];
for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)

//Setembro
const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisSetembroL = pReaisSetembro.length
var pReaisSetembroNumeros = [];
for (var r1 = 0; r1 < pReaisSetembroL; r1++)
pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisSetembroL2 = pReaisSetembro2.length
var pReaisSetembroNumeros2 = [];
for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)


const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarSetembroL = pDolarSetembro.length
var pDolarSetembroNumeros = [];
for (var d1 = 0; d1 < pDolarSetembroL; d1++)
pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarSetembroL2 = pDolarSetembro2.length
var pDolarSetembroNumeros2 = [];
for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)

//Outubro
const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisOutubroL = pReaisOutubro.length
var pReaisOutubroNumeros = [];
for (var r1 = 0; r1 < pReaisOutubroL; r1++)
pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisOutubroL2 = pReaisOutubro2.length
var pReaisOutubroNumeros2 = [];
for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)

const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarOutubroL = pDolarOutubro.length
var pDolarOutubroNumeros = [];
for (var d1 = 0; d1 < pDolarOutubroL; d1++)
pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarOutubroL2 = pDolarOutubro2.length
var pDolarOutubroNumeros2 = [];
for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)

//Novembro
const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisNovembroL = pReaisNovembro.length
var pReaisNovembroNumeros = [];
for (var r1 = 0; r1 < pReaisNovembroL; r1++)
pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisNovembroL2 = pReaisNovembro2.length
var pReaisNovembroNumeros2 = [];
for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)

const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarNovembroL = pDolarNovembro.length
var pDolarNovembroNumeros = [];
for (var d1 = 0; d1 < pDolarNovembroL; d1++)
pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarNovembroL2 = pDolarNovembro2.length
var pDolarNovembroNumeros2 = [];
for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)


//Dezembro
const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisDezembroL = pReaisDezembro.length
var pReaisDezembroNumeros = [];
for (var r1 = 0; r1 < pReaisDezembroL; r1++)
pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisDezembroL2 = pReaisDezembro2.length
var pReaisDezembroNumeros2 = [];
for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)

const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarDezembroL = pDolarDezembro.length
var pDolarDezembroNumeros = [];
for (var d1 = 0; d1 < pDolarDezembroL; d1++)
pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)

const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarDezembroL2 = pDolarDezembro2.length
var pDolarDezembroNumeros2 = [];
for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)

const NumJaneiroCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2) ){return val}})

const NumFevereiroCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2) ){return val}})

const NumMarcoCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumAbrilCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumMaioCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumJunhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumJulhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumAgostoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumSetembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumOutubroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumNovembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

const NumDezembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente2)){return val}})

// JaneiroCl2
const pReaisJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJaneiroCl2L = pReaisJaneiroCl2.length
var pReaisJaneiroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisJaneiroCl2L; r1++)
pReaisJaneiroCl2Numeros.push(Number(pReaisJaneiroCl2[r1]))
const pReaisJaneiroCl2Total1 = pReaisJaneiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJaneiroCl2L2 = pReaisJaneiroCl22.length
var pReaisJaneiroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisJaneiroCl2L2; r2++)
pReaisJaneiroCl2Numeros2.push(Number(pReaisJaneiroCl22[r2]))
const pReaisJaneiroCl2Total2 = pReaisJaneiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJaneiroCl2 = (pReaisJaneiroCl2Total1+pReaisJaneiroCl2Total2)

const pDolarJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJaneiroCl2L = pDolarJaneiroCl2.length
var pDolarJanrioNumeros = [];
for (var d1 = 0; d1 < pDolarJaneiroCl2L; d1++)
pDolarJanrioNumeros.push(Number(pDolarJaneiroCl2[d1]))
const pDolarJaneiroCl2Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJaneiroCl2L2 = pDolarJaneiroCl22.length
var pDolarJanrioNumeros2 = [];
for (var d2 = 0; d2 < pDolarJaneiroCl2L2; d2++)
pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl22[d2]))
const pDolarJaneiroCl2Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJaneiroCl2 = (pDolarJaneiroCl2Total1+pDolarJaneiroCl2Total2)



//FevereiroCl2  
const pReaisFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisFevereiroCl2L = pReaisFevereiroCl2.length
var pReaisFevereiroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisFevereiroCl2L; r1++)
pReaisFevereiroCl2Numeros.push(Number(pReaisFevereiroCl2[r1]))
const pReaisFevereiroCl2Total1 = pReaisFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisFevereiroCl2L2 = pReaisFevereiroCl22.length
var pReaisFevereiroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisFevereiroCl2L2; r2++)
pReaisFevereiroCl2Numeros2.push(Number(pReaisFevereiroCl22[r2]))
const pReaisFevereiroCl2Total2 = pReaisFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisFevereiroCl2 = (pReaisFevereiroCl2Total1+pReaisFevereiroCl2Total2)

const pDolarFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarFevereiroCl2L = pDolarFevereiroCl2.length
var pDolarFevereiroCl2Numeros = [];
for (var d1 = 0; d1 < pDolarFevereiroCl2L; d1++)
pDolarFevereiroCl2Numeros.push(Number(pDolarFevereiroCl2[d1]))
const pDolarFevereiroCl2Total1 = pDolarFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarFevereiroCl2L2 = pDolarFevereiroCl22.length
var pDolarFevereiroCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarFevereiroCl2L2; d2++)
pDolarFevereiroCl2Numeros2.push(Number(pDolarFevereiroCl22[d2]))
const pDolarFevereiroCl2Total2 = pDolarFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarFevereiroCl2 = (pDolarFevereiroCl2Total1+pDolarFevereiroCl2Total2)

//MarcoCl2
const pReaisMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMarcoCl2L = pReaisMarcoCl2.length
var pReaisMarcoCl2Numeros = [];
for (var r1 = 0; r1 < pReaisMarcoCl2L; r1++)
pReaisMarcoCl2Numeros.push(Number(pReaisMarcoCl2[r1]))
const pReaisMarcoCl2Total1 = pReaisMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMarcoCl2L2 = pReaisMarcoCl22.length
var pReaisMarcoCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisMarcoCl2L2; r2++)
pReaisMarcoCl2Numeros2.push(Number(pReaisMarcoCl22[r2]))
const pReaisMarcoCl2Total2 = pReaisMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMarcoCl2 = (pReaisMarcoCl2Total1+pReaisMarcoCl2Total2)

const pDolarMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMarcoCl2L = pDolarMarcoCl2.length
var pDolarMarcoCl2Numeros = [];
for (var d1 = 0; d1 < pDolarMarcoCl2L; d1++)
pDolarMarcoCl2Numeros.push(Number(pDolarMarcoCl2[d1]))
const pDolarMarcoCl2Total1 = pDolarMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMarcoCl2L2 = pDolarMarcoCl22.length
var pDolarMarcoCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarMarcoCl2L2; d2++)
pDolarMarcoCl2Numeros2.push(Number(pDolarMarcoCl22[d2]))
const pDolarMarcoCl2Total2 = pDolarMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMarcoCl2 = (pDolarMarcoCl2Total1+pDolarMarcoCl2Total2)

///AbrilCl2
const pReaisAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAbrilCl2L = pReaisAbrilCl2.length
var pReaisAbrilCl2Numeros = [];
for (var r1 = 0; r1 < pReaisAbrilCl2L; r1++)
pReaisAbrilCl2Numeros.push(Number(pReaisAbrilCl2[r1]))
const pReaisAbrilCl2Total1 = pReaisAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAbrilCl2L2 = pReaisAbrilCl22.length
var pReaisAbrilCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisAbrilCl2L2; r2++)
pReaisAbrilCl2Numeros2.push(Number(pReaisAbrilCl22[r2]))
const pReaisAbrilCl2Total2 = pReaisAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAbrilCl2 = (pReaisAbrilCl2Total1+pReaisAbrilCl2Total2)

const pDolarAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAbrilCl2L = pDolarAbrilCl2.length
var pDolarAbrilCl2Numeros = [];
for (var d1 = 0; d1 < pDolarAbrilCl2L; d1++)
pDolarAbrilCl2Numeros.push(Number(pDolarAbrilCl2[d1]))
const pDolarAbrilCl2Total1 = pDolarAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAbrilCl2L2 = pDolarAbrilCl22.length
var pDolarAbrilCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarAbrilCl2L2; d2++)
pDolarAbrilCl2Numeros2.push(Number(pDolarAbrilCl22[d2]))
const pDolarAbrilCl2Total2 = pDolarAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAbrilCl2 = (pDolarAbrilCl2Total1+pDolarAbrilCl2Total2)
//MaioCl2
const pReaisMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMaioCl2L = pReaisMaioCl2.length
var pReaisMaioCl2Numeros = [];
for (var r1 = 0; r1 < pReaisMaioCl2L; r1++)
pReaisMaioCl2Numeros.push(Number(pReaisMaioCl2[r1]))
const pReaisMaioCl2Total1 = pReaisMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMaioCl2L2 = pReaisMaioCl22.length
var pReaisMaioCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisMaioCl2L2; r2++)
pReaisMaioCl2Numeros2.push(Number(pReaisMaioCl22[r2]))
const pReaisMaioCl2Total2 = pReaisMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMaioCl2 = (pReaisMaioCl2Total1+pReaisMaioCl2Total2)

const pDolarMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMaioCl2L = pDolarMaioCl2.length
var pDolarMaioCl2Numeros = [];
for (var d1 = 0; d1 < pDolarMaioCl2L; d1++)
pDolarMaioCl2Numeros.push(Number(pDolarMaioCl2[d1]))
const pDolarMaioCl2Total1 = pDolarMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMaioCl2L2 = pDolarMaioCl22.length
var pDolarMaioCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarMaioCl2L2; d2++)
pDolarMaioCl2Numeros2.push(Number(pDolarMaioCl22[d2]))
const pDolarMaioCl2Total2 = pDolarMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMaioCl2 = (pDolarMaioCl2Total1+pDolarMaioCl2Total2)

//JunhoCl2
const pReaisJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJunhoCl2L = pReaisJunhoCl2.length
var pReaisJunhoCl2Numeros = [];
for (var r1 = 0; r1 < pReaisJunhoCl2L; r1++)
pReaisJunhoCl2Numeros.push(Number(pReaisJunhoCl2[r1]))
const pReaisJunhoCl2Total1 = pReaisJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJunhoCl2L2 = pReaisJunhoCl22.length
var pReaisJunhoCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisJunhoCl2L2; r2++)
pReaisJunhoCl2Numeros2.push(Number(pReaisJunhoCl22[r2]))
const pReaisJunhoCl2Total2 = pReaisJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJunhoCl2 = (pReaisJunhoCl2Total1+pReaisJunhoCl2Total2)

const pDolarJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJunhoCl2L = pDolarJunhoCl2.length
var pDolarJunhoCl2Numeros = [];
for (var d1 = 0; d1 < pDolarJunhoCl2L; d1++)
pDolarJunhoCl2Numeros.push(Number(pDolarJunhoCl2[d1]))
const pDolarJunhoCl2Total1 = pDolarJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJunhoCl2L2 = pDolarJunhoCl22.length
var pDolarJunhoCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarJunhoCl2L2; d2++)
pDolarJunhoCl2Numeros2.push(Number(pDolarJunhoCl22[d2]))
const pDolarJunhoCl2Total2 = pDolarJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJunhoCl2 = (pDolarJunhoCl2Total1+pDolarJunhoCl2Total2)

//JulhoCl2
const pReaisJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJulhoCl2L = pReaisJulhoCl2.length
var pReaisJulhoCl2Numeros = [];
for (var r1 = 0; r1 < pReaisJulhoCl2L; r1++)
pReaisJulhoCl2Numeros.push(Number(pReaisJulhoCl2[r1]))
const pReaisJulhoCl2Total1 = pReaisJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJulhoCl2L2 = pReaisJulhoCl22.length
var pReaisJulhoCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisJulhoCl2L2; r2++)
pReaisJulhoCl2Numeros2.push(Number(pReaisJulhoCl22[r2]))
const pReaisJulhoCl2Total2 = pReaisJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJulhoCl2 = (pReaisJulhoCl2Total1+pReaisJulhoCl2Total2)

const pDolarJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJulhoCl2L = pDolarJulhoCl2.length
var pDolaroJulhoCl2Numeros = [];
for (var d1 = 0; d1 < pDolarJulhoCl2L; d1++)
pDolaroJulhoCl2Numeros.push(Number(pDolarJulhoCl2[d1]))
const pDolarJulhoCl2Total1 = pDolaroJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJulhoCl2L2 = pDolarJulhoCl22.length
var pDolaroJulhoCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarJulhoCl2L2; d2++)
pDolaroJulhoCl2Numeros2.push(Number(pDolarJulhoCl22[d2]))
const pDolarJulhoCl2Total2 = pDolaroJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJulhoCl2 = (pDolarJulhoCl2Total1+pDolarJulhoCl2Total2)

//AgostoCl2
const pReaisAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAgostoCl2L = pReaisAgostoCl2.length
var pReaisAgostoCl2Numeros = [];
for (var r1 = 0; r1 < pReaisAgostoCl2L; r1++)
pReaisAgostoCl2Numeros.push(Number(pReaisAgostoCl2[r1]))
const pReaisAgostoCl2Total1 = pReaisAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAgostoCl2L2 = pReaisAgostoCl22.length
var pReaisAgostoCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisAgostoCl2L2; r2++)
pReaisAgostoCl2Numeros2.push(Number(pReaisAgostoCl22[r2]))
const pReaisAgostoCl2Total2 = pReaisAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAgostoCl2 = (pReaisAgostoCl2Total1+pReaisAgostoCl2Total2)

const pDolarAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAgostoCl2L = pDolarAgostoCl2.length
var pDolarAgostoCl2Numeros = [];
for (var d1 = 0; d1 < pDolarAgostoCl2L; d1++)
pDolarAgostoCl2Numeros.push(Number(pDolarAgostoCl2[d1]))
const pDolarAgostoCl2Total1 = pDolarAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAgostoCl2L2 = pDolarAgostoCl22.length
var pDolarAgostoCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarAgostoCl2L2; d2++)
pDolarAgostoCl2Numeros2.push(Number(pDolarAgostoCl22[d2]))
const pDolarAgostoCl2Total2 = pDolarAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAgostoCl2 = (pDolarAgostoCl2Total1+pDolarAgostoCl2Total2)

//SetembroCl2
const pReaisSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisSetembroCl2L = pReaisSetembroCl2.length
var pReaisSetembroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisSetembroCl2L; r1++)
pReaisSetembroCl2Numeros.push(Number(pReaisSetembroCl2[r1]))
const pReaisSetembroCl2Total1 = pReaisSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisSetembroCl2L2 = pReaisSetembroCl22.length
var pReaisSetembroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisSetembroCl2L2; r2++)
pReaisSetembroCl2Numeros2.push(Number(pReaisSetembroCl22[r2]))
const pReaisSetembroCl2Total2 = pReaisSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisSetembroCl2 = (pReaisSetembroCl2Total1+pReaisSetembroCl2Total2)


const pDolarSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarSetembroCl2L = pDolarSetembroCl2.length
var pDolarSetembroCl2Numeros = [];
for (var d1 = 0; d1 < pDolarSetembroCl2L; d1++)
pDolarSetembroCl2Numeros.push(Number(pDolarSetembroCl2[d1]))
const pDolarSetembroCl2Total1 = pDolarSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarSetembroCl2L2 = pDolarSetembroCl22.length
var pDolarSetembroCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarSetembroCl2L2; d2++)
pDolarSetembroCl2Numeros2.push(Number(pDolarSetembroCl22[d2]))
const pDolarSetembroCl2Total2 = pDolarSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarSetembroCl2 = (pDolarSetembroCl2Total1+pDolarSetembroCl2Total2)

//OutubroCl2
const pReaisOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisOutubroCl2L = pReaisOutubroCl2.length
var pReaisOutubroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisOutubroCl2L; r1++)
pReaisOutubroCl2Numeros.push(Number(pReaisOutubroCl2[r1]))
const pReaisOutubroCl2Total1 = pReaisOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisOutubroCl2L2 = pReaisOutubroCl22.length
var pReaisOutubroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisOutubroCl2L2; r2++)
pReaisOutubroCl2Numeros2.push(Number(pReaisOutubroCl22[r2]))
const pReaisOutubroCl2Total2 = pReaisOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisOutubroCl2 = (pReaisOutubroCl2Total1+pReaisOutubroCl2Total2)

const pDolarOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarOutubroCl2L = pDolarOutubroCl2.length
var pDolarOutubroCl2Numeros = [];
for (var d1 = 0; d1 < pDolarOutubroCl2L; d1++)
pDolarOutubroCl2Numeros.push(Number(pDolarOutubroCl2[d1]))
const pDolarOutubroCl2Total1 = pDolarOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarOutubroCl2L2 = pDolarOutubroCl22.length
var pDolarOutubroCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarOutubroCl2L2; d2++)
pDolarOutubroCl2Numeros2.push(Number(pDolarOutubroCl22[d2]))
const pDolarOutubroCl2Total2 = pDolarOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarOutubroCl2 = (pDolarOutubroCl2Total1+pDolarOutubroCl2Total2)

//NovembroCl2
const pReaisNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisNovembroCl2L = pReaisNovembroCl2.length
var pReaisNovembroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisNovembroCl2L; r1++)
pReaisNovembroCl2Numeros.push(Number(pReaisNovembroCl2[r1]))
const pReaisNovembroCl2Total1 = pReaisNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisNovembroCl2L2 = pReaisNovembroCl22.length
var pReaisNovembroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisNovembroCl2L2; r2++)
pReaisNovembroCl2Numeros2.push(Number(pReaisNovembroCl22[r2]))
const pReaisNovembroCl2Total2 = pReaisNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisNovembroCl2 = (pReaisNovembroCl2Total1+pReaisNovembroCl2Total2)

const pDolarNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarNovembroCl2L = pDolarNovembroCl2.length
var pDolarNovembroCl2Numeros = [];
for (var d1 = 0; d1 < pDolarNovembroCl2L; d1++)
pDolarNovembroCl2Numeros.push(Number(pDolarNovembroCl2[d1]))
const pDolarNovembroCl2Total1 = pDolarNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarNovembroCl2L2 = pDolarNovembroCl22.length
var pDolarNovembroCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarNovembroCl2L2; d2++)
pDolarNovembroCl2Numeros2.push(Number(pDolarNovembroCl22[d2]))
const pDolarNovembroCl2Total2 = pDolarNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarNovembroCl2 = (pDolarNovembroCl2Total1+pDolarNovembroCl2Total2)


//DezembroCl2
const pReaisDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisDezembroCl2L = pReaisDezembroCl2.length
var pReaisDezembroCl2Numeros = [];
for (var r1 = 0; r1 < pReaisDezembroCl2L; r1++)
pReaisDezembroCl2Numeros.push(Number(pReaisDezembroCl2[r1]))
const pReaisDezembroCl2Total1 = pReaisDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisDezembroCl2L2 = pReaisDezembroCl22.length
var pReaisDezembroCl2Numeros2 = [];
for (var r2 = 0; r2 < pReaisDezembroCl2L2; r2++)
pReaisDezembroCl2Numeros2.push(Number(pReaisDezembroCl22[r2]))
const pReaisDezembroCl2Total2 = pReaisDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisDezembroCl2 = (pReaisDezembroCl2Total1+pReaisDezembroCl2Total2)

const pDolarDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarDezembroCl2L = pDolarDezembroCl2.length
var pDolarDezembroCl2Numeros = [];
for (var d1 = 0; d1 < pDolarDezembroCl2L; d1++)
pDolarDezembroCl2Numeros.push(Number(pDolarDezembroCl2[d1]))
const pDolarDezembroCl2Total1 = pDolarDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarDezembroCl2L2 = pDolarDezembroCl22.length
var pDolarDezembroCl2Numeros2 = [];
for (var d2 = 0; d2 < pDolarDezembroCl2L2; d2++)
pDolarDezembroCl2Numeros2.push(Number(pDolarDezembroCl22[d2]))
const pDolarDezembroCl2Total2 = pDolarDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarDezembroCl2 = (pDolarDezembroCl2Total1+pDolarDezembroCl2Total2)

const NumJaneiroCl3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3) ){return val}})

const NumFevereiroCl3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3) ){return val}})

const NumMarcoCl3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumAbrilCl3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumMaioCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumJunhoCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumJulhoCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumAgostoCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumSetembroCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumOutubroCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumNovembroCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

const NumDezembroCl3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(cliente3)){return val}})

// JaneiroCl3
const pReaisJaneiroCl3 = NumJaneiroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJaneiroCl3L = pReaisJaneiroCl3.length
var pReaisJaneiroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisJaneiroCl3L; r1++)
pReaisJaneiroCl3Numeros.push(Number(pReaisJaneiroCl3[r1]))
const pReaisJaneiroCl3Total1 = pReaisJaneiroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJaneiroCl32 = NumJaneiroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJaneiroCl3L2 = pReaisJaneiroCl32.length
var pReaisJaneiroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisJaneiroCl3L2; r2++)
pReaisJaneiroCl3Numeros2.push(Number(pReaisJaneiroCl32[r2]))
const pReaisJaneiroCl3Total2 = pReaisJaneiroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJaneiroCl3 = (pReaisJaneiroCl3Total1+pReaisJaneiroCl3Total2)

const pDolarJaneiroCl3 = NumJaneiroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJaneiroCl3L = pDolarJaneiroCl3.length
var pDolarJanrioNumeros = [];
for (var d1 = 0; d1 < pDolarJaneiroCl3L; d1++)
pDolarJanrioNumeros.push(Number(pDolarJaneiroCl3[d1]))
const pDolarJaneiroCl3Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJaneiroCl32 = NumJaneiroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJaneiroCl3L2 = pDolarJaneiroCl32.length
var pDolarJanrioNumeros2 = [];
for (var d2 = 0; d2 < pDolarJaneiroCl3L2; d2++)
pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl32[d2]))
const pDolarJaneiroCl3Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJaneiroCl3 = (pDolarJaneiroCl3Total1+pDolarJaneiroCl3Total2)



//FevereiroCl3  
const pReaisFevereiroCl3 = NumFevereiroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisFevereiroCl3L = pReaisFevereiroCl3.length
var pReaisFevereiroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisFevereiroCl3L; r1++)
pReaisFevereiroCl3Numeros.push(Number(pReaisFevereiroCl3[r1]))
const pReaisFevereiroCl3Total1 = pReaisFevereiroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisFevereiroCl32 = NumFevereiroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisFevereiroCl3L2 = pReaisFevereiroCl32.length
var pReaisFevereiroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisFevereiroCl3L2; r2++)
pReaisFevereiroCl3Numeros2.push(Number(pReaisFevereiroCl32[r2]))
const pReaisFevereiroCl3Total2 = pReaisFevereiroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisFevereiroCl3 = (pReaisFevereiroCl3Total1+pReaisFevereiroCl3Total2)

const pDolarFevereiroCl3 = NumFevereiroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarFevereiroCl3L = pDolarFevereiroCl3.length
var pDolarFevereiroCl3Numeros = [];
for (var d1 = 0; d1 < pDolarFevereiroCl3L; d1++)
pDolarFevereiroCl3Numeros.push(Number(pDolarFevereiroCl3[d1]))
const pDolarFevereiroCl3Total1 = pDolarFevereiroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarFevereiroCl32 = NumFevereiroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarFevereiroCl3L2 = pDolarFevereiroCl32.length
var pDolarFevereiroCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarFevereiroCl3L2; d2++)
pDolarFevereiroCl3Numeros2.push(Number(pDolarFevereiroCl32[d2]))
const pDolarFevereiroCl3Total2 = pDolarFevereiroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarFevereiroCl3 = (pDolarFevereiroCl3Total1+pDolarFevereiroCl3Total2)

//MarcoCl3
const pReaisMarcoCl3 = NumMarcoCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMarcoCl3L = pReaisMarcoCl3.length
var pReaisMarcoCl3Numeros = [];
for (var r1 = 0; r1 < pReaisMarcoCl3L; r1++)
pReaisMarcoCl3Numeros.push(Number(pReaisMarcoCl3[r1]))
const pReaisMarcoCl3Total1 = pReaisMarcoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisMarcoCl32 = NumMarcoCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMarcoCl3L2 = pReaisMarcoCl32.length
var pReaisMarcoCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisMarcoCl3L2; r2++)
pReaisMarcoCl3Numeros2.push(Number(pReaisMarcoCl32[r2]))
const pReaisMarcoCl3Total2 = pReaisMarcoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMarcoCl3 = (pReaisMarcoCl3Total1+pReaisMarcoCl3Total2)

const pDolarMarcoCl3 = NumMarcoCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMarcoCl3L = pDolarMarcoCl3.length
var pDolarMarcoCl3Numeros = [];
for (var d1 = 0; d1 < pDolarMarcoCl3L; d1++)
pDolarMarcoCl3Numeros.push(Number(pDolarMarcoCl3[d1]))
const pDolarMarcoCl3Total1 = pDolarMarcoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarMarcoCl32 = NumMarcoCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMarcoCl3L2 = pDolarMarcoCl32.length
var pDolarMarcoCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarMarcoCl3L2; d2++)
pDolarMarcoCl3Numeros2.push(Number(pDolarMarcoCl32[d2]))
const pDolarMarcoCl3Total2 = pDolarMarcoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMarcoCl3 = (pDolarMarcoCl3Total1+pDolarMarcoCl3Total2)

///AbrilCl3
const pReaisAbrilCl3 = NumAbrilCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAbrilCl3L = pReaisAbrilCl3.length
var pReaisAbrilCl3Numeros = [];
for (var r1 = 0; r1 < pReaisAbrilCl3L; r1++)
pReaisAbrilCl3Numeros.push(Number(pReaisAbrilCl3[r1]))
const pReaisAbrilCl3Total1 = pReaisAbrilCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisAbrilCl32 = NumAbrilCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAbrilCl3L2 = pReaisAbrilCl32.length
var pReaisAbrilCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisAbrilCl3L2; r2++)
pReaisAbrilCl3Numeros2.push(Number(pReaisAbrilCl32[r2]))
const pReaisAbrilCl3Total2 = pReaisAbrilCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAbrilCl3 = (pReaisAbrilCl3Total1+pReaisAbrilCl3Total2)

const pDolarAbrilCl3 = NumAbrilCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAbrilCl3L = pDolarAbrilCl3.length
var pDolarAbrilCl3Numeros = [];
for (var d1 = 0; d1 < pDolarAbrilCl3L; d1++)
pDolarAbrilCl3Numeros.push(Number(pDolarAbrilCl3[d1]))
const pDolarAbrilCl3Total1 = pDolarAbrilCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarAbrilCl32 = NumAbrilCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAbrilCl3L2 = pDolarAbrilCl32.length
var pDolarAbrilCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarAbrilCl3L2; d2++)
pDolarAbrilCl3Numeros2.push(Number(pDolarAbrilCl32[d2]))
const pDolarAbrilCl3Total2 = pDolarAbrilCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAbrilCl3 = (pDolarAbrilCl3Total1+pDolarAbrilCl3Total2)
//MaioCl3
const pReaisMaioCl3 = NumMaioCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisMaioCl3L = pReaisMaioCl3.length
var pReaisMaioCl3Numeros = [];
for (var r1 = 0; r1 < pReaisMaioCl3L; r1++)
pReaisMaioCl3Numeros.push(Number(pReaisMaioCl3[r1]))
const pReaisMaioCl3Total1 = pReaisMaioCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisMaioCl32 = NumMaioCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisMaioCl3L2 = pReaisMaioCl32.length
var pReaisMaioCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisMaioCl3L2; r2++)
pReaisMaioCl3Numeros2.push(Number(pReaisMaioCl32[r2]))
const pReaisMaioCl3Total2 = pReaisMaioCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisMaioCl3 = (pReaisMaioCl3Total1+pReaisMaioCl3Total2)

const pDolarMaioCl3 = NumMaioCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarMaioCl3L = pDolarMaioCl3.length
var pDolarMaioCl3Numeros = [];
for (var d1 = 0; d1 < pDolarMaioCl3L; d1++)
pDolarMaioCl3Numeros.push(Number(pDolarMaioCl3[d1]))
const pDolarMaioCl3Total1 = pDolarMaioCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarMaioCl32 = NumMaioCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarMaioCl3L2 = pDolarMaioCl32.length
var pDolarMaioCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarMaioCl3L2; d2++)
pDolarMaioCl3Numeros2.push(Number(pDolarMaioCl32[d2]))
const pDolarMaioCl3Total2 = pDolarMaioCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarMaioCl3 = (pDolarMaioCl3Total1+pDolarMaioCl3Total2)

//JunhoCl3
const pReaisJunhoCl3 = NumJunhoCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJunhoCl3L = pReaisJunhoCl3.length
var pReaisJunhoCl3Numeros = [];
for (var r1 = 0; r1 < pReaisJunhoCl3L; r1++)
pReaisJunhoCl3Numeros.push(Number(pReaisJunhoCl3[r1]))
const pReaisJunhoCl3Total1 = pReaisJunhoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJunhoCl32 = NumJunhoCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJunhoCl3L2 = pReaisJunhoCl32.length
var pReaisJunhoCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisJunhoCl3L2; r2++)
pReaisJunhoCl3Numeros2.push(Number(pReaisJunhoCl32[r2]))
const pReaisJunhoCl3Total2 = pReaisJunhoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJunhoCl3 = (pReaisJunhoCl3Total1+pReaisJunhoCl3Total2)

const pDolarJunhoCl3 = NumJunhoCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJunhoCl3L = pDolarJunhoCl3.length
var pDolarJunhoCl3Numeros = [];
for (var d1 = 0; d1 < pDolarJunhoCl3L; d1++)
pDolarJunhoCl3Numeros.push(Number(pDolarJunhoCl3[d1]))
const pDolarJunhoCl3Total1 = pDolarJunhoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJunhoCl32 = NumJunhoCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJunhoCl3L2 = pDolarJunhoCl32.length
var pDolarJunhoCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarJunhoCl3L2; d2++)
pDolarJunhoCl3Numeros2.push(Number(pDolarJunhoCl32[d2]))
const pDolarJunhoCl3Total2 = pDolarJunhoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJunhoCl3 = (pDolarJunhoCl3Total1+pDolarJunhoCl3Total2)

//JulhoCl3
const pReaisJulhoCl3 = NumJulhoCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisJulhoCl3L = pReaisJulhoCl3.length
var pReaisJulhoCl3Numeros = [];
for (var r1 = 0; r1 < pReaisJulhoCl3L; r1++)
pReaisJulhoCl3Numeros.push(Number(pReaisJulhoCl3[r1]))
const pReaisJulhoCl3Total1 = pReaisJulhoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisJulhoCl32 = NumJulhoCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisJulhoCl3L2 = pReaisJulhoCl32.length
var pReaisJulhoCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisJulhoCl3L2; r2++)
pReaisJulhoCl3Numeros2.push(Number(pReaisJulhoCl32[r2]))
const pReaisJulhoCl3Total2 = pReaisJulhoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisJulhoCl3 = (pReaisJulhoCl3Total1+pReaisJulhoCl3Total2)

const pDolarJulhoCl3 = NumJulhoCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarJulhoCl3L = pDolarJulhoCl3.length
var pDolaroJulhoCl3Numeros = [];
for (var d1 = 0; d1 < pDolarJulhoCl3L; d1++)
pDolaroJulhoCl3Numeros.push(Number(pDolarJulhoCl3[d1]))
const pDolarJulhoCl3Total1 = pDolaroJulhoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarJulhoCl32 = NumJulhoCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarJulhoCl3L2 = pDolarJulhoCl32.length
var pDolaroJulhoCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarJulhoCl3L2; d2++)
pDolaroJulhoCl3Numeros2.push(Number(pDolarJulhoCl32[d2]))
const pDolarJulhoCl3Total2 = pDolaroJulhoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarJulhoCl3 = (pDolarJulhoCl3Total1+pDolarJulhoCl3Total2)

//AgostoCl3
const pReaisAgostoCl3 = NumAgostoCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisAgostoCl3L = pReaisAgostoCl3.length
var pReaisAgostoCl3Numeros = [];
for (var r1 = 0; r1 < pReaisAgostoCl3L; r1++)
pReaisAgostoCl3Numeros.push(Number(pReaisAgostoCl3[r1]))
const pReaisAgostoCl3Total1 = pReaisAgostoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisAgostoCl32 = NumAgostoCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisAgostoCl3L2 = pReaisAgostoCl32.length
var pReaisAgostoCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisAgostoCl3L2; r2++)
pReaisAgostoCl3Numeros2.push(Number(pReaisAgostoCl32[r2]))
const pReaisAgostoCl3Total2 = pReaisAgostoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisAgostoCl3 = (pReaisAgostoCl3Total1+pReaisAgostoCl3Total2)

const pDolarAgostoCl3 = NumAgostoCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarAgostoCl3L = pDolarAgostoCl3.length
var pDolarAgostoCl3Numeros = [];
for (var d1 = 0; d1 < pDolarAgostoCl3L; d1++)
pDolarAgostoCl3Numeros.push(Number(pDolarAgostoCl3[d1]))
const pDolarAgostoCl3Total1 = pDolarAgostoCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarAgostoCl32 = NumAgostoCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarAgostoCl3L2 = pDolarAgostoCl32.length
var pDolarAgostoCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarAgostoCl3L2; d2++)
pDolarAgostoCl3Numeros2.push(Number(pDolarAgostoCl32[d2]))
const pDolarAgostoCl3Total2 = pDolarAgostoCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarAgostoCl3 = (pDolarAgostoCl3Total1+pDolarAgostoCl3Total2)

//SetembroCl3
const pReaisSetembroCl3 = NumSetembroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisSetembroCl3L = pReaisSetembroCl3.length
var pReaisSetembroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisSetembroCl3L; r1++)
pReaisSetembroCl3Numeros.push(Number(pReaisSetembroCl3[r1]))
const pReaisSetembroCl3Total1 = pReaisSetembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisSetembroCl32 = NumSetembroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisSetembroCl3L2 = pReaisSetembroCl32.length
var pReaisSetembroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisSetembroCl3L2; r2++)
pReaisSetembroCl3Numeros2.push(Number(pReaisSetembroCl32[r2]))
const pReaisSetembroCl3Total2 = pReaisSetembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisSetembroCl3 = (pReaisSetembroCl3Total1+pReaisSetembroCl3Total2)


const pDolarSetembroCl3 = NumSetembroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarSetembroCl3L = pDolarSetembroCl3.length
var pDolarSetembroCl3Numeros = [];
for (var d1 = 0; d1 < pDolarSetembroCl3L; d1++)
pDolarSetembroCl3Numeros.push(Number(pDolarSetembroCl3[d1]))
const pDolarSetembroCl3Total1 = pDolarSetembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarSetembroCl32 = NumSetembroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarSetembroCl3L2 = pDolarSetembroCl32.length
var pDolarSetembroCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarSetembroCl3L2; d2++)
pDolarSetembroCl3Numeros2.push(Number(pDolarSetembroCl32[d2]))
const pDolarSetembroCl3Total2 = pDolarSetembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarSetembroCl3 = (pDolarSetembroCl3Total1+pDolarSetembroCl3Total2)

//OutubroCl3
const pReaisOutubroCl3 = NumOutubroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisOutubroCl3L = pReaisOutubroCl3.length
var pReaisOutubroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisOutubroCl3L; r1++)
pReaisOutubroCl3Numeros.push(Number(pReaisOutubroCl3[r1]))
const pReaisOutubroCl3Total1 = pReaisOutubroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisOutubroCl32 = NumOutubroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisOutubroCl3L2 = pReaisOutubroCl32.length
var pReaisOutubroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisOutubroCl3L2; r2++)
pReaisOutubroCl3Numeros2.push(Number(pReaisOutubroCl32[r2]))
const pReaisOutubroCl3Total2 = pReaisOutubroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisOutubroCl3 = (pReaisOutubroCl3Total1+pReaisOutubroCl3Total2)

const pDolarOutubroCl3 = NumOutubroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarOutubroCl3L = pDolarOutubroCl3.length
var pDolarOutubroCl3Numeros = [];
for (var d1 = 0; d1 < pDolarOutubroCl3L; d1++)
pDolarOutubroCl3Numeros.push(Number(pDolarOutubroCl3[d1]))
const pDolarOutubroCl3Total1 = pDolarOutubroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarOutubroCl32 = NumOutubroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarOutubroCl3L2 = pDolarOutubroCl32.length
var pDolarOutubroCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarOutubroCl3L2; d2++)
pDolarOutubroCl3Numeros2.push(Number(pDolarOutubroCl32[d2]))
const pDolarOutubroCl3Total2 = pDolarOutubroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarOutubroCl3 = (pDolarOutubroCl3Total1+pDolarOutubroCl3Total2)

//NovembroCl3
const pReaisNovembroCl3 = NumNovembroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisNovembroCl3L = pReaisNovembroCl3.length
var pReaisNovembroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisNovembroCl3L; r1++)
pReaisNovembroCl3Numeros.push(Number(pReaisNovembroCl3[r1]))
const pReaisNovembroCl3Total1 = pReaisNovembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisNovembroCl32 = NumNovembroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisNovembroCl3L2 = pReaisNovembroCl32.length
var pReaisNovembroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisNovembroCl3L2; r2++)
pReaisNovembroCl3Numeros2.push(Number(pReaisNovembroCl32[r2]))
const pReaisNovembroCl3Total2 = pReaisNovembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisNovembroCl3 = (pReaisNovembroCl3Total1+pReaisNovembroCl3Total2)

const pDolarNovembroCl3 = NumNovembroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarNovembroCl3L = pDolarNovembroCl3.length
var pDolarNovembroCl3Numeros = [];
for (var d1 = 0; d1 < pDolarNovembroCl3L; d1++)
pDolarNovembroCl3Numeros.push(Number(pDolarNovembroCl3[d1]))
const pDolarNovembroCl3Total1 = pDolarNovembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarNovembroCl32 = NumNovembroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarNovembroCl3L2 = pDolarNovembroCl32.length
var pDolarNovembroCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarNovembroCl3L2; d2++)
pDolarNovembroCl3Numeros2.push(Number(pDolarNovembroCl32[d2]))
const pDolarNovembroCl3Total2 = pDolarNovembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarNovembroCl3 = (pDolarNovembroCl3Total1+pDolarNovembroCl3Total2)


//DezembroCl3
const pReaisDezembroCl3 = NumDezembroCl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pReaisDezembroCl3L = pReaisDezembroCl3.length
var pReaisDezembroCl3Numeros = [];
for (var r1 = 0; r1 < pReaisDezembroCl3L; r1++)
pReaisDezembroCl3Numeros.push(Number(pReaisDezembroCl3[r1]))
const pReaisDezembroCl3Total1 = pReaisDezembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pReaisDezembroCl32 = NumDezembroCl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pReaisDezembroCl3L2 = pReaisDezembroCl32.length
var pReaisDezembroCl3Numeros2 = [];
for (var r2 = 0; r2 < pReaisDezembroCl3L2; r2++)
pReaisDezembroCl3Numeros2.push(Number(pReaisDezembroCl32[r2]))
const pReaisDezembroCl3Total2 = pReaisDezembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const ReaisDezembroCl3 = (pReaisDezembroCl3Total1+pReaisDezembroCl3Total2)

const pDolarDezembroCl3 = NumDezembroCl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var pDolarDezembroCl3L = pDolarDezembroCl3.length
var pDolarDezembroCl3Numeros = [];
for (var d1 = 0; d1 < pDolarDezembroCl3L; d1++)
pDolarDezembroCl3Numeros.push(Number(pDolarDezembroCl3[d1]))
const pDolarDezembroCl3Total1 = pDolarDezembroCl3Numeros.reduce((acc, curr) => acc + curr, 0)
  
const pDolarDezembroCl32 = NumDezembroCl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var pDolarDezembroCl3L2 = pDolarDezembroCl32.length
var pDolarDezembroCl3Numeros2 = [];
for (var d2 = 0; d2 < pDolarDezembroCl3L2; d2++)
pDolarDezembroCl3Numeros2.push(Number(pDolarDezembroCl32[d2]))
const pDolarDezembroCl3Total2 = pDolarDezembroCl3Numeros2.reduce((acc, curr) => acc + curr, 0)

const DolarDezembroCl3 = (pDolarDezembroCl3Total1+pDolarDezembroCl3Total2)



setProjetData({
labels: meses,
datasets:[
{
label:`Faturamento Reais - ${cliente1}`,
data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
fill: false,
borderColor: '#FF9900',
backgroundColor: 'rgba(255,153,0,.5)',
},
{
label:`Faturamento Dólares - ${cliente1}`,
data:[DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
fill: false,
borderColor: '#FCD08F',
backgroundColor: 'rgba(252,208,143,.5)',
},{
label:`Faturamento Reais - ${cliente2}`,
data: [ReaisJaneiroCl2, ReaisFevereiroCl2, ReaisMarcoCl2, ReaisAbrilCl2, ReaisMaioCl2, ReaisJunhoCl2, ReaisJulhoCl2, ReaisAgostoCl2, ReaisSetembroCl2, ReaisOutubroCl2, ReaisNovembroCl2, ReaisDezembroCl2 ],
fill: false,
borderColor: '#42ADA7',
backgroundColor: 'rgba(66,173,167,.5)',
},
{
label:`Faturamento Dólares - ${cliente2}`,
data:  [DolarJaneiroCl2, DolarFevereiroCl2, DolarMarcoCl2, DolarAbrilCl2, DolarMaioCl2, DolarJunhoCl2, DolarJulhoCl2, DolarAgostoCl2, DolarSetembroCl2, DolarOutubroCl2, DolarNovembroCl2, DolarDezembroCl2 ],
fill: false,
borderColor: '#11D5EB',
backgroundColor: 'rgba(17,213,235,.5)',
},
{
label:`Faturamento Reais - ${cliente3}`,
data: [ReaisJaneiroCl3, ReaisFevereiroCl3, ReaisMarcoCl3, ReaisAbrilCl3, ReaisMaioCl3, ReaisJunhoCl3, ReaisJulhoCl3, ReaisAgostoCl3, ReaisSetembroCl3, ReaisOutubroCl3, ReaisNovembroCl3, ReaisDezembroCl3 ],
fill: false,
borderColor: '#42ADA7',
backgroundColor: 'rgba(66,173,167,.5)',
},
{
label:`Faturamento Dólares - ${cliente3}`,
data:  [DolarJaneiroCl3, DolarFevereiroCl3, DolarMarcoCl3, DolarAbrilCl3, DolarMaioCl3, DolarJunhoCl3, DolarJulhoCl3, DolarAgostoCl3, DolarSetembroCl3, DolarOutubroCl3, DolarNovembroCl3, DolarDezembroCl3 ],
fill: false,
borderColor: '#11D5EB',
backgroundColor: 'rgba(17,213,235,.5)',
}


],


})
setGrafico(true);
  }
  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Países - Faturamento em Ano</div>
        <div>
            <p>
                Tipo:
                <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                <option value="">Selecione</option>
                <option value="um">Um País</option>
                <option value="dois">Dois Países</option>
                <option value="tres">Três Países</option>
            </select>
            </p>
        </div>
        {tipo === 'um' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select defaultValue={'2025'} onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'dois' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'tres' &&
          
            <div className='tipo'>
              <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um País:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>
        }
       
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

      </>     
    }
</div>
  )
}

export default PaisesFaturamentoAno