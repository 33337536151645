import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function QtdFatPeriodo() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    const [grafico2, setGrafico2]=useState(false)
    const [grafico3, setGrafico3]=useState(false)
    const [grafico4, setGrafico4]=useState(false)
    const [grafico5, setGrafico5]=useState(false)

    const [tipo, setTipo]=useState('Ano')

    const [ano1, setAno1]=useState('2025')
    const [ano2, setAno2]=useState('')
    const [ano3, setAno3]=useState('')

    const [de1, setDe1]=useState('')
    const [ate1, setAte1]=useState('')
    const [de2, setDe2]=useState('')
    const [ate2, setAte2]=useState('')
    const [de3, setDe3]=useState('')
    const [ate3, setAte3]=useState('')



    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025'];

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      return()=>{change()}
  
   },[]);



 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
    setLoading(false)
  },[projetos])


  const handleChart = ()=>{

    setGrafico(false)
    setGrafico2(false)
    setGrafico3(false)
    setGrafico4(false)
    setGrafico5(false)

        
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
  
    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
  
    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
   
    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisJaneiroL = pReaisJaneiro.length
    var pReaisJaneiroNumeros = [];
    for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
    pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
    const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisJaneiroL2 = pReaisJaneiro2.length
    var pReaisJaneiroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
    pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
    const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisJaneiro = ((pReaisJaneiroTotal1+pReaisJaneiroTotal2))

    const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarJaneiroL = pDolarJaneiro.length
    var pDolarJanrioNumeros = [];
    for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
    pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
    const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarJaneiroL2 = pDolarJaneiro2.length
    var pDolarJanrioNumeros2 = [];
    for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
    pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
    const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarJaneiro = ((pDolarJaneiroTotal1+pDolarJaneiroTotal2))

    const LJaneiro = NumJaneiro.length


    //fevereiro  
    const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisFevereiroL = pReaisFevereiro.length
    var pReaisFevereiroNumeros = [];
    for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
    pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
    const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisFevereiroL2 = pReaisFevereiro2.length
    var pReaisFevereiroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
    pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
    const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisFevereiro = ((pReaisFevereiroTotal1+pReaisFevereiroTotal2))

    const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarFevereiroL = pDolarFevereiro.length
    var pDolarFevereiroNumeros = [];
    for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
    pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
    const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarFevereiroL2 = pDolarFevereiro2.length
    var pDolarFevereiroNumeros2 = [];
    for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
    pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
    const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
    const LFevereiro = NumFevereiro.length
    
    //Marco
    const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisMarcoL = pReaisMarco.length
    var pReaisMarcoNumeros = [];
    for (var r1 = 0; r1 < pReaisMarcoL; r1++)
    pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
    const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisMarcoL2 = pReaisMarco2.length
    var pReaisMarcoNumeros2 = [];
    for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
    pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
    const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)

    const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarMarcoL = pDolarMarco.length
    var pDolarMarcoNumeros = [];
    for (var d1 = 0; d1 < pDolarMarcoL; d1++)
    pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
    const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarMarcoL2 = pDolarMarco2.length
    var pDolarMarcoNumeros2 = [];
    for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
    pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
    const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
    const LMarco = NumMarco.length


    ///Abril
    const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisAbrilL = pReaisAbril.length
    var pReaisAbrilNumeros = [];
    for (var r1 = 0; r1 < pReaisAbrilL; r1++)
    pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
    const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisAbrilL2 = pReaisAbril2.length
    var pReaisAbrilNumeros2 = [];
    for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
    pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
    const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)

    const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarAbrilL = pDolarAbril.length
    var pDolarAbrilNumeros = [];
    for (var d1 = 0; d1 < pDolarAbrilL; d1++)
    pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
    const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarAbrilL2 = pDolarAbril2.length
    var pDolarAbrilNumeros2 = [];
    for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
    pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
    const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
    const LAbril= NumAbril.length

    //Maio
    const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisMaioL = pReaisMaio.length
    var pReaisMaioNumeros = [];
    for (var r1 = 0; r1 < pReaisMaioL; r1++)
    pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
    const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisMaioL2 = pReaisMaio2.length
    var pReaisMaioNumeros2 = [];
    for (var r2 = 0; r2 < pReaisMaioL2; r2++)
    pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
    const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)

    const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarMaioL = pDolarMaio.length
    var pDolarMaioNumeros = [];
    for (var d1 = 0; d1 < pDolarMaioL; d1++)
    pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
    const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarMaioL2 = pDolarMaio2.length
    var pDolarMaioNumeros2 = [];
    for (var d2 = 0; d2 < pDolarMaioL2; d2++)
    pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
    const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
    const LMaio= NumMaio.length

    //Junho
    const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisJunhoL = pReaisJunho.length
    var pReaisJunhoNumeros = [];
    for (var r1 = 0; r1 < pReaisJunhoL; r1++)
    pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
    const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisJunhoL2 = pReaisJunho2.length
    var pReaisJunhoNumeros2 = [];
    for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
    pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
    const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)

    const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarJunhoL = pDolarJunho.length
    var pDolarJunhoNumeros = [];
    for (var d1 = 0; d1 < pDolarJunhoL; d1++)
    pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
    const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarJunhoL2 = pDolarJunho2.length
    var pDolarJunhoNumeros2 = [];
    for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
    pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
    const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
    const LJunho= NumJunho.length
    
    //Julho
    const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisJulhoL = pReaisJulho.length
    var pReaisJulhoNumeros = [];
    for (var r1 = 0; r1 < pReaisJulhoL; r1++)
    pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
    const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisJulhoL2 = pReaisJulho2.length
    var pReaisJulhoNumeros2 = [];
    for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
    pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
    const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)

    const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarJulhoL = pDolarJulho.length
    var pDolaroJulhoNumeros = [];
    for (var d1 = 0; d1 < pDolarJulhoL; d1++)
    pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
    const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarJulhoL2 = pDolarJulho2.length
    var pDolaroJulhoNumeros2 = [];
    for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
    pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
    const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
    const LJulho= NumJulho.length

    //Agosto
    const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisAgostoL = pReaisAgosto.length
    var pReaisAgostoNumeros = [];
    for (var r1 = 0; r1 < pReaisAgostoL; r1++)
    pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
    const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisAgostoL2 = pReaisAgosto2.length
    var pReaisAgostoNumeros2 = [];
    for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
    pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
    const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)

    const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarAgostoL = pDolarAgosto.length
    var pDolarAgostoNumeros = [];
    for (var d1 = 0; d1 < pDolarAgostoL; d1++)
    pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
    const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarAgostoL2 = pDolarAgosto2.length
    var pDolarAgostoNumeros2 = [];
    for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
    pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
    const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
    const LAgosto= NumAgosto.length
    
    //Setembro
    const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisSetembroL = pReaisSetembro.length
    var pReaisSetembroNumeros = [];
    for (var r1 = 0; r1 < pReaisSetembroL; r1++)
    pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
    const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisSetembroL2 = pReaisSetembro2.length
    var pReaisSetembroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
    pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
    const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
    

    const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarSetembroL = pDolarSetembro.length
    var pDolarSetembroNumeros = [];
    for (var d1 = 0; d1 < pDolarSetembroL; d1++)
    pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
    const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarSetembroL2 = pDolarSetembro2.length
    var pDolarSetembroNumeros2 = [];
    for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
    pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
    const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
    const LSetembro= NumSetembro.length
    
    //Outubro
    const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisOutubroL = pReaisOutubro.length
    var pReaisOutubroNumeros = [];
    for (var r1 = 0; r1 < pReaisOutubroL; r1++)
    pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
    const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisOutubroL2 = pReaisOutubro2.length
    var pReaisOutubroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
    pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
    const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)

    const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarOutubroL = pDolarOutubro.length
    var pDolarOutubroNumeros = [];
    for (var d1 = 0; d1 < pDolarOutubroL; d1++)
    pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
    const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarOutubroL2 = pDolarOutubro2.length
    var pDolarOutubroNumeros2 = [];
    for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
    pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
    const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
    const LOutubro= NumOutubro.length

    //Novembro
    const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisNovembroL = pReaisNovembro.length
    var pReaisNovembroNumeros = [];
    for (var r1 = 0; r1 < pReaisNovembroL; r1++)
    pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
    const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisNovembroL2 = pReaisNovembro2.length
    var pReaisNovembroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
    pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
    const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)

    const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarNovembroL = pDolarNovembro.length
    var pDolarNovembroNumeros = [];
    for (var d1 = 0; d1 < pDolarNovembroL; d1++)
    pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
    const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarNovembroL2 = pDolarNovembro2.length
    var pDolarNovembroNumeros2 = [];
    for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
    pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
    const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    const LNovembro= NumNovembro.length

    //Dezembro
    const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pReaisDezembroL = pReaisDezembro.length
    var pReaisDezembroNumeros = [];
    for (var r1 = 0; r1 < pReaisDezembroL; r1++)
    pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
    const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pReaisDezembroL2 = pReaisDezembro2.length
    var pReaisDezembroNumeros2 = [];
    for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
    pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
    const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)

    const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pDolarDezembroL = pDolarDezembro.length
    var pDolarDezembroNumeros = [];
    for (var d1 = 0; d1 < pDolarDezembroL; d1++)
    pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
    const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pDolarDezembroL2 = pDolarDezembro2.length
    var pDolarDezembroNumeros2 = [];
    for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
    pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
    const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
    const LDezembro= NumDezembro.length
   
       setProjetData({
           labels: meses,
           datasets:[

            {
                label:`Quantidade - ${ano1}`,
                data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
                fill: true,
                borderColor: '#3E3F3F',
                backgroundColor: 'rgba(63,63,63,.5)',
              },
            {
             label:`Dólares - ${ano1}`,
             data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
             fill: true,
             borderColor: '#315F30',
            backgroundColor: 'rgba(49,95,48,.5)',
           },

           {
            label:`Reais - ${ano1}`,
            data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
            fill: true,
            borderColor: '#FF9900',
             backgroundColor: 'rgba(255,153,0,.5)',
          },
          
           
    
          ],
          
          
         })
         setGrafico(true);
     }

     const handleChart2 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)

        const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

        const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
         
       
        // janeiro
        const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJaneiroL = pReaisJaneiro.length
        var pReaisJaneiroNumeros = [];
        for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
        pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
        const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJaneiroL2 = pReaisJaneiro2.length
        var pReaisJaneiroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
        pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
        const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJaneiro = ((pReaisJaneiroTotal1+pReaisJaneiroTotal2))
    
        const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJaneiroL = pDolarJaneiro.length
        var pDolarJanrioNumeros = [];
        for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
        pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
        const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJaneiroL2 = pDolarJaneiro2.length
        var pDolarJanrioNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
        pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
        const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJaneiro = ((pDolarJaneiroTotal1+pDolarJaneiroTotal2))
    
        const LJaneiro = NumJaneiro.length
    
    
        //fevereiro  
        const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisFevereiroL = pReaisFevereiro.length
        var pReaisFevereiroNumeros = [];
        for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
        pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
        const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisFevereiroL2 = pReaisFevereiro2.length
        var pReaisFevereiroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
        pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
        const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisFevereiro = ((pReaisFevereiroTotal1+pReaisFevereiroTotal2))
    
        const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarFevereiroL = pDolarFevereiro.length
        var pDolarFevereiroNumeros = [];
        for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
        pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
        const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarFevereiroL2 = pDolarFevereiro2.length
        var pDolarFevereiroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
        pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
        const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
        const LFevereiro = NumFevereiro.length
        
        //Marco
        const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMarcoL = pReaisMarco.length
        var pReaisMarcoNumeros = [];
        for (var r1 = 0; r1 < pReaisMarcoL; r1++)
        pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
        const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMarcoL2 = pReaisMarco2.length
        var pReaisMarcoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
        pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
        const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
    
        const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMarcoL = pDolarMarco.length
        var pDolarMarcoNumeros = [];
        for (var d1 = 0; d1 < pDolarMarcoL; d1++)
        pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
        const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMarcoL2 = pDolarMarco2.length
        var pDolarMarcoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
        pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
        const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
        const LMarco = NumMarco.length
    
    
        ///Abril
        const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAbrilL = pReaisAbril.length
        var pReaisAbrilNumeros = [];
        for (var r1 = 0; r1 < pReaisAbrilL; r1++)
        pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
        const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAbrilL2 = pReaisAbril2.length
        var pReaisAbrilNumeros2 = [];
        for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
        pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
        const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
    
        const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAbrilL = pDolarAbril.length
        var pDolarAbrilNumeros = [];
        for (var d1 = 0; d1 < pDolarAbrilL; d1++)
        pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
        const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAbrilL2 = pDolarAbril2.length
        var pDolarAbrilNumeros2 = [];
        for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
        pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
        const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
        const LAbril= NumAbril.length
    
        //Maio
        const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMaioL = pReaisMaio.length
        var pReaisMaioNumeros = [];
        for (var r1 = 0; r1 < pReaisMaioL; r1++)
        pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
        const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMaioL2 = pReaisMaio2.length
        var pReaisMaioNumeros2 = [];
        for (var r2 = 0; r2 < pReaisMaioL2; r2++)
        pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
        const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
    
        const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMaioL = pDolarMaio.length
        var pDolarMaioNumeros = [];
        for (var d1 = 0; d1 < pDolarMaioL; d1++)
        pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
        const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMaioL2 = pDolarMaio2.length
        var pDolarMaioNumeros2 = [];
        for (var d2 = 0; d2 < pDolarMaioL2; d2++)
        pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
        const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
        const LMaio= NumMaio.length
    
        //Junho
        const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJunhoL = pReaisJunho.length
        var pReaisJunhoNumeros = [];
        for (var r1 = 0; r1 < pReaisJunhoL; r1++)
        pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
        const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJunhoL2 = pReaisJunho2.length
        var pReaisJunhoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
        pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
        const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
    
        const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJunhoL = pDolarJunho.length
        var pDolarJunhoNumeros = [];
        for (var d1 = 0; d1 < pDolarJunhoL; d1++)
        pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
        const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJunhoL2 = pDolarJunho2.length
        var pDolarJunhoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
        pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
        const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
        const LJunho= NumJunho.length
        
        //Julho
        const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJulhoL = pReaisJulho.length
        var pReaisJulhoNumeros = [];
        for (var r1 = 0; r1 < pReaisJulhoL; r1++)
        pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
        const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJulhoL2 = pReaisJulho2.length
        var pReaisJulhoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
        pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
        const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
    
        const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJulhoL = pDolarJulho.length
        var pDolaroJulhoNumeros = [];
        for (var d1 = 0; d1 < pDolarJulhoL; d1++)
        pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
        const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJulhoL2 = pDolarJulho2.length
        var pDolaroJulhoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
        pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
        const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
        const LJulho= NumJulho.length
    
        //Agosto
        const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAgostoL = pReaisAgosto.length
        var pReaisAgostoNumeros = [];
        for (var r1 = 0; r1 < pReaisAgostoL; r1++)
        pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
        const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAgostoL2 = pReaisAgosto2.length
        var pReaisAgostoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
        pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
        const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
    
        const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAgostoL = pDolarAgosto.length
        var pDolarAgostoNumeros = [];
        for (var d1 = 0; d1 < pDolarAgostoL; d1++)
        pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
        const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAgostoL2 = pDolarAgosto2.length
        var pDolarAgostoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
        pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
        const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
        const LAgosto= NumAgosto.length
        
        //Setembro
        const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisSetembroL = pReaisSetembro.length
        var pReaisSetembroNumeros = [];
        for (var r1 = 0; r1 < pReaisSetembroL; r1++)
        pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
        const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisSetembroL2 = pReaisSetembro2.length
        var pReaisSetembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
        pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
        const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
        
    
        const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarSetembroL = pDolarSetembro.length
        var pDolarSetembroNumeros = [];
        for (var d1 = 0; d1 < pDolarSetembroL; d1++)
        pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
        const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarSetembroL2 = pDolarSetembro2.length
        var pDolarSetembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
        pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
        const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
        const LSetembro= NumSetembro.length
        
        //Outubro
        const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisOutubroL = pReaisOutubro.length
        var pReaisOutubroNumeros = [];
        for (var r1 = 0; r1 < pReaisOutubroL; r1++)
        pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
        const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisOutubroL2 = pReaisOutubro2.length
        var pReaisOutubroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
        pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
        const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
    
        const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarOutubroL = pDolarOutubro.length
        var pDolarOutubroNumeros = [];
        for (var d1 = 0; d1 < pDolarOutubroL; d1++)
        pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
        const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarOutubroL2 = pDolarOutubro2.length
        var pDolarOutubroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
        pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
        const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
        const LOutubro= NumOutubro.length
    
        //Novembro
        const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisNovembroL = pReaisNovembro.length
        var pReaisNovembroNumeros = [];
        for (var r1 = 0; r1 < pReaisNovembroL; r1++)
        pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
        const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisNovembroL2 = pReaisNovembro2.length
        var pReaisNovembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
        pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
        const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
    
        const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarNovembroL = pDolarNovembro.length
        var pDolarNovembroNumeros = [];
        for (var d1 = 0; d1 < pDolarNovembroL; d1++)
        pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
        const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarNovembroL2 = pDolarNovembro2.length
        var pDolarNovembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
        pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
        const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
        const LNovembro= NumNovembro.length
    
        //Dezembro
        const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisDezembroL = pReaisDezembro.length
        var pReaisDezembroNumeros = [];
        for (var r1 = 0; r1 < pReaisDezembroL; r1++)
        pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
        const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisDezembroL2 = pReaisDezembro2.length
        var pReaisDezembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
        pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
        const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
    
        const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarDezembroL = pDolarDezembro.length
        var pDolarDezembroNumeros = [];
        for (var d1 = 0; d1 < pDolarDezembroL; d1++)
        pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
        const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarDezembroL2 = pDolarDezembro2.length
        var pDolarDezembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
        pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
        const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
        const LDezembro= NumDezembro.length

        //ano2
        const NumJaneiro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-01-01` && val.data.projStartDate <= `${ano2}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-02-01` && val.data.projStartDate <= `${ano2}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumMarco2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-03-01` && val.data.projStartDate <= `${ano2}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumAbril2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-04-01` && val.data.projStartDate <= `${ano2}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumMaio2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-05-01` && val.data.projStartDate <= `${ano2}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJunho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-06-01` && val.data.projStartDate <= `${ano2}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJulho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-07-01` && val.data.projStartDate <= `${ano2}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumAgosto2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-08-01` && val.data.projStartDate <= `${ano2}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumSetembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-09-01` && val.data.projStartDate <= `${ano2}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumOutubro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-10-01` && val.data.projStartDate <= `${ano2}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumNovembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-11-01` && val.data.projStartDate <= `${ano2}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumDezembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-12-01` && val.data.projStartDate <= `${ano2}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        // janeiro
        const pReaisJaneiro12 = NumJaneiro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJaneiroL12 = pReaisJaneiro12.length
        var pReaisJaneiroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJaneiroL12; r1++)
        pReaisJaneiroNumeros12.push(Number(pReaisJaneiro12[r1]))
        const pReaisJaneiroTotal12 = pReaisJaneiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJaneiro22 = NumJaneiro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJaneiroL22 = pReaisJaneiro22.length
        var pReaisJaneiroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJaneiroL22; r2++)
        pReaisJaneiroNumeros22.push(Number(pReaisJaneiro22[r2]))
        const pReaisJaneiroTotal22 = pReaisJaneiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJaneiro2 = (pReaisJaneiroTotal12+pReaisJaneiroTotal22)
    
        const pDolarJaneiro12 = NumJaneiro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJaneiroL12 = pDolarJaneiro12.length
        var pDolarJanrioNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJaneiroL12; d1++)
        pDolarJanrioNumeros12.push(Number(pDolarJaneiro12[d1]))
        const pDolarJaneiroTotal12 = pDolarJanrioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJaneiro22 = NumJaneiro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJaneiroL22 = pDolarJaneiro22.length
        var pDolarJanrioNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJaneiroL22; d2++)
        pDolarJanrioNumeros22.push(Number(pDolarJaneiro22[d2]))
        const pDolarJaneiroTotal22 = pDolarJanrioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJaneiro2 = (pDolarJaneiroTotal12+pDolarJaneiroTotal22)

        const LJaneiro2 = NumJaneiro2.length
    
    
        //fevereiro
        const pReaisFevereiro12 = NumFevereiro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisFevereiroL12 = pReaisFevereiro12.length
        var pReaisFevereiroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisFevereiroL12; r1++)
        pReaisFevereiroNumeros12.push(Number(pReaisFevereiro12[r1]))
        const pReaisFevereiroTotal12 = pReaisFevereiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisFevereiro22 = NumFevereiro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisFevereiroL22 = pReaisFevereiro22.length
        var pReaisFevereiroNumeros22= [];
        for (var r2 = 0; r2 < pReaisFevereiroL22; r2++)
        pReaisFevereiroNumeros22.push(Number(pReaisFevereiro22[r2]))
        const pReaisFevereiroTotal22 = pReaisFevereiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisFevereiro2 = (pReaisFevereiroTotal12+pReaisFevereiroTotal22)
    
        const pDolarFevereiro12 = NumFevereiro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarFevereiroL12 = pDolarFevereiro12.length
        var pDolarFevereiroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarFevereiroL12; d1++)
        pDolarFevereiroNumeros12.push(Number(pDolarFevereiro12[d1]))
        const pDolarFevereiroTotal12 = pDolarFevereiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarFevereiro22 = NumFevereiro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarFevereiroL22 = pDolarFevereiro22.length
        var pDolarFevereiroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarFevereiroL22; d2++)
        pDolarFevereiroNumeros22.push(Number(pDolarFevereiro22[d2]))
        const pDolarFevereiroTotal22 = pDolarFevereiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarFevereiro2 = (pDolarFevereiroTotal12+pDolarFevereiroTotal22)

        const LFevereiro2 = NumFevereiro2.length
        
        //Marco
        const pReaisMarco12 = NumMarco2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMarcoL12 = pReaisMarco12.length
        var pReaisMarcoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisMarcoL12; r1++)
        pReaisMarcoNumeros12.push(Number(pReaisMarco12[r1]))
        const pReaisMarcoTotal12 = pReaisMarcoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMarco22 = NumMarco2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMarcoL22 = pReaisMarco22.length
        var pReaisMarcoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisMarcoL22; r2++)
        pReaisMarcoNumeros22.push(Number(pReaisMarco22[r2]))
        const pReaisMarcoTotal22 = pReaisMarcoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMarco2 = (pReaisMarcoTotal12+pReaisMarcoTotal22)
    
        const pDolarMarco12 = NumMarco2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMarcoL12 = pDolarMarco12.length
        var pDolarMarcoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarMarcoL12; d1++)
        pDolarMarcoNumeros12.push(Number(pDolarMarco12[d1]))
        const pDolarMarcoTotal12 = pDolarMarcoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMarco22 = NumMarco2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMarcoL22 = pDolarMarco22.length
        var pDolarMarcoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarMarcoL22; d2++)
        pDolarMarcoNumeros22.push(Number(pDolarMarco22[d2]))
        const pDolarMarcoTotal22 = pDolarMarcoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMarco2 = (pDolarMarcoTotal12+pDolarMarcoTotal22)

        const LMarco2 = NumMarco2.length
    
    
        ///Abril
        const pReaisAbril12 = NumAbril2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAbrilL12 = pReaisAbril12.length
        var pReaisAbrilNumeros12 = [];
        for (var r1 = 0; r1 < pReaisAbrilL12; r1++)
        pReaisAbrilNumeros12.push(Number(pReaisAbril12[r1]))
        const pReaisAbrilTotal12 = pReaisAbrilNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAbril22 = NumAbril2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAbrilL22 = pReaisAbril22.length
        var pReaisAbrilNumeros22 = [];
        for (var r2 = 0; r2 < pReaisAbrilL22; r2++)
        pReaisAbrilNumeros22.push(Number(pReaisAbril22[r2]))
        const pReaisAbrilTotal22 = pReaisAbrilNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAbril2 = (pReaisAbrilTotal12+pReaisAbrilTotal22)
    
        const pDolarAbril12 = NumAbril2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAbrilL12 = pDolarAbril12.length
        var pDolarAbrilNumeros12 = [];
        for (var d1 = 0; d1 < pDolarAbrilL12; d1++)
        pDolarAbrilNumeros12.push(Number(pDolarAbril12[d1]))
        const pDolarAbrilTotal12 = pDolarAbrilNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAbril22 = NumAbril2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAbrilL22 = pDolarAbril22.length
        var pDolarAbrilNumeros22 = [];
        for (var d2 = 0; d2 < pDolarAbrilL22; d2++)
        pDolarAbrilNumeros22.push(Number(pDolarAbril22[d2]))
        const pDolarAbrilTotal22 = pDolarAbrilNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAbril2 = (pDolarAbrilTotal12+pDolarAbrilTotal22)
        const LAbril2= NumAbril2.length
    
        //Maio
        const pReaisMaio12 = NumMaio2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMaioL12 = pReaisMaio12.length
        var pReaisMaioNumeros12 = [];
        for (var r1 = 0; r1 < pReaisMaioL12; r1++)
        pReaisMaioNumeros12.push(Number(pReaisMaio12[r1]))
        const pReaisMaioTotal12 = pReaisMaioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMaio22 = NumMaio2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMaioL22 = pReaisMaio22.length
        var pReaisMaioNumeros22 = [];
        for (var r2 = 0; r2 < pReaisMaioL22; r2++)
        pReaisMaioNumeros22.push(Number(pReaisMaio22[r2]))
        const pReaisMaioTotal22 = pReaisMaioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMaio2 = (pReaisMaioTotal12+pReaisMaioTotal22)
    
        const pDolarMaio12 = NumMaio2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMaioL12 = pDolarMaio12.length
        var pDolarMaioNumeros12 = [];
        for (var d1 = 0; d1 < pDolarMaioL12; d1++)
        pDolarMaioNumeros12.push(Number(pDolarMaio12[d1]))
        const pDolarMaioTotal12 = pDolarMaioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMaio22 = NumMaio2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMaioL22 = pDolarMaio22.length
        var pDolarMaioNumeros22 = [];
        for (var d2 = 0; d2 < pDolarMaioL22; d2++)
        pDolarMaioNumeros22.push(Number(pDolarMaio22[d2]))
        const pDolarMaioTotal22 = pDolarMaioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMaio2 = (pDolarMaioTotal12+pDolarMaioTotal22)

        const LMaio2= NumMaio2.length
    
        //Junho
        const pReaisJunho12 = NumJunho2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJunhoL12 = pReaisJunho12.length
        var pReaisJunhoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJunhoL12; r1++)
        pReaisJunhoNumeros12.push(Number(pReaisJunho12[r1]))
        const pReaisJunhoTotal12 = pReaisJunhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJunho22 = NumJunho2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJunhoL22 = pReaisJunho22.length
        var pReaisJunhoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJunhoL22; r2++)
        pReaisJunhoNumeros22.push(Number(pReaisJunho22[r2]))
        const pReaisJunhoTotal22 = pReaisJunhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJunho2 = (pReaisJunhoTotal12+pReaisJunhoTotal22)
    
        const pDolarJunho12 = NumJunho2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJunhoL12 = pDolarJunho12.length
        var pDolarJunhoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJunhoL12; d1++)
        pDolarJunhoNumeros12.push(Number(pDolarJunho12[d1]))
        const pDolarJunhoTotal12 = pDolarJunhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJunho22 = NumJunho2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJunhoL22 = pDolarJunho22.length
        var pDolarJunhoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJunhoL22; d2++)
        pDolarJunhoNumeros22.push(Number(pDolarJunho22[d2]))
        const pDolarJunhoTotal22 = pDolarJunhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJunho2 = (pDolarJunhoTotal12+pDolarJunhoTotal22)
        const LJunho2= NumJunho2.length
        
        //Julho
        const pReaisJulho12 = NumJulho2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJulhoL12 = pReaisJulho12.length
        var pReaisJulhoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJulhoL12; r1++)
        pReaisJulhoNumeros12.push(Number(pReaisJulho12[r1]))
        const pReaisJulhoTotal12 = pReaisJulhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJulho22 = NumJulho2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJulhoL22 = pReaisJulho22.length
        var pReaisJulhoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJulhoL22; r2++)
        pReaisJulhoNumeros22.push(Number(pReaisJulho22[r2]))
        const pReaisJulhoTotal22 = pReaisJulhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJulho2 = (pReaisJulhoTotal12+pReaisJulhoTotal22)
    
        const pDolarJulho12 = NumJulho2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJulhoL12 = pDolarJulho12.length
        var pDolaroJulhoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJulhoL12; d1++)
        pDolaroJulhoNumeros12.push(Number(pDolarJulho12[d1]))
        const pDolarJulhoTotal12 = pDolaroJulhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJulho22 = NumJulho2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJulhoL22 = pDolarJulho22.length
        var pDolaroJulhoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJulhoL22; d2++)
        pDolaroJulhoNumeros22.push(Number(pDolarJulho22[d2]))
        const pDolarJulhoTotal22 = pDolaroJulhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJulho2 = (pDolarJulhoTotal12+pDolarJulhoTotal22)

        const LJulho2= NumJulho2.length
        
        //Agosto
        const pReaisAgosto12 = NumAgosto2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAgostoL12 = pReaisAgosto12.length
        var pReaisAgostoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisAgostoL12; r1++)
        pReaisAgostoNumeros12.push(Number(pReaisAgosto12[r1]))
        const pReaisAgostoTotal12 = pReaisAgostoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAgosto22 = NumAgosto2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAgostoL22 = pReaisAgosto22.length
        var pReaisAgostoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisAgostoL22; r2++)
        pReaisAgostoNumeros22.push(Number(pReaisAgosto22[r2]))
        const pReaisAgostoTotal22 = pReaisAgostoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAgosto2 = (pReaisAgostoTotal12+pReaisAgostoTotal22)
    
        const pDolarAgosto12 = NumAgosto2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAgostoL12 = pDolarAgosto12.length
        var pDolarAgostoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarAgostoL12; d1++)
        pDolarAgostoNumeros12.push(Number(pDolarAgosto12[d1]))
        const pDolarAgostoTotal12 = pDolarAgostoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAgosto22 = NumAgosto2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAgostoL22 = pDolarAgosto22.length
        var pDolarAgostoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarAgostoL22; d2++)
        pDolarAgostoNumeros22.push(Number(pDolarAgosto22[d2]))
        const pDolarAgostoTotal22 = pDolarAgostoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAgosto2 = (pDolarAgostoTotal12+pDolarAgostoTotal22)

        const LAgosto2= NumAgosto2.length
        
        //Setembro
        const pReaisSetembro12 = NumSetembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisSetembroL12 = pReaisSetembro12.length
        var pReaisSetembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisSetembroL12; r1++)
        pReaisSetembroNumeros12.push(Number(pReaisSetembro12[r1]))
        const pReaisSetembroTotal12 = pReaisSetembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisSetembro22 = NumSetembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisSetembroL22 = pReaisSetembro22.length
        var pReaisSetembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisSetembroL22; r2++)
        pReaisSetembroNumeros22.push(Number(pReaisSetembro22[r2]))
        const pReaisSetembroTotal22 = pReaisSetembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisSetembro2= (pReaisSetembroTotal12+pReaisSetembroTotal22)
    
        const pDolarSetembro12 = NumSetembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarSetembroL12 = pDolarSetembro12.length
        var pDolarSetembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarSetembroL12; d1++)
        pDolarSetembroNumeros12.push(Number(pDolarSetembro12[d1]))
        const pDolarSetembroTotal12 = pDolarSetembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarSetembro22 = NumSetembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarSetembroL22 = pDolarSetembro22.length
        var pDolarSetembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarSetembroL22; d2++)
        pDolarSetembroNumeros22.push(Number(pDolarSetembro22[d2]))
        const pDolarSetembroTotal22 = pDolarSetembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarSetembro2 = (pDolarSetembroTotal12+pDolarSetembroTotal22)

        const LSetembro2= NumSetembro2.length
        
        //Outubro
        const pReaisOutubro12 = NumOutubro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisOutubroL12 = pReaisOutubro12.length
        var pReaisOutubroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisOutubroL12; r1++)
        pReaisOutubroNumeros12.push(Number(pReaisOutubro12[r1]))
        const pReaisOutubroTotal12 = pReaisOutubroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisOutubro22 = NumOutubro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisOutubroL22 = pReaisOutubro22.length
        var pReaisOutubroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisOutubroL22; r2++)
        pReaisOutubroNumeros22.push(Number(pReaisOutubro22[r2]))
        const pReaisOutubroTotal22 = pReaisOutubroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisOutubro2 = (pReaisOutubroTotal12+pReaisOutubroTotal22)
    
        const pDolarOutubro12 = NumOutubro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarOutubroL12 = pDolarOutubro12.length
        var pDolarOutubroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarOutubroL12; d1++)
        pDolarOutubroNumeros12.push(Number(pDolarOutubro12[d1]))
        const pDolarOutubroTotal12 = pDolarOutubroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarOutubro22 = NumOutubro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarOutubroL22 = pDolarOutubro22.length
        var pDolarOutubroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarOutubroL22; d2++)
        pDolarOutubroNumeros22.push(Number(pDolarOutubro22[d2]))
        const pDolarOutubroTotal22 = pDolarOutubroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarOutubro2 = (pDolarOutubroTotal12+pDolarOutubroTotal22)

        const LOutubro2= NumOutubro2.length
        
        //Novembro
        const pReaisNovembro12 = NumNovembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisNovembroL12 = pReaisNovembro12.length
        var pReaisNovembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisNovembroL12; r1++)
        pReaisNovembroNumeros12.push(Number(pReaisNovembro12[r1]))
        const pReaisNovembroTotal12 = pReaisNovembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisNovembro22 = NumNovembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisNovembroL22 = pReaisNovembro22.length
        var pReaisNovembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisNovembroL22; r2++)
        pReaisNovembroNumeros22.push(Number(pReaisNovembro22[r2]))
        const pReaisNovembroTotal22 = pReaisNovembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisNovembro2 = (pReaisNovembroTotal12+pReaisNovembroTotal22)
    
        const pDolarNovembro12 = NumNovembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarNovembroL12 = pDolarNovembro12.length
        var pDolarNovembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarNovembroL12; d1++)
        pDolarNovembroNumeros12.push(Number(pDolarNovembro12[d1]))
        const pDolarNovembroTotal12 = pDolarNovembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarNovembro22 = NumNovembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarNovembroL22 = pDolarNovembro22.length
        var pDolarNovembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarNovembroL22; d2++)
        pDolarNovembroNumeros22.push(Number(pDolarNovembro22[d2]))
        const pDolarNovembroTotal22 = pDolarNovembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarNovembro2 = (pDolarNovembroTotal12+pDolarNovembroTotal22)

        const LNovembro2= NumNovembro2.length
        
        //Dezembro
        const pReaisDezembro12 = NumDezembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisDezembroL12 = pReaisDezembro12.length
        var pReaisDezembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisDezembroL12; r1++)
        pReaisDezembroNumeros12.push(Number(pReaisDezembro12[r1]))
        const pReaisDezembroTotal12 = pReaisDezembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisDezembro22 = NumDezembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisDezembroL22 = pReaisDezembro22.length
        var pReaisDezembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisDezembroL22; r2++)
        pReaisDezembroNumeros22.push(Number(pReaisDezembro22[r2]))
        const pReaisDezembroTotal22 = pReaisDezembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisDezembro2 = (pReaisDezembroTotal12+pReaisDezembroTotal22)
    
        const pDolarDezembro12 = NumDezembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarDezembroL12 = pDolarDezembro12.length
        var pDolarDezembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarDezembroL12; d1++)
        pDolarDezembroNumeros12.push(Number(pDolarDezembro12[d1]))
        const pDolarDezembroTotal12 = pDolarDezembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarDezembro22 = NumDezembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarDezembroL22 = pDolarDezembro22.length
        var pDolarDezembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarDezembroL22; d2++)
        pDolarDezembroNumeros22.push(Number(pDolarDezembro22[d2]))
        const pDolarDezembroTotal22 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarDezembro2 = (pDolarDezembroTotal12+pDolarDezembroTotal22)

        const LDezembro2= NumDezembro2.length
    
       
           setProjetData({
               labels: meses,
               datasets:[
                {
                    label:`Quantidade - ${ano1}`,
                    data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
                    fill: false,
                    borderColor: '#3E3F3F',
                    backgroundColor: 'rgba(63,63,63,.5)',
                  },
                  {
                    label:`Quantidade - ${ano2}`,
                    data: [LJaneiro2, LFevereiro2, LMarco2, LAbril2, LMaio2, LJunho2, LJulho2, LAgosto2, LSetembro2, LOutubro2, LNovembro2, LDezembro2 ],
                    fill: false,
                    borderColor: '#545D5E',
                    backgroundColor: 'rgba(84,93,94,.5)',
                  },
                {
                 label:`Dólares - ${ano1}`,
                 data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
                 fill: false,
                 borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
               },
               {
                label:`Dólares - ${ano2}`,
                data: [DolarJaneiro2, DolarFevereiro2, DolarMarco2, DolarAbril2, DolarMaio2, DolarJunho2, DolarJulho2, DolarAgosto2, DolarSetembro2, DolarOutubro2, DolarNovembro2, DolarDezembro2 ],
                fill: false,
                borderColor: '#42ADA7',
               backgroundColor: 'rgba(66,173,167,.5)',
              },
    
               {
                label:`Reais - ${ano1}`,
                data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
                fill: false,
                borderColor: '#FF9900',
                 backgroundColor: 'rgba(255,153,0,.5)',
              },
               
              {
                label:`Reais - ${ano2}`,
                data: [ReaisJaneiro2, ReaisFevereiro2, ReaisMarco2, ReaisAbril2, ReaisMaio2, ReaisJunho2, ReaisJulho2, ReaisAgosto2, ReaisSetembro2, ReaisOutubro2, ReaisNovembro2, ReaisDezembro2 ],
                fill: false,
                borderColor: '#FF876C',
                 backgroundColor: 'rgba(255,135,108,.5)',
              },
               
        
              ],
              
              
             })
                
       
        setGrafico2(true);
    }

    const handleChart3 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
                

        const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

       
        const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
         
       
        // janeiro
        const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJaneiroL = pReaisJaneiro.length
        var pReaisJaneiroNumeros = [];
        for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
        pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
        const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJaneiroL2 = pReaisJaneiro2.length
        var pReaisJaneiroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
        pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
        const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJaneiro = ((pReaisJaneiroTotal1+pReaisJaneiroTotal2))
    
        const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJaneiroL = pDolarJaneiro.length
        var pDolarJanrioNumeros = [];
        for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
        pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
        const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJaneiroL2 = pDolarJaneiro2.length
        var pDolarJanrioNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
        pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
        const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJaneiro = ((pDolarJaneiroTotal1+pDolarJaneiroTotal2))
    
        const LJaneiro = NumJaneiro.length
    
    
        //fevereiro  
        const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisFevereiroL = pReaisFevereiro.length
        var pReaisFevereiroNumeros = [];
        for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
        pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
        const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisFevereiroL2 = pReaisFevereiro2.length
        var pReaisFevereiroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
        pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
        const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisFevereiro = ((pReaisFevereiroTotal1+pReaisFevereiroTotal2))
    
        const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarFevereiroL = pDolarFevereiro.length
        var pDolarFevereiroNumeros = [];
        for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
        pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
        const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarFevereiroL2 = pDolarFevereiro2.length
        var pDolarFevereiroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
        pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
        const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
        const LFevereiro = NumFevereiro.length
        
        //Marco
        const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMarcoL = pReaisMarco.length
        var pReaisMarcoNumeros = [];
        for (var r1 = 0; r1 < pReaisMarcoL; r1++)
        pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
        const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMarcoL2 = pReaisMarco2.length
        var pReaisMarcoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
        pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
        const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
    
        const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMarcoL = pDolarMarco.length
        var pDolarMarcoNumeros = [];
        for (var d1 = 0; d1 < pDolarMarcoL; d1++)
        pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
        const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMarcoL2 = pDolarMarco2.length
        var pDolarMarcoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
        pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
        const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
        const LMarco = NumMarco.length
    
    
        ///Abril
        const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAbrilL = pReaisAbril.length
        var pReaisAbrilNumeros = [];
        for (var r1 = 0; r1 < pReaisAbrilL; r1++)
        pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
        const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAbrilL2 = pReaisAbril2.length
        var pReaisAbrilNumeros2 = [];
        for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
        pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
        const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
    
        const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAbrilL = pDolarAbril.length
        var pDolarAbrilNumeros = [];
        for (var d1 = 0; d1 < pDolarAbrilL; d1++)
        pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
        const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAbrilL2 = pDolarAbril2.length
        var pDolarAbrilNumeros2 = [];
        for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
        pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
        const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
        const LAbril= NumAbril.length
    
        //Maio
        const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMaioL = pReaisMaio.length
        var pReaisMaioNumeros = [];
        for (var r1 = 0; r1 < pReaisMaioL; r1++)
        pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
        const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMaioL2 = pReaisMaio2.length
        var pReaisMaioNumeros2 = [];
        for (var r2 = 0; r2 < pReaisMaioL2; r2++)
        pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
        const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
    
        const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMaioL = pDolarMaio.length
        var pDolarMaioNumeros = [];
        for (var d1 = 0; d1 < pDolarMaioL; d1++)
        pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
        const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMaioL2 = pDolarMaio2.length
        var pDolarMaioNumeros2 = [];
        for (var d2 = 0; d2 < pDolarMaioL2; d2++)
        pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
        const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
        const LMaio= NumMaio.length
    
        //Junho
        const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJunhoL = pReaisJunho.length
        var pReaisJunhoNumeros = [];
        for (var r1 = 0; r1 < pReaisJunhoL; r1++)
        pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
        const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJunhoL2 = pReaisJunho2.length
        var pReaisJunhoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
        pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
        const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
    
        const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJunhoL = pDolarJunho.length
        var pDolarJunhoNumeros = [];
        for (var d1 = 0; d1 < pDolarJunhoL; d1++)
        pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
        const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJunhoL2 = pDolarJunho2.length
        var pDolarJunhoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
        pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
        const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
        const LJunho= NumJunho.length
        
        //Julho
        const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJulhoL = pReaisJulho.length
        var pReaisJulhoNumeros = [];
        for (var r1 = 0; r1 < pReaisJulhoL; r1++)
        pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
        const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJulhoL2 = pReaisJulho2.length
        var pReaisJulhoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
        pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
        const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
    
        const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJulhoL = pDolarJulho.length
        var pDolaroJulhoNumeros = [];
        for (var d1 = 0; d1 < pDolarJulhoL; d1++)
        pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
        const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJulhoL2 = pDolarJulho2.length
        var pDolaroJulhoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
        pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
        const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
        const LJulho= NumJulho.length
    
        //Agosto
        const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAgostoL = pReaisAgosto.length
        var pReaisAgostoNumeros = [];
        for (var r1 = 0; r1 < pReaisAgostoL; r1++)
        pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
        const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAgostoL2 = pReaisAgosto2.length
        var pReaisAgostoNumeros2 = [];
        for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
        pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
        const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
    
        const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAgostoL = pDolarAgosto.length
        var pDolarAgostoNumeros = [];
        for (var d1 = 0; d1 < pDolarAgostoL; d1++)
        pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
        const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAgostoL2 = pDolarAgosto2.length
        var pDolarAgostoNumeros2 = [];
        for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
        pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
        const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
        const LAgosto= NumAgosto.length
        
        //Setembro
        const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisSetembroL = pReaisSetembro.length
        var pReaisSetembroNumeros = [];
        for (var r1 = 0; r1 < pReaisSetembroL; r1++)
        pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
        const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisSetembroL2 = pReaisSetembro2.length
        var pReaisSetembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
        pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
        const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
        
    
        const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarSetembroL = pDolarSetembro.length
        var pDolarSetembroNumeros = [];
        for (var d1 = 0; d1 < pDolarSetembroL; d1++)
        pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
        const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarSetembroL2 = pDolarSetembro2.length
        var pDolarSetembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
        pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
        const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
        const LSetembro= NumSetembro.length
        
        //Outubro
        const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisOutubroL = pReaisOutubro.length
        var pReaisOutubroNumeros = [];
        for (var r1 = 0; r1 < pReaisOutubroL; r1++)
        pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
        const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisOutubroL2 = pReaisOutubro2.length
        var pReaisOutubroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
        pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
        const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
    
        const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarOutubroL = pDolarOutubro.length
        var pDolarOutubroNumeros = [];
        for (var d1 = 0; d1 < pDolarOutubroL; d1++)
        pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
        const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarOutubroL2 = pDolarOutubro2.length
        var pDolarOutubroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
        pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
        const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
        const LOutubro= NumOutubro.length
    
        //Novembro
        const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisNovembroL = pReaisNovembro.length
        var pReaisNovembroNumeros = [];
        for (var r1 = 0; r1 < pReaisNovembroL; r1++)
        pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
        const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisNovembroL2 = pReaisNovembro2.length
        var pReaisNovembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
        pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
        const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
    
        const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarNovembroL = pDolarNovembro.length
        var pDolarNovembroNumeros = [];
        for (var d1 = 0; d1 < pDolarNovembroL; d1++)
        pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
        const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarNovembroL2 = pDolarNovembro2.length
        var pDolarNovembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
        pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
        const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
        const LNovembro= NumNovembro.length
    
        //Dezembro
        const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisDezembroL = pReaisDezembro.length
        var pReaisDezembroNumeros = [];
        for (var r1 = 0; r1 < pReaisDezembroL; r1++)
        pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
        const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisDezembroL2 = pReaisDezembro2.length
        var pReaisDezembroNumeros2 = [];
        for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
        pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
        const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
    
        const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarDezembroL = pDolarDezembro.length
        var pDolarDezembroNumeros = [];
        for (var d1 = 0; d1 < pDolarDezembroL; d1++)
        pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
        const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarDezembroL2 = pDolarDezembro2.length
        var pDolarDezembroNumeros2 = [];
        for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
        pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
        const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
        const LDezembro= NumDezembro.length

        //ano2
        const NumJaneiro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-01-01` && val.data.projStartDate <= `${ano2}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-02-01` && val.data.projStartDate <= `${ano2}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumMarco2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-03-01` && val.data.projStartDate <= `${ano2}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumAbril2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-04-01` && val.data.projStartDate <= `${ano2}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumMaio2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-05-01` && val.data.projStartDate <= `${ano2}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJunho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-06-01` && val.data.projStartDate <= `${ano2}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJulho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-07-01` && val.data.projStartDate <= `${ano2}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumAgosto2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-08-01` && val.data.projStartDate <= `${ano2}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumSetembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-09-01` && val.data.projStartDate <= `${ano2}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumOutubro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-10-01` && val.data.projStartDate <= `${ano2}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumNovembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-11-01` && val.data.projStartDate <= `${ano2}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumDezembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-12-01` && val.data.projStartDate <= `${ano2}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        // janeiro
        const pReaisJaneiro12 = NumJaneiro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJaneiroL12 = pReaisJaneiro12.length
        var pReaisJaneiroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJaneiroL12; r1++)
        pReaisJaneiroNumeros12.push(Number(pReaisJaneiro12[r1]))
        const pReaisJaneiroTotal12 = pReaisJaneiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJaneiro22 = NumJaneiro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJaneiroL22 = pReaisJaneiro22.length
        var pReaisJaneiroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJaneiroL22; r2++)
        pReaisJaneiroNumeros22.push(Number(pReaisJaneiro22[r2]))
        const pReaisJaneiroTotal22 = pReaisJaneiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJaneiro2 = (pReaisJaneiroTotal12+pReaisJaneiroTotal22)
    
        const pDolarJaneiro12 = NumJaneiro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJaneiroL12 = pDolarJaneiro12.length
        var pDolarJanrioNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJaneiroL12; d1++)
        pDolarJanrioNumeros12.push(Number(pDolarJaneiro12[d1]))
        const pDolarJaneiroTotal12 = pDolarJanrioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJaneiro22 = NumJaneiro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJaneiroL22 = pDolarJaneiro22.length
        var pDolarJanrioNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJaneiroL22; d2++)
        pDolarJanrioNumeros22.push(Number(pDolarJaneiro22[d2]))
        const pDolarJaneiroTotal22 = pDolarJanrioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJaneiro2 = (pDolarJaneiroTotal12+pDolarJaneiroTotal22)

        const LJaneiro2 = NumJaneiro2.length
    
    
        //fevereiro
        const pReaisFevereiro12 = NumFevereiro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisFevereiroL12 = pReaisFevereiro12.length
        var pReaisFevereiroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisFevereiroL12; r1++)
        pReaisFevereiroNumeros12.push(Number(pReaisFevereiro12[r1]))
        const pReaisFevereiroTotal12 = pReaisFevereiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisFevereiro22 = NumFevereiro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisFevereiroL22 = pReaisFevereiro22.length
        var pReaisFevereiroNumeros22= [];
        for (var r2 = 0; r2 < pReaisFevereiroL22; r2++)
        pReaisFevereiroNumeros22.push(Number(pReaisFevereiro22[r2]))
        const pReaisFevereiroTotal22 = pReaisFevereiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisFevereiro2 = (pReaisFevereiroTotal12+pReaisFevereiroTotal22)
    
        const pDolarFevereiro12 = NumFevereiro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarFevereiroL12 = pDolarFevereiro12.length
        var pDolarFevereiroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarFevereiroL12; d1++)
        pDolarFevereiroNumeros12.push(Number(pDolarFevereiro12[d1]))
        const pDolarFevereiroTotal12 = pDolarFevereiroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarFevereiro22 = NumFevereiro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarFevereiroL22 = pDolarFevereiro22.length
        var pDolarFevereiroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarFevereiroL22; d2++)
        pDolarFevereiroNumeros22.push(Number(pDolarFevereiro22[d2]))
        const pDolarFevereiroTotal22 = pDolarFevereiroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarFevereiro2 = (pDolarFevereiroTotal12+pDolarFevereiroTotal22)

        const LFevereiro2 = NumFevereiro2.length
        
        //Marco
        const pReaisMarco12 = NumMarco2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMarcoL12 = pReaisMarco12.length
        var pReaisMarcoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisMarcoL12; r1++)
        pReaisMarcoNumeros12.push(Number(pReaisMarco12[r1]))
        const pReaisMarcoTotal12 = pReaisMarcoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMarco22 = NumMarco2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMarcoL22 = pReaisMarco22.length
        var pReaisMarcoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisMarcoL22; r2++)
        pReaisMarcoNumeros22.push(Number(pReaisMarco22[r2]))
        const pReaisMarcoTotal22 = pReaisMarcoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMarco2 = (pReaisMarcoTotal12+pReaisMarcoTotal22)
    
        const pDolarMarco12 = NumMarco2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMarcoL12 = pDolarMarco12.length
        var pDolarMarcoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarMarcoL12; d1++)
        pDolarMarcoNumeros12.push(Number(pDolarMarco12[d1]))
        const pDolarMarcoTotal12 = pDolarMarcoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMarco22 = NumMarco2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMarcoL22 = pDolarMarco22.length
        var pDolarMarcoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarMarcoL22; d2++)
        pDolarMarcoNumeros22.push(Number(pDolarMarco22[d2]))
        const pDolarMarcoTotal22 = pDolarMarcoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMarco2 = (pDolarMarcoTotal12+pDolarMarcoTotal22)

        const LMarco2 = NumMarco2.length
    
    
        ///Abril
        const pReaisAbril12 = NumAbril2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAbrilL12 = pReaisAbril12.length
        var pReaisAbrilNumeros12 = [];
        for (var r1 = 0; r1 < pReaisAbrilL12; r1++)
        pReaisAbrilNumeros12.push(Number(pReaisAbril12[r1]))
        const pReaisAbrilTotal12 = pReaisAbrilNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAbril22 = NumAbril2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAbrilL22 = pReaisAbril22.length
        var pReaisAbrilNumeros22 = [];
        for (var r2 = 0; r2 < pReaisAbrilL22; r2++)
        pReaisAbrilNumeros22.push(Number(pReaisAbril22[r2]))
        const pReaisAbrilTotal22 = pReaisAbrilNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAbril2 = (pReaisAbrilTotal12+pReaisAbrilTotal22)
    
        const pDolarAbril12 = NumAbril2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAbrilL12 = pDolarAbril12.length
        var pDolarAbrilNumeros12 = [];
        for (var d1 = 0; d1 < pDolarAbrilL12; d1++)
        pDolarAbrilNumeros12.push(Number(pDolarAbril12[d1]))
        const pDolarAbrilTotal12 = pDolarAbrilNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAbril22 = NumAbril2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAbrilL22 = pDolarAbril22.length
        var pDolarAbrilNumeros22 = [];
        for (var d2 = 0; d2 < pDolarAbrilL22; d2++)
        pDolarAbrilNumeros22.push(Number(pDolarAbril22[d2]))
        const pDolarAbrilTotal22 = pDolarAbrilNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAbril2 = (pDolarAbrilTotal12+pDolarAbrilTotal22)
        const LAbril2= NumAbril2.length
    
        //Maio
        const pReaisMaio12 = NumMaio2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMaioL12 = pReaisMaio12.length
        var pReaisMaioNumeros12 = [];
        for (var r1 = 0; r1 < pReaisMaioL12; r1++)
        pReaisMaioNumeros12.push(Number(pReaisMaio12[r1]))
        const pReaisMaioTotal12 = pReaisMaioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMaio22 = NumMaio2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMaioL22 = pReaisMaio22.length
        var pReaisMaioNumeros22 = [];
        for (var r2 = 0; r2 < pReaisMaioL22; r2++)
        pReaisMaioNumeros22.push(Number(pReaisMaio22[r2]))
        const pReaisMaioTotal22 = pReaisMaioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMaio2 = (pReaisMaioTotal12+pReaisMaioTotal22)
    
        const pDolarMaio12 = NumMaio2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMaioL12 = pDolarMaio12.length
        var pDolarMaioNumeros12 = [];
        for (var d1 = 0; d1 < pDolarMaioL12; d1++)
        pDolarMaioNumeros12.push(Number(pDolarMaio12[d1]))
        const pDolarMaioTotal12 = pDolarMaioNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMaio22 = NumMaio2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMaioL22 = pDolarMaio22.length
        var pDolarMaioNumeros22 = [];
        for (var d2 = 0; d2 < pDolarMaioL22; d2++)
        pDolarMaioNumeros22.push(Number(pDolarMaio22[d2]))
        const pDolarMaioTotal22 = pDolarMaioNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMaio2 = (pDolarMaioTotal12+pDolarMaioTotal22)

        const LMaio2= NumMaio2.length
    
        //Junho
        const pReaisJunho12 = NumJunho2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJunhoL12 = pReaisJunho12.length
        var pReaisJunhoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJunhoL12; r1++)
        pReaisJunhoNumeros12.push(Number(pReaisJunho12[r1]))
        const pReaisJunhoTotal12 = pReaisJunhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJunho22 = NumJunho2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJunhoL22 = pReaisJunho22.length
        var pReaisJunhoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJunhoL22; r2++)
        pReaisJunhoNumeros22.push(Number(pReaisJunho22[r2]))
        const pReaisJunhoTotal22 = pReaisJunhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJunho2 = (pReaisJunhoTotal12+pReaisJunhoTotal22)
    
        const pDolarJunho12 = NumJunho2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJunhoL12 = pDolarJunho12.length
        var pDolarJunhoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJunhoL12; d1++)
        pDolarJunhoNumeros12.push(Number(pDolarJunho12[d1]))
        const pDolarJunhoTotal12 = pDolarJunhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJunho22 = NumJunho2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJunhoL22 = pDolarJunho22.length
        var pDolarJunhoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJunhoL22; d2++)
        pDolarJunhoNumeros22.push(Number(pDolarJunho22[d2]))
        const pDolarJunhoTotal22 = pDolarJunhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJunho2 = (pDolarJunhoTotal12+pDolarJunhoTotal22)
        const LJunho2= NumJunho2.length
        
        //Julho
        const pReaisJulho12 = NumJulho2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJulhoL12 = pReaisJulho12.length
        var pReaisJulhoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisJulhoL12; r1++)
        pReaisJulhoNumeros12.push(Number(pReaisJulho12[r1]))
        const pReaisJulhoTotal12 = pReaisJulhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJulho22 = NumJulho2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJulhoL22 = pReaisJulho22.length
        var pReaisJulhoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisJulhoL22; r2++)
        pReaisJulhoNumeros22.push(Number(pReaisJulho22[r2]))
        const pReaisJulhoTotal22 = pReaisJulhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJulho2 = (pReaisJulhoTotal12+pReaisJulhoTotal22)
    
        const pDolarJulho12 = NumJulho2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJulhoL12 = pDolarJulho12.length
        var pDolaroJulhoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarJulhoL12; d1++)
        pDolaroJulhoNumeros12.push(Number(pDolarJulho12[d1]))
        const pDolarJulhoTotal12 = pDolaroJulhoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJulho22 = NumJulho2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJulhoL22 = pDolarJulho22.length
        var pDolaroJulhoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarJulhoL22; d2++)
        pDolaroJulhoNumeros22.push(Number(pDolarJulho22[d2]))
        const pDolarJulhoTotal22 = pDolaroJulhoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJulho2 = (pDolarJulhoTotal12+pDolarJulhoTotal22)

        const LJulho2= NumJulho2.length
        
        //Agosto
        const pReaisAgosto12 = NumAgosto2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAgostoL12 = pReaisAgosto12.length
        var pReaisAgostoNumeros12 = [];
        for (var r1 = 0; r1 < pReaisAgostoL12; r1++)
        pReaisAgostoNumeros12.push(Number(pReaisAgosto12[r1]))
        const pReaisAgostoTotal12 = pReaisAgostoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAgosto22 = NumAgosto2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAgostoL22 = pReaisAgosto22.length
        var pReaisAgostoNumeros22 = [];
        for (var r2 = 0; r2 < pReaisAgostoL22; r2++)
        pReaisAgostoNumeros22.push(Number(pReaisAgosto22[r2]))
        const pReaisAgostoTotal22 = pReaisAgostoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAgosto2 = (pReaisAgostoTotal12+pReaisAgostoTotal22)
    
        const pDolarAgosto12 = NumAgosto2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAgostoL12 = pDolarAgosto12.length
        var pDolarAgostoNumeros12 = [];
        for (var d1 = 0; d1 < pDolarAgostoL12; d1++)
        pDolarAgostoNumeros12.push(Number(pDolarAgosto12[d1]))
        const pDolarAgostoTotal12 = pDolarAgostoNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAgosto22 = NumAgosto2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAgostoL22 = pDolarAgosto22.length
        var pDolarAgostoNumeros22 = [];
        for (var d2 = 0; d2 < pDolarAgostoL22; d2++)
        pDolarAgostoNumeros22.push(Number(pDolarAgosto22[d2]))
        const pDolarAgostoTotal22 = pDolarAgostoNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAgosto2 = (pDolarAgostoTotal12+pDolarAgostoTotal22)

        const LAgosto2= NumAgosto2.length
        
        //Setembro
        const pReaisSetembro12 = NumSetembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisSetembroL12 = pReaisSetembro12.length
        var pReaisSetembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisSetembroL12; r1++)
        pReaisSetembroNumeros12.push(Number(pReaisSetembro12[r1]))
        const pReaisSetembroTotal12 = pReaisSetembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisSetembro22 = NumSetembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisSetembroL22 = pReaisSetembro22.length
        var pReaisSetembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisSetembroL22; r2++)
        pReaisSetembroNumeros22.push(Number(pReaisSetembro22[r2]))
        const pReaisSetembroTotal22 = pReaisSetembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisSetembro2= (pReaisSetembroTotal12+pReaisSetembroTotal22)
    
        const pDolarSetembro12 = NumSetembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarSetembroL12 = pDolarSetembro12.length
        var pDolarSetembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarSetembroL12; d1++)
        pDolarSetembroNumeros12.push(Number(pDolarSetembro12[d1]))
        const pDolarSetembroTotal12 = pDolarSetembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarSetembro22 = NumSetembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarSetembroL22 = pDolarSetembro22.length
        var pDolarSetembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarSetembroL22; d2++)
        pDolarSetembroNumeros22.push(Number(pDolarSetembro22[d2]))
        const pDolarSetembroTotal22 = pDolarSetembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarSetembro2 = (pDolarSetembroTotal12+pDolarSetembroTotal22)

        const LSetembro2= NumSetembro2.length
        
        //Outubro
        const pReaisOutubro12 = NumOutubro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisOutubroL12 = pReaisOutubro12.length
        var pReaisOutubroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisOutubroL12; r1++)
        pReaisOutubroNumeros12.push(Number(pReaisOutubro12[r1]))
        const pReaisOutubroTotal12 = pReaisOutubroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisOutubro22 = NumOutubro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisOutubroL22 = pReaisOutubro22.length
        var pReaisOutubroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisOutubroL22; r2++)
        pReaisOutubroNumeros22.push(Number(pReaisOutubro22[r2]))
        const pReaisOutubroTotal22 = pReaisOutubroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisOutubro2 = (pReaisOutubroTotal12+pReaisOutubroTotal22)
    
        const pDolarOutubro12 = NumOutubro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarOutubroL12 = pDolarOutubro12.length
        var pDolarOutubroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarOutubroL12; d1++)
        pDolarOutubroNumeros12.push(Number(pDolarOutubro12[d1]))
        const pDolarOutubroTotal12 = pDolarOutubroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarOutubro22 = NumOutubro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarOutubroL22 = pDolarOutubro22.length
        var pDolarOutubroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarOutubroL22; d2++)
        pDolarOutubroNumeros22.push(Number(pDolarOutubro22[d2]))
        const pDolarOutubroTotal22 = pDolarOutubroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarOutubro2 = (pDolarOutubroTotal12+pDolarOutubroTotal22)

        const LOutubro2= NumOutubro2.length
        
        //Novembro
        const pReaisNovembro12 = NumNovembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisNovembroL12 = pReaisNovembro12.length
        var pReaisNovembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisNovembroL12; r1++)
        pReaisNovembroNumeros12.push(Number(pReaisNovembro12[r1]))
        const pReaisNovembroTotal12 = pReaisNovembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisNovembro22 = NumNovembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisNovembroL22 = pReaisNovembro22.length
        var pReaisNovembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisNovembroL22; r2++)
        pReaisNovembroNumeros22.push(Number(pReaisNovembro22[r2]))
        const pReaisNovembroTotal22 = pReaisNovembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisNovembro2 = (pReaisNovembroTotal12+pReaisNovembroTotal22)
    
        const pDolarNovembro12 = NumNovembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarNovembroL12 = pDolarNovembro12.length
        var pDolarNovembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarNovembroL12; d1++)
        pDolarNovembroNumeros12.push(Number(pDolarNovembro12[d1]))
        const pDolarNovembroTotal12 = pDolarNovembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarNovembro22 = NumNovembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarNovembroL22 = pDolarNovembro22.length
        var pDolarNovembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarNovembroL22; d2++)
        pDolarNovembroNumeros22.push(Number(pDolarNovembro22[d2]))
        const pDolarNovembroTotal22 = pDolarNovembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const DolarNovembro2 = (pDolarNovembroTotal12+pDolarNovembroTotal22)

        const LNovembro2= NumNovembro2.length
        
        //Dezembro
        const pReaisDezembro12 = NumDezembro2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisDezembroL12 = pReaisDezembro12.length
        var pReaisDezembroNumeros12 = [];
        for (var r1 = 0; r1 < pReaisDezembroL12; r1++)
        pReaisDezembroNumeros12.push(Number(pReaisDezembro12[r1]))
        const pReaisDezembroTotal12 = pReaisDezembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisDezembro22 = NumDezembro2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisDezembroL22 = pReaisDezembro22.length
        var pReaisDezembroNumeros22 = [];
        for (var r2 = 0; r2 < pReaisDezembroL22; r2++)
        pReaisDezembroNumeros22.push(Number(pReaisDezembro22[r2]))
        const pReaisDezembroTotal22 = pReaisDezembroNumeros22.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisDezembro2 = (pReaisDezembroTotal12+pReaisDezembroTotal22)
    
        const pDolarDezembro12 = NumDezembro2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarDezembroL12 = pDolarDezembro12.length
        var pDolarDezembroNumeros12 = [];
        for (var d1 = 0; d1 < pDolarDezembroL12; d1++)
        pDolarDezembroNumeros12.push(Number(pDolarDezembro12[d1]))
        const pDolarDezembroTotal12 = pDolarDezembroNumeros12.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarDezembro22 = NumDezembro2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarDezembroL22 = pDolarDezembro22.length
        var pDolarDezembroNumeros22 = [];
        for (var d2 = 0; d2 < pDolarDezembroL22; d2++)
        pDolarDezembroNumeros22.push(Number(pDolarDezembro22[d2]))
        const pDolarDezembroTotal22 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
        const DolarDezembro2 = (pDolarDezembroTotal12+pDolarDezembroTotal22)

        const LDezembro2= NumDezembro2.length
    

        //Ano 3
        const NumJaneiro3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-01-01` && val.data.projStartDate <= `${ano3}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-02-01` && val.data.projStartDate <= `${ano3}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumMarco3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-03-01` && val.data.projStartDate <= `${ano3}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
          const NumAbril3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-04-01` && val.data.projStartDate <= `${ano3}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumMaio3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-05-01` && val.data.projStartDate <= `${ano3}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJunho3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-06-01` && val.data.projStartDate <= `${ano3}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumJulho3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-07-01` && val.data.projStartDate <= `${ano3}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumAgosto3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-08-01` && val.data.projStartDate <= `${ano3}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumSetembro3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-09-01` && val.data.projStartDate <= `${ano3}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumOutubro3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-10-01` && val.data.projStartDate <= `${ano3}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumNovembro3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-11-01` && val.data.projStartDate <= `${ano3}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
            
          const NumDezembro3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-12-01` && val.data.projStartDate <= `${ano3}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
   
        // janeiro
        const pReaisJaneiro13 = NumJaneiro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJaneiroL13 = pReaisJaneiro13.length
        var pReaisJaneiroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisJaneiroL13; r1++)
        pReaisJaneiroNumeros13.push(Number(pReaisJaneiro13[r1]))
        const pReaisJaneiroTotal13 = pReaisJaneiroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJaneiro33 = NumJaneiro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJaneiroL33 = pReaisJaneiro33.length
        var pReaisJaneiroNumeros33 = [];
        for (var r3 = 0; r3 < pReaisJaneiroL33; r3++)
        pReaisJaneiroNumeros33.push(Number(pReaisJaneiro33[r3]))
        const pReaisJaneiroTotal33 = pReaisJaneiroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJaneiro3 = (pReaisJaneiroTotal13+pReaisJaneiroTotal33)
    
        const pDolarJaneiro13 = NumJaneiro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJaneiroL13 = pDolarJaneiro13.length
        var pDolarJanrioNumeros13 = [];
        for (var d1 = 0; d1 < pDolarJaneiroL13; d1++)
        pDolarJanrioNumeros13.push(Number(pDolarJaneiro13[d1]))
        const pDolarJaneiroTotal13 = pDolarJanrioNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJaneiro33 = NumJaneiro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJaneiroL33 = pDolarJaneiro33.length
        var pDolarJanrioNumeros33 = [];
        for (var d3 = 0; d3 < pDolarJaneiroL33; d3++)
        pDolarJanrioNumeros33.push(Number(pDolarJaneiro33[d3]))
        const pDolarJaneiroTotal33 = pDolarJanrioNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJaneiro3 = (pDolarJaneiroTotal13+pDolarJaneiroTotal33)

        const LJaneiro3 = NumJaneiro3.length
    
    
        //fevereiro
        const pReaisFevereiro13 = NumFevereiro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisFevereiroL13 = pReaisFevereiro13.length
        var pReaisFevereiroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisFevereiroL13; r1++)
        pReaisFevereiroNumeros13.push(Number(pReaisFevereiro13[r1]))
        const pReaisFevereiroTotal13 = pReaisFevereiroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisFevereiro33 = NumFevereiro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisFevereiroL33 = pReaisFevereiro33.length
        var pReaisFevereiroNumeros33= [];
        for (var r3 = 0; r3 < pReaisFevereiroL33; r3++)
        pReaisFevereiroNumeros33.push(Number(pReaisFevereiro33[r3]))
        const pReaisFevereiroTotal33 = pReaisFevereiroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisFevereiro3 = (pReaisFevereiroTotal13+pReaisFevereiroTotal33)
    
        const pDolarFevereiro13 = NumFevereiro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarFevereiroL13 = pDolarFevereiro13.length
        var pDolarFevereiroNumeros13 = [];
        for (var d1 = 0; d1 < pDolarFevereiroL13; d1++)
        pDolarFevereiroNumeros13.push(Number(pDolarFevereiro13[d1]))
        const pDolarFevereiroTotal13 = pDolarFevereiroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarFevereiro33 = NumFevereiro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarFevereiroL33 = pDolarFevereiro33.length
        var pDolarFevereiroNumeros33 = [];
        for (var d3 = 0; d3 < pDolarFevereiroL33; d3++)
        pDolarFevereiroNumeros33.push(Number(pDolarFevereiro33[d3]))
        const pDolarFevereiroTotal33 = pDolarFevereiroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarFevereiro3 = (pDolarFevereiroTotal13+pDolarFevereiroTotal33)

        const LFevereiro3 = NumFevereiro3.length
        
        //Marco
        const pReaisMarco13 = NumMarco3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMarcoL13 = pReaisMarco13.length
        var pReaisMarcoNumeros13 = [];
        for (var r1 = 0; r1 < pReaisMarcoL13; r1++)
        pReaisMarcoNumeros13.push(Number(pReaisMarco13[r1]))
        const pReaisMarcoTotal13 = pReaisMarcoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMarco33 = NumMarco3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMarcoL33 = pReaisMarco33.length
        var pReaisMarcoNumeros33 = [];
        for (var r3 = 0; r3 < pReaisMarcoL33; r3++)
        pReaisMarcoNumeros33.push(Number(pReaisMarco33[r3]))
        const pReaisMarcoTotal33 = pReaisMarcoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMarco3 = (pReaisMarcoTotal13+pReaisMarcoTotal33)
    
        const pDolarMarco13 = NumMarco3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMarcoL13 = pDolarMarco13.length
        var pDolarMarcoNumeros13 = [];
        for (var d1 = 0; d1 < pDolarMarcoL13; d1++)
        pDolarMarcoNumeros13.push(Number(pDolarMarco13[d1]))
        const pDolarMarcoTotal13 = pDolarMarcoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMarco33 = NumMarco3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMarcoL33 = pDolarMarco33.length
        var pDolarMarcoNumeros33 = [];
        for (var d3 = 0; d3 < pDolarMarcoL33; d3++)
        pDolarMarcoNumeros33.push(Number(pDolarMarco33[d3]))
        const pDolarMarcoTotal33 = pDolarMarcoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMarco3 = (pDolarMarcoTotal13+pDolarMarcoTotal33)

        const LMarco3 = NumMarco3.length
    
    
        ///Abril
        const pReaisAbril13 = NumAbril3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAbrilL13 = pReaisAbril13.length
        var pReaisAbrilNumeros13 = [];
        for (var r1 = 0; r1 < pReaisAbrilL13; r1++)
        pReaisAbrilNumeros13.push(Number(pReaisAbril13[r1]))
        const pReaisAbrilTotal13 = pReaisAbrilNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAbril33 = NumAbril3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAbrilL33 = pReaisAbril33.length
        var pReaisAbrilNumeros33 = [];
        for (var r3 = 0; r3 < pReaisAbrilL33; r3++)
        pReaisAbrilNumeros33.push(Number(pReaisAbril33[r3]))
        const pReaisAbrilTotal33 = pReaisAbrilNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAbril3 = (pReaisAbrilTotal13+pReaisAbrilTotal33)
    
        const pDolarAbril13 = NumAbril3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAbrilL13 = pDolarAbril13.length
        var pDolarAbrilNumeros13 = [];
        for (var d1 = 0; d1 < pDolarAbrilL13; d1++)
        pDolarAbrilNumeros13.push(Number(pDolarAbril13[d1]))
        const pDolarAbrilTotal13 = pDolarAbrilNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAbril33 = NumAbril3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAbrilL33 = pDolarAbril33.length
        var pDolarAbrilNumeros33 = [];
        for (var d3 = 0; d3 < pDolarAbrilL33; d3++)
        pDolarAbrilNumeros33.push(Number(pDolarAbril33[d3]))
        const pDolarAbrilTotal33 = pDolarAbrilNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAbril3 = (pDolarAbrilTotal13+pDolarAbrilTotal33)

        const LAbril3= NumAbril3.length
    
    
        //Maio
        const pReaisMaio13 = NumMaio3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisMaioL13 = pReaisMaio13.length
        var pReaisMaioNumeros13 = [];
        for (var r1 = 0; r1 < pReaisMaioL13; r1++)
        pReaisMaioNumeros13.push(Number(pReaisMaio13[r1]))
        const pReaisMaioTotal13 = pReaisMaioNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisMaio33 = NumMaio3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisMaioL33 = pReaisMaio33.length
        var pReaisMaioNumeros33 = [];
        for (var r3 = 0; r3 < pReaisMaioL33; r3++)
        pReaisMaioNumeros33.push(Number(pReaisMaio33[r3]))
        const pReaisMaioTotal33 = pReaisMaioNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisMaio3 = (pReaisMaioTotal13+pReaisMaioTotal33)
    
        const pDolarMaio13 = NumMaio3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarMaioL13 = pDolarMaio13.length
        var pDolarMaioNumeros13 = [];
        for (var d1 = 0; d1 < pDolarMaioL13; d1++)
        pDolarMaioNumeros13.push(Number(pDolarMaio13[d1]))
        const pDolarMaioTotal13 = pDolarMaioNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarMaio33 = NumMaio3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarMaioL33 = pDolarMaio33.length
        var pDolarMaioNumeros33 = [];
        for (var d3 = 0; d3 < pDolarMaioL33; d3++)
        pDolarMaioNumeros33.push(Number(pDolarMaio33[d3]))
        const pDolarMaioTotal33 = pDolarMaioNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarMaio3 = (pDolarMaioTotal13+pDolarMaioTotal33)

        const LMaio3= NumMaio3.length
    
        //Junho
        const pReaisJunho13 = NumJunho3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJunhoL13 = pReaisJunho13.length
        var pReaisJunhoNumeros13 = [];
        for (var r1 = 0; r1 < pReaisJunhoL13; r1++)
        pReaisJunhoNumeros13.push(Number(pReaisJunho13[r1]))
        const pReaisJunhoTotal13 = pReaisJunhoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJunho33 = NumJunho3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJunhoL33 = pReaisJunho33.length
        var pReaisJunhoNumeros33 = [];
        for (var r3 = 0; r3 < pReaisJunhoL33; r3++)
        pReaisJunhoNumeros33.push(Number(pReaisJunho33[r3]))
        const pReaisJunhoTotal33 = pReaisJunhoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJunho3 = (pReaisJunhoTotal13+pReaisJunhoTotal33)
    
        const pDolarJunho13 = NumJunho3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJunhoL13 = pDolarJunho13.length
        var pDolarJunhoNumeros13 = [];
        for (var d1 = 0; d1 < pDolarJunhoL13; d1++)
        pDolarJunhoNumeros13.push(Number(pDolarJunho13[d1]))
        const pDolarJunhoTotal13 = pDolarJunhoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJunho33 = NumJunho3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJunhoL33 = pDolarJunho33.length
        var pDolarJunhoNumeros33 = [];
        for (var d3 = 0; d3 < pDolarJunhoL33; d3++)
        pDolarJunhoNumeros33.push(Number(pDolarJunho33[d3]))
        const pDolarJunhoTotal33 = pDolarJunhoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJunho3 = (pDolarJunhoTotal13+pDolarJunhoTotal33)
        
        const LJunho3= NumJunho3.length

        //Julho
        const pReaisJulho13 = NumJulho3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisJulhoL13 = pReaisJulho13.length
        var pReaisJulhoNumeros13 = [];
        for (var r1 = 0; r1 < pReaisJulhoL13; r1++)
        pReaisJulhoNumeros13.push(Number(pReaisJulho13[r1]))
        const pReaisJulhoTotal13 = pReaisJulhoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisJulho33 = NumJulho3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisJulhoL33 = pReaisJulho33.length
        var pReaisJulhoNumeros33 = [];
        for (var r3 = 0; r3 < pReaisJulhoL33; r3++)
        pReaisJulhoNumeros33.push(Number(pReaisJulho33[r3]))
        const pReaisJulhoTotal33 = pReaisJulhoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisJulho3 = (pReaisJulhoTotal13+pReaisJulhoTotal33)
    
        const pDolarJulho13 = NumJulho3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarJulhoL13 = pDolarJulho13.length
        var pDolaroJulhoNumeros13 = [];
        for (var d1 = 0; d1 < pDolarJulhoL13; d1++)
        pDolaroJulhoNumeros13.push(Number(pDolarJulho13[d1]))
        const pDolarJulhoTotal13 = pDolaroJulhoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarJulho33 = NumJulho3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarJulhoL33 = pDolarJulho33.length
        var pDolaroJulhoNumeros33 = [];
        for (var d3 = 0; d3 < pDolarJulhoL33; d3++)
        pDolaroJulhoNumeros33.push(Number(pDolarJulho33[d3]))
        const pDolarJulhoTotal33 = pDolaroJulhoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarJulho3 = (pDolarJulhoTotal13+pDolarJulhoTotal33)

        const LJulho3= NumJulho3.length
        
        //Agosto
        const pReaisAgosto13 = NumAgosto3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisAgostoL13 = pReaisAgosto13.length
        var pReaisAgostoNumeros13 = [];
        for (var r1 = 0; r1 < pReaisAgostoL13; r1++)
        pReaisAgostoNumeros13.push(Number(pReaisAgosto13[r1]))
        const pReaisAgostoTotal13 = pReaisAgostoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisAgosto33 = NumAgosto3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisAgostoL33 = pReaisAgosto33.length
        var pReaisAgostoNumeros33 = [];
        for (var r3 = 0; r3 < pReaisAgostoL33; r3++)
        pReaisAgostoNumeros33.push(Number(pReaisAgosto33[r3]))
        const pReaisAgostoTotal33 = pReaisAgostoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisAgosto3 = (pReaisAgostoTotal13+pReaisAgostoTotal33)
    
        const pDolarAgosto13 = NumAgosto3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarAgostoL13 = pDolarAgosto13.length
        var pDolarAgostoNumeros13 = [];
        for (var d1 = 0; d1 < pDolarAgostoL13; d1++)
        pDolarAgostoNumeros13.push(Number(pDolarAgosto13[d1]))
        const pDolarAgostoTotal13 = pDolarAgostoNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarAgosto33 = NumAgosto3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarAgostoL33 = pDolarAgosto33.length
        var pDolarAgostoNumeros33 = [];
        for (var d3 = 0; d3 < pDolarAgostoL33; d3++)
        pDolarAgostoNumeros33.push(Number(pDolarAgosto33[d3]))
        const pDolarAgostoTotal33 = pDolarAgostoNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarAgosto3 = (pDolarAgostoTotal13+pDolarAgostoTotal33)

        const LAgosto3= NumAgosto3.length
        
        //Setembro
        const pReaisSetembro13 = NumSetembro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisSetembroL13 = pReaisSetembro13.length
        var pReaisSetembroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisSetembroL13; r1++)
        pReaisSetembroNumeros13.push(Number(pReaisSetembro13[r1]))
        const pReaisSetembroTotal13 = pReaisSetembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisSetembro33 = NumSetembro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisSetembroL33 = pReaisSetembro33.length
        var pReaisSetembroNumeros33 = [];
        for (var r3 = 0; r3 < pReaisSetembroL33; r3++)
        pReaisSetembroNumeros33.push(Number(pReaisSetembro33[r3]))
        const pReaisSetembroTotal33 = pReaisSetembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisSetembro3= (pReaisSetembroTotal13+pReaisSetembroTotal33)
    
        const pDolarSetembro13 = NumSetembro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarSetembroL13 = pDolarSetembro13.length
        var pDolarSetembroNumeros13 = [];
        for (var d1 = 0; d1 < pDolarSetembroL13; d1++)
        pDolarSetembroNumeros13.push(Number(pDolarSetembro13[d1]))
        const pDolarSetembroTotal13 = pDolarSetembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarSetembro33 = NumSetembro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarSetembroL33 = pDolarSetembro33.length
        var pDolarSetembroNumeros33 = [];
        for (var d3 = 0; d3 < pDolarSetembroL33; d3++)
        pDolarSetembroNumeros33.push(Number(pDolarSetembro33[d3]))
        const pDolarSetembroTotal33 = pDolarSetembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarSetembro3 = (pDolarSetembroTotal13+pDolarSetembroTotal33)

        const LSetembro3= NumSetembro3.length
        
        //Outubro
        const pReaisOutubro13 = NumOutubro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisOutubroL13 = pReaisOutubro13.length
        var pReaisOutubroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisOutubroL13; r1++)
        pReaisOutubroNumeros13.push(Number(pReaisOutubro13[r1]))
        const pReaisOutubroTotal13 = pReaisOutubroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisOutubro33 = NumOutubro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisOutubroL33 = pReaisOutubro33.length
        var pReaisOutubroNumeros33 = [];
        for (var r3 = 0; r3 < pReaisOutubroL33; r3++)
        pReaisOutubroNumeros33.push(Number(pReaisOutubro33[r3]))
        const pReaisOutubroTotal33 = pReaisOutubroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisOutubro3 = (pReaisOutubroTotal13+pReaisOutubroTotal33)
    
        const pDolarOutubro13 = NumOutubro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarOutubroL13 = pDolarOutubro13.length
        var pDolarOutubroNumeros13 = [];
        for (var d1 = 0; d1 < pDolarOutubroL13; d1++)
        pDolarOutubroNumeros13.push(Number(pDolarOutubro13[d1]))
        const pDolarOutubroTotal13 = pDolarOutubroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarOutubro33 = NumOutubro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarOutubroL33 = pDolarOutubro33.length
        var pDolarOutubroNumeros33 = [];
        for (var d3 = 0; d3 < pDolarOutubroL33; d3++)
        pDolarOutubroNumeros33.push(Number(pDolarOutubro33[d3]))
        const pDolarOutubroTotal33 = pDolarOutubroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarOutubro3 = (pDolarOutubroTotal13+pDolarOutubroTotal33)

        const LOutubro3= NumOutubro3.length
        
        //Novembro
        const pReaisNovembro13 = NumNovembro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisNovembroL13 = pReaisNovembro13.length
        var pReaisNovembroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisNovembroL13; r1++)
        pReaisNovembroNumeros13.push(Number(pReaisNovembro13[r1]))
        const pReaisNovembroTotal13 = pReaisNovembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisNovembro33 = NumNovembro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisNovembroL33 = pReaisNovembro33.length
        var pReaisNovembroNumeros33 = [];
        for (var r3 = 0; r3 < pReaisNovembroL33; r3++)
        pReaisNovembroNumeros33.push(Number(pReaisNovembro33[r3]))
        const pReaisNovembroTotal33 = pReaisNovembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisNovembro3 = (pReaisNovembroTotal13+pReaisNovembroTotal33)
    
        const pDolarNovembro13 = NumNovembro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarNovembroL13 = pDolarNovembro13.length
        var pDolarNovembroNumeros13 = [];
        for (var d1 = 0; d1 < pDolarNovembroL13; d1++)
        pDolarNovembroNumeros13.push(Number(pDolarNovembro13[d1]))
        const pDolarNovembroTotal13 = pDolarNovembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarNovembro33 = NumNovembro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarNovembroL33 = pDolarNovembro33.length
        var pDolarNovembroNumeros33 = [];
        for (var d3 = 0; d3 < pDolarNovembroL33; d3++)
        pDolarNovembroNumeros33.push(Number(pDolarNovembro33[d3]))
        const pDolarNovembroTotal33 = pDolarNovembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarNovembro3 = (pDolarNovembroTotal13+pDolarNovembroTotal33)

        const LNovembro3= NumNovembro3.length
        
        //Dezembro
        const pReaisDezembro13 = NumDezembro3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisDezembroL13 = pReaisDezembro13.length
        var pReaisDezembroNumeros13 = [];
        for (var r1 = 0; r1 < pReaisDezembroL13; r1++)
        pReaisDezembroNumeros13.push(Number(pReaisDezembro13[r1]))
        const pReaisDezembroTotal13 = pReaisDezembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisDezembro33 = NumDezembro3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisDezembroL33 = pReaisDezembro33.length
        var pReaisDezembroNumeros33 = [];
        for (var r3 = 0; r3 < pReaisDezembroL33; r3++)
        pReaisDezembroNumeros33.push(Number(pReaisDezembro33[r3]))
        const pReaisDezembroTotal33 = pReaisDezembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const ReaisDezembro3 = (pReaisDezembroTotal13+pReaisDezembroTotal33)
    
        const pDolarDezembro13 = NumDezembro3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarDezembroL13 = pDolarDezembro13.length
        var pDolarDezembroNumeros13 = [];
        for (var d1 = 0; d1 < pDolarDezembroL13; d1++)
        pDolarDezembroNumeros13.push(Number(pDolarDezembro13[d1]))
        const pDolarDezembroTotal13 = pDolarDezembroNumeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarDezembro33 = NumDezembro3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarDezembroL33 = pDolarDezembro33.length
        var pDolarDezembroNumeros33 = [];
        for (var d3 = 0; d3 < pDolarDezembroL33; d3++)
        pDolarDezembroNumeros33.push(Number(pDolarDezembro33[d3]))
        const pDolarDezembroTotal33 = pDolarDezembroNumeros33.reduce((acc, curr) => acc + curr, 0)
    
        const DolarDezembro3 = (pDolarDezembroTotal13+pDolarDezembroTotal33)

        const LDezembro3= NumDezembro3.length
    
       
           setProjetData({
               labels: meses,
               datasets:[
                {
                    label:`Quantidade - ${ano1}`,
                    data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
                    fill: false,
                    borderColor: '#3E3F3F',
                    backgroundColor: 'rgba(63,63,63,.5)',
                  },
                  {
                    label:`Quantidade - ${ano2}`,
                    data: [LJaneiro2, LFevereiro2, LMarco2, LAbril2, LMaio2, LJunho2, LJulho2, LAgosto2, LSetembro2, LOutubro2, LNovembro2, LDezembro2 ],
                    fill: false,
                    borderColor: '#545D5E',
                    backgroundColor: 'rgba(84,93,94,.5)',
                  },

                  {
                    label:`Quantidade - ${ano3}`,
                    data: [LJaneiro3, LFevereiro3, LMarco3, LAbril3, LMaio3, LJunho3, LJulho3, LAgosto3, LSetembro3, LOutubro3, LNovembro3, LDezembro3 ],
                    fill: false,
                    borderColor: '#889B9D',
                    backgroundColor: 'rgba(136,155,157,.5)',
                  },
                {
                 label:`Dólares - ${ano1}`,
                 data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
                 fill: false,
                 borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
               },

               {
                label:`Dólares - ${ano2}`,
                data: [DolarJaneiro2, DolarFevereiro2, DolarMarco2, DolarAbril2, DolarMaio2, DolarJunho2, DolarJulho2, DolarAgosto2, DolarSetembro2, DolarOutubro2, DolarNovembro2, DolarDezembro2 ],
                fill: false,
                borderColor: '#42ADA7',
               backgroundColor: 'rgba(66,173,167,.5)',
              },
              {
                label:`Dólares - ${ano3}`,
                data: [DolarJaneiro3, DolarFevereiro3, DolarMarco3, DolarAbril3, DolarMaio3, DolarJunho3, DolarJulho3, DolarAgosto3, DolarSetembro3, DolarOutubro3, DolarNovembro3, DolarDezembro3 ],
                fill: false,
                borderColor: '#11D5EB',
               backgroundColor: 'rgba(17,213,235,.5)',
              },
    
               {
                label:`Reais - ${ano1}`,
                data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
                fill: false,
                borderColor: '#FF9900',
                 backgroundColor: 'rgba(255,153,0,.5)',
              },

              {
                label:`Reais - ${ano2}`,
                data: [ReaisJaneiro2, ReaisFevereiro2, ReaisMarco2, ReaisAbril2, ReaisMaio2, ReaisJunho2, ReaisJulho2, ReaisAgosto2, ReaisSetembro2, ReaisOutubro2, ReaisNovembro2, ReaisDezembro2 ],
                fill: false,
                borderColor: '#FF876C',
                 backgroundColor: 'rgba(255,135,108,.5)',
              },
               
              {
                label:`Reais - ${ano3}`,
                data: [ReaisJaneiro3, ReaisFevereiro3, ReaisMarco3, ReaisAbril3, ReaisMaio3, ReaisJunho3, ReaisJulho3, ReaisAgosto3, ReaisSetembro3, ReaisOutubro3, ReaisNovembro3, ReaisDezembro3],
                fill: false,
                borderColor: '#FF5400',
                 backgroundColor: 'rgba(255,84,0,.5)',
              },
        
              ],
              
              
             })
             setGrafico3(true);
    }
    const handleChart4 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
    
            
        
    
        const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado' ){return val}})

        const pReaisPr113 = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisPr1L13 = pReaisPr113.length
        var pReaisPr1Numeros13 = [];
        for (var r1 = 0; r1 < pReaisPr1L13; r1++)
        pReaisPr1Numeros13.push(Number(pReaisPr113[r1]))
        const pReaisPr1Total13 = pReaisPr1Numeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisPr133 = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisPr1L33 = pReaisPr133.length
        var pReaisPr1Numeros33 = [];
        for (var r3 = 0; r3 < pReaisPr1L33; r3++)
        pReaisPr1Numeros33.push(Number(pReaisPr133[r3]))
        const pReaisPr1Total33 = pReaisPr1Numeros33.reduce((acc, curr) => acc + curr, 0)

        const ReaisPr13 = (pReaisPr1Total13+pReaisPr1Total33)

        const pDolarPr113 = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarPr1L13 = pDolarPr113.length
        var pDolarPr1Numeros13 = [];
        for (var d1 = 0; d1 < pDolarPr1L13; d1++)
        pDolarPr1Numeros13.push(Number(pDolarPr113[d1]))
        const pDolarPr1Total13 = pDolarPr1Numeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarPr133 = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarPr1L33 = pDolarPr133.length
        var pDolarPr1Numeros33 = [];
        for (var d3 = 0; d3 < pDolarPr1L33; d3++)
        pDolarPr1Numeros33.push(Number(pDolarPr133[d3]))
        const pDolarPr1Total33 = pDolarPr1Numeros33.reduce((acc, curr) => acc + curr, 0)

        const DolarPr13 = (pDolarPr1Total13+pDolarPr1Total33)

        const Lano1= projsAno.length
        

        const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado' ){return val}})

        const pReaisPr213 = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pReaisPr2L13 = pReaisPr213.length
        var pReaisPr2Numeros13 = [];
        for (var r1 = 0; r1 < pReaisPr2L13; r1++)
        pReaisPr2Numeros13.push(Number(pReaisPr213[r1]))
        const pReaisPr2Total13 = pReaisPr2Numeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pReaisPr233 = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pReaisPr2L33 = pReaisPr233.length
        var pReaisPr2Numeros33 = [];
        for (var r3 = 0; r3 < pReaisPr2L33; r3++)
        pReaisPr2Numeros33.push(Number(pReaisPr233[r3]))
        const pReaisPr2Total33 = pReaisPr2Numeros33.reduce((acc, curr) => acc + curr, 0)

        const ReaisPr23 = (pReaisPr2Total13+pReaisPr2Total33)

        const pDolarPr213 = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var pDolarPr2L13 = pDolarPr213.length
        var pDolarPr2Numeros13 = [];
        for (var d1 = 0; d1 < pDolarPr2L13; d1++)
        pDolarPr2Numeros13.push(Number(pDolarPr213[d1]))
        const pDolarPr2Total13 = pDolarPr2Numeros13.reduce((acc, curr) => acc + curr, 0)
            
        const pDolarPr233 = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var pDolarPr2L33 = pDolarPr233.length
        var pDolarPr2Numeros33 = [];
        for (var d3 = 0; d3 < pDolarPr2L33; d3++)
        pDolarPr2Numeros33.push(Number(pDolarPr233[d3]))
        const pDolarPr2Total33 = pDolarPr2Numeros33.reduce((acc, curr) => acc + curr, 0)

        const DolarPr23 = (pDolarPr2Total13+pDolarPr2Total33)

        const Lano2= projsAno2.length
       
        
       
           setProjetData({
               labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
               datasets:[
                {
                    label:`Quantidade`,
                    data: [Lano1, Lano2],
                    fill: true,
                    borderColor: '#3E3F3F',
                    backgroundColor: 'rgba(62,63,63,.5)',
                  },
                {
                 label:`Dólar`,
                 data: [DolarPr13, DolarPr23],
                 fill: true,
                 borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
               },
               {
                label:`Reais`,
                data: [ReaisPr13, ReaisPr23],
                fill: true,
                borderColor: '#FF9900',
                 backgroundColor: 'rgba(255,153,0,.5)',
              },
              ],
              
              
             })
             setGrafico4(true);
    }

    const handleChart5 = ()=>{

      setGrafico(false)
      setGrafico2(false)
      setGrafico3(false)
      setGrafico4(false)
      setGrafico5(false)
  
          
      
  
      const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado' ){return val}})

      const pReaisPr113 = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisPr1L13 = pReaisPr113.length
      var pReaisPr1Numeros13 = [];
      for (var r1 = 0; r1 < pReaisPr1L13; r1++)
      pReaisPr1Numeros13.push(Number(pReaisPr113[r1]))
      const pReaisPr1Total13 = pReaisPr1Numeros13.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisPr133 = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisPr1L33 = pReaisPr133.length
      var pReaisPr1Numeros33 = [];
      for (var r3 = 0; r3 < pReaisPr1L33; r3++)
      pReaisPr1Numeros33.push(Number(pReaisPr133[r3]))
      const pReaisPr1Total33 = pReaisPr1Numeros33.reduce((acc, curr) => acc + curr, 0)

      const ReaisPr13 = (pReaisPr1Total13+pReaisPr1Total33)

      const pDolarPr113 = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarPr1L13 = pDolarPr113.length
      var pDolarPr1Numeros13 = [];
      for (var d1 = 0; d1 < pDolarPr1L13; d1++)
      pDolarPr1Numeros13.push(Number(pDolarPr113[d1]))
      const pDolarPr1Total13 = pDolarPr1Numeros13.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarPr133 = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarPr1L33 = pDolarPr133.length
      var pDolarPr1Numeros33 = [];
      for (var d3 = 0; d3 < pDolarPr1L33; d3++)
      pDolarPr1Numeros33.push(Number(pDolarPr133[d3]))
      const pDolarPr1Total33 = pDolarPr1Numeros33.reduce((acc, curr) => acc + curr, 0)

      const DolarPr13 = (pDolarPr1Total13+pDolarPr1Total33)
      

      const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado' ){return val}})

      const pReaisPr213 = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisPr2L13 = pReaisPr213.length
      var pReaisPr2Numeros13 = [];
      for (var r1 = 0; r1 < pReaisPr2L13; r1++)
      pReaisPr2Numeros13.push(Number(pReaisPr213[r1]))
      const pReaisPr2Total13 = pReaisPr2Numeros13.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisPr233 = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisPr2L33 = pReaisPr233.length
      var pReaisPr2Numeros33 = [];
      for (var r3 = 0; r3 < pReaisPr2L33; r3++)
      pReaisPr2Numeros33.push(Number(pReaisPr233[r3]))
      const pReaisPr2Total33 = pReaisPr2Numeros33.reduce((acc, curr) => acc + curr, 0)

      const ReaisPr23 = (pReaisPr2Total13+pReaisPr2Total33)

      const pDolarPr213 = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarPr2L13 = pDolarPr213.length
      var pDolarPr2Numeros13 = [];
      for (var d1 = 0; d1 < pDolarPr2L13; d1++)
      pDolarPr2Numeros13.push(Number(pDolarPr213[d1]))
      const pDolarPr2Total13 = pDolarPr2Numeros13.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarPr233 = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarPr2L33 = pDolarPr233.length
      var pDolarPr2Numeros33 = [];
      for (var d3 = 0; d3 < pDolarPr2L33; d3++)
      pDolarPr2Numeros33.push(Number(pDolarPr233[d3]))
      const pDolarPr2Total33 = pDolarPr2Numeros33.reduce((acc, curr) => acc + curr, 0)

      const DolarPr23 = (pDolarPr2Total13+pDolarPr2Total33)


      const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado' ){return val}})

      const pReaisPr214 = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisPr2L14 = pReaisPr214.length
      var pReaisPr2Numeros14 = [];
      for (var r1 = 0; r1 < pReaisPr2L14; r1++)
      pReaisPr2Numeros14.push(Number(pReaisPr214[r1]))
      const pReaisPr2Total14 = pReaisPr2Numeros14.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisPr234 = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisPr2L34 = pReaisPr234.length
      var pReaisPr2Numeros34 = [];
      for (var r3 = 0; r3 < pReaisPr2L34; r3++)
      pReaisPr2Numeros34.push(Number(pReaisPr234[r3]))
      const pReaisPr2Total34 = pReaisPr2Numeros34.reduce((acc, curr) => acc + curr, 0)

      const ReaisPr24 = (pReaisPr2Total14+pReaisPr2Total34)

      const pDolarPr214 = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarPr2L14 = pDolarPr214.length
      var pDolarPr2Numeros14 = [];
      for (var d1 = 0; d1 < pDolarPr2L14; d1++)
      pDolarPr2Numeros14.push(Number(pDolarPr214[d1]))
      const pDolarPr2Total14= pDolarPr2Numeros14.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarPr234 = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarPr2L34 = pDolarPr234.length
      var pDolarPr2Numeros343 = [];
      for (var d3 = 0; d3 < pDolarPr2L34; d3++)
      pDolarPr2Numeros343.push(Number(pDolarPr234[d3]))
      const pDolarPr2Total34 = pDolarPr2Numeros343.reduce((acc, curr) => acc + curr, 0)

      const DolarPr24 = (pDolarPr2Total14+pDolarPr2Total34)

      const Lano1 = projsAno.length
      const Lano2= projsAno2.length
      const Lano3= projsAno3.length
     
      
     
         setProjetData({
             labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`,  `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
             datasets:[
                {
                    label:`Quantidade`,
                    data: [Lano1, Lano2, Lano3],
                    fill: true,
                    borderColor: '#3E3F3F',
                    backgroundColor: 'rgba(62,63,63,.5)',
                  },
              {
               label:`Dólar`,
               data: [DolarPr13, DolarPr23, DolarPr24],
               fill: true,
               borderColor: '#315F30',
              backgroundColor: 'rgba(49,95,48,.5)',
             },
             {
              label:`Reais`,
              data: [ReaisPr13, ReaisPr23, ReaisPr24],
              fill: true,
              borderColor: '#FF9900',
               backgroundColor: 'rgba(255,153,0,.5)',
            },
            ],
            
            
           })
        
       
          
             setGrafico5(true);
         }
    


  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Quantitativo e Faturamento de Projetos - Período</div>
        <div>
            <p>
                Tipo:
                <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setGrafico2(false); setGrafico3(false); setGrafico4(false);setGrafico5(false); setAno1(''); setAno2(''); setAno3(''); setDe1(''); setDe2('');setDe3(''); setAte1(''); setAte2(''); setAte3('')}}>
                <option value="">Selecione</option>
                <option value="Ano">Ano</option>
                <option value="Ano2">Dois Anos</option>
                <option value="Ano3">Três Anos</option>
                <option value="Periodo2">Dois Períodos</option>
                <option value="Periodo3">Três Períodos</option>
            </select>
            </p>
        </div>
        {tipo === 'Ano' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select defaultValue={'2025'} onChange={(e)=>{setAno1(e.target.value); setGrafico(false); setGrafico2(false); setGrafico3(false); setGrafico4(false); setGrafico5(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Ano2' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno1(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno2(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' && ano2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }

        {tipo === 'Ano3' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno1(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno2(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno3(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' && ano2 !== '' && ano3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Periodo2' &&
            <div className='tipo'>
            <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                    
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' &&
                <div><button onClick={handleChart4}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Periodo3' &&
           <div className='tipo'>
           <div className='selecionar'>
           <p>Selecione o 1° período:</p>
               <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
           </div>
           <div className='selecionar'>
               <p>Selecione o 2° período:</p>
               <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                   
           </div>
           <div className='selecionar'>
               <p>Selecione o 3° período:</p>
               <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value)}} /></p>
                   
           </div>
           {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' &&
               <div><button onClick={handleChart5}>Gerar Gráfico</button></div>
           }
           </div>
        }
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

    {grafico2 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico3 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico4 &&
        <div className="pageContent">
        <div className="pageChart">
          <BarChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico5 &&
        <div className="pageContent">
        <div className="pageChart">
          <BarChart chartData={projectData} />
        </div>
      </div>
      }
      
      </>     
    }
</div>
  )
}

export default QtdFatPeriodo